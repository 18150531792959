// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityPropertyGroupIdBuf } from '../entity/entity-property-group-id-buf.js';
import { EntityPropertyKeyIdBuf } from '../entity/entity-property-key-id-buf.js';


/**
 * Remember that this data is little endian, but the ordering is big endian.
 * So in order to parse this, you need to parse the group into a hex string,
 * then concat it with the keys transformed into a uuid. On js this is:
 * ```js
 * import { UUID } from uuidv7
 * new UUID(new UInt8Array(key))
 * ```
 */
export class EntityPropertyIdBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):EntityPropertyIdBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

group(obj?:EntityPropertyGroupIdBuf):EntityPropertyGroupIdBuf|null {
  return (obj || new EntityPropertyGroupIdBuf()).__init(this.bb_pos, this.bb!);
}

key(obj?:EntityPropertyKeyIdBuf):EntityPropertyKeyIdBuf|null {
  return (obj || new EntityPropertyKeyIdBuf()).__init(this.bb_pos + 4, this.bb!);
}

static sizeOf():number {
  return 20;
}

static createEntityPropertyIdBuf(builder:flatbuffers.Builder, group_id: number, key_key: number[]|null):flatbuffers.Offset {
  builder.prep(4, 20);
  builder.prep(1, 16);

  for (let i = 15; i >= 0; --i) {
    builder.writeInt8((key_key?.[i] ?? 0));

  }

  builder.prep(4, 4);
  builder.writeInt32(group_id);
  return builder.offset();
}

}
