import { Gesture } from "./BaseGesture"

export default function () {
  const { navigateToFile } = useNavigation()
  const { isSelectionEmpty, archiveSelectedFiles, clearSelected, selectedFiles, isSelectedSidebar, selectItemUpInSidebarView, selectItemDownInSidebarView } = useSelection()
  const { removeFromSidebar } = useSidebar()
  const { isEditingTextInput, gestureLayers } = useGestures()
  const { mainViewState, updateContentBoundsSync } = useMainView()
  const { myself } = useUser()
  const { peekState, openPeek, closePeek } = usePeek()

  class SelectionKeyboardShortcut extends Gesture {
    layer = gestureLayers.selectionKeyboardShortcut

    async onKeyDown(keyboard: KeyboardEvent) {
      const {
        selectItemRightInFolderView,
        selectItemLeftInFolderView,
        selectItemUpInFolderView,
        selectItemDownInFolderView,
      } = useSelection()

      if (isSelectionEmpty() || isEditingTextInput())
        return

      const files = selectedFiles()

      // TODO: check for permissions before deleting/moving
      if (["Backspace", "Delete"].includes(keyboard.key)) {
        if (!myself.value.archive)
          return this.console.warn("Cannot delete when logged out")

        this.console.log("Moving selected files to archive and removing from sidebar")
        removeFromSidebar(selectedFiles())
        archiveSelectedFiles()
        clearSelected()
        keyboard.preventDefault()
        return this.capture()
      }

      if ([" ", "Spacebar"].includes(keyboard.key)) {
        if (peekState.value.visible) {
          this.console.log("Closing file peek")
          closePeek()
          keyboard.preventDefault()
          return this.capture()
        }
        this.console.log("Spacebar pressed. Opening quick preview of file")
        openPeek()
        keyboard.preventDefault()
        return this.capture()
      }

      if (["Enter"].includes(keyboard.key)) {
        if (files.length !== 1) {
          this.console.warn("Cannot open two files at once, skipping")
          keyboard.preventDefault()
          return this.capture()
        }
        const file = files[0]
        closePeek()
        navigateToFile(file)
        clearSelected()
        keyboard.preventDefault()
        return this.capture()
      }

      if (["Escape"].includes(keyboard.key)) {
        if (peekState.value.visible) {
          this.console.log("Escape pressed. Closing file peek")
          closePeek()
          keyboard.preventDefault()
          return this.capture()
        }
        else {
          this.console.log("Escape pressed. Clearing selected files")
          clearSelected()
          keyboard.preventDefault()
          return this.capture()
        }
      }

      if (["ArrowLeft"].includes(keyboard.key)) {
        if (files.length !== 1) {
          this.console.warn("Cannot keyboard navigate with multiple items pressed")
          keyboard.preventDefault()
          return this.capture()
        }
        else if (isSelectedSidebar(files[0].fileId)) {
          this.console.warn("Ignoring right arrow key as we made a sidebar selection")
          keyboard.preventDefault()
          return this.capture()
        }
        else if (["folder", "search-results"].includes(mainViewState.value.state)) {
          this.console.log("Arrow left pressed, selecting item to left")
          updateContentBoundsSync()
          selectItemLeftInFolderView()
          keyboard.preventDefault()
          return this.capture()
        }
        // TODO: add kb nav for search results
      }

      if (["ArrowRight"].includes(keyboard.key)) {
        if (files.length !== 1) {
          this.console.warn("Cannot keyboard navigate with multiple items pressed")
          keyboard.preventDefault()
          return this.capture()
        }
        else if (isSelectedSidebar(files[0].fileId)) {
          this.console.warn("Ignoring right arrow key as we made a sidebar selection")
          keyboard.preventDefault()
          return this.capture()
        }
        else if (["folder", "search-results"].includes(mainViewState.value.state)) {
          this.console.log("Arrow right pressed, selecting item to right")
          updateContentBoundsSync()
          selectItemRightInFolderView()
          keyboard.preventDefault()
          return this.capture()
        }
      }

      if (["ArrowUp"].includes(keyboard.key)) {
        if (files.length !== 1) {
          this.console.warn("Cannot keyboard navigate with multiple items pressed")
          keyboard.preventDefault()
          return this.capture()
        }
        keyboard.preventDefault()
        if (isSelectedSidebar(files[0].fileId)) {
          this.console.log("Arrow up pressed in sidebar, selecting item above")
          selectItemUpInSidebarView()
          keyboard.preventDefault()
          return this.capture()
        }
        else if (["folder", "search-results"].includes(mainViewState.value.state)) {
          this.console.log("Arrow up pressed, selecting item above")
          updateContentBoundsSync()
          selectItemUpInFolderView()
          keyboard.preventDefault()
          return this.capture()
        }
        // TODO: add kb nav for search results
      }

      if (["ArrowDown"].includes(keyboard.key)) {
        if (files.length !== 1) {
          this.console.warn("Cannot keyboard navigate with multiple items pressed")
          keyboard.preventDefault()
          return this.capture()
        }
        keyboard.preventDefault()
        if (isSelectedSidebar(files[0].fileId)) {
          this.console.log("Arrow up pressed in sidebar, selecting item above")
          selectItemDownInSidebarView()
          keyboard.preventDefault()
          return this.capture()
        }
        else if (["folder", "search-results"].includes(mainViewState.value.state)) {
          this.console.log("Arrow down pressed, selecting item below")
          updateContentBoundsSync()
          selectItemDownInFolderView()
          keyboard.preventDefault()
          return this.capture()
        }
      }
    }
  }
  return new SelectionKeyboardShortcut()
}
