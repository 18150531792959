// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileBuf } from '../file/file-buf.js';
import { NotificationMessageIdBuf } from '../message/notification-message-id-buf.js';
import { NotificationSenderIdBuf } from '../message/notification-sender-id-buf.js';


export class FilesSharedWithSelfMessageDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FilesSharedWithSelfMessageDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFilesSharedWithSelfMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FilesSharedWithSelfMessageDatagramBuf):FilesSharedWithSelfMessageDatagramBuf {
  return (obj || new FilesSharedWithSelfMessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFilesSharedWithSelfMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FilesSharedWithSelfMessageDatagramBuf):FilesSharedWithSelfMessageDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FilesSharedWithSelfMessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

messageId(obj?:NotificationMessageIdBuf):NotificationMessageIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new NotificationMessageIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

senderId(obj?:NotificationSenderIdBuf):NotificationSenderIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new NotificationSenderIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

files(index: number, obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

filesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startFilesSharedWithSelfMessageDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addMessageId(builder:flatbuffers.Builder, messageIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, messageIdOffset, 0);
}

static addSenderId(builder:flatbuffers.Builder, senderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, senderIdOffset, 0);
}

static addFiles(builder:flatbuffers.Builder, filesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, filesOffset, 0);
}

static createFilesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startFilesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endFilesSharedWithSelfMessageDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // message_id
  builder.requiredField(offset, 6) // sender_id
  builder.requiredField(offset, 8) // files
  return offset;
}

}
