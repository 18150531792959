interface State {
  email: string
  password: string
  inviteCode: string
  newPassword: string
  verificationCode: string
  userId: string
}
let authState: Ref<State>
const console = useLogger("use-authentication", theme.colors.pink.hex)

function defaultAuthState(): State {
  return {
    email: "",
    password: "",
    newPassword: "",
    inviteCode: "",
    verificationCode: "",
    userId: "",
  }
}

function clearAuthState() {
  authState.value = defaultAuthState()
}

function isOnAuthPage() {
  const { path } = useRoute()
  return path === "/login" || path === "/signup" || path === "/verify"
}

async function signUp(request: SignupRequest) {
  const { api } = useCache()
  const { signup } = useTauri()

  let response: SignupResponse
  if (isDesktop())
    response = await signup(request)
  else
    response = await $fetch<SignupResponse>(api("/auth/signup"), {
      method: "POST",
      body: request,
    })
  console.debug("Sign-up response: ", response)
  authState.value.userId = response.userId
  await navigateTo({ name: "verify" })
}

async function verify(request: VerifyRequest) {
  const { navigateToOnboarding } = useNavigation()
  const { api, initAuthenticatedWebsocketConnection } = useCache()
  const { identify } = useAnalytics()
  const { verify } = useTauri()

  let response: LoginResponse
  if (isDesktop())
    // throw new Error('Desktop verification not implemented')
    response = await verify(request)
  else
    response = await $fetch<LoginResponse>(api("/auth/verify"), {
      method: "POST",
      body: request,
    })

  console.debug("Verify response:", response)
  identify(authState.value.userId, authState.value.email)
  navigateToOnboarding()
  initAuthenticatedWebsocketConnection()
}

async function resendVerification(request: NewVerificationCodeRequest) {
  const { resendVerification } = useTauri()
  if (isDesktop())
    await resendVerification(request)
  else
    await $fetch<LoginResponse>(api("/auth/resend-verification"), {
      method: "POST",
      body: request,
    })
  console.debug("Resent verification code")
}

async function logIn(request: LoginRequest) {
  const { api, initAuthenticatedWebsocketConnection } = useCache()
  const { login } = useTauri()
  const { identify } = useAnalytics()

  let response: LoginResponse
  if (isDesktop())
    response = await login(request)
  else
    response = await $fetch<LoginResponse>(api("/auth/login"), {
      method: "POST",
      body: request,
    })
  console.debug("Logged in!", response)
  identify(response.userId, authState.value.email)
  // On login we manually navigate to the home dir. We can't use the
  // `navigateToHome` method, since that assumes that we have already
  // completed the websocket setup process. In our case here, we are
  // likely not connected via websocket yet so we need to let the
  // after-login callback do its job.
  navigateTo("/home")
  initAuthenticatedWebsocketConnection()
}

async function logOut() {
  const { sendCacheLogout } = useCache()
  const { clearUserData } = useUser()
  const { resetFileSystemNavigationState } = useNavigation()

  // Eagerly navigate to login page
  navigateTo("/login")

  // Now we can reset the user data,
  clearUserData()
  resetFileSystemNavigationState()

  // Log out of the websocket connection to prevent more messages
  sendCacheLogout()

  // Now we log out via REST in order to unset the cookie. If this fails,
  // We will at least have deleted client data from above
  await $fetch(api("/auth/logout"), {
    method: "POST",
  })
  console.debug("Logged out")
}

async function forgotPassword(request: ForgotPasswordRequest) {
  const { api } = useCache()
  const { forgotPassword } = useTauri()

  if (isDesktop())
    await forgotPassword(request)
  else
    await $fetch(api("/auth/forgot-password"), {
      method: "POST",
      body: request,
    })
  console.debug("Sent forgot password request")
  await navigateTo("/change-password")
}

async function changePassword(request: ChangePasswordRequest) {
  const { navigateToHome } = useNavigation()
  const { api, initAuthenticatedWebsocketConnection } = useCache()
  const { identify } = useAnalytics()
  const { changePassword } = useTauri()

  let response: LoginResponse
  if (isDesktop())
    response = await changePassword(request)
  else
    response = await $fetch<LoginResponse>(api("/auth/change-password"), {
      method: "POST",
      body: request,
    })
  console.debug("Changed password and logged in!", response)
  identify(response.userId, authState.value.email)

  // Navigate to home first so that we get the right callback on user info received.
  navigateToHome()
  initAuthenticatedWebsocketConnection()
}

function toReadableError(error: any) {
  // On Tauri, the error will just be a string
  if (typeof error === "string")
    error = { statusCode: 401, data: error }
  if (!("statusCode" in error))
    return "An unknown error occurred"
  const status = error.statusCode
  if (status === 422)
    return "Please check the formatting of your input"
  const data: string = error.data || ""
  if (data.startsWith("4002"))
    return "That email is unverified, please sign up again"
  if (data.startsWith("4003"))
    return "Your password is incorrect, try again"
  if (data.startsWith("4004"))
    return "That e-mail is not registered"
  if (data.startsWith("4005"))
    return "That e-mail already exists, try logging in"
  if (data.startsWith("4006"))
    return "Your invite code is invalid"
  if (data.startsWith("4007"))
    return "The new password entered is invalid"
  if (data.startsWith("4008"))
    return "That e-mail is already verified, try logging in"
  return "Something went wrong, try again or contact us"
}

export default function () {
  authState = useState("user-data", defaultAuthState)

  return {
    authState,
    clearAuthState,
    logIn,
    signUp,
    verify,
    logOut,
    forgotPassword,
    resendVerification,
    changePassword,
    isOnAuthPage,
    toReadableError,
  }
}
