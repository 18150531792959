import * as flatbuffers from "flatbuffers"
import { InterfaceFile } from "~/classes/file"
import { addFileToBuf, createRequestIdBuf, parseFile, parseRequestId } from "~/utils/flatbuffers"
import { FileUpdateRequestDatagramBuf, RequestDatagramBuf } from "~/classes/generated/request"
import type { ErrorBuf } from "~/classes/generated/datagram"
import { DatagramBuf } from "~/classes/generated/datagram"
import type { FileUpdateResponseDatagramBuf } from "~/classes/generated/response"
import type { FileUpdateMessageDatagramBuf } from "~/classes/generated/message"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"
import { parseMaybeFile } from "~/utils/flatbuffers/file"

const console = useLogger("file/update")

export function sendFileUpdateRequest(request: FileUpdateRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  const file = addFileToBuf(fbb, request.file)
  FileUpdateRequestDatagramBuf.startFileUpdateRequestDatagramBuf(fbb)
  FileUpdateRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  FileUpdateRequestDatagramBuf.addFile(fbb, file)
  const updateRequest = FileUpdateRequestDatagramBuf.endFileUpdateRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addFileUpdate(fbb, updateRequest)
  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

/** Parse a Flatbuffers representation of a response */
export function handleFileUpdateResponseDatagramBuf(datagram: FileUpdateResponseDatagramBuf) {
  // TODO: get rid of datagram result, and find out a better way to
  // error out of this, since we need to send notifications
  onFileUpdateResponse({
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    file: parseMaybeFile(datagram.file()),
  })
}

export function handleFileUpdateMessageDatagramBuf(message: FileUpdateMessageDatagramBuf) {
  const file = parseFile(message.file()!)

  updateFileInView(new InterfaceFile(file))
}

export function onFileUpdateResponse(response: FileUpdateResponseDatagram) {
  if (response.error)
    return onFileUpdateErrored(response.error, response.requestId)
  if (!response.file)
    throw new Error("No file in update response")

  const iFile = InterfaceFile.from(response.file)
  console.debug("File updated", response.file)
  updateFileInView(iFile)
}

export function onFileUpdateErrored(
  error: DatagramError,
  _requestId: string,
) {
  const { notifyDatagramError } = useNotifications()

  return notifyDatagramError(error, "File update was unsuccessful")
}

export function onFileUpdateLocal(file: InterfaceFile) {
  updateFileInView(file)
}

function updateFileInView(file: InterfaceFile) {
  const { mainViewState, addFileToFolderView } = useMainView()
  const { navigationState } = useNavigation()

  // Update the folder, sidebar, path, preview, and search views
  // TODO: update sidebar state
  const folderViewFile = mainViewState.value.contents[file.fileId]
  if (folderViewFile)
    addFileToFolderView(folderViewFile.mergeWith(file))

  if (navigationState.value.file) {
    const pathViewFiles = navigationState.value.file?.path()
    if (pathViewFiles !== undefined) {
      for (const pathFile of pathViewFiles)
        if (pathFile.fileId === file.fileId)
          for (const prop of Object.values(file.properties))
            pathFile.properties[prop.propertyId] = prop
      // Explicitly assign to the object so that vue can know it was updated
      navigationState.value.file.setPath(pathViewFiles)
    }
  }
}
