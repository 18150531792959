import { uuidv7 } from "uuidv7"
import type * as flatbuffers from "flatbuffers"
import type { FileBuf } from "../generated/file"
import { Property, PropertyGroup, PropertySchema, hasGroup } from "../entity"
import { InterfaceFolderFile, InterfaceImageFile, InterfaceUnknownFile } from "."
import type { File } from "~/models/polyschema/File"
import { last } from "~/utils/helpers"
import { addFileToBuf, parseFile } from "~/utils/flatbuffers"

export class InterfaceFile {
  _file: File

  constructor(file: File) {
    this._file = file
  }

  static from(file: File) {
    const iFile = new InterfaceFile(file)
    const mimeType = iFile.mimeType()
    if (!mimeType)
      throw new Error("Cannot determine file type")
    if (mimeType === "inode/directory")
      return new InterfaceFolderFile(file)
    if (mimeType.startsWith("image/"))
      return new InterfaceImageFile(file)
    return new InterfaceUnknownFile(file)
  }

  static fromBuf(buf: FileBuf): InterfaceFile {
    return new InterfaceFile(parseFile(buf))
  }

  addToBuf(builder: flatbuffers.Builder) {
    return addFileToBuf(builder, this._file)
  }

  /** When we don't have enough info about a file to type it */
  static untyped(file: File) {
    return new InterfaceFile(file)
  }

  /** When we don't have enough info about a file to type it */
  static blank(fileId?: string) {
    fileId = fileId ?? this.fakeClientId()
    return InterfaceFile.untyped({ fileId, properties: {} })
  }

  get selection() {
    if (this._file.selection)
      return this._file.selection
    else return { everything: true } as EntityPropertySelection
  }

  set selection(selection: EntityPropertySelection) {
    if (selection.everything)
      this._file.selection = undefined
    this._file.selection = selection
  }

  get properties() {
    return this._file.properties
  }

  property(id: Property): FileProperty | undefined {
    return this._file.properties?.[id]
  }

  /**
   * We assign fake client IDs to files as their temporary file ID when we do actions
   * on the client before the server has provided us with a file ID. These fake IDs
   * should never be sent to the server and should be removed by the cache, which only
   * keeps them until the files have been confirmed by the server, at which point they
   * must be promptly deleted. If the cache notices a client ID in a file with an
   * older DB version, it should delete the entry as that means that the file was
   * never committed. If it notices a client ID in a file with the present DB version,
   * the file is automatically marked as pending (as that means an update should be
   * on its way)
   */
  static fakeClientId() {
    return `5caff01d-${uuidv7()}`
  }

  static toUrl(s3Uri?: string) {
    // TODO: replace with a placeholder or loading?
    if (!s3Uri)
      return ""
    return s3Uri
      // // This seems to not work on localhost
      // .replace("s3://store-with-poly-local", "http://s3.us-west-2.localhost.localstack.cloud:4566/store-with-poly-local")
      .replace("s3://store-with-poly-local", "http://s3.localhost.localstack.cloud:4566/store-with-poly-local")
      .replace("s3://store-with-poly-dev", "https://d6294mhay1ctn.cloudfront.net")
      .replace("s3://store-with-poly-prod", "https://d3b8el8c5kqwdk.cloudfront.net")
  }

  /**
   * @returns whether the file doesn't have a file id yet
   */
  isFake() {
    return this.fileId.startsWith("5caff01d")
  }

  /**
   * We place "blank" files in place of real ones in the UI while we are retrieving data, if
   * we know the file Id already. One place we use this is in the sidebar.
   * @returns whether this file is a blank placeholder for further data retrieval
   */
  isBlank() {
    return Object.keys(this._file.properties).length === 0
  }

  /**
   * Staleness refers to whether our locally cached copy of the file is out of date, such as
   * when we are retrieving a new version from the server. Technically, only properties can
   * be stale, but for this function we just return true if any are stale
   *
   * @returns whether any of the properties of this file are stale
   */
  isStale() {
    for (const property of Object.values(this._file.properties))
      if (property!.stale)
        return true
    return false
  }

  /**
   * Pending is a property which is at a higher version than the server, waiting to push its
   * updates to the server. This function returns true if any of the properties are pending.
   *
   * @returns whether any of the properties of this file are pending
   */
  isPending() {
    for (const property of Object.values(this._file.properties))
      if (property!.pending)
        return true
    return false
  }

  /** Gets the parent id by either looking for the parent property or looking at the path */
  parentId(): string {
    const property = this.property(Property.PARENT_ID)
    if (property && property.value.type === PropertySchema.FileIdValue)
      return property.value.fileId
    console.warn("No parent id property, looking in path for parent id")
    const parentId = this._file.path?.[0]?.fileId
    if (parentId)
      return parentId
    throw new Error("Cannot retrieve parent id of file")
  }

  path(): InterfaceFolderFile[] | undefined {
    if (!this._file.path)
      return
    return this._file.path.map(f => new InterfaceFolderFile(f))
  }

  setPath(path: InterfaceFile[]) {
    this._file.path = path.map(f => f._file)
  }

  setPathRaw(path: FilePath) {
    this._file.path = path
  }

  rootFolder(): InterfaceFolderFile | undefined {
    if (this._file.path === undefined)
      return
    if (!this._file.path.length)
      return new InterfaceFolderFile(this._file)
    return new InterfaceFolderFile(last(this._file.path))
  }

  /** Gets the shared shared folder that owns this file, if any */
  rootSharedFolderId(): string | undefined {
    const home = this.property(Property.SHARED_FOLDER_HOME_OWNER_ID)
    if (home && home.value.type === PropertySchema.SharedFolderIdValue)
      return home.value.sharedFolderId
    const archive = this.property(Property.SHARED_FOLDER_ARCHIVE_OWNER_ID)
    if (archive && archive.value.type === PropertySchema.SharedFolderIdValue)
      return archive.value.sharedFolderId
    const path = this.path()
    if (path && path.length)
      return last(path).rootSharedFolderId()
  }

  get fileId(): string {
    return this._file.fileId
  }

  set fileId(newId: string) {
    this._file.fileId = newId
  }

  clientUri(): string | undefined {
    return InterfaceFile.toUrl(this.uri())
  }

  uri() {
    const property = this.property(Property.URI)
    // TODO: check for the item in the selection
    if (!property)
      throw new Error("no URI property present")
    if (property.value.type === PropertySchema.S3BlobUriValue)
      return property.value.s3BlobUri
    throw new Error("Property is not a uri type property")
  }

  setUri(s3BlobUri: string) {
    if (!this._file.properties[Property.URI])
      throw new Error("No uri property")
    this._file.properties[Property.URI].value = {
      type: PropertySchema.S3BlobUriValue,
      s3BlobUri,
    }
  }

  name(): string | undefined {
    if (this.isHomeFolder())
      return "Home"
    if (this.isArchiveFolder())
      return "Archive"
    const property = this.property(Property.NAME)
    if (!property)
      return
    if (property.value.type === PropertySchema.NonNullableStringValue)
      return property.value.nonNullableString
    throw new Error("Schema invalid for name")
  }

  createdAt(): Date | undefined {
    const property = this.property(Property.CREATED_AT)
    if (!property)
      return
    if (property.value.type === PropertySchema.UtcTimestampMillisValue) {
      return new Date(property.value.utcTimestampMillis)
    }
    throw new Error("Schema invalid for created at")
  }

  modifiedAt(): Date | undefined {
    const property = this.property(Property.MODIFIED_AT)
    if (!property)
      return
    if (property.value.type === PropertySchema.UtcTimestampMillisValue) {
      return new Date(property.value.utcTimestampMillis)
    }
    throw new Error("Schema invalid for modified at")
  }

  mimeType(): string | undefined {
    const property = this.property(Property.MIME_TYPE)
    if (!property)
      return
    if (property.value.type === PropertySchema.MimeTypeValue)
      return property.value.mimeType
    console.log(property.value)
    throw new Error("Schema invalid for name")
  }

  isFolder(): boolean {
    if (this.isHomeFolder() || this.isArchiveFolder())
      return true
    const property = this.property(Property.MIME_TYPE)
    if (property && property.value.type === PropertySchema.MimeTypeValue)
      return property.value.mimeType === "inode/directory"

    console.error("Missing or invalid property", property)
    throw new Error("Mime type property missing or invalid, can't know if this is a folder")
  }

  isImage(): boolean {
    const property = this.property(Property.MIME_TYPE)
    if (property && property.value.type === PropertySchema.MimeTypeValue)
      return property.value.mimeType.startsWith("image/")
    console.warn("Unable to know if this file is an image")
    return false
  }

  isHomeFolder(): boolean {
    return !!this._file.properties[Property.HOME_DIR_OWNER_ID]
  }

  isArchiveFolder(): boolean {
    return !!this._file.properties[Property.ARCHIVE_DIR_OWNER_ID]
  }

  isSpaceHomeFolder(): boolean {
    return !!this._file.properties[Property.SHARED_FOLDER_HOME_OWNER_ID]
  }

  isSpaceArchiveFolder(): boolean {
    return !!this._file.properties[Property.SHARED_FOLDER_ARCHIVE_OWNER_ID]
  }

  size(): number | undefined {
    const property = this.property(Property.SIZE)
    if (!property)
      return
    if (property.value.type === PropertySchema.SizeBytesValue)
      return property.value.sizeBytes
    console.error("No size property or its malformed", property)
  }

  hasClientId() {
    return !!this.property(Property.CLIENT_ID)
  }

  clientId(): string | undefined {
    const property = this.property(Property.CLIENT_ID)
    if (!property)
      return
    if (property.value.type === PropertySchema.FileIdValue)
      return property.value.fileId
    console.error("client id property is malformed", property)
  }

  isPermanentlyDeleted(): boolean {
    return !!this._file.properties[Property.IS_PERMANENTLY_DELETED]
  }

  imageWidth() {
    const property = this.property(Property.WIDTH)
    if (!property)
      return
    if (property && property.value.type === PropertySchema.PositiveIntegerValue)
      return property.value.positiveInteger
    console.error("image width is malformed", property)
  }

  imageHeight() {
    const property = this.property(Property.HEIGHT)
    if (!property)
      return
    if (property && property.value.type === PropertySchema.PositiveIntegerValue)
      return property.value.positiveInteger
    console.error("image height is malformed", property)
  }

  rating() {
    const property = this.property(Property.FILE_RATING)
    if (!property)
      return
    if (property && property.value.type === PropertySchema.PositiveFloatValue)
      return property.value.PositiveFloat
    console.error("File rating is malformed", property)
  }

  /** returns all tag properties, minus the deleted ones */
  tagProperties() {
    return Object
      .values(this._file.properties)
      .filter(p => hasGroup(p!.propertyId as Property, PropertyGroup.FILE_TAG_META))
      .filter(p => !p!.deleted) as FileProperty[]
  }

  tags() {
    const tags = this
      .tagProperties()
      .map(p => p!.value.type === PropertySchema.HashableTagValue
        ? p!.value.hashableTag
        : undefined)
      .filter(t => t) as string[]
    return new Set(tags)
  }

  sharedWithProperties() {
    return Object
      .values(this._file.properties)
      .filter(p => hasGroup(p!.propertyId as Property, PropertyGroup.FILE_SHARING_USERS_META))
  }

  isContainedBy(folderId: string) {
    if (!this._file.path)
      throw new Error("Cannot determine contained-by question without path")
    const parents = this._file.path.map(f => f.fileId)
    return parents.includes(folderId)
  }

  mergeWith(other: InterfaceFile) {
    for (const prop of Object.values(other._file.properties))
      this._file.properties[prop!.propertyId] = prop
    if (other._file.path?.length)
      this._file.path = other._file.path
    return this
  }

  addProperty(property: FileProperty) {
    this._file.properties[property.propertyId] = property
  }

  /** Returns true if the file has a certain property or virtual property */
  hasProperty(property: string) {
    switch (property as Property) {
      case (Property.VIRTUAL_DIMENSIONS): return this.isImage()
      case (Property.VIRTUAL_PATH): return true
      case (Property.VIRTUAL_PREVIEW): return true
      case (Property.VIRTUAL_SEARCH_RELEVANCE): return false
      case (Property.VIRTUAL_TAGS): return true // TODO: look in selection
    }
    return this.properties[property] !== undefined
  }

  hasGenerativeMetadata() {
    const properties = [
      Property.GENERATION_PROMPT,
      Property.GENERATION_NEGATIVE_PROMPT,
      Property.GENERATION_SOURCE,
      Property.GENERATION_MODEL,
      Property.GENERATION_MODEL_VERSION,
      Property.GENERATION_SEED,
    ]
    for (const propertyId of properties)
      if (this.property(propertyId))
        return true
    return false
  }

  exif() {
    return this.property(Property.FILE_EXIF_JSON)
  }

  thumbnail(propertyId: Property) {
    const property = this.property(propertyId)
    if (!property)
      return
    if (property && property.value.type === PropertySchema.S3ThumbnailUriValue)
      return InterfaceFile.toUrl(property.value.s3ThumbnailUri)
    console.error("Malformed URI", property)
    throw new Error("Malformed thumbnail Uri")
  }

  icon64CropUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_CROP_64X64)
  }

  icon512CropUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_CROP_512X512)
  }

  icon192CropUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_CROP_192X192)
  }

  icon1536CropUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_CROP_1536X1536)
  }

  icon4608CropUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_CROP_4608X4608)
  }

  icon64NativeUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_NATIVE_64_MAX)
  }

  icon512NativeUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_NATIVE_512_MAX)
  }

  icon192NativeUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_NATIVE_192_MAX)
  }

  icon1536NativeUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_NATIVE_1536_MAX)
  }

  icon4608NativeUri() {
    return this.thumbnail(Property.IMG_THUMBNAIL_NATIVE_4608_MAX)
  }
}
