import type { InterfaceFolderFile } from "../file/InterfaceFolderFile"
import { Gesture } from "./BaseGesture"

export default function () {
  const { getMainViewFileFromEvent, gestureState, gestureLayers } = useGestures()
  const { moveSelectedFiles } = useSelection()
  class MainViewFileDropToMove extends Gesture {
    layer = gestureLayers.mainView

    reset() {
      this.switchMode("none")
    }

    async onMouseUp(mouse: MouseEvent) {
      if (gestureState.value.gesture !== "drag-file")
        return

      // if we dropped on another file
      const droppedOn = getMainViewFileFromEvent(mouse)
      if (droppedOn && droppedOn.isFolder()) {
        this.debug("Dropped on a folder!", droppedOn)
        this.capture()
        this.switchMode("none")
        return moveSelectedFiles(droppedOn as InterfaceFolderFile)
      }
      // This means we're just dropping into the page itself. This
      // could mean we just want to cancel the drag, or that we are
      // dragging in a file from the sidebar, or another window/tab?
    }
  }
  return new MainViewFileDropToMove()
}
