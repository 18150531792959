// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class SearchV1NumberPropertySearchFilterBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1NumberPropertySearchFilterBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1NumberPropertySearchFilterBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf {
  return (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1NumberPropertySearchFilterBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

matches():string|null
matches(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
matches(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

greaterThan():string|null
greaterThan(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
greaterThan(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

lessThan():string|null
lessThan(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
lessThan(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startSearchV1NumberPropertySearchFilterBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addMatches(builder:flatbuffers.Builder, matchesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, matchesOffset, 0);
}

static addGreaterThan(builder:flatbuffers.Builder, greaterThanOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, greaterThanOffset, 0);
}

static addLessThan(builder:flatbuffers.Builder, lessThanOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, lessThanOffset, 0);
}

static endSearchV1NumberPropertySearchFilterBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSearchV1NumberPropertySearchFilterBuf(builder:flatbuffers.Builder, matchesOffset:flatbuffers.Offset, greaterThanOffset:flatbuffers.Offset, lessThanOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchV1NumberPropertySearchFilterBuf.startSearchV1NumberPropertySearchFilterBuf(builder);
  SearchV1NumberPropertySearchFilterBuf.addMatches(builder, matchesOffset);
  SearchV1NumberPropertySearchFilterBuf.addGreaterThan(builder, greaterThanOffset);
  SearchV1NumberPropertySearchFilterBuf.addLessThan(builder, lessThanOffset);
  return SearchV1NumberPropertySearchFilterBuf.endSearchV1NumberPropertySearchFilterBuf(builder);
}
}
