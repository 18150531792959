import { InterfaceFile } from "~/classes/file/InterfaceFile"

interface State {
  visible: boolean
  target?: ContextMenuTarget
  type?: ContextMenuType
  x: number
  y: number
}
let contextMenuState: Ref<State>

function isContextMenuVisible() {
  return contextMenuState.value.visible
}

function showContextMenu(
  mouse: MouseEvent,
  type: ContextMenuType,
  target?: ContextMenuTarget,
  xPos?: number,
  yPos?: number,
) {
  const { capture } = useAnalytics()
  contextMenuState.value = {
    target,
    type,
    visible: true,
    x: xPos ?? mouse.pageX,
    y: yPos ?? mouse.pageY,
  }
  capture("user_opened_context_menu")
}

function hideContextMenu() {
  contextMenuState.value.visible = false
  contextMenuState.value.type = undefined
}

function createNewFolderInCurrentView() {
  const { createFolder } = useFileSystem()
  const { navigationState } = useNavigation()

  const folder = navigationState.value.file as InterfaceFolderFile
  const name = "New Folder"
  if (!folder || !folder.isFolder)
    return console.error("Not in a folder")
  createFolder(folder, name)
  // now we need to remember to hide
}

function uploadHere() {
  const { uploadFromFilePicker } = useTransferManager()
  uploadFromFilePicker()
}

function moveToArchive() {
  const { moveFiles } = useFileSystem()
  const { myself } = useUser()
  const file = contextMenuState.value.target
  if (!file)
    throw new Error("Move to archive has no target")
  if (file instanceof InterfaceFile) {
    if (!myself.value.archive)
      throw new Error("User not logged in or no archive folder")
    moveFiles([file], myself.value.archive)
    return hideContextMenu()
  }
  return console.error("Non-file object cant move to archive")
}

function permanentlyDelete() {
  const { permanentlyDeleteFiles } = useFileSystem()
  const file = contextMenuState.value.target
  if (!file)
    throw new Error("Move to archive has no target")
  if (file instanceof InterfaceFile) {
    permanentlyDeleteFiles([file])
  }
  return console.error("Non-file object cant be permanently deleted")
}

export default function () {
  contextMenuState = useState("context-menu-state", () => ({
    visible: false,
    x: 0,
    y: 0,
  }))

  return {
    contextMenuState,
    isContextMenuVisible,
    showContextMenu,
    hideContextMenu,
    createNewFolderInCurrentView,
    uploadHere,
    moveToArchive,
    permanentlyDelete,
  }
}
