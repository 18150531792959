// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class SearchV1InFolderMatcherBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1InFolderMatcherBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1InFolderMatcherBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1InFolderMatcherBuf):SearchV1InFolderMatcherBuf {
  return (obj || new SearchV1InFolderMatcherBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1InFolderMatcherBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1InFolderMatcherBuf):SearchV1InFolderMatcherBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1InFolderMatcherBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

folderIds(index: number):string
folderIds(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
folderIds(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

folderIdsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startSearchV1InFolderMatcherBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addFolderIds(builder:flatbuffers.Builder, folderIdsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, folderIdsOffset, 0);
}

static createFolderIdsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startFolderIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endSearchV1InFolderMatcherBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // folder_ids
  return offset;
}

static createSearchV1InFolderMatcherBuf(builder:flatbuffers.Builder, folderIdsOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchV1InFolderMatcherBuf.startSearchV1InFolderMatcherBuf(builder);
  SearchV1InFolderMatcherBuf.addFolderIds(builder, folderIdsOffset);
  return SearchV1InFolderMatcherBuf.endSearchV1InFolderMatcherBuf(builder);
}
}
