interface SelectionInfo {
  file?: InterfaceFile
  search?: InterfaceSearch
  location: SelectionLocation // represents where the selection was made
}

interface State {
  selected: Record<string, SelectionInfo>
}
let selectionState: Ref<State>

function selectedFiles() {
  return Object.values(selectionState.value.selected).map(i => i.file).filter(f => !!f)
}

function selectedFile() {
  const files = selectedFiles()
  if (len(files) > 1)
    throw new Error("More than one item selected")
  if (len(files) === 0)
    return undefined
  return files[0]
}

function isSelectionEmpty() {
  return len(selectionState.value.selected) === 0
}

function isSelected(fileOrSearchId: string) {
  return !!selectionState.value.selected[fileOrSearchId]
}

function isSelectedSidebar(fileOrSearchId: string) {
  return selectionState.value.selected[fileOrSearchId]?.location === "sidebar"
}

function isSelectedMainView(fileOrSearchId: string) {
  return selectionState.value.selected[fileOrSearchId]?.location === "main-view"
}

function isSelectedWaterfall(fileOrSearchId: string) {
  return selectionState.value.selected[fileOrSearchId]?.location === "waterfall"
}

function clearSelected() {
  selectionState.value.selected = {}
}

function addFileToSelection(file: InterfaceFile, location: SelectionLocation) {
  selectionState.value.selected[file.fileId] = { file, location }
}

function selectFile(file: InterfaceFile, location: SelectionLocation) {
  selectionState.value.selected = { [file.fileId]: { file, location } }
}

function selectFiles(files: InterfaceFile[], location: SelectionLocation) {
  selectionState.value.selected = {}
  for (const file of files)
    selectionState.value.selected[file.fileId] = { file, location }
}

function deselectFile(file: InterfaceFile) {
  delete selectionState.value.selected[file.fileId]
}

function selectItemLeftInFolderView() {
  const { scrollToFile, sortedContents } = useMainView()
  // TODO: go to next line if item if there is nothing to right
  const { mainViewState } = useMainView()
  const file = selectedFile()
  if (!file)
    throw new Error("No selected file")
  const myBounds = mainViewState.value.contentBounds[file.fileId]
  if (!myBounds)
    throw new Error("Selection is not in content bounds dict")
  const next = min(
    Object.entries(mainViewState.value.contentBounds),
    ([fileId, bounds]) => {
      if (fileId === file.fileId || bounds.right > myBounds.left)
        return undefined
      const l = myBounds.left - bounds.right
      const t = mean(bounds.top, bounds.bottom) - mean(myBounds.top, myBounds.bottom)
      return (l ** 2 + t ** 2) ** 0.5
    },
  )
  let fileId
  if (next)
    fileId = next[0]
  else {
    const index = sortedContents.value.findIndex(f => f.fileId === file.fileId)
    fileId = sortedContents.value[index - 1]?.fileId
  }
  if (!fileId) // nothing before this item
    return console.warn("No item found to left of current item")
  const nextSelection = mainViewState.value.contents[fileId]
  if (!nextSelection)
    throw new Error("The min content bounds item was not in folder")
  selectFile(nextSelection, "main-view")
  scrollToFile(fileId)
}

function selectItemRightInFolderView() {
  const { scrollToFile, sortedContents } = useMainView()
  // TODO: go to next line if item if there is nothing to right
  const { mainViewState } = useMainView()
  const file = selectedFile()
  if (!file)
    throw new Error("No selected file")
  const myBounds = mainViewState.value.contentBounds[file.fileId]
  if (!myBounds)
    throw new Error("Selection is not in content bounds dict")
  const next = min(
    Object.entries(mainViewState.value.contentBounds),
    ([fileId, bounds]) => {
      if (fileId === file.fileId || bounds.left < myBounds.right)
        return undefined
      const l = bounds.left - myBounds.right
      const t = mean(bounds.top, bounds.bottom) - mean(myBounds.top, myBounds.bottom)
      return (l ** 2 + t ** 2) ** 0.5
    },
  )
  let fileId
  if (next)
    fileId = next[0]
  else {
    const index = sortedContents.value.findIndex(f => f.fileId === file.fileId)
    fileId = sortedContents.value[index + 1]?.fileId
  }
  if (!fileId) // nothing after this item
    return console.warn("No item found to right of current item")
  const nextSelection = mainViewState.value.contents[fileId]
  if (!nextSelection)
    throw new Error("The min content bounds item was not in folder")
  selectFile(nextSelection, "main-view")
  scrollToFile(fileId)
}

function selectItemUpInFolderView() {
  const { scrollToFile } = useMainView()
  // TODO: go to next line if item if there is nothing to right
  const { mainViewState } = useMainView()
  const file = selectedFile()
  if (!file)
    throw new Error("No selected file")
  const myBounds = mainViewState.value.contentBounds[file.fileId]
  if (!myBounds)
    throw new Error("Selection is not in content bounds dict")
  const next = min(
    Object.entries(mainViewState.value.contentBounds),
    ([fileId, bounds]) => {
      if (fileId === file.fileId || bounds.bottom > myBounds.top)
        return undefined
      const t = myBounds.top - bounds.bottom
      const l = mean(bounds.left, bounds.right) - mean(myBounds.left, myBounds.right)
      return (l ** 2 + t ** 2) ** 0.5
    },
  )
  if (!next)
    return
  const fileId = next[0]
  const nextSelection = mainViewState.value.contents[fileId]
  if (!nextSelection)
    throw new Error("The min content bounds item was not in folder")
  selectFile(nextSelection, "main-view")
  scrollToFile(fileId)
}

function selectItemDownInFolderView() {
  const { scrollToFile } = useMainView()
  // TODO: go to next line if item if there is nothing to right
  const { mainViewState } = useMainView()
  const file = selectedFile()
  if (!file)
    throw new Error("No selected file")
  const myBounds = mainViewState.value.contentBounds[file.fileId]
  if (!myBounds)
    throw new Error("Selection is not in content bounds dict")
  const next = min(
    Object.entries(mainViewState.value.contentBounds),
    ([fileId, bounds]) => {
      if (fileId === file.fileId || bounds.top < myBounds.bottom)
        return undefined
      const t = bounds.top - myBounds.bottom
      const l = mean(bounds.left, bounds.right) - mean(myBounds.left, myBounds.right)
      return (l ** 2 + t ** 2) ** 0.5
    },
  )
  if (!next)
    return
  const fileId = next[0]
  const nextSelection = mainViewState.value.contents[fileId]
  if (!nextSelection)
    throw new Error("The min content bounds item was not in folder")
  selectFile(nextSelection, "main-view")
  scrollToFile(fileId)
}

function selectItemUpInSidebarView() {
  const file = selectedFile()
  if (!file)
    throw new Error("No selected file")
  const { lastFile, fileBefore } = useSidebar()
  const nextFile = fileBefore(file.fileId) || lastFile()
  if (!nextFile)
    return console.error("No prior item to select in sidebar")
  selectFile(nextFile, "sidebar")
}

function selectItemDownInSidebarView() {
  const file = selectedFile()
  if (!file)
    throw new Error("No selected file")
  const { firstFile, fileAfter } = useSidebar()
  const nextFile = fileAfter(file.fileId) || firstFile()
  if (!nextFile)
    return console.error("No next item to select in sidebar")
  selectFile(nextFile, "sidebar")
}

/**
 * Moves the selection to a given destination folder. We must be
 * careful to only move files / folders that are not parents of the destination.
 * Moreover, we must ensure that all files / folders have a path in order to be
 * able to move them.
 * @param destination the folder to move the files to
 */
function moveSelectedFiles(destination: InterfaceFolderFile) {
  const { moveFiles } = useFileSystem()
  if (!destination)
    throw new Error("Nonexistent destination")
  const destFileIds = destination.path()?.map(f => f.fileId) ?? []
  const files = selectedFiles()
  if (!files.length)
    throw new Error("Not selecting any files")
  const notAlreadyMoved = []
  for (const file of files) {
    if (!file._file.path)
      throw new Error("Cannot delete a file without a path")
    if (destFileIds.includes(file.fileId))
      throw new Error("Cannot move a folder into its own child")
    if (file.parentId() !== destination.fileId)
      notAlreadyMoved.push(file)
  }
  if (!notAlreadyMoved.length)
    console.warn("All files already archived")
  moveFiles(notAlreadyMoved, destination)
}

/**
 * Moves the selected files to the archive directory. Throws
 * an error if all the files are already in the archive. Skips
 * files that are already archived (if in a multi-select).
 */
function archiveSelectedFiles() {
  const { myself } = useUser()
  const { moveFiles } = useFileSystem()
  const archive = myself.value.archive
  if (!archive)
    throw new Error("Cannot move files when logged out")
  const files = selectedFiles()
  if (!files.length)
    throw new Error("Not selecting any files")
  const notAlreadyArchived = []
  for (const file of files) {
    if (!file._file.path)
      throw new Error("Cannot delete a file without a path")
    if (!file.isContainedBy(archive.fileId))
      notAlreadyArchived.push(file)
  }
  if (!notAlreadyArchived.length)
    console.warn("All files already archived")
  moveFiles(notAlreadyArchived, archive)
}

function selectedSearch() {
  const searches = Object.values(selectionState.value.selected).map(i => i.search).filter(s => !!s)
  if (len(searches) > 1)
    throw new Error("More than one item selected")
  if (len(searches) === 0)
    return undefined
  return searches[0]
}

function selectSearch(search: InterfaceSearch, location: SelectionLocation) {
  selectionState.value.selected = { [search.searchId()]: { search, location } }
}

export default function () {
  selectionState = useState("selection-state", () => ({
    selected: {},
  }))

  return {
    selectionState,
    selectedFiles,
    selectedFile,
    selectFiles,
    selectFile,
    isSelectionEmpty,
    isSelected,
    isSelectedSidebar,
    isSelectedMainView,
    isSelectedWaterfall,
    clearSelected,
    addFileToSelection,
    deselectFile,
    moveSelectedFiles,
    archiveSelectedFiles,
    selectItemLeftInFolderView,
    selectItemRightInFolderView,
    selectItemUpInFolderView,
    selectItemDownInFolderView,
    selectItemUpInSidebarView,
    selectItemDownInSidebarView,
    selectedSearch,
    selectSearch,
  }
}
