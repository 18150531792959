// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { SearchV1IntentBuf } from '../search/search-v1-intent-buf.js';


export class SearchIntentValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchIntentValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchIntentValueBuf(bb:flatbuffers.ByteBuffer, obj?:SearchIntentValueBuf):SearchIntentValueBuf {
  return (obj || new SearchIntentValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchIntentValueBuf(bb:flatbuffers.ByteBuffer, obj?:SearchIntentValueBuf):SearchIntentValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchIntentValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value(obj?:SearchV1IntentBuf):SearchV1IntentBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new SearchV1IntentBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSearchIntentValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endSearchIntentValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // value
  return offset;
}

static createSearchIntentValueBuf(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchIntentValueBuf.startSearchIntentValueBuf(builder);
  SearchIntentValueBuf.addValue(builder, valueOffset);
  return SearchIntentValueBuf.endSearchIntentValueBuf(builder);
}
}
