// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class LogoutBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):LogoutBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsLogoutBuf(bb:flatbuffers.ByteBuffer, obj?:LogoutBuf):LogoutBuf {
  return (obj || new LogoutBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsLogoutBuf(bb:flatbuffers.ByteBuffer, obj?:LogoutBuf):LogoutBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new LogoutBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static startLogoutBuf(builder:flatbuffers.Builder) {
  builder.startObject(0);
}

static endLogoutBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createLogoutBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  LogoutBuf.startLogoutBuf(builder);
  return LogoutBuf.endLogoutBuf(builder);
}
}
