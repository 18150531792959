// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { CacheBuf } from '../cache/cache-buf.js';
import { MessageDatagramBuf } from '../message/message-datagram-buf.js';
import { RequestDatagramBuf } from '../request/request-datagram-buf.js';
import { ResponseDatagramBuf } from '../response/response-datagram-buf.js';


export class DatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):DatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:DatagramBuf):DatagramBuf {
  return (obj || new DatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:DatagramBuf):DatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new DatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requests(index: number, obj?:RequestDatagramBuf):RequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestDatagramBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

requestsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

responses(index: number, obj?:ResponseDatagramBuf):ResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

responsesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

message(obj?:MessageDatagramBuf):MessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new MessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

cache(obj?:CacheBuf):CacheBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new CacheBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addRequests(builder:flatbuffers.Builder, requestsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, requestsOffset, 0);
}

static createRequestsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startRequestsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addResponses(builder:flatbuffers.Builder, responsesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, responsesOffset, 0);
}

static createResponsesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startResponsesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addMessage(builder:flatbuffers.Builder, messageOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, messageOffset, 0);
}

static addCache(builder:flatbuffers.Builder, cacheOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, cacheOffset, 0);
}

static endDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
