// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class ExifJsonStringValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ExifJsonStringValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsExifJsonStringValueBuf(bb:flatbuffers.ByteBuffer, obj?:ExifJsonStringValueBuf):ExifJsonStringValueBuf {
  return (obj || new ExifJsonStringValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsExifJsonStringValueBuf(bb:flatbuffers.ByteBuffer, obj?:ExifJsonStringValueBuf):ExifJsonStringValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ExifJsonStringValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startExifJsonStringValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endExifJsonStringValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // value
  return offset;
}

static createExifJsonStringValueBuf(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  ExifJsonStringValueBuf.startExifJsonStringValueBuf(builder);
  ExifJsonStringValueBuf.addValue(builder, valueOffset);
  return ExifJsonStringValueBuf.endExifJsonStringValueBuf(builder);
}
}
