// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileCopyMessageDatagramBuf } from '../message/file-copy-message-datagram-buf.js';
import { FileCreateMessageDatagramBuf } from '../message/file-create-message-datagram-buf.js';
import { FileDeleteMessageDatagramBuf } from '../message/file-delete-message-datagram-buf.js';
import { FileMoveMessageDatagramBuf } from '../message/file-move-message-datagram-buf.js';
import { FilePropertiesMessageDatagramBuf } from '../message/file-properties-message-datagram-buf.js';
import { FileShareMessageDatagramBuf } from '../message/file-share-message-datagram-buf.js';
import { FileUpdateMessageDatagramBuf } from '../message/file-update-message-datagram-buf.js';
import { FilesSharedWithSelfMessageDatagramBuf } from '../message/files-shared-with-self-message-datagram-buf.js';
import { FolderListPageMessageDatagramBuf } from '../message/folder-list-page-message-datagram-buf.js';
import { SearchMessageDatagramBuf } from '../message/search-message-datagram-buf.js';
import { SharedFolderCreateMessageDatagramBuf } from '../message/shared-folder-create-message-datagram-buf.js';
import { SharedFolderMembershipMessageDatagramBuf } from '../message/shared-folder-membership-message-datagram-buf.js';
import { UserUpdateMessageDatagramBuf } from '../message/user-update-message-datagram-buf.js';


export class MessageDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):MessageDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:MessageDatagramBuf):MessageDatagramBuf {
  return (obj || new MessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:MessageDatagramBuf):MessageDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new MessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

fileCopy(obj?:FileCopyMessageDatagramBuf):FileCopyMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new FileCopyMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileCreate(obj?:FileCreateMessageDatagramBuf):FileCreateMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FileCreateMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileDelete(obj?:FileDeleteMessageDatagramBuf):FileDeleteMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FileDeleteMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileMove(obj?:FileMoveMessageDatagramBuf):FileMoveMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new FileMoveMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileProperties(obj?:FilePropertiesMessageDatagramBuf):FilePropertiesMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new FilePropertiesMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileShare(obj?:FileShareMessageDatagramBuf):FileShareMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new FileShareMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileUpdate(obj?:FileUpdateMessageDatagramBuf):FileUpdateMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new FileUpdateMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

folderList(obj?:FolderListPageMessageDatagramBuf):FolderListPageMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? (obj || new FolderListPageMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

search(obj?:SearchMessageDatagramBuf):SearchMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? (obj || new SearchMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

filesSharedWithSelf(obj?:FilesSharedWithSelfMessageDatagramBuf):FilesSharedWithSelfMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? (obj || new FilesSharedWithSelfMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderCreate(obj?:SharedFolderCreateMessageDatagramBuf):SharedFolderCreateMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? (obj || new SharedFolderCreateMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderMembership(obj?:SharedFolderMembershipMessageDatagramBuf):SharedFolderMembershipMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? (obj || new SharedFolderMembershipMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

userUpdate(obj?:UserUpdateMessageDatagramBuf):UserUpdateMessageDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? (obj || new UserUpdateMessageDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startMessageDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(13);
}

static addFileCopy(builder:flatbuffers.Builder, fileCopyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, fileCopyOffset, 0);
}

static addFileCreate(builder:flatbuffers.Builder, fileCreateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, fileCreateOffset, 0);
}

static addFileDelete(builder:flatbuffers.Builder, fileDeleteOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, fileDeleteOffset, 0);
}

static addFileMove(builder:flatbuffers.Builder, fileMoveOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, fileMoveOffset, 0);
}

static addFileProperties(builder:flatbuffers.Builder, filePropertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, filePropertiesOffset, 0);
}

static addFileShare(builder:flatbuffers.Builder, fileShareOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, fileShareOffset, 0);
}

static addFileUpdate(builder:flatbuffers.Builder, fileUpdateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, fileUpdateOffset, 0);
}

static addFolderList(builder:flatbuffers.Builder, folderListOffset:flatbuffers.Offset) {
  builder.addFieldOffset(7, folderListOffset, 0);
}

static addSearch(builder:flatbuffers.Builder, searchOffset:flatbuffers.Offset) {
  builder.addFieldOffset(8, searchOffset, 0);
}

static addFilesSharedWithSelf(builder:flatbuffers.Builder, filesSharedWithSelfOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, filesSharedWithSelfOffset, 0);
}

static addSharedFolderCreate(builder:flatbuffers.Builder, sharedFolderCreateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(10, sharedFolderCreateOffset, 0);
}

static addSharedFolderMembership(builder:flatbuffers.Builder, sharedFolderMembershipOffset:flatbuffers.Offset) {
  builder.addFieldOffset(11, sharedFolderMembershipOffset, 0);
}

static addUserUpdate(builder:flatbuffers.Builder, userUpdateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(12, userUpdateOffset, 0);
}

static endMessageDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
