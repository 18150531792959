import { ViewModeType } from "../generated/interface"
import { Gesture } from "./BaseGesture"

export default function () {
  const { getMainViewFileFromEvent, isPrimaryClick, isOverMainView, gestureLayers } = useGestures()
  const { mainViewState } = useMainView()
  const { mainViewTreeChevronClass } = useGlobals()
  const { emitInterfaceEvent } = useEvents()
  const { viewModeState } = useViewMode()

  class TreeViewClickToExpandFolder extends Gesture {
    layer = gestureLayers.viewMode

    #didClickChevron = false

    reset() {
      this.#didClickChevron = false
    }

    wasChevronClick(mouse: MouseEvent) {
      return !!getFirstMatchingParent(
        mouse.target,
        t => !!t.classList?.contains(mainViewTreeChevronClass),
      )
    }

    async onMouseDown(mouse: MouseEvent) {
      if (
        !isPrimaryClick(mouse)
        || !["folder", "search-results"].includes(mainViewState.value.state)
        || !enumEq(viewModeState.value.current.mode, ViewModeType.Tree)
        || !isOverMainView(mouse)
      )
        return

      const folder = getMainViewFileFromEvent(mouse)
      if (!folder || !this.wasChevronClick(mouse))
        return

      this.capture()
      this.debug("Clicked on chevron for file, toggling", folder)
      this.#didClickChevron = true

      if (mainViewState.value.openFolders[folder.fileId]) {
        this.console.log("Folder is already open, sending close message")
        emitInterfaceEvent("afterShrunkFolder", folder as InterfaceFolderFile)
      }
      else {
        this.console.log("Folder is closed, sending open message")
        emitInterfaceEvent("afterExpandedFolder", folder as InterfaceFolderFile)
      }
    }

    async onMouseUp(_mouse: MouseEvent) {
      if (this.#didClickChevron)
        this.capture()
      return this.reset()
    }
  }
  return new TreeViewClickToExpandFolder()
}
