import * as flatbuffers from "flatbuffers"
import { InterfaceFile } from "~/classes/file"
import { addFileToBuf, createRequestIdBuf, parseFile, parseRequestId } from "~/utils/flatbuffers"
import { FileCreateRequestDatagramBuf, RequestDatagramBuf } from "~/classes/generated/request"
import { DatagramBuf } from "~/classes/generated/datagram"
import type { FileCreateResponseDatagramBuf } from "~/classes/generated/response"
import type { FileCreateMessageDatagramBuf } from "~/classes/generated/message"
import { parseMaybeFile } from "~/utils/flatbuffers/file"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"

const console = useLogger("file/create")

export function sendFileCreateRequest(request: FileCreateRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  const file = addFileToBuf(fbb, request.file)
  FileCreateRequestDatagramBuf.startFileCreateRequestDatagramBuf(fbb)
  FileCreateRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  FileCreateRequestDatagramBuf.addFile(fbb, file)
  FileCreateRequestDatagramBuf.addCacheOnly(fbb, request.cacheOnly ?? false)
  const createRequest = FileCreateRequestDatagramBuf.endFileCreateRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addFileCreate(fbb, createRequest)
  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

/** Parse a Flatbuffers representation of a response */
export function handleFileCreateResponseDatagramBuf(datagram: FileCreateResponseDatagramBuf) {
  // TODO: get rid of datagram result, and find out a better way to
  // error out of this, since we need to send notifications
  onFileCreateResponse({
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    file: parseMaybeFile(datagram.file()),
  })
}

export function onFileCreateResponse(response: FileCreateResponseDatagram) {
  const { addFileToFolderView } = useMainView()
  const { navigationState } = useNavigation()

  if (response.error)
    return onFileCreateErrored(response.error)
  if (!response.file)
    throw new Error("No file in create response")

  const iFile = InterfaceFile.from(response.file)
  if (iFile.parentId() !== navigationState.value.file?.fileId) {
    console.warn("File created not in current folder, skipping", iFile)
    return
  }
  addFileToFolderView(iFile)
}

export function onFileCreateErrored(error: DatagramError,
) {
  const { notifyDatagramError } = useNotifications()
  return notifyDatagramError(error, "File creation was unsuccessful")
}

export function handleFileCreateMessageDatagramBuf(message: FileCreateMessageDatagramBuf) {
  const { addFileToFolderView } = useMainView()
  const { navigationState } = useNavigation()

  const file = parseFile(message.file()!)

  const iFile = InterfaceFile.from(file)
  if (iFile.parentId() !== navigationState.value.file?.fileId)
    return console.warn("Created file not in current folder", File)

  addFileToFolderView(iFile)
}
