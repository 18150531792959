// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { TransferIdBuf } from '../transfer/transfer-id-buf.js';


export class DownloadStatusMessageBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):DownloadStatusMessageBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsDownloadStatusMessageBuf(bb:flatbuffers.ByteBuffer, obj?:DownloadStatusMessageBuf):DownloadStatusMessageBuf {
  return (obj || new DownloadStatusMessageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsDownloadStatusMessageBuf(bb:flatbuffers.ByteBuffer, obj?:DownloadStatusMessageBuf):DownloadStatusMessageBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new DownloadStatusMessageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

transferId(obj?:TransferIdBuf):TransferIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new TransferIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

waiting():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

completed():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

failed():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

inProgress():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startDownloadStatusMessageBuf(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addTransferId(builder:flatbuffers.Builder, transferIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, transferIdOffset, 0);
}

static addWaiting(builder:flatbuffers.Builder, waiting:number) {
  builder.addFieldInt32(1, waiting, 0);
}

static addCompleted(builder:flatbuffers.Builder, completed:number) {
  builder.addFieldInt32(2, completed, 0);
}

static addFailed(builder:flatbuffers.Builder, failed:number) {
  builder.addFieldInt32(3, failed, 0);
}

static addInProgress(builder:flatbuffers.Builder, inProgress:number) {
  builder.addFieldInt32(4, inProgress, 0);
}

static endDownloadStatusMessageBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // transfer_id
  return offset;
}

static createDownloadStatusMessageBuf(builder:flatbuffers.Builder, transferIdOffset:flatbuffers.Offset, waiting:number, completed:number, failed:number, inProgress:number):flatbuffers.Offset {
  DownloadStatusMessageBuf.startDownloadStatusMessageBuf(builder);
  DownloadStatusMessageBuf.addTransferId(builder, transferIdOffset);
  DownloadStatusMessageBuf.addWaiting(builder, waiting);
  DownloadStatusMessageBuf.addCompleted(builder, completed);
  DownloadStatusMessageBuf.addFailed(builder, failed);
  DownloadStatusMessageBuf.addInProgress(builder, inProgress);
  return DownloadStatusMessageBuf.endDownloadStatusMessageBuf(builder);
}
}
