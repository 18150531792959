// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { UserSidebarItemBuf } from '../user/user-sidebar-item-buf.js';


export class UserSidebarItemValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):UserSidebarItemValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsUserSidebarItemValueBuf(bb:flatbuffers.ByteBuffer, obj?:UserSidebarItemValueBuf):UserSidebarItemValueBuf {
  return (obj || new UserSidebarItemValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsUserSidebarItemValueBuf(bb:flatbuffers.ByteBuffer, obj?:UserSidebarItemValueBuf):UserSidebarItemValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new UserSidebarItemValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value(obj?:UserSidebarItemBuf):UserSidebarItemBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new UserSidebarItemBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startUserSidebarItemValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endUserSidebarItemValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // value
  return offset;
}

static createUserSidebarItemValueBuf(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  UserSidebarItemValueBuf.startUserSidebarItemValueBuf(builder);
  UserSidebarItemValueBuf.addValue(builder, valueOffset);
  return UserSidebarItemValueBuf.endUserSidebarItemValueBuf(builder);
}
}
