// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class HashableTagValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):HashableTagValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsHashableTagValueBuf(bb:flatbuffers.ByteBuffer, obj?:HashableTagValueBuf):HashableTagValueBuf {
  return (obj || new HashableTagValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsHashableTagValueBuf(bb:flatbuffers.ByteBuffer, obj?:HashableTagValueBuf):HashableTagValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new HashableTagValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

tag():string|null
tag(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
tag(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startHashableTagValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addTag(builder:flatbuffers.Builder, tagOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, tagOffset, 0);
}

static endHashableTagValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // tag
  return offset;
}

static createHashableTagValueBuf(builder:flatbuffers.Builder, tagOffset:flatbuffers.Offset):flatbuffers.Offset {
  HashableTagValueBuf.startHashableTagValueBuf(builder);
  HashableTagValueBuf.addTag(builder, tagOffset);
  return HashableTagValueBuf.endHashableTagValueBuf(builder);
}
}
