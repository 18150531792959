import { uuidv7 } from "uuidv7"
import { Property, PropertySchema } from "../entity"
import { InterfaceFolderFile } from "../file/InterfaceFolderFile"

export class InterfaceSharedFolderInfo {
  _info: SharedFolderInfo

  constructor(info: SharedFolderInfo) {
    this._info = info
  }

  static fakeSharedFolderId() {
    return `da0ff1ce-${uuidv7()}`
  }

  sharedFolderId() {
    return this._info.sharedFolder.sharedFolderId
  }

  sharedFolderProperty(id: Property): SharedFolderProperty | undefined {
    return this._info.sharedFolder.properties?.[id]
  }

  homeProperty(id: Property): FileProperty | undefined {
    return this._info.home.properties?.[id]
  }

  name() {
    const property = this.sharedFolderProperty(Property.SHARED_FOLDER_DISPLAY_NAME)
    if (!property)
      return
    if (property.value.type === PropertySchema.NonNullableStringValue)
      return property.value.nonNullableString
    throw new Error("Schema invalid for name")
  }

  handle() {
    const property = this.sharedFolderProperty(Property.SHARED_FOLDER_HANDLE)
    if (!property)
      return
    if (property.value.type === PropertySchema.EntityHandleValue)
      return property.value.entityHandle
    throw new Error("Schema invalid for handle")
  }

  publicRole() {
    const property = this.homeProperty(Property.PUBLIC_USER_ROLE)
    if (!property)
      throw new Error("Space is badly broken")
    if (property.value.type === PropertySchema.RoleUuidValue)
      return property.value.roleUuid
    throw new Error("Schema invalid for public role")
  }

  isPublic() {
    return !this.publicRole().startsWith("00000000")
  }

  home() {
    return new InterfaceFolderFile(this._info.home)
  }

  archive() {
    return new InterfaceFolderFile(this._info.archive)
  }

  users() {
    const sharedWithProperties = this.home().sharedWithProperties()
    return sharedWithProperties as FileProperty[]
  }

  archiveUsers() {
    const sharedWithProperties = this.archive().sharedWithProperties()
    return sharedWithProperties as FileProperty[]
  }

  /** Properties required when creating a shared folder */
  static creationProperties({
    name,
    handle,
    userId,
  }: { name: string, handle: string, userId: string }) {
    const properties: Record<string, SharedFolderProperty> = {}
    properties[Property.SHARED_FOLDER_HANDLE] = {
      propertyId: Property.SHARED_FOLDER_HANDLE,
      value: { type: PropertySchema.EntityHandleValue, entityHandle: handle },
      author: userId,
      schema: PropertySchema.EntityHandleValue,
      timestamp: Date.now(),
    }
    properties[Property.SHARED_FOLDER_DISPLAY_NAME] = {
      propertyId: Property.SHARED_FOLDER_DISPLAY_NAME,
      value: { type: PropertySchema.NonNullableStringValue, nonNullableString: name },
      author: userId,
      schema: PropertySchema.NonNullableStringValue,
      timestamp: Date.now(),
    }
    return properties
  }
}
