import { UUID } from "uuidv7"

export enum PropertySchema {
  FileIdValue = 0x00000000,
  UserIdValue = 0x00000001,
  SharedFolderIdValue = 0x00000002,
  WorkspaceIdValue = 0x00000004,
  UtcTimestampMillisValue = 0x00000010,
  S3BlobUriValue = 0x00000020,
  S3ThumbnailUriValue = 0x00000021,
  NonNullableStringValue = 0x00000030,
  NullableStringValue = 0x00000031,
  HashableTagValue = 0x00000032,
  EmailValue = 0x00000033,
  EntityHandleValue = 0x00000034,
  BoolValue = 0x00000040,
  PositiveIntegerValue = 0x00000050,
  PositiveFloatValue = 0x00000051,
  SizeBytesValue = 0x00000052,
  AnyIntegerValue = 0x00000053,
  MimeTypeValue = 0x0000_0060,
  ExifJsonStringValue = 0x0000_0061,
  RoleUuidValue = 0x0000_0070,
  EntityPropertySelectionValue = 0x0000_0110,
  ViewModeSettingsValue = 0x0000_0111,
  UserSidebarItemValue = 0x0000_0112,
  SearchIntentValue = 0x0000_0500,
  SearchQueryValue = 0x0000_0501,
  SearchResultValue = 0x0000_0502,
}

export enum Property {
  // Core
  URI = "00000000-00000000-0000-0000-0000-000000000000",
  PARENT_ID = "00000000-00000000-0000-0000-0000-000000000001",
  NAME = "00000000-00000000-0000-0000-0000-000000000002",
  CREATED_AT = "00000000-00000000-0000-0000-0000-000000000003",
  IS_DIR = "00000000-00000000-0000-0000-0000-000000000004",
  CLIENT_ID = "00000000-00000000-0000-0000-0000-000000000008",

  // System
  HOME_DIR_OWNER_ID = "00000001-00000000-0000-0000-0000-000000001000",
  ARCHIVE_DIR_OWNER_ID = "00000001-00000000-0000-0000-0000-000000001001",
  SHARED_FOLDER_HOME_OWNER_ID = "00000001-00000000-0000-0000-0000-000000001002",
  SHARED_FOLDER_ARCHIVE_OWNER_ID = "00000001-00000000-0000-0000-0000-000000001003",
  IS_PERMANENTLY_DELETED = "00000001-00000000-0000-0000-0000-000000001004",
  PUBLIC_USER_ROLE = "00000001-00000000-0000-0000-0000-000000001005",

  // Derived
  MIME_TYPE = "00000002-00000000-0000-0000-0000-000000000005",
  SIZE = "00000002-00000000-0000-0000-0000-000000000006",
  COPY_ORIGIN = "00000002-00000000-0000-0000-0000-000000000009",
  MODIFIED_AT = "00000002-00000000-0000-0000-0000-000000000010",

  // Client Only & Virtual
  PROPERTY_SELECTION = "00000100-00000000-0000-0000-0000-00000000100",
  VIRTUAL_PREVIEW = "00000100-00000000-0000-0000-0000-00000000101",
  VIRTUAL_DIMENSIONS = "00000100-00000000-0000-0000-0000-00000000102",
  VIRTUAL_TAGS = "00000100-00000000-0000-0000-0000-00000000103",
  VIRTUAL_PATH = "00000100-00000000-0000-0000-0000-00000000104",
  VIRTUAL_SEARCH_RELEVANCE = "00000100-00000000-0000-0000-0000-00000000105",

  // Browse
  IMG_THUMBNAIL_CROP_64X64 = "00003000-00000000-0000-0000-0000-000000003000",
  IMG_THUMBNAIL_CROP_192X192 = "00003000-00000000-0000-0000-0000-000000003001",
  IMG_THUMBNAIL_CROP_512X512 = "00003000-00000000-0000-0000-0000-000000003002",
  IMG_THUMBNAIL_CROP_1536X1536 = "00003000-00000000-0000-0000-0000-000000003003",
  IMG_THUMBNAIL_CROP_4608X4608 = "00003000-00000000-0000-0000-0000-000000003004",
  IMG_THUMBNAIL_NATIVE_64_MAX = "00003000-00000000-0000-0000-0000-000000003010",
  IMG_THUMBNAIL_NATIVE_192_MAX = "00003000-00000000-0000-0000-0000-000000003011",
  IMG_THUMBNAIL_NATIVE_512_MAX = "00003000-00000000-0000-0000-0000-000000003012",
  IMG_THUMBNAIL_NATIVE_1536_MAX = "00003000-00000000-0000-0000-0000-000000003013",
  IMG_THUMBNAIL_NATIVE_4608_MAX = "00003000-00000000-0000-0000-0000-000000003014",

  // Image
  WIDTH = "00003002-00000000-0000-0000-0000-000000004000",
  HEIGHT = "00003002-00000000-0000-0000-0000-000000004001",

  // Extended
  FILE_NOTES = "00005000-00000000-0000-0000-0000-000000005000",
  FILE_RATING = "00005000-00000000-0000-0000-0000-000000005001",
  FILE_EXIF_JSON = "00005000-00000000-0000-0000-0000-000000005002",
  GENERATION_PROMPT = "00005000-00000000-0000-0000-0000-000000004100",
  GENERATION_NEGATIVE_PROMPT = "00005000-00000000-0000-0000-0000-000000004101",
  GENERATION_SOURCE = "00005000-00000000-0000-0000-0000-000000004102",
  GENERATION_SEED = "00005000-00000000-0000-0000-0000-000000004103",
  GENERATION_MODEL = "00005000-00000000-0000-0000-0000-000000004104",
  GENERATION_MODEL_VERSION = "00005000-00000000-0000-0000-0000-000000004105",

  // Image Extended

  // User Core
  USER_EMAIL = "00010000-00000000-0000-0000-0001-000000000000",
  USER_AVATAR_URI = "00010000-00000000-0000-0000-0001-000000000002",
  USER_GLOBAL_VIEW_MODE = "00010000-00000000-0000-0000-0001-000000000003",
  USER_CREATED_AT = "00010000-00000000-0000-0000-0001-000000000010",
  USER_CURRENT_WORKSPACE = "00010000-00000000-0000-0000-0001-000000000020",
  USER_HANDLE = "00010000-00000000-0000-0000-0002-ff0000000000",

  // Shared folder Core
  SHARED_FOLDER_DISPLAY_NAME = "00020000-00000000-0000-0000-0002-000000000000",
  SHARED_FOLDER_CREATED_AT = "00020000-00000000-0000-0000-0002-000000000010",
  SHARED_FOLDER_HANDLE = "00020000-00000000-0000-0000-0002-ff0000000000",

  // Workspace core
  WORKSPACE_NAME = "00040000-00000000-0000-0000-0004-000000000000",
  WORKSPACE_IS_ROOT = "00040000-00000000-0000-0000-0004-000000000001",
  WORKSPACE_CREATED_AT = "00040000-00000000-0000-0000-0004-000000000002",
  WORKSPACE_OWNER = "00040000-00000000-0000-0000-0004-000000000003",

  // Search Core
  SEARCH_CREATED_AT = "00050000-00000000-0000-0000-0005-000000000000",
  SEARCH_CLIENT_ID = "00050000-00000000-0000-0000-0005-000000000001",
  SEARCH_INTENT = "00050000-00000000-0000-0000-0005-000000000002",
  // Search Derived
  SEARCH_EXECUTED_AT = "00050001-00000000-0000-0000-0005-000000000003",
  SEARCH_QUERY = "00050001-00000000-0000-0000-0005-000000000004",
  SEARCH_USER = "00050000-00000000-0000-0000-0005-000000000005",
}
/** A pre-created set object to check for inclusion in properties */
export const Properties: Set<string> = new Set([...Object.values(Property)])
export const VirtualProperties: Set<string> = new Set([
  Property.VIRTUAL_PREVIEW,
  Property.VIRTUAL_DIMENSIONS,
  Property.VIRTUAL_TAGS,
  Property.VIRTUAL_PATH,
  Property.VIRTUAL_SEARCH_RELEVANCE,
])

export enum PropertyGroup {
  FILE_CORE = 0x00000000,
  FILE_SYSTEM = 0x00000001,
  FILE_DERIVED = 0x00000002,
  FILE_CLIENT_ONLY = 0x00000100,
  FILE_SHARING = 0x00001000,
  FILE_SHARING_USERS_META = 0x00001001,
  FILE_SHARING_SPACES_META = 0x00001002,
  FILE_FOLDER = 0x00002000,
  FILE_BROWSE = 0x00003000,
  FILE_TAG_META = 0x00003001,
  FILE_IMAGE = 0x00003002,
  FILE_AUDIO = 0x00003003,
  FILE_VIDEO = 0x00003004,
  FILE_THREE_DIMENSIONAL = 0x00003005,
  FILE_EXTENDED = 0x00005000,
  FILE_IMAGE_EXTENDED = 0x00005002,
  FILE_AUDIO_EXTENDED = 0x00005003,
  FILE_VIDEO_EXTENDED = 0x00005004,
  FILE_THREE_DIMENSIONAL_EXTENDED = 0x00005005,
  USER_CORE = 0x00010000,
  USER_CLIENT_ONLY = 0x0010001,
  USER_SIDEBAR_ORDERED_META = 0x00010002,
  USER_SYSTEM = 0x00010003,
  SHARED_FOLDER_CORE = 0x00020000,
  SHARED_FOLDERS_SYSTEM = 0x00020001,
  SHARED_FOLDER_USERS = 0x00020010,
  CUSTOM_FIELD_CORE = 0x00030000,
  SEARCH_CORE = 0x00050000,
  SEARCH_DERIVED = 0x00050001,
  SEARCH_RESULTS_META = 0x00050002,
}

export function propertyName(propertyId: Property) {
  return ({
    [Property.NAME]: "Name",
    [Property.SIZE]: "File Size",
    [Property.PARENT_ID]: "Location",
    [Property.CREATED_AT]: "Created",
    [Property.MODIFIED_AT]: "Modified",
    [Property.MIME_TYPE]: "MIME Type",
    [Property.WIDTH]: "Image Width",
    [Property.HEIGHT]: "Image Height",
    [Property.GENERATION_PROMPT]: "Prompt",
    [Property.GENERATION_NEGATIVE_PROMPT]: "Negative Prompt",
    [Property.GENERATION_SOURCE]: "Source",
    [Property.GENERATION_MODEL]: "Model",
    [Property.GENERATION_MODEL_VERSION]: "Model Version",
    [Property.GENERATION_SEED]: "Seed",
    [Property.VIRTUAL_PATH]: "Path",
    [Property.VIRTUAL_DIMENSIONS]: "Dimensions",
    [Property.VIRTUAL_TAGS]: "Tags",
    [Property.VIRTUAL_SEARCH_RELEVANCE]: "Search Relevance",
  } as Record<Property, string>)[propertyId] || "Unknown Property"
}

/** Generates the property id for the "shared-with" property on files */
export function sharedWith(userId: string) {
  if (userId.length !== 45) // entity id extended uuid
    throw new Error("Invalid entity id provided")
  const pid = `${PropertyGroup.FILE_SHARING_USERS_META.toString(16).padStart(8, "0")}-${userId.slice(9)}`
  if (pid.length !== 45)
    throw new Error("Invalid result")
  return pid
}

/** Generates the property id for the "shared-folder-user" property on shared folders */
export function sharedFolderUser(userId: string) {
  if (userId.length !== 45) // entity id extended uuid
    throw new Error("Invalid entity id provided")
  const pid = `${PropertyGroup.SHARED_FOLDER_USERS.toString(16).padStart(8, "0")}-${userId.slice(9)}`
  if (pid.length !== 45)
    throw new Error("Invalid result")
  return pid
}

export function orderedId(groupId: PropertyGroup, index: number) {
  const values = new Float64Array(2)
  values[0] = 0
  values[1] = index
  const bytes = values.buffer
  const uuid = UUID.ofInner(new Uint8Array(bytes))
  const api = groupId.toString(16).padStart(8, "0")
  return `${api}-${uuid.toString()}`
}

export function groupFor(propertyId: Property): PropertyGroup {
  return Number.parseInt(propertyId.slice(0, 8), 16)
}

export function hasGroup(propertyId: Property, groupId: PropertyGroup) {
  return enumEq(groupFor(propertyId), groupId)
}

export enum FileUserRoleTier {
  Owner = 0x0f000000,
  Admin = 0x03000000,
  Editor = 0x02000000,
  Viewer = 0x01000000,
  Base = 0x00000000,
}

export function tierFor(roleUuid: string): FileUserRoleTier {
  return Number.parseInt(roleUuid.slice(0, 8), 16)
}

export function tierName(roleUuid: string): string {
  switch (tierFor(roleUuid)) {
    case FileUserRoleTier.Owner: return "Owner"
    case FileUserRoleTier.Admin: return "Admin"
    case FileUserRoleTier.Editor: return "Editor"
    case FileUserRoleTier.Viewer: return "Viewer"
    case FileUserRoleTier.Base: return "Base"
  }
}

export function hasTier(roleUuid: string, tier: FileUserRoleTier) {
  return enumEq(tierFor(roleUuid), tier)
}
