import { Node, mergeAttributes } from "@tiptap/core"
import type { Editor } from "@tiptap/vue-3"
import { VueNodeViewRenderer } from "@tiptap/vue-3"
import { Property, PropertyGroup, PropertySchema } from "../entity"
import { InterfaceFile } from "../file/InterfaceFile"
import { TagRef } from "./TagRef"
import tagCompletion from "~/components/Search/Editor/TagCompletion.vue"

const console = useLogger("TagCompletion", theme.colors.violet.hex)

export class TagCompletion {
  static TAG = "tagCompletion"

  static createNode() {
    return Node.create({
      name: TagCompletion.TAG,
      group: "block",
      content: "inline*",
      parseHTML() {
        return [{ tag: TagCompletion.TAG }]
      },
      renderHTML({ HTMLAttributes }) {
        return [TagCompletion.TAG, mergeAttributes(HTMLAttributes), 0]
      },
      addNodeView() {
        return VueNodeViewRenderer(tagCompletion)
      },
      // NOTE: the tag completion is triggered by a keyboard shortcut from the FileCompletion
      // component
    })
  }

  static createAtCursor(editor: Editor) {
    return editor.chain().insertContent({ type: TagCompletion.TAG }).focus().run()
  }

  static containsCursor(editor: Editor) {
    // TODO: what happens during a large selection action?
    return editor.state.selection.$from.node().type.name === TagCompletion.TAG
  }

  static onUpdated(content: string) {
    const { searchState } = useSearch()

    if (searchState.value.suggestions.tags === undefined)
      searchState.value.suggestions = { tags: [content], selection: 0 }
    else
      searchState.value.suggestions.tags[0] = content

    // now we need to check our cache for files matching this completion.
    // We need just the bare bones properties of each file and its tag
    // to maximize speed
    sendFileFindRequest({
      requestId: generateWorkerRequestId(),
      groupId: PropertyGroup.FILE_TAG_META,
      limit: 10,
      cacheOnly: true,
      contains: [content],
      selection: { // TODO: remove this need. mime type needed for parsing currently
        groups: [],
        properties: [Property.MIME_TYPE],
      },
      pathSelection: {
        properties: [],
        groups: [],
      },
    })
  }

  static onUpdateResponse(response: FileFindResponseDatagram) {
    if (!response.files)
      return
    const { searchState } = useSearch()
    // TODO: with tags, the current text being written by the user should be
    //       response 0, always (to let them enter an arbitrary tag)
    const tags = new Set<string>()
    const current = searchState.value.suggestions.tags?.[0]
    if (current)
      tags.add(current)
    // We have to extract the tags from the files and show only those
    for (const file of response.files) {
      for (const tagProperty of new InterfaceFile(file).tagProperties())
        if (tagProperty.value.type === PropertySchema.HashableTagValue)
          tags.add(tagProperty.value.hashableTag)
    }

    searchState.value.suggestions = { tags: [...tags.values()], selection: 0 }
  }

  /** If selection is not included, goes with the current selection */
  static acceptSuggestion(editor: Editor, selection?: number) {
    const { searchState } = useSearch()
    selection ??= searchState.value.suggestions.selection ?? 0
    if (searchState.value.mode !== "tag")
      throw new Error("Must be in tag mode")
    const suggestion = searchState.value.suggestions.tags?.[selection]
    if (!suggestion)
      throw new Error("No suggestion to select")

    console.log("Selecting suggestion", suggestion)
    // accepting the suggestion means adding this to your search
    // remove the current

    // get current node
    const cursor = editor.state.selection.$from
    const from = cursor.start() - 1 // -1 to include the node itself
    const to = cursor.end()

    return editor
      .chain()
      .deleteRange({ from, to })
      .insertContent([
        { type: TagRef.TAG, attrs: { value: suggestion } },
        { type: "text", text: " " },
      ])
      .focus()
      .run()
  }

  /**
   * Exits the tag completion mode and accepts the most recent suggestion, placing cursor
   * to the right of the current completion. This is used, for example, when you press
   * 'escape' or 'right arrow'
   */
  static acceptLatestCompletion() {
    return console.error("accept completion not implemented")
  }
}
