// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { NotificationMessageIdBuf } from '../message/notification-message-id-buf.js';
import { NotificationSenderIdBuf } from '../message/notification-sender-id-buf.js';
import { UserBuf } from '../user/user-buf.js';


export class UserUpdateMessageDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):UserUpdateMessageDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsUserUpdateMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:UserUpdateMessageDatagramBuf):UserUpdateMessageDatagramBuf {
  return (obj || new UserUpdateMessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsUserUpdateMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:UserUpdateMessageDatagramBuf):UserUpdateMessageDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new UserUpdateMessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

messageId(obj?:NotificationMessageIdBuf):NotificationMessageIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new NotificationMessageIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

senderId(obj?:NotificationSenderIdBuf):NotificationSenderIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new NotificationSenderIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

user(obj?:UserBuf):UserBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new UserBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startUserUpdateMessageDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addMessageId(builder:flatbuffers.Builder, messageIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, messageIdOffset, 0);
}

static addSenderId(builder:flatbuffers.Builder, senderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, senderIdOffset, 0);
}

static addUser(builder:flatbuffers.Builder, userOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, userOffset, 0);
}

static endUserUpdateMessageDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // message_id
  builder.requiredField(offset, 6) // sender_id
  builder.requiredField(offset, 8) // user
  return offset;
}

}
