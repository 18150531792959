import { Property } from "~/classes/entity"
import { InterfaceFile, InterfaceFolderFile } from "~/classes/file"
import { InterfaceSearch } from "~/classes/search"
import type { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"

interface State {
  firstPageLoaded: boolean

  file?: InterfaceFile
  search?: InterfaceSearch
}
let navigationState: Ref<State>

const console = useLogger("use-navigation", theme.colors.violet.hex)

/**
 * Using the path, sets up file system navigation details and sets off a "navigateToX" call.
 * Note that this may need to use the "vue next tick" to set things up, since this might
 * get called too soon if we don't call it after logging in.
 */
function initFileSystemNavigation() {
  const { myself } = useUser()
  const { cacheState } = useCache()
  const { path, params, name } = useRoute()

  if (!cacheState.value.isWebsocketConnected)
    return console.info("Skipping file system setup until socket is connected")

  if (navigationState.value.firstPageLoaded)
    return console.info("Skipping duplicate file system setup")
  navigationState.value.firstPageLoaded = true

  if (name === "file-fileId") { // file preview route
    const fileId = params.fileId
    if (!fileId || typeof fileId != "string")
      throw new Error(`Couldn't parse URL: ${params}`)
    onAfterNavigatedToFile(fileId)
  }
  else if (["home", "index"].includes(name!.toString())) {
    if (!myself.value.home) {
      navigationState.value.firstPageLoaded = false
      return console.info("Skipping file system setup until home id is available")
    }
    onAfterNavigatedToHome()
  }
  else if (["archive"].includes(name!.toString()) && myself.value.archive) {
    if (!myself.value.archive) {
      navigationState.value.firstPageLoaded = false
      return console.info("Skipping file system setup until home id is available")
    }
    onAfterNavigatedToArchive()
  }
  else if (["shared-handle"].includes(name!.toString())) {
    const handle = params.handle
    if (!handle || typeof handle != "string")
      throw new Error(`Couldn't parse URL: ${params}`)
    if (!myself.value.home) {
      navigationState.value.firstPageLoaded = false
      return console.info("Skipping file system setup until user info is available")
    }
    onAfterNavigatedToSharedFolder(handle)
  }
  else if (["folder-fileId"].includes(name!.toString())) {
    const folderId = params.fileId
    if (!folderId || typeof folderId != "string")
      throw new Error(`Couldn't parse URL: ${params}`)
    onAfterNavigatedToFolder(folderId)
  }
  else if (["search-searchId"].includes(name!.toString())) {
    const searchId = params.searchId
    if (!searchId || typeof searchId != "string")
      throw new Error(`Couldn't parse URL: ${params}`)
    onAfterNavigatedToSearch()
  }
  else {
    navigationState.value.firstPageLoaded = true
    return console.warn("unknown path, skipping file system init", path)
  }
}

function resetFileSystemNavigationState() {
  navigationState.value.firstPageLoaded = false
  navigationState.value.file = undefined
  navigationState.value.search = undefined
}

/**
 * On each of our layouts we initialize our workers. On authenticated pages
 * we also need to initialize the websocket connection. These actions are
 * idempotent, but should never be called multiple times simultaneously
 */
async function onAppMounted(authenticated = false) {
  const { initAuthenticatedWebsocketConnection, initCacheWorker } = useCache()
  const { initTransferManager } = useTransferManager()
  const { initTauriHandlers } = useTauri()
  await initCacheWorker()
  await initTransferManager()
  await initTauriHandlers()
  if (authenticated)
    await initAuthenticatedWebsocketConnection()
}

function isOnSearchPage() {
  return useRoute().name === "search-searchId"
}

/**
 * On navigation to a file / folder, we check the window.history.state object to see if
 * there is any information there that we need to place in our own history state. If there
 * is, we want to use that information. If not, we put our fallback information in there.
 */
function setHistoryFromStateWithFallback(folderId: string, openFolders?: Record<string, boolean>) {
  const { mainViewState } = useMainView()
  const state = window.history.state

  if ("rootFolder" in state) {
    if (state.rootFolder !== folderId)
      return console.error("The history state doesn't match our fallback root folder, skipping")
    mainViewState.value.rootFolder = state.rootFolder

    if (!state.openFolders || !len(state.openFolders))
      return console.error("No open folders in state")
    else
      mainViewState.value.openFolders = state.openFolders
  }
  else {
    mainViewState.value.rootFolder = folderId
    mainViewState.value.openFolders = openFolders ?? { [folderId]: true }
    updateHistoryState()
  }
}

/** Updates our history state with the most recent details */
function updateHistoryState() {
  const { mainViewState } = useMainView()
  window.history.replaceState({
    rootFolder: mainViewState.value.rootFolder,
    openFolders: clone(mainViewState.value.openFolders),
  }, "")
}

function setPathViewToFile(file: InterfaceFile) {
  console.debug("Setting path view to file", file)
  navigationState.value.search = undefined
  navigationState.value.file = file
}

function setPathViewToSearch(search: InterfaceSearch) {
  navigationState.value.file = undefined
  navigationState.value.search = search
}

async function navigateToOnboarding() {
  return navigateTo("/onboarding")
}

async function navigateToHome() {
  const { myself } = useUser()

  if (myself.value.home)
    if (navigationState.value.file?.fileId === myself.value.home.fileId)
      return console.warn("Skipping redundant navigation")
    else
      setPathViewToFile(myself.value.home)

  return navigateTo("/home")
}

async function onAfterNavigatedToHome() {
  const { myself } = useUser()
  const { cacheState } = useCache()
  const { emitInterfaceEvent } = useEvents()
  const { clearMainViewState, mainViewState } = useMainView()
  const { clearSelected } = useSelection()
  const { removeSearchFromSort } = useViewMode()

  if (!cacheState.value.isWebsocketConnected)
    return console.info("Skipping navigation until socket is connected")

  if (!myself.value.home)
    return console.warn("Can't navigate to home, not logged in")

  mainViewState.value.state = "folder"
  clearMainViewState()
  clearSelected()
  removeSearchFromSort()

  setPathViewToFile(myself.value.home)
  setHistoryFromStateWithFallback(myself.value.home.fileId)
  emitInterfaceEvent("afterNavigatedToFolder", myself.value.home)
}

async function navigateToArchive() {
  const { myself } = useUser()

  if (myself.value.archive)
    if (navigationState.value.file?.fileId === myself.value.archive.fileId)
      return console.warn("Skipping redundant navigation")
    else
      setPathViewToFile(myself.value.archive)

  navigateTo("/archive")
}

async function onAfterNavigatedToArchive() {
  const { myself } = useUser()
  const { cacheState } = useCache()
  const { emitInterfaceEvent } = useEvents()
  const { clearMainViewState, mainViewState } = useMainView()
  const { clearSelected } = useSelection()
  const { removeSearchFromSort } = useViewMode()

  if (!cacheState.value.isWebsocketConnected)
    return console.info("Skipping navigation until socket is connected")

  if (!myself.value.archive)
    return console.warn("Can't navigate to home, not logged in")

  mainViewState.value.state = "folder"
  clearSelected()
  clearMainViewState()
  removeSearchFromSort()
  setPathViewToFile(myself.value.archive)
  setHistoryFromStateWithFallback(myself.value.archive.fileId)
  emitInterfaceEvent("afterNavigatedToFolder", myself.value.archive)
}

async function navigateToFolder(folder: InterfaceFolderFile) {
  const { myself } = useUser()

  if (myself.value.home && folder.fileId === myself.value.home.fileId)
    return navigateToHome()
  else if (myself.value.archive && folder.fileId === myself.value.archive.fileId)
    return navigateToArchive()
  else if (navigationState.value.file?.fileId === folder.fileId)
    return console.warn("Skipping redundant navigation")

  setPathViewToFile(folder)
  navigateTo(`/folder/${folder.fileId}`)
}

async function onAfterNavigatedToFolder(folderId: string) {
  const { cacheState } = useCache()
  const { emitInterfaceEvent } = useEvents()
  const { clearSelected } = useSelection()
  const { clearMainViewState, mainViewState } = useMainView()
  const { removeSearchFromSort } = useViewMode()

  if (!cacheState.value.isWebsocketConnected)
    return console.info("Skipping file system setup until socket is connected")

  mainViewState.value.state = "folder"
  clearSelected()
  clearMainViewState()
  removeSearchFromSort()

  // We check if the path already has the folder id set. If so, we use that file as our
  // source of truth. If that file is not set, then the cause is almost certainly because
  // we just landed here from a fresh URL, so if so we create a blank file and then let
  // our file system callbacks hydrate the file and give us the relevant info.

  let folderFile = navigationState.value.file
  if (!folderFile || folderFile.fileId !== folderId) {
    folderFile = InterfaceFolderFile.blank(folderId)
    setPathViewToFile(folderFile)
  }

  setHistoryFromStateWithFallback(folderId)
  emitInterfaceEvent("afterNavigatedToFolder", folderFile as InterfaceFolderFile)
}

async function navigateToFile(file: InterfaceFile) {
  if (!file.isBlank() && file.isFolder())
    return navigateToFolder(file as InterfaceFolderFile)

  if (navigationState.value.file?.fileId === file.fileId)
    return console.error("Skipping redundant navigation")

  setPathViewToFile(file)

  navigateTo(`/file/${file.fileId}`)
}

async function onAfterNavigatedToFile(fileId: string) {
  const { cacheState } = useCache()
  const { mainViewState } = useMainView()
  const { emitInterfaceEvent } = useEvents()
  const { clearSelected } = useSelection()
  const { clearMainViewState } = useMainView()
  const { getFileProperties } = useFileSystem()

  if (!cacheState.value.isWebsocketConnected)
    return console.info("Skipping file system setup until socket is connected")

  navigationState.value.firstPageLoaded = true

  clearMainViewState()
  clearSelected()

  let file = navigationState.value.file
  if (!file || file.fileId !== fileId) {
    file = InterfaceFile.blank(fileId)
    setPathViewToFile(file)
    getFileProperties(file.fileId)
  }

  mainViewState.value.state = "preview"
  emitInterfaceEvent("afterNavigatedToNonFolderFile", file)
}

async function navigateToSpace(SharedFolderInfo: InterfaceSharedFolderInfo) {
  if (navigationState.value.file?.fileId === SharedFolderInfo.home().fileId)
    return console.warn("Skipping redundant navigation")

  navigateTo(`/shared/${SharedFolderInfo.handle()}`)
}

async function onAfterNavigatedToSharedFolder(handle: string) {
  const { cacheState } = useCache()
  const { emitInterfaceEvent } = useEvents()
  const { getUserSharedFolderInfoByHandle } = useSharedFolders()
  const { myself } = useUser()
  const { clearSelected } = useSelection()
  const { clearMainViewState, mainViewState } = useMainView()
  const { removeSearchFromSort } = useViewMode()

  if (!cacheState.value.isWebsocketConnected)
    return console.info("Skipping file system setup until socket is connected")

  if (!myself.value.home)
    return console.error("Can't navigate to shared folder, not logged in")

  const maybeSharedFolderInfo = getUserSharedFolderInfoByHandle(handle)
  if (!maybeSharedFolderInfo)
    return console.error("Space discovery is not yet supported")

  navigationState.value.firstPageLoaded = true

  mainViewState.value.state = "folder"

  clearSelected()
  clearMainViewState()
  removeSearchFromSort()

  setPathViewToFile(maybeSharedFolderInfo.home())
  setHistoryFromStateWithFallback(maybeSharedFolderInfo.home()!.fileId)
  emitInterfaceEvent("afterNavigatedToFolder", maybeSharedFolderInfo.home())
}

/** Retrieves the search id from the path, if we are on a search page */
function getSearchIdFromPath() {
  const { params, name } = useRoute()
  if (name !== "search-searchId")
    return
  if (!params.searchId || typeof params.searchId != "string")
    return
  return params.searchId
}

async function navigateToSearch(search: InterfaceSearch) {
  const searchId = getSearchIdFromPath()
  if (!searchId || searchId !== search.searchId())
    navigateTo(`/search/${search.searchId()}`)
  setPathViewToSearch(search)
}

async function onAfterNavigatedToSearch() {
  const { cacheState } = useCache()
  const { searchState } = useSearch()
  const { mainViewState } = useMainView()
  const { emitInterfaceEvent } = useEvents()
  const { addSearchToSort } = useViewMode()

  if (!cacheState.value.isWebsocketConnected)
    return console.info("Skipping file system setup until socket is connected")

  navigationState.value.firstPageLoaded = true

  const searchId = getSearchIdFromPath()
  if (!searchId)
    throw new Error("No search found to list")

  searchState.value.isEditing = false
  mainViewState.value.state = "search-results"
  addSearchToSort()

  // Account for cold navigation, i.e. navigating from a fresh page load
  if (!navigationState.value.search || navigationState.value.search.searchId() !== searchId) {
    console.log("Cold navigating to a search page")
    const search = InterfaceSearch.blank(searchId)
    setPathViewToSearch(search)
    emitInterfaceEvent("afterNavigatedToExistingSearch", search)
  }
}

/** Returns the shared folder information for the current file, if any */
function sharedFolderIdForCurrentFile(): string | undefined {
  const { sharedFolderState } = useSharedFolders()
  const currentFile = navigationState.value.file
  if (!currentFile)
    return
  const currentSharedFolderId = currentFile.rootSharedFolderId()
  if (!currentSharedFolderId)
    return
  const SharedFolderInfo = sharedFolderState.value.all[currentSharedFolderId]
  if (!SharedFolderInfo)
    console.error("No shared folder info found for this folder's root")
  return SharedFolderInfo.sharedFolderId()
}

export default function () {
  navigationState = useState("navigation-state", () => ({
    firstPageLoaded: false,
  }))

  return {
    navigationState,
    updateHistoryState,
    resetFileSystemNavigationState,
    onAppMounted,
    initFileSystemNavigation,
    isOnSearchPage,
    setPathViewToFile,
    setPathViewToSearch,
    navigateToOnboarding,
    navigateToHome,
    onAfterNavigatedToHome,
    navigateToArchive,
    onAfterNavigatedToArchive,
    navigateToFolder,
    onAfterNavigatedToFolder,
    navigateToSpace,
    onAfterNavigatedToSharedFolder,
    navigateToFile,
    onAfterNavigatedToFile,
    navigateToSearch,
    onAfterNavigatedToSearch,
    sharedFolderIdForCurrentFile,
  }
}
