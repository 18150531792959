// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum EntityApiIdBuf {
  FileIdV0 = 0,
  UserIdV0 = 65536,
  SharedFolderIdV0 = 131072,
  WorkspaceIdV0 = 262144,
  SearchIdV0 = 327680,
  FileIdClientOnly = 1555034141,
  SearchIdClientOnly = 3390155550,
  SharedFolderIdClientOnly = 3658478030,
  WorkspaceIdClientOnly = 3735929054,
  UserIdClientOnly = 3741231085
}
