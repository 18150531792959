import { Node, mergeAttributes } from "@tiptap/core"
import type { Editor } from "@tiptap/vue-3"
import { VueNodeViewRenderer } from "@tiptap/vue-3"
import { Property, PropertyGroup } from "../entity"
import propertyCompletion from "~/components/Search/Editor/PropertyCompletion.vue"

const console = useLogger("PropertyCompletion", theme.colors.violet.hex)

export class PropertyCompletion {
  static TAG = "propertyCompletion"

  static createNode() {
    return Node.create({
      name: PropertyCompletion.TAG,
      group: "block",
      content: "inline*",
      parseHTML() {
        return [{ tag: PropertyCompletion.TAG }]
      },
      renderHTML({ HTMLAttributes }) {
        return [PropertyCompletion.TAG, mergeAttributes(HTMLAttributes), 0]
      },
      addNodeView() {
        return VueNodeViewRenderer(propertyCompletion)
      },
      // NOTE: the property completion is triggered by a keyboard shortcut from the FileCompletion
      // component
    })
  }

  static createAtCursor(editor: Editor) {
    return editor.chain().insertContent({ type: PropertyCompletion.TAG }).focus().run()
  }

  static containsCursor(editor: Editor) {
    // TODO: what happens during a large selection action?
    return editor.state.selection.$from.node().type.name === PropertyCompletion.TAG
  }

  static onUpdated(content: string) {
    const { searchState } = useSearch()

    // we need to parse the user text into a simple set of recommendations then apply
    // those recommendations

    if (content === "") {
      searchState.value.mode = "property"
      searchState.value.suggestions = {
        properties: { suggestions: PropertyCompletion.defaultSuggestions() },
        selection: 0,
      }
      return
    }

    // TODO
    searchState.value.mode = "property"
    searchState.value.suggestions = {
      properties: { suggestions: PropertyCompletion.defaultSuggestions() },
      selection: 0,
    }
    return
  }

  /** We do not send async requests to the cache, so this does nothing */
  static onUpdateResponse(_: FileFindResponseDatagram) {
    return console.warn("Properties mode received unintended file find response")
  }

  /** If selection is not included, goes with the current selection */
  static acceptSuggestion(selection?: number) {
    const { searchState } = useSearch()
    searchState.value.suggestions.selection ??= selection ?? 0
    if (searchState.value.mode !== "property")
      throw new Error("Must be in property mode")

  // accepting the suggestion means adding this to your search
  }

  /**
   * Exits the property completion mode and accepts the most recent suggestion, placing cursor
   * to the right of the current completion. This is used, for example, when you press
   * 'escape' or 'right arrow'
   */
  static acceptLatestCompletion() {
    return console.error("accept completion not implemented")
  }

  static defaultSuggestions() {
    return [
      "Color",
      "Created",
      "Modified",
      "Accessed",
      "File Size",
      "Type",
      "Image Width",
      "Image Height",
      "Image Aspect Ratio",
    ]
  }

  static _parseCompletionText(_content: string) {

    // format:
    // {name} {<|<=|=|>=|>|CONTAINS|DOES NOT CONTAIN} {value}
  }
}
