interface State {
  visible: boolean
  isShowingProperties: boolean
  isShowingDiffs: boolean
}
let peekState: Ref<State>
const console = useLogger("use-peek", theme.colors.teal.hex)

const MAX_FILES = 3

function canPeek(files?: InterfaceFile[]) {
  const { selectedFiles } = useSelection()
  files ??= selectedFiles()
  return files.length !== 0 && files.length <= MAX_FILES && all(files, f => f.isImage())
}

function canDiff() {
  const { selectedFiles } = useSelection()
  return selectedFiles().length === 2
}

function openPeek() {
  if (!canPeek())
    return console.error("Cannot peek selected files")

  // If we cannot show the diffs then we must mark this as false
  if (!canDiff())
    peekState.value.isShowingDiffs = false

  peekState.value.visible = true
}

function closePeek() {
  peekState.value.visible = false
}

function peekFiles(files: InterfaceFile[], location: SelectionLocation) {
  const { selectFiles } = useSelection()
  if (!canPeek(files))
    return console.error("Cannot peek file")
  selectFiles(files, location)
  openPeek()
}

function toggleProperties() {
  peekState.value.isShowingProperties = !peekState.value.isShowingProperties
}

function toggleDiff() {
  if (!canDiff())
    throw new Error("Cannot toggle diff")
  peekState.value.isShowingDiffs = !peekState.value.isShowingDiffs
  if (peekState.value.isShowingDiffs)
    peekState.value.isShowingProperties = true
}

export default function () {
  peekState = useState("peek-state", () => ({
    visible: false,
    isShowingProperties: false,
    isShowingDiffs: false,
  }))

  return {
    peekState,
    canPeek,
    canDiff,
    openPeek,
    closePeek,
    peekFiles,
    toggleProperties,
    toggleDiff,
  }
}
