import { Gesture } from "./BaseGesture"

export default function () {
  const { getPropertyIdFromEvent, gestureState, isOverPanel, gestureLayers } = useGestures()
  const { startEditingProperty, isEditingProperty } = useProperty()
  const { targetFiles } = usePanel()
  class PanelClickPropertyToEdit extends Gesture {
    layer = gestureLayers.panel

    #didStartEditing = false

    reset() {
      this.#didStartEditing = false
    }

    async onMouseDown(
      mouse: MouseEvent,
    ) {
      if (!isOverPanel(mouse))
        return
      const propertyId = getPropertyIdFromEvent(mouse)
      if (!propertyId)
        return
      const files = targetFiles()
      if (files.length > 1)
        return this.console.warn("Multi-file editing not supported")
      if (!files.length)
        return this.console.error("No preview file found to edit")
      const file = files[0]

      // make the property editable
      this.debug("Editing File property", file, file.property(propertyId))
      this.capture()
      this.#didStartEditing = true
      if (!isEditingProperty(file.fileId, propertyId))
        startEditingProperty(file, propertyId)
    }

    async onMouseUp(
      mouse: MouseEvent,
    ) {
      // We want to capture the mouseup event for our editing selection click as
      // we don't want another handler stealing that event from us.

      if (!this.#didStartEditing)
        return

      // if the position of the mouse is where it was on mouse down, then we're
      // a click
      if (
        gestureState.value.mouseDown?.clientX !== mouse.clientX
        || gestureState.value.mouseDown?.clientY !== mouse.clientY
      )
        return this.reset()

      this.capture()
      return this.reset()
    }
  }
  return new PanelClickPropertyToEdit()
}
