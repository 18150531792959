// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum ViewModeType {
  Icon = 0,
  Grid = 1,
  Feed = 2,
  Detail = 3,
  Tree = 4,
  Column = 5
}
