// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileCopyRequestDatagramBuf } from '../request/file-copy-request-datagram-buf.js';
import { FileCreateRequestDatagramBuf } from '../request/file-create-request-datagram-buf.js';
import { FileDeleteRequestDatagramBuf } from '../request/file-delete-request-datagram-buf.js';
import { FileFindRequestDatagramBuf } from '../request/file-find-request-datagram-buf.js';
import { FileMoveRequestDatagramBuf } from '../request/file-move-request-datagram-buf.js';
import { FilePrepareRequestDatagramBuf } from '../request/file-prepare-request-datagram-buf.js';
import { FilePropertiesRequestDatagramBuf } from '../request/file-properties-request-datagram-buf.js';
import { FileShareRequestDatagramBuf } from '../request/file-share-request-datagram-buf.js';
import { FileUpdateRequestDatagramBuf } from '../request/file-update-request-datagram-buf.js';
import { FilesSharedWithSelfRequestDatagramBuf } from '../request/files-shared-with-self-request-datagram-buf.js';
import { FolderListRequestDatagramBuf } from '../request/folder-list-request-datagram-buf.js';
import { FolderSubscribeRequestDatagramBuf } from '../request/folder-subscribe-request-datagram-buf.js';
import { SearchFindRequestDatagramBuf } from '../request/search-find-request-datagram-buf.js';
import { SearchRequestDatagramBuf } from '../request/search-request-datagram-buf.js';
import { SelfInfoRequestDatagramBuf } from '../request/self-info-request-datagram-buf.js';
import { SharedFolderCreateRequestDatagramBuf } from '../request/shared-folder-create-request-datagram-buf.js';
import { SharedFolderMembershipRequestDatagramBuf } from '../request/shared-folder-membership-request-datagram-buf.js';
import { SharedFolderPropertiesRequestDatagramBuf } from '../request/shared-folder-properties-request-datagram-buf.js';
import { UserFindRequestDatagramBuf } from '../request/user-find-request-datagram-buf.js';
import { UserUpdateRequestDatagramBuf } from '../request/user-update-request-datagram-buf.js';


export class RequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):RequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:RequestDatagramBuf):RequestDatagramBuf {
  return (obj || new RequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:RequestDatagramBuf):RequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new RequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

fileCopy(obj?:FileCopyRequestDatagramBuf):FileCopyRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new FileCopyRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileCreate(obj?:FileCreateRequestDatagramBuf):FileCreateRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FileCreateRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileDelete(obj?:FileDeleteRequestDatagramBuf):FileDeleteRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FileDeleteRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileMove(obj?:FileMoveRequestDatagramBuf):FileMoveRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new FileMoveRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

filePrepare(obj?:FilePrepareRequestDatagramBuf):FilePrepareRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new FilePrepareRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileProperties(obj?:FilePropertiesRequestDatagramBuf):FilePropertiesRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new FilePropertiesRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileShare(obj?:FileShareRequestDatagramBuf):FileShareRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new FileShareRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

folderSubscribe(obj?:FolderSubscribeRequestDatagramBuf):FolderSubscribeRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? (obj || new FolderSubscribeRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileUpdate(obj?:FileUpdateRequestDatagramBuf):FileUpdateRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? (obj || new FileUpdateRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

folderList(obj?:FolderListRequestDatagramBuf):FolderListRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? (obj || new FolderListRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

selfInfo(obj?:SelfInfoRequestDatagramBuf):SelfInfoRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? (obj || new SelfInfoRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

filesSharedWithSelf(obj?:FilesSharedWithSelfRequestDatagramBuf):FilesSharedWithSelfRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? (obj || new FilesSharedWithSelfRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

search(obj?:SearchRequestDatagramBuf):SearchRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? (obj || new SearchRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderCreate(obj?:SharedFolderCreateRequestDatagramBuf):SharedFolderCreateRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 30);
  return offset ? (obj || new SharedFolderCreateRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderProperties(obj?:SharedFolderPropertiesRequestDatagramBuf):SharedFolderPropertiesRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 32);
  return offset ? (obj || new SharedFolderPropertiesRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderMembership(obj?:SharedFolderMembershipRequestDatagramBuf):SharedFolderMembershipRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 34);
  return offset ? (obj || new SharedFolderMembershipRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

userFind(obj?:UserFindRequestDatagramBuf):UserFindRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 36);
  return offset ? (obj || new UserFindRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

userUpdate(obj?:UserUpdateRequestDatagramBuf):UserUpdateRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 38);
  return offset ? (obj || new UserUpdateRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileFind(obj?:FileFindRequestDatagramBuf):FileFindRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 40);
  return offset ? (obj || new FileFindRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

searchFind(obj?:SearchFindRequestDatagramBuf):SearchFindRequestDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 42);
  return offset ? (obj || new SearchFindRequestDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(20);
}

static addFileCopy(builder:flatbuffers.Builder, fileCopyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, fileCopyOffset, 0);
}

static addFileCreate(builder:flatbuffers.Builder, fileCreateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, fileCreateOffset, 0);
}

static addFileDelete(builder:flatbuffers.Builder, fileDeleteOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, fileDeleteOffset, 0);
}

static addFileMove(builder:flatbuffers.Builder, fileMoveOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, fileMoveOffset, 0);
}

static addFilePrepare(builder:flatbuffers.Builder, filePrepareOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, filePrepareOffset, 0);
}

static addFileProperties(builder:flatbuffers.Builder, filePropertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, filePropertiesOffset, 0);
}

static addFileShare(builder:flatbuffers.Builder, fileShareOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, fileShareOffset, 0);
}

static addFolderSubscribe(builder:flatbuffers.Builder, folderSubscribeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(7, folderSubscribeOffset, 0);
}

static addFileUpdate(builder:flatbuffers.Builder, fileUpdateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(8, fileUpdateOffset, 0);
}

static addFolderList(builder:flatbuffers.Builder, folderListOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, folderListOffset, 0);
}

static addSelfInfo(builder:flatbuffers.Builder, selfInfoOffset:flatbuffers.Offset) {
  builder.addFieldOffset(10, selfInfoOffset, 0);
}

static addFilesSharedWithSelf(builder:flatbuffers.Builder, filesSharedWithSelfOffset:flatbuffers.Offset) {
  builder.addFieldOffset(11, filesSharedWithSelfOffset, 0);
}

static addSearch(builder:flatbuffers.Builder, searchOffset:flatbuffers.Offset) {
  builder.addFieldOffset(12, searchOffset, 0);
}

static addSharedFolderCreate(builder:flatbuffers.Builder, sharedFolderCreateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(13, sharedFolderCreateOffset, 0);
}

static addSharedFolderProperties(builder:flatbuffers.Builder, sharedFolderPropertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(14, sharedFolderPropertiesOffset, 0);
}

static addSharedFolderMembership(builder:flatbuffers.Builder, sharedFolderMembershipOffset:flatbuffers.Offset) {
  builder.addFieldOffset(15, sharedFolderMembershipOffset, 0);
}

static addUserFind(builder:flatbuffers.Builder, userFindOffset:flatbuffers.Offset) {
  builder.addFieldOffset(16, userFindOffset, 0);
}

static addUserUpdate(builder:flatbuffers.Builder, userUpdateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(17, userUpdateOffset, 0);
}

static addFileFind(builder:flatbuffers.Builder, fileFindOffset:flatbuffers.Offset) {
  builder.addFieldOffset(18, fileFindOffset, 0);
}

static addSearchFind(builder:flatbuffers.Builder, searchFindOffset:flatbuffers.Offset) {
  builder.addFieldOffset(19, searchFindOffset, 0);
}

static endRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
