import { Gesture } from "./BaseGesture"

export default function () {
  const globals = useGlobals()
  const { isPrimaryClick, gestureState, gestureLayers } = useGestures()
  const { panelState, hidePanel, showPanel } = usePanel()

  class PanelDragToResize extends Gesture {
    layer = gestureLayers.panel

    #isDragging = false
    #dragStartX = 0
    #panelSizeStart = 0

    #panelMinWidth = 120
    #panelMaxWidth = 480

    reset(): void {
      this.#isDragging = false
      this.#dragStartX = 0
      this.#panelSizeStart = 0
    }

    async onMouseDown(
      mouse: MouseEvent,
    ) {
      if (!isPrimaryClick(mouse))
        return this.reset()
      if (!getFirstMatchingParent(
        mouse.target,
        t => t.id === globals.panelDraggerId,
      ))
        return this.reset()
      if (!gestureState.value.mouseDown)
        throw new Error("No mouse position to use")

      this.capture()
      this.#isDragging = true
      this.switchMode("drag-divider")
      this.#dragStartX = gestureState.value.mouseDown.clientX
      this.#panelSizeStart = panelState.value.width
      this.console.log("User clicked on panel dragger, resizing")
    }

    async onMouseMove(
      mouse: MouseEvent,
    ) {
      if (!this.#isDragging)
        return this.reset()

      this.capture()
      const distX = mouse.pageX - this.#dragStartX
      if (this.#panelMinWidth > (this.#panelSizeStart - distX)) {
        hidePanel()
        panelState.value.width = this.#panelSizeStart
      }
      else {
        showPanel()
        panelState.value.width = clamp(
          this.#panelSizeStart - distX,
          this.#panelMinWidth,
          this.#panelMaxWidth,
        )
      }
    }

    async onMouseUp(
      _mouse: MouseEvent,
    ) {
      if (!this.#isDragging)
        return
      this.reset()
    }
  }
  return new PanelDragToResize()
}
