// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class EntityPropertyGroupIdBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):EntityPropertyGroupIdBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

id():number {
  return this.bb!.readUint32(this.bb_pos);
}

static sizeOf():number {
  return 4;
}

static createEntityPropertyGroupIdBuf(builder:flatbuffers.Builder, id: number):flatbuffers.Offset {
  builder.prep(4, 4);
  builder.writeInt32(id);
  return builder.offset();
}

}
