import type { InterfaceFile } from "~/classes/file"
import { ViewModeType } from "~/classes/generated/interface"

import { Property } from "~/classes/entity"

// TODO: come up with a better way of telling if we are editing a property

const console = useLogger("use-main-view", theme.colors.stroke.lightest)

interface State {
  state: InterfaceModeType
  /**
   * The leaf files all displayed in the UI presently. Note that we don't clear this list
   * before navigation, as it makes it more annoying to browse if the items flash in and out,
   * so instead we leave it up to each of our view modes to strategically clear the view
   * when needed.
   */
  contents: Record<string, InterfaceFile>
  /** Scores represent search result scores per scored item. Not all items may be scored */
  contentScores: Record<string, number>
  contentBounds: Record<string, DOMRect>

  /** The root folder of this view (not the root directory overall) */
  rootFolder?: string
  /** All the folders that are currently open in the view (value is unused) */
  openFolders: Record<string, boolean>
}
let mainViewState: Ref<State>

/** clears the main view state, the current contents, folder */
function clearMainViewState() {
  mainViewState.value.contentBounds = {}
  mainViewState.value.contentScores = {}
  mainViewState.value.contents = {}
}

function isInSearchMode() {
  return mainViewState.value.state === "search-results"
}

/**
 * Adds a file to a folder in the viewport. If the file's parent is not in view, we ignore
 * the request.
 * NOTE: during creation, file's fileId is their client Id. Thus, when adding a file to the
 * view, we also check if it's client id is present and remove that first.
 * @param file the file to add to the current folder
 */
function addFileToFolderView(file: InterfaceFile) {
  const { navigationState } = useNavigation()

  // Clear the file in case it had a client id, and remove old info
  removeFileFromFolderView(file)

  if (!mainViewState.value.rootFolder || !mainViewState.value.openFolders)
    return console.error("Can't add files to view without root and open folders")

  // If we have a root folder of our main view (column and tree view), then we should
  // operate differently -- only add the file if it's parent is in our open folders
  if (mainViewState.value.rootFolder) {
    if (!mainViewState.value.openFolders)
      return console.error("No open folders")
    if (mainViewState.value.openFolders[file.parentId()])
      mainViewState.value.contents[file.fileId] = file
    return
  }

  if (!navigationState.value.file)
    return console.warn("Can't add file to view as there is no current folder", file)
  if (file.parentId() !== navigationState.value.file.fileId)
    return console.warn("Cannot add file to view, as it's parent doesn't match", file)
  mainViewState.value.contents[file.fileId] = file
}

function addFolderToOpenFolders(folder: InterfaceFolderFile) {
  const { updateHistoryState } = useNavigation()
  mainViewState.value.openFolders[folder.fileId] = true
  updateHistoryState()
}

function removeFolderFromOpenFolders(folderId: string) {
  const { updateHistoryState } = useNavigation()
  delete mainViewState.value.openFolders[folderId]
  for (const file of Object.values(mainViewState.value.contents)) {
    if (file.parentId() === folderId)
      removeFileFromFolderView(file)
  }
  updateHistoryState()
}

function openFolderInColumnView(folder: InterfaceFolderFile) {
  const { viewModeState } = useViewMode()
  if (!enumEq(viewModeState.value.current.mode, ViewModeType.Column))
    throw new Error("Not in column view")

  // if the folder is already in the list of open folders we pass
  if (mainViewState.value.openFolders[folder.fileId])
    return console.log("Folder is already open in column view, skipping opening")

  // Now we need to ensure that the only open folders are the ones on this folder's path
  const openFolders: Record<string, boolean> = { [folder.fileId]: true }
  if (!folder.path())
    throw new Error("File has no path")
  for (const file of folder.path()!) {
    openFolders[file.fileId] = true
    if (file.fileId === mainViewState.value.rootFolder)
      break
  }
  setOpenFolders(Object.keys(openFolders))
}

/** Sets the list of open folders and returns a list of folders that were newly added */
function setOpenFolders(folderIds: string[]): string[] {
  const newFolders = []
  const { updateHistoryState } = useNavigation()
  for (const folderId of keys(mainViewState.value.openFolders))
    if (!folderIds.includes(folderId))
      delete mainViewState.value.openFolders[folderId]
  for (const folderId of folderIds) {
    if (!mainViewState.value.openFolders[folderId])
      newFolders.push(folderId)
    mainViewState.value.openFolders[folderId] = true
  }
  for (const file of Object.values(mainViewState.value.contents)) {
    if (!folderIds.includes(file.parentId()))
      removeFileFromFolderView(file)
  }
  updateHistoryState()
  return newFolders
}

function getChildByFileId(el: Element | Document, fileId: string) {
  return el.querySelector(`[data-file-id="${fileId}"]`) || undefined
}

function addFileToSearchView(file: InterfaceFile, score?: number) {
  mainViewState.value.contents[file.fileId] = file
  if (score !== undefined)
    mainViewState.value.contentScores[file.fileId] = score
}

function removeFileFromSearchView(fileId: string) {
  delete mainViewState.value.contents[fileId]
  delete mainViewState.value.contentScores[fileId]
}

/**
 * Removes a file from the current folder in view
 * NOTE: We use both file ids and client ids to show files on screen.
 * @param file The file to remove from the folder
 */
function removeFileFromFolderView(file: InterfaceFile) {
  delete mainViewState.value.contents[file.fileId]
  if (file.hasClientId())
    delete mainViewState.value.contents[file.clientId()!]
}

function _compare(a: InterfaceFile, b: InterfaceFile, sort: Property) {
  switch (sort) {
    case Property.NAME: return naturalCompare(a.name() || "", b.name() || "")
    case Property.SIZE: return (a.size() ?? 0) - (b.size() ?? 0)
    case Property.CREATED_AT: return (a.createdAt()?.getTime() ?? 0) - (b.createdAt()?.getTime() ?? 0)
    case Property.MODIFIED_AT: return (a.modifiedAt()?.getTime() ?? 0) - (b.modifiedAt()?.getTime() ?? 0)
    case Property.WIDTH: return (a.imageWidth() ?? 0) - (b.imageWidth() ?? 0)
    case Property.HEIGHT: return (a.imageHeight() ?? 0) - (b.imageHeight() ?? 0)
    case Property.FILE_RATING: return (a.rating() ?? 0) - (b.rating() ?? 0)
    case Property.VIRTUAL_SEARCH_RELEVANCE: return (mainViewState.value.contentScores[a.fileId] ?? -100) - (mainViewState.value.contentScores[b.fileId] ?? -100)
  }
  throw new Error(`Cannot sort by property ${sort}`)
}

/**
 * Sorts properties by the current view mode sort (not in place). This works by
 * iterating over the different sorts and if the sort is zero, moves onto the next
 * sorting comparator.
 * TODO: should we place undefined values last regardless of sort direction?
 */
function sortByViewModeSort(files: InterfaceFile[]) {
  const { viewModeState } = useViewMode()
  return files.sort((a: InterfaceFile, b: InterfaceFile) => {
    // If there are no sorts, we sort by created at ascending as a default
    // TODO: should this default be transparent to users?
    if (!viewModeState.value.current.sort.length)
      return _compare(a, b, Property.CREATED_AT)
    for (const sort of viewModeState.value.current.sort) {
      const diff = _compare(a, b, sort.propertyId)
      if (diff !== 0)
        return sort.descending ? -diff : diff
    }
    return 0
  })
}

/** A computed value that stores a sorted list of folder contents by folder */
const sortedContents = computed(() => sortByViewModeSort(Object.values(mainViewState.value.contents)))

function updateContentBoundsSync() {
  console.log("Updating content bounds synchronously")
  const { folderDataFileId, mainViewId } = useGlobals()
  const allFiles = document.getElementById(mainViewId)!.querySelectorAll(dataAttr(folderDataFileId))
  allFiles.forEach((item) => {
    const fileId = (item as HTMLDivElement).dataset?.fileId
    if (!fileId)
      return console.error("File in view has no id", item)
    if (!mainViewState.value.contents[fileId])
      return
    const { scrollX, scrollY } = useGestures().gestureState.value.mainView
    const rect = item.getBoundingClientRect()
    mainViewState.value.contentBounds[fileId] = {
      ...rect,
      x: rect.x + scrollX,
      left: rect.left + scrollX,
      right: rect.right + scrollX,
      y: rect.y + scrollY,
      top: rect.top + scrollY,
      bottom: rect.bottom + scrollY,
      width: rect.width,
      height: rect.height,

    }
  })
}

function scrollToFile(fileId: string) {
  const globals = useGlobals()
  const bottomPadding = 4
  const topPadding = globals.mainViewTopBarPadding
  if (!["folder", "search-results"].includes(mainViewState.value.state))
    throw new Error("Cannot scroll to file when not in folder or search mode")
  const fileLocation = mainViewState.value.contentBounds[fileId]
  if (!fileLocation)
    return console.error("Cannot scroll to nonexistent file")
  const container = document.getElementById(globals.mainViewId)
  if (!container)
    throw new Error("Cannot find container to scroll")
  const scrollTop = container.scrollTop
  const scrollBottom = scrollTop + container.clientHeight

  if (fileLocation.bottom > scrollBottom)
    container.scrollTo(0, fileLocation.bottom - container.clientHeight + bottomPadding)
  else if (fileLocation.top < scrollTop)
    container.scrollTo(0, fileLocation.top - topPadding)
}

/**
 * Tell the UI to focus on a file when it is next available, such as when creating
 * a new folder or file. Scrolls to the file and selects it
 * @param _fileId The file id to select
 */
function focusFileWhenAvailable(_fileId: string) {
  throw new Error("Not implemented")
}

function fileUrl(file: InterfaceFile) {
  if (file.isHomeFolder())
    return "/"
  if (file.isArchiveFolder())
    return "/archive"
  if (file.isFolder())
    return `/folder/${file.fileId}`
  return `/file/${file.fileId}`
}

export default function () {
  mainViewState = useState("main-view-state", () => ({
    state: "folder" as InterfaceModeType,
    search: {
      scores: {}, // no search details
    },
    contents: {}, // files in folder
    contentScores: {},
    contentBounds: {},
    sortBy: Property.CREATED_AT,
    descending: false,

    openFolders: {},
  }))

  return {
    mainViewState,
    isInSearchMode,
    fileUrl,
    addFileToFolderView,
    removeFileFromFolderView,
    // updateContentBounds,
    updateContentBoundsSync,
    addFolderToOpenFolders,
    removeFolderFromOpenFolders,
    openFolderInColumnView,
    setOpenFolders,
    sortedContents,
    scrollToFile,
    addFileToSearchView,
    removeFileFromSearchView,
    focusFileWhenAvailable,
    clearMainViewState,
    getChildByFileId,
    sortByViewModeSort,
  }
}
