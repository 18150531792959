// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class DidPermanentlyDisconnectBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):DidPermanentlyDisconnectBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsDidPermanentlyDisconnectBuf(bb:flatbuffers.ByteBuffer, obj?:DidPermanentlyDisconnectBuf):DidPermanentlyDisconnectBuf {
  return (obj || new DidPermanentlyDisconnectBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsDidPermanentlyDisconnectBuf(bb:flatbuffers.ByteBuffer, obj?:DidPermanentlyDisconnectBuf):DidPermanentlyDisconnectBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new DidPermanentlyDisconnectBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static startDidPermanentlyDisconnectBuf(builder:flatbuffers.Builder) {
  builder.startObject(0);
}

static endDidPermanentlyDisconnectBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createDidPermanentlyDisconnectBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  DidPermanentlyDisconnectBuf.startDidPermanentlyDisconnectBuf(builder);
  return DidPermanentlyDisconnectBuf.endDidPermanentlyDisconnectBuf(builder);
}
}
