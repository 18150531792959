// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileBuf } from '../file/file-buf.js';
import { SearchV1ImageSearchFilterBuf } from '../search/search-v1-image-search-filter-buf.js';
import { SearchV1ImageSearchSortBuf } from '../search/search-v1-image-search-sort-buf.js';
import { SearchV1InFolderMatcherBuf } from '../search/search-v1-in-folder-matcher-buf.js';
import { SearchV1NumberPropertySearchFilterBuf } from '../search/search-v1-number-property-search-filter-buf.js';
import { SearchV1PropertySearchSortBuf } from '../search/search-v1-property-search-sort-buf.js';
import { SearchV1TextPropertySearchFilterBuf } from '../search/search-v1-text-property-search-filter-buf.js';
import { UserBuf } from '../user/user-buf.js';


export class SearchV1QueryBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1QueryBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1QueryBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1QueryBuf):SearchV1QueryBuf {
  return (obj || new SearchV1QueryBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1QueryBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1QueryBuf):SearchV1QueryBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1QueryBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

explanation():string|null
explanation(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
explanation(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

searchInFolder(obj?:SearchV1InFolderMatcherBuf):SearchV1InFolderMatcherBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new SearchV1InFolderMatcherBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

imageSearch(obj?:SearchV1ImageSearchFilterBuf):SearchV1ImageSearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new SearchV1ImageSearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

imageSort(obj?:SearchV1ImageSearchSortBuf):SearchV1ImageSearchSortBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new SearchV1ImageSearchSortBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

propertySort(obj?:SearchV1PropertySearchSortBuf):SearchV1PropertySearchSortBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new SearchV1PropertySearchSortBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fullText(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

createdAt(obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

modifiedAt(obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

name(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sizeBytes(obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

notes(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

tags(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

rating(obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

mimeType(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 30);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

imageWidth(obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 32);
  return offset ? (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

imageHeight(obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 34);
  return offset ? (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

imageDimensions(obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 36);
  return offset ? (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

generationPrompt(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 38);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

generationNegativePrompt(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 40);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

generationSource(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 42);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

generationModel(obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 44);
  return offset ? (obj || new SearchV1TextPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

generationSeed(obj?:SearchV1NumberPropertySearchFilterBuf):SearchV1NumberPropertySearchFilterBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 46);
  return offset ? (obj || new SearchV1NumberPropertySearchFilterBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

referencedFiles(index: number, obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 48);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

referencedFilesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 48);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

referencedUsers(index: number, obj?:UserBuf):UserBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 50);
  return offset ? (obj || new UserBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

referencedUsersLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 50);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startSearchV1QueryBuf(builder:flatbuffers.Builder) {
  builder.startObject(24);
}

static addExplanation(builder:flatbuffers.Builder, explanationOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, explanationOffset, 0);
}

static addSearchInFolder(builder:flatbuffers.Builder, searchInFolderOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, searchInFolderOffset, 0);
}

static addImageSearch(builder:flatbuffers.Builder, imageSearchOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, imageSearchOffset, 0);
}

static addImageSort(builder:flatbuffers.Builder, imageSortOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, imageSortOffset, 0);
}

static addPropertySort(builder:flatbuffers.Builder, propertySortOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, propertySortOffset, 0);
}

static addFullText(builder:flatbuffers.Builder, fullTextOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, fullTextOffset, 0);
}

static addCreatedAt(builder:flatbuffers.Builder, createdAtOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, createdAtOffset, 0);
}

static addModifiedAt(builder:flatbuffers.Builder, modifiedAtOffset:flatbuffers.Offset) {
  builder.addFieldOffset(7, modifiedAtOffset, 0);
}

static addName(builder:flatbuffers.Builder, nameOffset:flatbuffers.Offset) {
  builder.addFieldOffset(8, nameOffset, 0);
}

static addSizeBytes(builder:flatbuffers.Builder, sizeBytesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, sizeBytesOffset, 0);
}

static addNotes(builder:flatbuffers.Builder, notesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(10, notesOffset, 0);
}

static addTags(builder:flatbuffers.Builder, tagsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(11, tagsOffset, 0);
}

static addRating(builder:flatbuffers.Builder, ratingOffset:flatbuffers.Offset) {
  builder.addFieldOffset(12, ratingOffset, 0);
}

static addMimeType(builder:flatbuffers.Builder, mimeTypeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(13, mimeTypeOffset, 0);
}

static addImageWidth(builder:flatbuffers.Builder, imageWidthOffset:flatbuffers.Offset) {
  builder.addFieldOffset(14, imageWidthOffset, 0);
}

static addImageHeight(builder:flatbuffers.Builder, imageHeightOffset:flatbuffers.Offset) {
  builder.addFieldOffset(15, imageHeightOffset, 0);
}

static addImageDimensions(builder:flatbuffers.Builder, imageDimensionsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(16, imageDimensionsOffset, 0);
}

static addGenerationPrompt(builder:flatbuffers.Builder, generationPromptOffset:flatbuffers.Offset) {
  builder.addFieldOffset(17, generationPromptOffset, 0);
}

static addGenerationNegativePrompt(builder:flatbuffers.Builder, generationNegativePromptOffset:flatbuffers.Offset) {
  builder.addFieldOffset(18, generationNegativePromptOffset, 0);
}

static addGenerationSource(builder:flatbuffers.Builder, generationSourceOffset:flatbuffers.Offset) {
  builder.addFieldOffset(19, generationSourceOffset, 0);
}

static addGenerationModel(builder:flatbuffers.Builder, generationModelOffset:flatbuffers.Offset) {
  builder.addFieldOffset(20, generationModelOffset, 0);
}

static addGenerationSeed(builder:flatbuffers.Builder, generationSeedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(21, generationSeedOffset, 0);
}

static addReferencedFiles(builder:flatbuffers.Builder, referencedFilesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(22, referencedFilesOffset, 0);
}

static createReferencedFilesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startReferencedFilesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addReferencedUsers(builder:flatbuffers.Builder, referencedUsersOffset:flatbuffers.Offset) {
  builder.addFieldOffset(23, referencedUsersOffset, 0);
}

static createReferencedUsersVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startReferencedUsersVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endSearchV1QueryBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 48) // referenced_files
  builder.requiredField(offset, 50) // referenced_users
  return offset;
}

}
