import * as flatbuffers from "flatbuffers"
import { addUserToBuf, createRequestIdBuf, parseRequestId, parseUser } from "~/utils/flatbuffers"
import { RequestDatagramBuf, UserUpdateRequestDatagramBuf } from "~/classes/generated/request"
import { DatagramBuf } from "~/classes/generated/datagram"
import type { UserUpdateResponseDatagramBuf } from "~/classes/generated/response"
import type { UserUpdateMessageDatagramBuf } from "~/classes/generated/message"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"
import { parseMaybeUser } from "~/utils/flatbuffers/user"
import { InterfaceUser } from "~/classes/users/InterfaceUser"

const console = useLogger("user/update")

export function sendUserUpdateRequest(request: UserUpdateRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  const user = addUserToBuf(fbb, request.user)
  UserUpdateRequestDatagramBuf.startUserUpdateRequestDatagramBuf(fbb)
  UserUpdateRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  UserUpdateRequestDatagramBuf.addUser(fbb, user)
  const updateRequest = UserUpdateRequestDatagramBuf.endUserUpdateRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addUserUpdate(fbb, updateRequest)
  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

/** Parse a Flatbuffers representation of a response */
export function handleUserUpdateResponseDatagramBuf(datagram: UserUpdateResponseDatagramBuf) {
  // TODO: get rid of datagram result, and find out a better way to
  // error out of this, since we need to send notifications
  onUserUpdateResponse({
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    user: parseMaybeUser(datagram.user()),
  })
}

export function handleUserUpdateMessageDatagramBuf(message: UserUpdateMessageDatagramBuf) {
  const user = parseUser(message.user()!)

  updateUserInView(new InterfaceUser(user))
}

export function onUserUpdateResponse(response: UserUpdateResponseDatagram) {
  if (response.error)
    return onUserUpdateErrored(response.error, response.requestId)
  if (!response.user)
    throw new Error("No user in update response")

  const iUser = new InterfaceUser(response.user)
  console.debug("User updated", response.user)
  updateUserInView(iUser)
}

export function onUserUpdateErrored(
  error: DatagramError,
  _requestId: string,
) {
  const { notifyDatagramError } = useNotifications()

  return notifyDatagramError(error, "User update was unsuccessful")
}

function updateUserInView(user: InterfaceUser) {
  return console.warn("Update User In View not implemented")
}
