import type { Property } from "~/classes/entity"
import { InterfaceFile } from "~/classes/file"
import { InterfaceSearch } from "~/classes/search"
import { InterfaceUser } from "~/classes/users/InterfaceUser"

interface State {
  files: Record<string, InterfaceFile> // by file id
  searches: Record<string, InterfaceSearch> // by file id
  width: number
  visible: boolean
}
let sidebarState: Ref<State>

function loadPinnedItemsFromUserInfo(userInfo: SelfInfo) {
  const user = new InterfaceUser(userInfo.user)
  const sidebarFiles = dictByKey(userInfo.sidebarFiles, f => f.fileId)
  const sidebarSearches = dictByKey(userInfo.sidebarSearches, s => s.searchId)

  sidebarState.value.files = {}
  for (const fileId of user.sidebarFileItems().values()) {
    if (!sidebarFiles[fileId])
      console.error("File not found for file id", fileId)
    else sidebarState.value.files[fileId] = InterfaceFile.from(sidebarFiles[fileId])
  }

  sidebarState.value.searches = {}
  for (const searchId of user.sidebarSearchItems().values()) {
    if (!sidebarSearches[searchId])
      console.error("Search not found for search id", searchId)
    else sidebarState.value.searches[searchId] = new InterfaceSearch(sidebarSearches[searchId])
  }
}

/** Helper method that removes a bunch of user properties but doesn't save to the server */
function _removeFromSidebarHelper(files?: InterfaceFile[], searches?: InterfaceSearch[]) {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  const properties: Record<string, UserProperty> = {}
  for (const file of (files ?? [])) {
    const index = fileIndex(file.fileId)
    if (index === undefined)
      continue
    let property: UserProperty | undefined
    property = InterfaceUser.createPropertyForSidebarFile(file.fileId, index)
    property = myself.value.user.property(property.propertyId as Property)
    if (property === undefined)
      continue
    delete myself.value.user._user.properties[property.propertyId]
    property.deleted = true
    property.pending = true
    properties[property.propertyId] = property
  }

  for (const search of (searches ?? [])) {
    const index = searchIndex(search.searchId())
    if (index === undefined)
      continue
    let property: UserProperty | undefined
    property = InterfaceUser.createPropertyForSidebarFile(search.searchId(), index)
    property = myself.value.user.property(property.propertyId as Property)
    if (property === undefined)
      continue
    delete myself.value.user._user.properties[property.propertyId]
    property.deleted = true
    property.pending = true
    properties[property.propertyId] = property
  }

  return properties
}

/** Removes files/searches from the sidebar and updates the server with this info */
function removeFromSidebar(files?: InterfaceFile[], searches?: InterfaceSearch[]) {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  const properties = _removeFromSidebarHelper(files, searches)
  if (len(properties) === 0)
    return console.warn("No properties to remove")
  sendUserUpdateRequest({
    requestId: generateWorkerRequestId(),
    user: { userId: myself.value.user.userId, properties },
  })
}

/**
 * Adds a set of files to the sidebar by spreading out among the fractional index space.
 * This method also removes any files that were there before, so this can be used as a
 * "move" operation as well
 */
function addFilesToSidebar(
  files: InterfaceFile[],
  between: [number | undefined, number | undefined],
) {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  const limit = Number.MAX_VALUE / 64
  const low = between[0] ?? -limit
  const high = between[1] ?? limit
  const gap = high / (files.length + 1.0) - low / (files.length + 1.0)
  if (gap < 0)
    throw new Error("Gap is not supposed to be negative")
  const properties: Record<string, UserProperty> = {}
  for (const [i, file] of enumerate(files)) {
    if (sidebarState.value.files[file.fileId] !== undefined) {
      console.warn("File already exists in sidebar, skipping", file)
      continue
    }
    const noise = (Math.random() - 0.5) * gap / 2
    const index = low + (gap * (i + 1)) + noise
    if (!Number.isFinite(index) || Number.isNaN(index))
      throw new Error("something went wrong with numerical stability")
    const property = InterfaceUser.createPropertyForSidebarFile(file.fileId, index)
    property.pending = true
    properties[property.propertyId] = property
  }

  // remove any files that were there before
  for (const property of Object.values(_removeFromSidebarHelper(files)))
    properties[property.propertyId] = property
  // Add our new properties
  for (const property of Object.values(properties))
    myself.value.user._user.properties[property.propertyId] = property

  sendUserUpdateRequest({
    requestId: generateWorkerRequestId(),
    user: { userId: myself.value.user.userId, properties },
  })

  // Now we can add the file to our local state here safely
  for (const file of files)
    sidebarState.value.files[file.fileId] = file
}

function addFilesToSidebarLast(
  files: InterfaceFile[],
) {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  let max: number | undefined
  for (const [orderedId, _] of myself.value.user.sidebarFileItems().entries())
    if (!max || orderedId > max)
      max = orderedId
  return addFilesToSidebar(files, [max, undefined])
}

function sortedSidebarFiles() {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  const files: [number, InterfaceFile][] = []
  const sortedSidebarFileItems = myself.value.user.sortedSidebarFileItems()
  for (const [orderedId, fileId] of sortedSidebarFileItems) {
    const file = sidebarState.value.files[fileId]
    if (file)
      files.push([orderedId, file])
  }
  return files
}

function sortedSidebarSearches() {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  const searches: [number, InterfaceSearch][] = []
  const sortedSidebarSearchItems = myself.value.user.sortedSidebarSearchItems()
  for (const [orderedId, searchId] of sortedSidebarSearchItems) {
    const search = sidebarState.value.searches[searchId]
    if (search)
      searches.push([orderedId, search])
  }
  return searches
}

function fileIndex(fileId: string): number | undefined {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  for (const [index, sidebarFileId] of myself.value.user.sidebarFileItems().entries()) {
    if (sidebarFileId === fileId)
      return index
  }
}

function searchIndex(searchId: string): number | undefined {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  for (const [index, sidebarFileId] of myself.value.user.sidebarSearchItems().entries()) {
    if (sidebarFileId === searchId)
      return index
  }
}

function isFileInSidebar(file: InterfaceFile) {
  return !!sidebarState.value.files[file.fileId]
}

function isSearchInSidebar(search: InterfaceSearch) {
  return !!sidebarState.value.searches[search.searchId()]
}

function _firstFileItem() {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  const sorted = myself.value.user.sortedSidebarFileItems()
  if (sorted.length > 0)
    return sorted[0]
}

function _lastFileItem() {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  const sorted = myself.value.user.sortedSidebarFileItems()
  if (sorted.length > 0)
    return sorted[sorted.length - 1]
}

function firstFile() {
  const first = _firstFileItem()
  if (first)
    return sidebarState.value.files[first[1]]
}

function lastFile() {
  const last = _lastFileItem()
  if (last)
    return sidebarState.value.files[last[1]]
}

function lastIndex() {
  const last = _lastFileItem()
  if (last)
    return last[0]
}

function _fileItemBefore(fileId: string) {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  let priorIndex: number | undefined
  let priorFileId: string | undefined
  for (const [index, sidebarFileId] of myself.value.user.sortedSidebarFileItems())
    if (sidebarFileId === fileId)
      return [priorIndex!, priorFileId!]
    else {
      priorIndex = index
      priorFileId = sidebarFileId
    }
}

function fileBefore(fileId: string) {
  const item = _fileItemBefore(fileId)
  if (item)
    return sidebarState.value.files[item[1]]
}

function _fileItemAfter(fileId: string) {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  let foundPrior = false
  for (const [index, sidebarFileId] of myself.value.user.sortedSidebarSearchItems())
    if (foundPrior)
      return [index, sidebarFileId]
    else if (sidebarFileId === fileId)
      foundPrior = true
}

function fileAfter(fileId: string) {
  const item = _fileItemAfter(fileId)
  if (item)
    return sidebarState.value.files[item[1]]
}

function showSidebar() {
  sidebarState.value.visible = true
}

function hideSidebar() {
  sidebarState.value.visible = false
}

/**
 * Adds a search to the sidebar by placing it between other search items
 */
function addSearchesToSidebar(
  searches: InterfaceSearch[],
  between: [number | undefined, number | undefined],
) {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  const limit = Number.MAX_VALUE / 64
  const low = between[0] ?? -limit
  const high = between[1] ?? limit
  const gap = high / (searches.length + 1.0) - low / (searches.length + 1.0)
  if (gap < 0)
    throw new Error("Gap is not supposed to be negative")
  const properties: Record<string, UserProperty> = {}
  for (const [i, search] of enumerate(searches)) {
    if (sidebarState.value.searches[search.searchId()] !== undefined) {
      console.warn("Search already exists in sidebar, skipping", search)
      continue
    }
    const noise = (Math.random() - 0.5) * gap / 2
    const index = low + (gap * (i + 1)) + noise
    if (!Number.isFinite(index) || Number.isNaN(index))
      throw new Error("something went wrong with numerical stability")
    const property = InterfaceUser.createPropertyForSidebarSearch(search.searchId(), index)
    property.pending = true
    properties[property.propertyId] = property
  }

  // remove any searches that were there before
  for (const property of Object.values(_removeFromSidebarHelper(undefined, searches)))
    properties[property.propertyId] = property
  // Add our new properties
  for (const property of Object.values(properties))
    myself.value.user._user.properties[property.propertyId] = property

  sendUserUpdateRequest({
    requestId: generateWorkerRequestId(),
    user: { userId: myself.value.user.userId, properties },
  })

  // Now we can add the search to our local state here safely
  for (const search of searches)
    sidebarState.value.searches[search.searchId()] = search
}

function addSearchesToSidebarLast(
  searches: InterfaceSearch[],
) {
  const { myself } = useUser()
  if (!myself.value.user)
    throw new Error("No user")
  let max: number | undefined
  for (const [orderedId, _] of myself.value.user.sidebarSearchItems().entries())
    if (!max || orderedId > max)
      max = orderedId
  return addSearchesToSidebar(searches, [max, undefined])
}

export default function () {
  sidebarState = useState("sidebar-state", () => ({
    files: {},
    searches: {},
    width: 224,
    visible: true,
  }))

  return {
    sidebarState,
    addFilesToSidebar,
    addFilesToSidebarLast,
    removeFromSidebar,
    fileIndex,
    loadPinnedItemsFromUserInfo,
    showSidebar,
    hideSidebar,
    isFileInSidebar,
    isSearchInSidebar,
    sortedSidebarFiles,
    sortedSidebarSearches,
    firstFile,
    lastFile,
    lastIndex,
    fileBefore,
    fileAfter,
    addSearchesToSidebar,
    addSearchesToSidebarLast,
  }
}
