// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileBuf } from '../file/file-buf.js';
import { SearchBuf } from '../search/search-buf.js';
import { SharedFolderInfoBuf } from '../shared-folder/shared-folder-info-buf.js';
import { UserBuf } from '../user/user-buf.js';


export class SelfInfoBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SelfInfoBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSelfInfoBuf(bb:flatbuffers.ByteBuffer, obj?:SelfInfoBuf):SelfInfoBuf {
  return (obj || new SelfInfoBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSelfInfoBuf(bb:flatbuffers.ByteBuffer, obj?:SelfInfoBuf):SelfInfoBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SelfInfoBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

user(obj?:UserBuf):UserBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new UserBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

home(obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

archive(obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sidebarSharedFolders(index: number, obj?:SharedFolderInfoBuf):SharedFolderInfoBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new SharedFolderInfoBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

sidebarSharedFoldersLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

sidebarFiles(index: number, obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

sidebarFilesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

sidebarSearches(index: number, obj?:SearchBuf):SearchBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new SearchBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

sidebarSearchesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startSelfInfoBuf(builder:flatbuffers.Builder) {
  builder.startObject(6);
}

static addUser(builder:flatbuffers.Builder, userOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, userOffset, 0);
}

static addHome(builder:flatbuffers.Builder, homeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, homeOffset, 0);
}

static addArchive(builder:flatbuffers.Builder, archiveOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, archiveOffset, 0);
}

static addSidebarSharedFolders(builder:flatbuffers.Builder, sidebarSharedFoldersOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, sidebarSharedFoldersOffset, 0);
}

static createSidebarSharedFoldersVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSidebarSharedFoldersVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addSidebarFiles(builder:flatbuffers.Builder, sidebarFilesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, sidebarFilesOffset, 0);
}

static createSidebarFilesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSidebarFilesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addSidebarSearches(builder:flatbuffers.Builder, sidebarSearchesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, sidebarSearchesOffset, 0);
}

static createSidebarSearchesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSidebarSearchesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endSelfInfoBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // user
  builder.requiredField(offset, 6) // home
  builder.requiredField(offset, 8) // archive
  builder.requiredField(offset, 10) // sidebar_shared_folders
  builder.requiredField(offset, 12) // sidebar_files
  builder.requiredField(offset, 14) // sidebar_searches
  return offset;
}

}
