import { Gesture } from "./BaseGesture"

export default function () {
  const globals = useGlobals()
  const { clearSelected, isSelectionEmpty } = useSelection()
  const { getMainViewFileFromEvent, isOverMainView, gestureLayers } = useGestures()

  class MainViewBackgroundClickToDeselect extends Gesture {
    layer = gestureLayers.mainViewBackground

    async onMouseDown(
      mouse: MouseEvent,
    ) {
      if (getMainViewFileFromEvent(mouse) || !isOverMainView(mouse))
        return

      if (getFirstMatchingParent(
        mouse.target,
        div => div.classList?.contains(globals.navIgnoreMainViewGestures),
      ))
        return

      if (!isSelectionEmpty()) {
        this.debug("Deselecting selected files")
        clearSelected()
      }
    }
  }
  return new MainViewBackgroundClickToDeselect()
}
