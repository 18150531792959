// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class EntityHandleValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):EntityHandleValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsEntityHandleValueBuf(bb:flatbuffers.ByteBuffer, obj?:EntityHandleValueBuf):EntityHandleValueBuf {
  return (obj || new EntityHandleValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsEntityHandleValueBuf(bb:flatbuffers.ByteBuffer, obj?:EntityHandleValueBuf):EntityHandleValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new EntityHandleValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

handle():string|null
handle(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
handle(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startEntityHandleValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addHandle(builder:flatbuffers.Builder, handleOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, handleOffset, 0);
}

static endEntityHandleValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // handle
  return offset;
}

static createEntityHandleValueBuf(builder:flatbuffers.Builder, handleOffset:flatbuffers.Offset):flatbuffers.Offset {
  EntityHandleValueBuf.startEntityHandleValueBuf(builder);
  EntityHandleValueBuf.addHandle(builder, handleOffset);
  return EntityHandleValueBuf.endEntityHandleValueBuf(builder);
}
}
