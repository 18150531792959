import * as flatbuffers from "flatbuffers"
import { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"
import { RequestDatagramBuf, SharedFolderCreateRequestDatagramBuf } from "~/classes/generated/request"
import { addSharedFolderToBuf, createRequestIdBuf, parseMaybeSharedFolderInfo, parseRequestId } from "~/utils/flatbuffers"
import { DatagramBuf, ErrorBuf } from "~/classes/generated/datagram"

import type { SharedFolderCreateResponseDatagramBuf } from "~/classes/generated/response"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"

const console = useLogger("sharedFolder/create")

export function sendSharedFolderCreateRequest(request: SharedFolderCreateRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  const sharedFolder = addSharedFolderToBuf(fbb, request.sharedFolder)
  SharedFolderCreateRequestDatagramBuf.startSharedFolderCreateRequestDatagramBuf(fbb)
  SharedFolderCreateRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  SharedFolderCreateRequestDatagramBuf.addSharedFolder(fbb, sharedFolder)
  const createRequest = SharedFolderCreateRequestDatagramBuf.endSharedFolderCreateRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addSharedFolderCreate(fbb, createRequest)
  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])

  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))

  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function handleSharedFolderCreateResponseDatagramBuf(datagram: SharedFolderCreateResponseDatagramBuf) {
  onSharedFolderCreateResponse({
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    sharedFolderInfo: parseMaybeSharedFolderInfo(datagram.sharedFolderInfo()),
  })
}

export function onSharedFolderCreateResponse(response: SharedFolderCreateResponseDatagram) {
  const { addSharedFolderInfo, resetSpaceCreationState } = useSharedFolders()

  // TODO: Multiple creations might need us to store the request id
  resetSpaceCreationState()

  if (response.error)
    return onSharedFolderCreateErrored(response.error)
  if (!response.sharedFolderInfo)
    throw new Error("No space info in response")

  console.log("Space created successfully", response.sharedFolderInfo)
  addSharedFolderInfo(new InterfaceSharedFolderInfo(response.sharedFolderInfo))
}

export function onSharedFolderCreateErrored(error: DatagramError) {
  const { notifyDatagramError } = useNotifications()
  return notifyDatagramError(error, "File creation was unsuccessful")
}
