// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityApiIdBuf } from '../entity/entity-api-id-buf.js';


/**
 * Remember that this data is little endian, but the ordering is big endian.
 * So in order to parse this, you need to parse the group into a hex string,
 * then concat it with the keys transformed into a uuid. On js this is:
 * ```js
 * import { UUID } from uuidv7
 * new UUID(new UInt8Array(key))
 * ```
 */
export class EntityIdBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):EntityIdBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

api():EntityApiIdBuf {
  return this.bb!.readUint32(this.bb_pos);
}

key(index: number):number|null {
    return this.bb!.readUint8(this.bb_pos + 4 + index);
}

static sizeOf():number {
  return 20;
}

static createEntityIdBuf(builder:flatbuffers.Builder, api: EntityApiIdBuf, key: number[]|null):flatbuffers.Offset {
  builder.prep(4, 20);

  for (let i = 15; i >= 0; --i) {
    builder.writeInt8((key?.[i] ?? 0));

  }

  builder.writeInt32(api);
  return builder.offset();
}

}
