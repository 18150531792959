import * as flatbuffers from "flatbuffers"
import type { SharedFolderMembershipResponseDatagramBuf } from "~/classes/generated/response"
import { addSharedFolderInfoToBuf, createRequestIdBuf, parseMaybeSharedFolderInfo, parseRequestId, parseSharedFolderInfo } from "~/utils/flatbuffers"
import { RequestDatagramBuf, SharedFolderMembershipRequestDatagramBuf } from "~/classes/generated/request"
import { DatagramBuf } from "~/classes/generated/datagram"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"
import type { SharedFolderMembershipRequestDatagram } from "~/models/polyschema/SharedFolderMembershipRequestDatagram"
import type { SharedFolderMembershipResponseDatagram } from "~/models/polyschema/SharedFolderMembershipResponseDatagram"
import type { SharedFolderMembershipMessageDatagramBuf } from "~/classes/generated/message"
import { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"

const console = useLogger("sharedFolder/membership", theme.colors.teal.hex)

export function sendSharedFolderMembershipRequest(request: SharedFolderMembershipRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  const sharedFolderInfo = addSharedFolderInfoToBuf(fbb, request.sharedFolderInfo)
  SharedFolderMembershipRequestDatagramBuf.startSharedFolderMembershipRequestDatagramBuf(fbb)
  SharedFolderMembershipRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  SharedFolderMembershipRequestDatagramBuf.addSharedFolderInfo(fbb, sharedFolderInfo)
  const buf = SharedFolderMembershipRequestDatagramBuf.endSharedFolderMembershipRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addSharedFolderMembership(fbb, buf)
  const requestDatagram = RequestDatagramBuf.endRequestDatagramBuf(fbb)

  const requestBuf = DatagramBuf.createRequestsVector(fbb, [requestDatagram])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requestBuf)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function handleSharedFolderMembershipResponseDatagramBuf(datagram: SharedFolderMembershipResponseDatagramBuf) {
  const { emitInterfaceEvent } = useEvents()
  const response: SharedFolderMembershipResponseDatagram = {
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    sharedFolderInfo: parseMaybeSharedFolderInfo(datagram.sharedFolderInfo()),
  }
  if (response.error)
    onSharedFolderMembershipErrored(response.error)

  emitInterfaceEvent("SharedFolderMembershipResponse", response)
}

export function onSharedFolderMembershipErrored(error: DatagramError) {
  const { notifyDatagramError } = useNotifications()

  console.error("User find errored", error)
  notifyDatagramError(error, "Failed to find user details")
}

export function handleSharedFolderMembershipMessageDatagramBuf(message: SharedFolderMembershipMessageDatagramBuf) {
  const info = parseSharedFolderInfo(message.sharedFolderInfo()!)
  const { onSharedFolderInfoUpdated } = useSharedFolders()

  onSharedFolderInfoUpdated(new InterfaceSharedFolderInfo(info))
}
