// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { DidConnectBuf } from '../cache/did-connect-buf.js';
import { DidPermanentlyDisconnectBuf } from '../cache/did-permanently-disconnect-buf.js';
import { DidTemporarilyDisconnectBuf } from '../cache/did-temporarily-disconnect-buf.js';
import { LoginBuf } from '../cache/login-buf.js';
import { LogoutBuf } from '../cache/logout-buf.js';


export class CacheBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):CacheBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsCacheBuf(bb:flatbuffers.ByteBuffer, obj?:CacheBuf):CacheBuf {
  return (obj || new CacheBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsCacheBuf(bb:flatbuffers.ByteBuffer, obj?:CacheBuf):CacheBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new CacheBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

login(obj?:LoginBuf):LoginBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new LoginBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

logout(obj?:LogoutBuf):LogoutBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new LogoutBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

didConnect(obj?:DidConnectBuf):DidConnectBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new DidConnectBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

didPermanentlyDisconnect(obj?:DidPermanentlyDisconnectBuf):DidPermanentlyDisconnectBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new DidPermanentlyDisconnectBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

didTemporarilyDisconnect(obj?:DidTemporarilyDisconnectBuf):DidTemporarilyDisconnectBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new DidTemporarilyDisconnectBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startCacheBuf(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addLogin(builder:flatbuffers.Builder, loginOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, loginOffset, 0);
}

static addLogout(builder:flatbuffers.Builder, logoutOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, logoutOffset, 0);
}

static addDidConnect(builder:flatbuffers.Builder, didConnectOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, didConnectOffset, 0);
}

static addDidPermanentlyDisconnect(builder:flatbuffers.Builder, didPermanentlyDisconnectOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, didPermanentlyDisconnectOffset, 0);
}

static addDidTemporarilyDisconnect(builder:flatbuffers.Builder, didTemporarilyDisconnectOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, didTemporarilyDisconnectOffset, 0);
}

static endCacheBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
