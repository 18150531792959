// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { CopyFileFailedMessageBuf } from '../transfer/copy-file-failed-message-buf.js';
import { CopyFilePendingMessageBuf } from '../transfer/copy-file-pending-message-buf.js';
import { CopyFinishedMessageBuf } from '../transfer/copy-finished-message-buf.js';
import { CopyStartedMessageBuf } from '../transfer/copy-started-message-buf.js';
import { CopyStatusMessageBuf } from '../transfer/copy-status-message-buf.js';
import { DownloadFinishedMessageBuf } from '../transfer/download-finished-message-buf.js';
import { DownloadStartedMessageBuf } from '../transfer/download-started-message-buf.js';
import { DownloadStatusMessageBuf } from '../transfer/download-status-message-buf.js';
import { UploadFileFailedMessageBuf } from '../transfer/upload-file-failed-message-buf.js';
import { UploadFilePendingMessageBuf } from '../transfer/upload-file-pending-message-buf.js';
import { UploadFinishedMessageBuf } from '../transfer/upload-finished-message-buf.js';
import { UploadSourceFileFinishedMessageBuf } from '../transfer/upload-source-file-finished-message-buf.js';
import { UploadStartedMessageBuf } from '../transfer/upload-started-message-buf.js';
import { UploadStatusMessageBuf } from '../transfer/upload-status-message-buf.js';


export class TransferMessageBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):TransferMessageBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsTransferMessageBuf(bb:flatbuffers.ByteBuffer, obj?:TransferMessageBuf):TransferMessageBuf {
  return (obj || new TransferMessageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsTransferMessageBuf(bb:flatbuffers.ByteBuffer, obj?:TransferMessageBuf):TransferMessageBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new TransferMessageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

uploadStarted(obj?:UploadStartedMessageBuf):UploadStartedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new UploadStartedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

uploadStatus(obj?:UploadStatusMessageBuf):UploadStatusMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new UploadStatusMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

uploadFilePending(obj?:UploadFilePendingMessageBuf):UploadFilePendingMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new UploadFilePendingMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

uploadFileFailed(obj?:UploadFileFailedMessageBuf):UploadFileFailedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new UploadFileFailedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

uploadSourceFileFinished(obj?:UploadSourceFileFinishedMessageBuf):UploadSourceFileFinishedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new UploadSourceFileFinishedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

uploadFinished(obj?:UploadFinishedMessageBuf):UploadFinishedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new UploadFinishedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

downloadStarted(obj?:DownloadStartedMessageBuf):DownloadStartedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new DownloadStartedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

downloadStatus(obj?:DownloadStatusMessageBuf):DownloadStatusMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? (obj || new DownloadStatusMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

downloadFinished(obj?:DownloadFinishedMessageBuf):DownloadFinishedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? (obj || new DownloadFinishedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

copyStarted(obj?:CopyStartedMessageBuf):CopyStartedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? (obj || new CopyStartedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

copyStatus(obj?:CopyStatusMessageBuf):CopyStatusMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? (obj || new CopyStatusMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

copyFilePending(obj?:CopyFilePendingMessageBuf):CopyFilePendingMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? (obj || new CopyFilePendingMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

copyFileFailed(obj?:CopyFileFailedMessageBuf):CopyFileFailedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? (obj || new CopyFileFailedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

copyFinished(obj?:CopyFinishedMessageBuf):CopyFinishedMessageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 30);
  return offset ? (obj || new CopyFinishedMessageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startTransferMessageBuf(builder:flatbuffers.Builder) {
  builder.startObject(14);
}

static addUploadStarted(builder:flatbuffers.Builder, uploadStartedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, uploadStartedOffset, 0);
}

static addUploadStatus(builder:flatbuffers.Builder, uploadStatusOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, uploadStatusOffset, 0);
}

static addUploadFilePending(builder:flatbuffers.Builder, uploadFilePendingOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, uploadFilePendingOffset, 0);
}

static addUploadFileFailed(builder:flatbuffers.Builder, uploadFileFailedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, uploadFileFailedOffset, 0);
}

static addUploadSourceFileFinished(builder:flatbuffers.Builder, uploadSourceFileFinishedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, uploadSourceFileFinishedOffset, 0);
}

static addUploadFinished(builder:flatbuffers.Builder, uploadFinishedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, uploadFinishedOffset, 0);
}

static addDownloadStarted(builder:flatbuffers.Builder, downloadStartedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, downloadStartedOffset, 0);
}

static addDownloadStatus(builder:flatbuffers.Builder, downloadStatusOffset:flatbuffers.Offset) {
  builder.addFieldOffset(7, downloadStatusOffset, 0);
}

static addDownloadFinished(builder:flatbuffers.Builder, downloadFinishedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(8, downloadFinishedOffset, 0);
}

static addCopyStarted(builder:flatbuffers.Builder, copyStartedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, copyStartedOffset, 0);
}

static addCopyStatus(builder:flatbuffers.Builder, copyStatusOffset:flatbuffers.Offset) {
  builder.addFieldOffset(10, copyStatusOffset, 0);
}

static addCopyFilePending(builder:flatbuffers.Builder, copyFilePendingOffset:flatbuffers.Offset) {
  builder.addFieldOffset(11, copyFilePendingOffset, 0);
}

static addCopyFileFailed(builder:flatbuffers.Builder, copyFileFailedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(12, copyFileFailedOffset, 0);
}

static addCopyFinished(builder:flatbuffers.Builder, copyFinishedOffset:flatbuffers.Offset) {
  builder.addFieldOffset(13, copyFinishedOffset, 0);
}

static endTransferMessageBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
