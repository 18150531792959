import * as flatbuffers from "flatbuffers"
import type { SelfInfoResponseDatagramBuf } from "~/classes/generated/response"
import { createRequestIdBuf, parseFile, parseRequestId, parseSearch, parseUser, parseVector } from "~/utils/flatbuffers"
import { RequestDatagramBuf, SelfInfoRequestDatagramBuf } from "~/classes/generated/request"
import type { ErrorBuf } from "~/classes/generated/datagram"
import { DatagramBuf } from "~/classes/generated/datagram"
import { parseSharedFolderInfo } from "~/utils/flatbuffers/sharedFolder"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"
import SidebarFile from "~/components/ContextMenu/Dropdown/Target/SidebarFile.vue"

const console = useLogger("user/info", theme.colors.pink.hex)

export function sendSelfInfoRequest(request: SelfInfoRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  SelfInfoRequestDatagramBuf.startSelfInfoRequestDatagramBuf(fbb)
  SelfInfoRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  const infoRequest = SelfInfoRequestDatagramBuf.endSelfInfoRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addSelfInfo(fbb, infoRequest)
  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function handleSelfInfoResponseDatagramBuf(datagram: SelfInfoResponseDatagramBuf) {
  const { onUserInfoReceived } = useUser()
  const error = parseMaybeDatagramError(datagram.error())
  if (error)
    return onSelfInfoRequestErrored(error)

  const selfInfo = datagram.selfInfo()
  if (!selfInfo)
    throw new Error("Nothing received")

  const home = parseFile(selfInfo.home()!)
  const archive = parseFile(selfInfo.archive()!)
  const user = parseUser(selfInfo.user()!)

  const sidebarSharedFolders = parseVector(i => selfInfo.sidebarSharedFolders(i), selfInfo.sidebarSharedFoldersLength()).map(parseSharedFolderInfo)
  const sidebarFiles = parseVector(i => selfInfo.sidebarFiles(i), selfInfo.sidebarFilesLength()).map(parseFile)
  const sidebarSearches = parseVector(i => selfInfo.sidebarSearches(i), selfInfo.sidebarSearchesLength()).map(parseSearch)

  const info = {
    requestId: parseRequestId(datagram.requestId()!),
    cacheOnly: datagram.cacheOnly(),
    selfInfo: {
      home,
      archive,
      user,
      sidebarSharedFolders,
      sidebarFiles,
      sidebarSearches,
    },
  }
  console.log("User Info parsed", info)
  onUserInfoReceived(info as any)
}

export function onSelfInfoRequestErrored(error: DatagramError) {
  console.error("Self info errored", error)
  const { logOut } = useAuthentication()
  return logOut()
}
