// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class SizeBytesValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SizeBytesValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSizeBytesValueBuf(bb:flatbuffers.ByteBuffer, obj?:SizeBytesValueBuf):SizeBytesValueBuf {
  return (obj || new SizeBytesValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSizeBytesValueBuf(bb:flatbuffers.ByteBuffer, obj?:SizeBytesValueBuf):SizeBytesValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SizeBytesValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

size():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt('0');
}

static startSizeBytesValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addSize(builder:flatbuffers.Builder, size:bigint) {
  builder.addFieldInt64(0, size, BigInt('0'));
}

static endSizeBytesValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSizeBytesValueBuf(builder:flatbuffers.Builder, size:bigint):flatbuffers.Offset {
  SizeBytesValueBuf.startSizeBytesValueBuf(builder);
  SizeBytesValueBuf.addSize(builder, size);
  return SizeBytesValueBuf.endSizeBytesValueBuf(builder);
}
}
