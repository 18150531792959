import * as flatbuffers from "flatbuffers"
import type { SearchFindResponseDatagramBuf } from "~/classes/generated/response"
import { createRequestIdBuf, parseRequestId } from "~/utils/flatbuffers"
import { DatagramBuf } from "~/classes/generated/datagram"
import { parseMaybeDatagramError, parseVector } from "~/utils/flatbuffers/datagram"
import { parseSearch } from "~/utils/flatbuffers/search"
import { RequestDatagramBuf, SearchFindRequestDatagramBuf } from "~/classes/generated/request"
import { addEntityPropertySelectionToBuf, createEntityPropertyGroupIdBuf, createEntityPropertyIdBuf } from "~/utils/flatbuffers/entity"

const console = useLogger("search/find", theme.colors.purple.hex)

export function sendSearchFindRequest(request: SearchFindRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  let prefix, contains, selection
  if (request.prefix !== undefined)
    prefix = fbb.createString(request.prefix)
  if (request.contains !== undefined)
    contains = SearchFindRequestDatagramBuf.createContainsVector(fbb, request.contains.map(s => fbb.createString(s)))
  if (request.selection !== undefined)
    selection = addEntityPropertySelectionToBuf(fbb, request.selection)

  SearchFindRequestDatagramBuf.startSearchFindRequestDatagramBuf(fbb)
  SearchFindRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  SearchFindRequestDatagramBuf.addLimit(fbb, request.limit)
  SearchFindRequestDatagramBuf.addCacheOnly(fbb, request.cacheOnly)

  if (request.groupId !== undefined)
    SearchFindRequestDatagramBuf.addGroupId(fbb, createEntityPropertyGroupIdBuf(fbb, request.groupId))
  if (request.propertyId !== undefined)
    SearchFindRequestDatagramBuf.addPropertyId(fbb, createEntityPropertyIdBuf(fbb, request.propertyId))
  if (prefix !== undefined)
    SearchFindRequestDatagramBuf.addPrefix(fbb, prefix)
  if (contains !== undefined)
    SearchFindRequestDatagramBuf.addContains(fbb, contains)
  if (selection !== undefined)
    SearchFindRequestDatagramBuf.addSelection(fbb, selection)

  const buf = SearchFindRequestDatagramBuf.endSearchFindRequestDatagramBuf(fbb)
  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addSearchFind(fbb, buf)

  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function handleSearchFindResponseDatagramBuf(datagram: SearchFindResponseDatagramBuf) {
  const { emitInterfaceEvent } = useEvents()
  const response: SearchFindResponseDatagram = {
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    searches: parseVector(i => datagram.searches(i), datagram.searchesLength()).map(parseSearch),
  }
  if (response.error)
    onSearchFindErrored(response.error)
  emitInterfaceEvent("searchFindResponse", response)
}

export function onSearchFindErrored(error: DatagramError) {
  const { notifyDatagramError } = useNotifications()
  console.error("Search lookup errored", error)
  notifyDatagramError(error, "Failed to find search details")
}
