import * as flatbuffers from "flatbuffers"
import type { FileFindResponseDatagramBuf } from "~/classes/generated/response"
import { createEntityIdBuf, createRequestIdBuf, parseRequestId } from "~/utils/flatbuffers"
import { DatagramBuf } from "~/classes/generated/datagram"
import { parseMaybeDatagramError, parseVector } from "~/utils/flatbuffers/datagram"
import { parseFile, parseMaybeFile } from "~/utils/flatbuffers/file"
import { FileFindRequestDatagramBuf, RequestDatagramBuf } from "~/classes/generated/request"
import { addEntityPropertySelectionToBuf, createEntityPropertyGroupIdBuf, createEntityPropertyIdBuf } from "~/utils/flatbuffers/entity"

const console = useLogger("file/find", theme.colors.teal.hex)

export function sendFileFindRequest(request: FileFindRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  let prefix, contains, selection, pathSelection
  if (request.prefix)
    prefix = fbb.createString(request.prefix)
  if (request.contains)
    contains = FileFindRequestDatagramBuf.createContainsVector(fbb, request.contains.map(s => fbb.createString(s)))

  if (request.selection)
    selection = addEntityPropertySelectionToBuf(fbb, request.selection)
  if (request.pathSelection)
    pathSelection = addEntityPropertySelectionToBuf(fbb, request.pathSelection)

  FileFindRequestDatagramBuf.startFileFindRequestDatagramBuf(fbb)
  FileFindRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  FileFindRequestDatagramBuf.addLimit(fbb, request.limit)
  FileFindRequestDatagramBuf.addCacheOnly(fbb, request.cacheOnly)

  if (request.groupId !== undefined)
    FileFindRequestDatagramBuf.addGroupId(fbb, createEntityPropertyGroupIdBuf(fbb, request.groupId))
  if (request.propertyId !== undefined)
    FileFindRequestDatagramBuf.addPropertyId(fbb, createEntityPropertyIdBuf(fbb, request.propertyId))
  if (request.parentId)
    FileFindRequestDatagramBuf.addParentId(fbb, createEntityIdBuf(fbb, request.parentId))
  if (prefix)
    FileFindRequestDatagramBuf.addPrefix(fbb, prefix)
  if (contains)
    FileFindRequestDatagramBuf.addContains(fbb, contains)
  if (selection)
    FileFindRequestDatagramBuf.addSelection(fbb, selection)
  if (pathSelection)
    FileFindRequestDatagramBuf.addPathSelection(fbb, pathSelection)

  const buf = FileFindRequestDatagramBuf.endFileFindRequestDatagramBuf(fbb)
  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addFileFind(fbb, buf)

  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function handleFileFindResponseDatagramBuf(datagram: FileFindResponseDatagramBuf) {
  const { emitInterfaceEvent } = useEvents()
  const response: FileFindResponseDatagram = {
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    files: parseVector(i => datagram.files(i), datagram.filesLength()).map(parseFile),
  }
  if (response.error)
    onFileFindErrored(response.error)
  emitInterfaceEvent("fileFindResponse", response)
}

export function onFileFindErrored(error: DatagramError) {
  const { notifyDatagramError } = useNotifications()
  console.error("file find errored", error)
  notifyDatagramError(error, "Failed to find file details")
}
