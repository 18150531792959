// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileCopyResponseDatagramBuf } from '../response/file-copy-response-datagram-buf.js';
import { FileCreateResponseDatagramBuf } from '../response/file-create-response-datagram-buf.js';
import { FileDeleteResponseDatagramBuf } from '../response/file-delete-response-datagram-buf.js';
import { FileFindResponseDatagramBuf } from '../response/file-find-response-datagram-buf.js';
import { FileMoveResponseDatagramBuf } from '../response/file-move-response-datagram-buf.js';
import { FilePrepareResponseDatagramBuf } from '../response/file-prepare-response-datagram-buf.js';
import { FilePropertiesResponseDatagramBuf } from '../response/file-properties-response-datagram-buf.js';
import { FileShareResponseDatagramBuf } from '../response/file-share-response-datagram-buf.js';
import { FileUpdateResponseDatagramBuf } from '../response/file-update-response-datagram-buf.js';
import { FilesSharedWithSelfResponseDatagramBuf } from '../response/files-shared-with-self-response-datagram-buf.js';
import { FolderListPageResponseDatagramBuf } from '../response/folder-list-page-response-datagram-buf.js';
import { FolderSubscribeResponseDatagramBuf } from '../response/folder-subscribe-response-datagram-buf.js';
import { SearchFindResponseDatagramBuf } from '../response/search-find-response-datagram-buf.js';
import { SearchResponseDatagramBuf } from '../response/search-response-datagram-buf.js';
import { SelfInfoResponseDatagramBuf } from '../response/self-info-response-datagram-buf.js';
import { SharedFolderCreateResponseDatagramBuf } from '../response/shared-folder-create-response-datagram-buf.js';
import { SharedFolderMembershipResponseDatagramBuf } from '../response/shared-folder-membership-response-datagram-buf.js';
import { SharedFolderPropertiesResponseDatagramBuf } from '../response/shared-folder-properties-response-datagram-buf.js';
import { UserFindResponseDatagramBuf } from '../response/user-find-response-datagram-buf.js';
import { UserUpdateResponseDatagramBuf } from '../response/user-update-response-datagram-buf.js';


export class ResponseDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ResponseDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:ResponseDatagramBuf):ResponseDatagramBuf {
  return (obj || new ResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:ResponseDatagramBuf):ResponseDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

fileCopy(obj?:FileCopyResponseDatagramBuf):FileCopyResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new FileCopyResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileCreate(obj?:FileCreateResponseDatagramBuf):FileCreateResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FileCreateResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileDelete(obj?:FileDeleteResponseDatagramBuf):FileDeleteResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FileDeleteResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileMove(obj?:FileMoveResponseDatagramBuf):FileMoveResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new FileMoveResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

filePrepare(obj?:FilePrepareResponseDatagramBuf):FilePrepareResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new FilePrepareResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileProperties(obj?:FilePropertiesResponseDatagramBuf):FilePropertiesResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new FilePropertiesResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileShare(obj?:FileShareResponseDatagramBuf):FileShareResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new FileShareResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

folderSubscribe(obj?:FolderSubscribeResponseDatagramBuf):FolderSubscribeResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? (obj || new FolderSubscribeResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileUpdate(obj?:FileUpdateResponseDatagramBuf):FileUpdateResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? (obj || new FileUpdateResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

folderList(obj?:FolderListPageResponseDatagramBuf):FolderListPageResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? (obj || new FolderListPageResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

filesSharedWithSelf(obj?:FilesSharedWithSelfResponseDatagramBuf):FilesSharedWithSelfResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? (obj || new FilesSharedWithSelfResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

selfInfo(obj?:SelfInfoResponseDatagramBuf):SelfInfoResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 26);
  return offset ? (obj || new SelfInfoResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderCreate(obj?:SharedFolderCreateResponseDatagramBuf):SharedFolderCreateResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 28);
  return offset ? (obj || new SharedFolderCreateResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderProperties(obj?:SharedFolderPropertiesResponseDatagramBuf):SharedFolderPropertiesResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 30);
  return offset ? (obj || new SharedFolderPropertiesResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderMembership(obj?:SharedFolderMembershipResponseDatagramBuf):SharedFolderMembershipResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 32);
  return offset ? (obj || new SharedFolderMembershipResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

userFind(obj?:UserFindResponseDatagramBuf):UserFindResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 34);
  return offset ? (obj || new UserFindResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

search(obj?:SearchResponseDatagramBuf):SearchResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 36);
  return offset ? (obj || new SearchResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

userUpdate(obj?:UserUpdateResponseDatagramBuf):UserUpdateResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 38);
  return offset ? (obj || new UserUpdateResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

fileFind(obj?:FileFindResponseDatagramBuf):FileFindResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 40);
  return offset ? (obj || new FileFindResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

searchFind(obj?:SearchFindResponseDatagramBuf):SearchFindResponseDatagramBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 42);
  return offset ? (obj || new SearchFindResponseDatagramBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startResponseDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(20);
}

static addFileCopy(builder:flatbuffers.Builder, fileCopyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, fileCopyOffset, 0);
}

static addFileCreate(builder:flatbuffers.Builder, fileCreateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, fileCreateOffset, 0);
}

static addFileDelete(builder:flatbuffers.Builder, fileDeleteOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, fileDeleteOffset, 0);
}

static addFileMove(builder:flatbuffers.Builder, fileMoveOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, fileMoveOffset, 0);
}

static addFilePrepare(builder:flatbuffers.Builder, filePrepareOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, filePrepareOffset, 0);
}

static addFileProperties(builder:flatbuffers.Builder, filePropertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, filePropertiesOffset, 0);
}

static addFileShare(builder:flatbuffers.Builder, fileShareOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, fileShareOffset, 0);
}

static addFolderSubscribe(builder:flatbuffers.Builder, folderSubscribeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(7, folderSubscribeOffset, 0);
}

static addFileUpdate(builder:flatbuffers.Builder, fileUpdateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(8, fileUpdateOffset, 0);
}

static addFolderList(builder:flatbuffers.Builder, folderListOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, folderListOffset, 0);
}

static addFilesSharedWithSelf(builder:flatbuffers.Builder, filesSharedWithSelfOffset:flatbuffers.Offset) {
  builder.addFieldOffset(10, filesSharedWithSelfOffset, 0);
}

static addSelfInfo(builder:flatbuffers.Builder, selfInfoOffset:flatbuffers.Offset) {
  builder.addFieldOffset(11, selfInfoOffset, 0);
}

static addSharedFolderCreate(builder:flatbuffers.Builder, sharedFolderCreateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(12, sharedFolderCreateOffset, 0);
}

static addSharedFolderProperties(builder:flatbuffers.Builder, sharedFolderPropertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(13, sharedFolderPropertiesOffset, 0);
}

static addSharedFolderMembership(builder:flatbuffers.Builder, sharedFolderMembershipOffset:flatbuffers.Offset) {
  builder.addFieldOffset(14, sharedFolderMembershipOffset, 0);
}

static addUserFind(builder:flatbuffers.Builder, userFindOffset:flatbuffers.Offset) {
  builder.addFieldOffset(15, userFindOffset, 0);
}

static addSearch(builder:flatbuffers.Builder, searchOffset:flatbuffers.Offset) {
  builder.addFieldOffset(16, searchOffset, 0);
}

static addUserUpdate(builder:flatbuffers.Builder, userUpdateOffset:flatbuffers.Offset) {
  builder.addFieldOffset(17, userUpdateOffset, 0);
}

static addFileFind(builder:flatbuffers.Builder, fileFindOffset:flatbuffers.Offset) {
  builder.addFieldOffset(18, fileFindOffset, 0);
}

static addSearchFind(builder:flatbuffers.Builder, searchFindOffset:flatbuffers.Offset) {
  builder.addFieldOffset(19, searchFindOffset, 0);
}

static endResponseDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
