import { uuidv4 } from "uuidv7"

/**
 * Comlink events are basically json messages that follow a specific protocol.
 * If we want to listen for messages in addition to allowing comlink access,
 * we must filter out comlink messages.
 * @param event the event in question
 * @returns true if the event is a comlink event
 */
export function isComlinkMessageEvent(event: MessageEvent) {
  const message = event.data
  return "type" in message && ["RAW", "HANDLER"].includes(message.type)
}

/**
 * Generates a new request id for use in worker communications
 * @returns a v4 uuid
 */
export function generateWorkerRequestId() {
  return uuidv4()
}
