// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileBuf } from '../file/file-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class FileUpdateRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FileUpdateRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFileUpdateRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FileUpdateRequestDatagramBuf):FileUpdateRequestDatagramBuf {
  return (obj || new FileUpdateRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFileUpdateRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FileUpdateRequestDatagramBuf):FileUpdateRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FileUpdateRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

file(obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startFileUpdateRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addFile(builder:flatbuffers.Builder, fileOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, fileOffset, 0);
}

static endFileUpdateRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // file
  return offset;
}

}
