// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class S3ThumbnailUriValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):S3ThumbnailUriValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsS3ThumbnailUriValueBuf(bb:flatbuffers.ByteBuffer, obj?:S3ThumbnailUriValueBuf):S3ThumbnailUriValueBuf {
  return (obj || new S3ThumbnailUriValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsS3ThumbnailUriValueBuf(bb:flatbuffers.ByteBuffer, obj?:S3ThumbnailUriValueBuf):S3ThumbnailUriValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new S3ThumbnailUriValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

thumbnailUri():string|null
thumbnailUri(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
thumbnailUri(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startS3ThumbnailUriValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addThumbnailUri(builder:flatbuffers.Builder, thumbnailUriOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, thumbnailUriOffset, 0);
}

static endS3ThumbnailUriValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // thumbnail_uri
  return offset;
}

static createS3ThumbnailUriValueBuf(builder:flatbuffers.Builder, thumbnailUriOffset:flatbuffers.Offset):flatbuffers.Offset {
  S3ThumbnailUriValueBuf.startS3ThumbnailUriValueBuf(builder);
  S3ThumbnailUriValueBuf.addThumbnailUri(builder, thumbnailUriOffset);
  return S3ThumbnailUriValueBuf.endS3ThumbnailUriValueBuf(builder);
}
}
