import { ViewModeType } from "../generated/interface"
import { Gesture } from "./BaseGesture"

export default function () {
  const { getMainViewFileFromEvent, isPrimaryClick, isOverMainView, gestureLayers, gestureState } = useGestures()
  const { isSelected } = useSelection()
  const { mainViewState } = useMainView()
  const { emitInterfaceEvent } = useEvents()
  const { viewModeState } = useViewMode()

  class ColumnViewClickToExpandFolder extends Gesture {
    layer = gestureLayers.viewMode

    /** Not used but may be needed later? */
    #didSelect = false

    reset() {
      this.#didSelect = false
    }

    async onMouseDown(mouse: MouseEvent) {
      if (
        !isPrimaryClick(mouse)
        || !["folder", "search-results"].includes(mainViewState.value.state)
        || !enumEq(viewModeState.value.current.mode, ViewModeType.Column)
        || !isOverMainView(mouse)
        || gestureState.value.keysPressed.has("Shift")
      )
        return

      const file = getMainViewFileFromEvent(mouse)
      if (!file || !file.isFolder())
        return
      if (isSelected(file.fileId) || mainViewState.value.openFolders[file.fileId])
        return this.console.warn("Skipping column view open since file is selected/open")

      // Don't capture this click as we want to also select the folder
      this.debug("Clicked folder on column view, opening", file)
      this.#didSelect = true
      emitInterfaceEvent("afterExpandedFolder", file as InterfaceFolderFile)
    }

    async onMouseUp(_: MouseEvent) {
      return this.reset()
    }
  }
  return new ColumnViewClickToExpandFolder()
}
