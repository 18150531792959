// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { SharedFolderPropertyBuf } from '../shared-folder/shared-folder-property-buf.js';


export class SharedFolderBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SharedFolderBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSharedFolderBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderBuf):SharedFolderBuf {
  return (obj || new SharedFolderBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSharedFolderBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderBuf):SharedFolderBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SharedFolderBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

sharedFolderId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

properties(index: number, obj?:SharedFolderPropertyBuf):SharedFolderPropertyBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new SharedFolderPropertyBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

propertiesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startSharedFolderBuf(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addSharedFolderId(builder:flatbuffers.Builder, sharedFolderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, sharedFolderIdOffset, 0);
}

static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, propertiesOffset, 0);
}

static createPropertiesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endSharedFolderBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // shared_folder_id
  return offset;
}

static createSharedFolderBuf(builder:flatbuffers.Builder, sharedFolderIdOffset:flatbuffers.Offset, propertiesOffset:flatbuffers.Offset):flatbuffers.Offset {
  SharedFolderBuf.startSharedFolderBuf(builder);
  SharedFolderBuf.addSharedFolderId(builder, sharedFolderIdOffset);
  SharedFolderBuf.addProperties(builder, propertiesOffset);
  return SharedFolderBuf.endSharedFolderBuf(builder);
}
}
