import { Gesture } from "./BaseGesture"

export default function () {
  const { mainViewState } = useMainView()
  const globals = useGlobals()
  const { showContextMenu, hideContextMenu } = useContextMenu()
  const { navigationState } = useNavigation()
  const { getMainViewFileFromEvent, isContextMenuClick, getSidebarSharedFolderFromEvent, getSidebarFileFromEvent, getPathFileFromEvent, getPathFileElementFromEvent, gestureLayers, getSidebarSearchFromEvent } = useGestures()

  class ContextMenuClick extends Gesture {
    layer = gestureLayers.contextMenu

    #isContextMenuClick = false

    reset(): void {
      this.#isContextMenuClick = false
    }

    async onMouseDown(
      mouse: MouseEvent,
    ) {
      if (!isContextMenuClick(mouse)) {
        // if we are clicking on the context menu itself
        if (!getFirstMatchingParent(mouse.target, div => div.id === globals.contextMenuId))
          hideContextMenu()
        return this.reset()
      }

      if (getFirstMatchingParent(mouse.target, div => div.classList && div.classList?.contains(globals.navIgnoreMainViewGestures))) {
        this.console.log("Ignoring gesture due to ignore-gestures class")
        return this.reset()
      }

      this.console.log("Context menu requested")
      this.#isContextMenuClick = true
      this.capture()

      const sharedFolder = getSidebarSharedFolderFromEvent(mouse)
      if (sharedFolder) {
        this.console.debug("Right clicked on a shared folder", sharedFolder)
        return showContextMenu(mouse, "sidebar-shared-folder", sharedFolder)
      }

      const pathFile = getPathFileFromEvent(mouse)
      if (pathFile) {
        if (pathFile.isHomeFolder())
          return this.console.warn("No right click for home")
        if (pathFile.isArchiveFolder())
          return this.console.warn("No right click for archive")
        this.console.debug("Right-clicked path file!", pathFile)

        const location = getPathFileElementFromEvent(mouse)!.getBoundingClientRect()
        const padBottom = 12
        return showContextMenu(mouse, "path-file", pathFile, location.left, location.bottom + padBottom)
      }

      const searchResultsPill = getFirstMatchingParent(mouse.target, div => div.id === globals.searchResultsIntentPillId)
      if (searchResultsPill) {
        const search = navigationState.value.search
        if (!search)
          throw new Error("No search object, cannot open right click menu")
        this.console.debug("Right-clicked search intent!", search)
        const location = searchResultsPill.getBoundingClientRect()
        const padBottom = 12
        return showContextMenu(mouse, "search-bar", search, location.left, location.bottom + padBottom)
      }

      const sFile = getSidebarFileFromEvent(mouse)
      if (sFile) {
        if (sFile.isHomeFolder())
          return this.console.warn("No right click for home")
        if (sFile.isArchiveFolder())
          return this.console.warn("No right click for archive")
        this.console.debug("Right clicked on a sidebar file!", sFile)
        return showContextMenu(mouse, "sidebar-file", sFile)
      }

      const sSearch = getSidebarSearchFromEvent(mouse)
      if (sSearch) {
        this.console.debug("Right clicked on a sidebar search!", sSearch)
        return showContextMenu(mouse, "sidebar-search", sSearch)
      }

      const file = getMainViewFileFromEvent(mouse)
      if (file) {
        if (file.isHomeFolder())
          return this.console.warn("No right click for home")
        if (file.isArchiveFolder())
          return this.console.warn("No right click for archive")
        this.console.debug("Right clicked on a main view file!", file)
        return showContextMenu(mouse, "main-view-file", file)
      }

      // TODO: sidebar right clicks
      if (getFirstMatchingParent(mouse.target, div => div.id === globals.sidebarId))
        return this.reset()

      if (["folder", "search-results"].includes(mainViewState.value.state))
        return showContextMenu(mouse, "main-background")
      else if (mainViewState.value.state === "preview")
        return showContextMenu(mouse, "image-preview")
    }

    // TODO: detect when to disable the context menu
    async onContextMenu(
      mouse: MouseEvent,
    ) {
      // this.console.log('Native context menu cancelled')
      mouse.preventDefault()
    }

    async onMouseUp(
      _mouse: MouseEvent,
    ) {
      this.reset()
    }

    async onMouseLeave(
      _mouse: MouseEvent,
    ) {
      this.reset()
    }
  }

  return new ContextMenuClick()
}
