// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { EntityPropertySelectionBuf } from '../entity/entity-property-selection-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class SharedFolderPropertiesRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SharedFolderPropertiesRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSharedFolderPropertiesRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderPropertiesRequestDatagramBuf):SharedFolderPropertiesRequestDatagramBuf {
  return (obj || new SharedFolderPropertiesRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSharedFolderPropertiesRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderPropertiesRequestDatagramBuf):SharedFolderPropertiesRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SharedFolderPropertiesRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

sharedFolderId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

selection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSharedFolderPropertiesRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addSharedFolderId(builder:flatbuffers.Builder, sharedFolderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, sharedFolderIdOffset, 0);
}

static addSelection(builder:flatbuffers.Builder, selectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, selectionOffset, 0);
}

static endSharedFolderPropertiesRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // shared_folder_id
  builder.requiredField(offset, 8) // selection
  return offset;
}

}
