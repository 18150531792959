// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class SearchV1ImageSearchSortBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1ImageSearchSortBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1ImageSearchSortBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1ImageSearchSortBuf):SearchV1ImageSearchSortBuf {
  return (obj || new SearchV1ImageSearchSortBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1ImageSearchSortBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1ImageSearchSortBuf):SearchV1ImageSearchSortBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1ImageSearchSortBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

phraseNear():string|null
phraseNear(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
phraseNear(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

phraseFar():string|null
phraseFar(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
phraseFar(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

imageIdNear():string|null
imageIdNear(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
imageIdNear(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

imageIdFar():string|null
imageIdFar(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
imageIdFar(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startSearchV1ImageSearchSortBuf(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addPhraseNear(builder:flatbuffers.Builder, phraseNearOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, phraseNearOffset, 0);
}

static addPhraseFar(builder:flatbuffers.Builder, phraseFarOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, phraseFarOffset, 0);
}

static addImageIdNear(builder:flatbuffers.Builder, imageIdNearOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, imageIdNearOffset, 0);
}

static addImageIdFar(builder:flatbuffers.Builder, imageIdFarOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, imageIdFarOffset, 0);
}

static endSearchV1ImageSearchSortBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSearchV1ImageSearchSortBuf(builder:flatbuffers.Builder, phraseNearOffset:flatbuffers.Offset, phraseFarOffset:flatbuffers.Offset, imageIdNearOffset:flatbuffers.Offset, imageIdFarOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchV1ImageSearchSortBuf.startSearchV1ImageSearchSortBuf(builder);
  SearchV1ImageSearchSortBuf.addPhraseNear(builder, phraseNearOffset);
  SearchV1ImageSearchSortBuf.addPhraseFar(builder, phraseFarOffset);
  SearchV1ImageSearchSortBuf.addImageIdNear(builder, imageIdNearOffset);
  SearchV1ImageSearchSortBuf.addImageIdFar(builder, imageIdFarOffset);
  return SearchV1ImageSearchSortBuf.endSearchV1ImageSearchSortBuf(builder);
}
}
