import type { Property } from "~/classes/entity"
import type { Gesture } from "~/classes/gestures/BaseGesture"

import { BinarySearchTree } from "~/utils/binarySearchTree"

interface GestureState {
  initialized: boolean
  gesture: GestureModeType
  mouseButtonsPressed: Set<number>
  keysPressed: Set<string>
  mainView: {
    scrollX: number
    scrollY: number
  }
  mousePosition?: {
    clientX: number
    clientY: number
  }
  mouseDown?: {
    clientX: number
    clientY: number
  }
  filesDragged?: InterfaceFile[]
  fileDraggedOver?: InterfaceFile
  searchDragged?: InterfaceSearch
  sidebarDragOver: boolean
  sidebarFilesDropBetween?: [number | undefined, number | undefined]
  desktop: {
    dragAndDropHandler?: Function
  }
}

let gestureState: Ref<GestureState>
// const gestures = new BinarySearchTree<Gesture>((a, b) => a.layer - b.layer)
const gestures: Gesture[] = []
const console = useLogger("use-gestures", theme.colors.blue.hex)

/**
 * We use layers to order our gestures handlers, so that we
 * can easily mount a new gesture into the right place.
 */
const gestureLayers = {
  peek: 610,
  drag: 600,
  search: 500,
  contextMenu: 420,
  overlay: 400,
  navigation: 300,
  titleBar: 200,
  sidebar: 160,
  panel: 150,
  viewMode: 140,
  mainViewProperty: 130,
  mainView: 120,
  mainViewBackground: 110,
  base: 100,
  selectionKeyboardShortcut: 90,
  keyboardShortcut: 80,
}

async function initGestures() {
  if (gestureState.value.initialized)
    return console.warn("Gesture state is already initialized, skipping re-initialization")

  document.body.addEventListener("mousedown", onGestureEvent, true)
  document.body.addEventListener("mouseup", onGestureEvent, true)
  document.body.addEventListener("mousemove", onGestureEvent, true)
  document.body.addEventListener("mouseenter", onGestureEvent, true)
  document.body.addEventListener("mouseleave", onGestureEvent, true)
  document.body.addEventListener("contextmenu", onGestureEvent, false)
  document.body.addEventListener("cut", onGestureEvent)
  document.body.addEventListener("copy", onGestureEvent)
  document.body.addEventListener("paste", onGestureEvent)
  document.addEventListener("keydown", onGestureEvent)
  document.addEventListener("keyup", onGestureEvent)
  document.addEventListener("wheel", onGestureEvent)

  if (isDesktop()) {
    console.log("registering drag and drop event")
    const { registerDragAndDropEvent } = useTauri()
    gestureState.value.desktop.dragAndDropHandler = await registerDragAndDropEvent()
  }

  if (window) {
    window.addEventListener("dragover", onGestureEvent, false)
    window.addEventListener("dragenter", onGestureEvent, false)
    window.addEventListener("dragleave", onGestureEvent, false)
    window.addEventListener("drop", onGestureEvent, false)
    window.addEventListener("blur", resetKeyboardState)
    window.addEventListener("focus", resetKeyboardState)
  }

  // Initializes the gesture list, which is traversed from highest to
  // lowest priority to determine the handler for a particular action/event,
  // so ordering does matter here. We use the `capture()` method inside
  // each gesture to signal that this gesture handler has captured the event,
  // and no further handlers can see it.
  // NOTE: The binary tree of gestures preserves insertion order, so these
  // insertions go from lowest to highest priority

  // Sidebar Mouse Gestures
  gestures.push(useSidebarDragToResize())
  gestures.push(useSidebarFileDropToAdd())
  gestures.push(useSidebarFileDragToMove())
  gestures.push(useSidebarItemClickToSelectOrOpen())
  gestures.push(useSidebarBackgroundClickToDeselect())

  // File drop events (need to take precedence over clicks)
  gestures.push(useDropExternalFilesIntoSearch())
  gestures.push(useDragExternalFilesInToUpload())
  gestures.push(useMainViewFileDropToMove())

  // Peek Mouse Gestures
  gestures.push(useMouseGesturesOnPeek())

  // Panel Mouse Gestures
  gestures.push(usePanelClickPropertyToEdit())
  gestures.push(usePanelDragToResize())

  // Search Bar Gestures
  gestures.push(useSearchBarDragToSave())
  gestures.push(useSearchBarFileDragInToSearch())

  // Context Menu Gestures
  gestures.push(useContextMenuClick())

  // Nav Views Gestures
  gestures.push(usePathViewFileClickToOpen())

  // View mode specific gestures
  gestures.push(useTreeViewClickToExpandFolder())
  gestures.push(useColumnViewClickToExpandFolder())
  gestures.push(useColumnViewDragToResize())

  // Main View Gestures
  gestures.push(usePeekViewBackgroundClickToClosePeek())
  gestures.push(useMainViewClickToCloseSearch())
  gestures.push(useMainViewFileDragToMove())
  gestures.push(useMainViewFileClickPropertyToEdit())
  gestures.push(useMainOrWaterfallViewFileClickToSelectOrOpen())
  gestures.push(useMainViewBackgroundClickToStopEditing())
  gestures.push(useMainViewBackgroundClickToDeselect())
  gestures.push(useMainViewBackgroundDragToMultiSelect())
  // Clipboard events
  gestures.push(useKeyboardShortcutsForClipboardActions())

  // Selection keyboard events
  gestures.push(useSelectionKeyboardShortcut())

  // Contextual keyboard events
  gestures.push(useSearchBarEscToClose())
  gestures.push(usePreviewShortcuts())
  gestures.push(useDesktopKeyboardShortcut())
  gestures.push(useGlobalKeyboardShortcut())

  gestures.sort((a, b) => b.layer - a.layer)

  for (const gesture of gestures)
    gesture.onMounted()

  gestureState.value.initialized = true
}

function destroyGestures() {
  document.body.removeEventListener("mousedown", onGestureEvent)
  document.body.removeEventListener("mouseup", onGestureEvent)
  document.body.removeEventListener("mousemove", onGestureEvent)
  document.body.removeEventListener("mouseenter", onGestureEvent)
  document.body.removeEventListener("mouseleave", onGestureEvent)
  document.body.removeEventListener("contextmenu", onGestureEvent)
  document.body.removeEventListener("cut", onGestureEvent)
  document.body.removeEventListener("copy", onGestureEvent)
  document.body.removeEventListener("paste", onGestureEvent)
  document.removeEventListener("keydown", onGestureEvent)
  document.removeEventListener("keyup", onGestureEvent)
  document.removeEventListener("wheel", onGestureEvent)

  if (window) {
    window.removeEventListener("dragover", onGestureEvent)
    window.removeEventListener("dragenter", onGestureEvent)
    window.removeEventListener("dragleave", onGestureEvent)
    window.removeEventListener("drop", onGestureEvent)
    window.removeEventListener("blur", resetKeyboardState)
    window.removeEventListener("focus", resetKeyboardState)
  }

  if (isDesktop() && gestureState.value.desktop.dragAndDropHandler)
    gestureState.value.desktop.dragAndDropHandler()

  for (const gesture of gestures)
    gesture.onUnmounted()

  gestureState.value.initialized = false
}

function mountGesture(gesture: Gesture) {
  for (const existing of gestures)
    if (existing === gesture)
      throw new Error("Cannot add duplicate gesture")
  gestures.push(gesture)
  gestures.sort((a, b) => b.layer - a.layer)

  // If the gestures have already been initialized, we need to mount it here,
  // otherwise it will get mounted by the gesture system anyway
  if (gestureState.value.initialized)
    gesture.onMounted()
}

function unmountGesture(gesture: Gesture) {
  if (!gestureState.value.initialized)
    throw new Error("Gestures not initialized yet")
  const ind = gestures.findIndex(g => g === gesture)
  if (ind === -1)
    return
  gestures.splice(ind, 1)
  gesture.onUnmounted()
}

// TODO: do we want to preventdefault when we capture an action?
async function onGestureEvent(event: MouseEvent | KeyboardEvent | DragEvent | ClipboardEvent | WheelEvent) {
  let callback: (g: Gesture) => Promise<void>

  if (event.type === "mousedown") {
    const mouse = event as MouseEvent
    updateMouseButtonsPressedFromEvent(mouse)
    gestureState.value.mouseDown = {
      clientX: mouse.clientX,
      clientY: mouse.clientY,
    }
    callback = g => g.onMouseDown(mouse)
  }
  else if (event.type === "mouseup") {
    const mouse = event as MouseEvent
    updateMouseButtonsPressedFromEvent(mouse)
    callback = g => g.onMouseUp(mouse)
  }
  else if (event.type === "mousemove") {
    const mouse = event as MouseEvent
    gestureState.value.mousePosition = {
      clientX: mouse.clientX,
      clientY: mouse.clientY,
    }
    callback = g => g.onMouseMove(mouse)
  }
  else if (event.type === "mouseenter") {
    const mouse = event as MouseEvent
    updateMouseButtonsPressedFromEvent(mouse)
    gestureState.value.mousePosition = {
      clientX: mouse.clientX,
      clientY: mouse.clientY,
    }
    callback = g => g.onMouseEnter(mouse)
  }
  else if (event.type === "mouseleave") {
    const mouse = event as MouseEvent
    gestureState.value.mousePosition = undefined
    callback = g => g.onMouseLeave(mouse)
  }
  else if (event.type === "wheel") {
    const wheel = event as WheelEvent
    callback = g => g.onWheel(wheel)
  }
  else if (event.type === "contextmenu") {
    const mouse = event as MouseEvent
    gestureState.value.mousePosition = {
      clientX: mouse.clientX,
      clientY: mouse.clientY,
    }
    callback = g => g.onContextMenu(mouse)
  }
  else if (event.type === "keydown") {
    const keyboard = event as KeyboardEvent
    gestureState.value.keysPressed.add(keyboard.key)
    callback = g => g.onKeyDown(keyboard)
  }
  else if (event.type === "keyup") {
    const keyboard = event as KeyboardEvent
    gestureState.value.keysPressed.delete(keyboard.key)
    callback = g => g.onKeyUp(keyboard)
  }
  else if (event.type === "dragenter") {
    event.preventDefault()
    const drag = event as DragEvent
    callback = g => g.onDragEnter(drag)
  }
  else if (event.type === "dragleave") {
    event.preventDefault()
    const drag = event as DragEvent
    callback = g => g.onDragLeave(drag)
  }
  else if (event.type === "dragover") {
    event.preventDefault()
    const drag = event as DragEvent
    gestureState.value.mousePosition = {
      clientX: drag.clientX,
      clientY: drag.clientY,
    }
    callback = g => g.onDragOver(drag)
  }
  else if (event.type === "drop") {
    event.preventDefault()
    const drag = event as DragEvent
    callback = g => g.onDrop(drag)
  }
  else if (event.type === "cut") {
    const clipboard = event as ClipboardEvent
    callback = g => g.onCut(clipboard)
  }
  else if (event.type === "copy") {
    const clipboard = event as ClipboardEvent
    callback = g => g.onCopy(clipboard)
  }
  else if (event.type === "paste") {
    const clipboard = event as ClipboardEvent
    callback = g => g.onPaste(clipboard)
  }
  else {
    throw new Error(`Unknown event type: ${event.type}`)
  }

  let newMode

  for (const gesture of gestures) {
    // Call the event handler for this particular gesture definition
    await callback(gesture)

    if (gesture.requestedMode) {
      if (newMode) {
        console.error("Multiple gestures requested mode switch", gesture)
        throw new Error("Multiple gestures requested mode switch")
      }
      newMode = gesture.requestedMode
      // console.log("new mode", newMode, gesture)
      gesture.unswitchMode()
    }
    // A gesture can "capture" the gesture stack, meaning they prevent gestures lower
    // in the stack to affect the event. If a gesture captures the stack in this way,
    // we break early (and reset its capture flag)
    if (gesture.isCapturing) {
      gesture.uncapture()
      break
    }
  }
  if (newMode) {
    // console.log('Switching mode', newMode)
    gestureState.value.gesture = newMode
  }

  // Now we must unset any expired gesture variables
  if (event.type === "mouseup")
    resetGestureState()
}

function resetGestureState() {
  gestureState.value.sidebarFilesDropBetween = undefined
  gestureState.value.sidebarDragOver = false
  gestureState.value.fileDraggedOver = undefined
  gestureState.value.filesDragged = undefined
  gestureState.value.searchDragged = undefined
  gestureState.value.mouseDown = undefined
  gestureState.value.gesture = "none"
}

function resetKeyboardState() {
  gestureState.value.keysPressed = new Set()
}

function isEditingTextInput() {
  const activeElement = document.activeElement
  return activeElement?.tagName === "INPUT"
    || activeElement?.tagName === "TEXTAREA"
    || activeElement?.attributes.getNamedItem("contenteditable")
}

/**
 * Determines whether the mouse event was a primary click (e.g. a regular mousedown without
 * a context-menu intent). On mac, users might do ctrl + click to do a context menu click
 * @param event the mouse event firing this
 */
function isPrimaryClick(event?: MouseEvent) {
  return event?.button === 0 && !event.ctrlKey
}

/**
 * Determines whether the mouse event was a context menu click. On mac, users might do ctrl +
 * click to do a context menu click
 * @param event the mouse event firing this
 */
function isContextMenuClick(event?: MouseEvent) {
  return event?.button === 2 || (event?.button === 0 && event.ctrlKey)
}

function isOverMainView(mouse: MouseEvent) {
  const { mainViewId } = useGlobals()
  return getFirstMatchingParent(
    mouse.target,
    e => e.id === mainViewId,
  )
}

function isOverWaterfall(mouse: MouseEvent) {
  const { sidebarWaterfallId } = useGlobals()
  return getFirstMatchingParent(
    mouse.target,
    e => e.id === sidebarWaterfallId,
  )
}

function isOverSidebar(mouse: MouseEvent) {
  const { sidebarId } = useGlobals()
  return getFirstMatchingParent(mouse.target, e => e.id === sidebarId)
}

function isOverPanel(mouse: MouseEvent) {
  const { panelId } = useGlobals()
  return getFirstMatchingParent(mouse.target, e => e.id === panelId)
}

function updateMouseButtonsPressedFromEvent(event: MouseEvent) {
  const allPressed = event.buttons
  if (!allPressed) {
    gestureState.value.mouseButtonsPressed = new Set()
    return
  }
  const pressed = new Set<number>()
  if (allPressed & 1)
    pressed.add(0) // main button
  if (allPressed & 2)
    pressed.add(2) // right button
  if (allPressed & 4)
    pressed.add(1) // middle button
  gestureState.value.mouseButtonsPressed = pressed
}

function getFileIdFromEvent(event: MouseEvent) {
  return getFirstMatchingParent(
    event.target,
    t => !!t.dataset?.fileId,
  )?.dataset?.fileId
}

function getSearchIdFromEvent(event: MouseEvent) {
  return getFirstMatchingParent(
    event.target,
    t => !!t.dataset?.searchId,
  )?.dataset?.searchId
}

function getPathFileIdFromEvent(event: MouseEvent) {
  return getPathFileElementFromEvent(event)?.dataset?.pathFileId
}

function getSharedFolderIdFromEvent(event: MouseEvent) {
  return getFirstMatchingParent(
    event.target,
    t => !!t.dataset?.sharedFolderId,
  )?.dataset?.sharedFolderId
}

function getPropertyIdFromEvent(event: MouseEvent) {
  return getFirstMatchingParent(
    event.target,
    t => !!t.dataset?.propertyId,
  )?.dataset?.propertyId as Property | undefined
}

function getPathFileElementFromEvent(event: MouseEvent) {
  return getFirstMatchingParent(
    event.target,
    t => !!t.dataset?.pathFileId,
  )
}

function getMainViewFileFromEvent(event: MouseEvent) {
  const { mainViewState } = useMainView()
  const fileId = getFileIdFromEvent(event)
  if (!fileId)
    return undefined
  return mainViewState.value.contents[fileId]
}

function getSidebarSearchFromEvent(event: MouseEvent) {
  const { sidebarState } = useSidebar()
  const searchId = getSearchIdFromEvent(event)
  if (!searchId)
    return
  return sidebarState.value.searches[searchId]
}

function getSidebarFileFromEvent(event: MouseEvent) {
  const { sidebarState } = useSidebar()
  const fileId = getFileIdFromEvent(event)
  if (!fileId)
    return
  return sidebarState.value.files[fileId]
}

function getSidebarFileElementFromEvent(event: MouseEvent) {
  const { fileIndex } = useSidebar()
  const element = getFirstMatchingParent(
    event.target,
    t => !!t.dataset?.fileId,
  )
  const fileId = element?.dataset?.fileId
  if (!fileId)
    return
  const index = fileIndex(fileId)
  if (index === undefined)
    return
  return element
}

function getSearchBarSearchFromEvent(event: MouseEvent) {
  // TODO: should we confirm that this comes from the search bar?
  const { navigationState } = useNavigation()
  const searchId = getSearchIdFromEvent(event)
  if (!searchId)
    return
  const search = navigationState.value.search
  if (search?.searchId() === searchId)
    return search
  return
}

function getPathFileFromEvent(event: MouseEvent) {
  const { navigationState } = useNavigation()
  const fileId = getPathFileIdFromEvent(event)
  const pathFile = navigationState.value.file
  if (!fileId || !pathFile)
    return undefined
  if (pathFile.fileId === fileId)
    return pathFile
  for (const file of pathFile.path() || [])
    if (file.fileId === fileId)
      return file
  return console.error("Path file id didn't match anything")
}

function getSidebarSharedFolderFromEvent(event: MouseEvent) {
  const { sharedFolderState } = useSharedFolders()
  const sharedFolderId = getSharedFolderIdFromEvent(event)
  if (!sharedFolderId)
    return undefined
  return sharedFolderState.value.all[sharedFolderId]
}

export default function () {
  gestureState = useState<GestureState>("gesture-data", () => ({
    initialized: false,
    gesture: "none",
    mouseButtonsPressed: new Set(),
    keysPressed: new Set(),
    mainView: { scrollX: 0, scrollY: 0 },
    sidebarDragOver: false,
    desktop: {},
  }))

  return {
    gestureState,
    gestureLayers,
    initGestures,
    mountGesture,
    unmountGesture,
    destroyGestures,
    isPrimaryClick,
    isContextMenuClick,
    isEditingTextInput,
    isOverSidebar,
    isOverPanel,
    isOverMainView,
    isOverWaterfall,
    getMainViewFileFromEvent,
    getPropertyIdFromEvent,
    getSidebarFileFromEvent,
    getSidebarFileElementFromEvent,
    getSidebarSearchFromEvent,
    getSearchBarSearchFromEvent,
    getPathFileElementFromEvent,
    getPathFileFromEvent,
    getSidebarSharedFolderFromEvent,
  }
}
