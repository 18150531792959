import { Gesture } from "./BaseGesture"

export default function () {
  const { sidebarId } = useGlobals()
  const { gestureState, getSearchBarSearchFromEvent, gestureLayers } = useGestures()
  const { addSearchesToSidebarLast } = useSidebar()

  class SearchBarDragToSave extends Gesture {
    layer = gestureLayers.search

    #didDrag = false

    reset() {
      this.#didDrag = false
    }

    isOverSidebar(mouse: MouseEvent) {
      return getFirstMatchingParent(
        mouse.target,
        e => e.id === sidebarId,
      )
    }

    // Dragging to move means we want to
    async onMouseDown(mouse: MouseEvent) {
      const search = getSearchBarSearchFromEvent(mouse)
      if (!search)
        return this.reset()

      this.debug("Mouse down on a search item", search)
      this.capture()
      this.switchMode("drag-search")
      this.#didDrag = true
      gestureState.value.gesture = "drag-search"
      gestureState.value.searchDragged = search
    }

    async onMouseMove(mouse: MouseEvent) {
    // TODO:
    // start drag animation and switch modes
    // keep dragged item on screen as mode is switched

      if (!gestureState.value.mouseDown
        || gestureState.value.gesture !== "drag-search"
      )
        return this.reset()

      this.capture()

      if (this.isOverSidebar(mouse)) {
        if (!gestureState.value.sidebarDragOver)
          gestureState.value.sidebarDragOver = true
      }
    }

    async onMouseUp(mouse: MouseEvent) {
      if (gestureState.value.gesture !== "drag-search" || !this.isOverSidebar(mouse))
        return this.reset()
      if (!gestureState.value.sidebarDragOver)
        throw new Error("sidebarDragOver should be set on mouse up")
      const search = gestureState.value.searchDragged
      if (!search)
        return this.console.error("No search object found to drop")

      this.debug("Dropped search over sidebar, adding", search)
      this.capture()
      this.reset()

      addSearchesToSidebarLast([search])
    }
  }
  return new SearchBarDragToSave()
}
