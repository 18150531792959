import { Gesture } from "./BaseGesture"

export default function () {
  const globals = useGlobals()
  const { isPrimaryClick, gestureState, gestureLayers } = useGestures()
  const { sidebarState, showSidebar, hideSidebar } = useSidebar()

  class SidebarDragToResize extends Gesture {
    layer = gestureLayers.sidebar

    #isDragging = false
    #dragStartX = 0
    #sidebarSizeStart = 0

    #sidebarMinWidth = 120
    #sidebarMaxWidth = 480

    reset(): void {
      this.#isDragging = false
      this.#dragStartX = 0
      this.#sidebarSizeStart = 0
    }

    async onMouseDown(
      mouse: MouseEvent,
    ) {
      if (!isPrimaryClick(mouse))
        return this.reset()
      if (!getFirstMatchingParent(
        mouse.target,
        t => t.id === globals.sidebarDraggerId,
      ))
        return this.reset()
      if (!gestureState.value.mouseDown)
        throw new Error("No mouse position to use")

      this.capture()
      this.#isDragging = true
      this.switchMode("drag-divider")
      this.#dragStartX = gestureState.value.mouseDown.clientX
      this.#sidebarSizeStart = sidebarState.value.width
      this.console.log("User clicked on sidebar dragger, resizing")
    }

    async onMouseMove(
      mouse: MouseEvent,
    ) {
      if (!this.#isDragging)
        return this.reset()

      this.capture()
      const distX = mouse.pageX - this.#dragStartX
      if (this.#sidebarMinWidth > (this.#sidebarSizeStart + distX)) {
        sidebarState.value.width = this.#sidebarSizeStart
        hideSidebar()
      }
      else {
        showSidebar()
        sidebarState.value.width = clamp(
          this.#sidebarSizeStart + distX,
          this.#sidebarMinWidth,
          this.#sidebarMaxWidth,
        )
      }
    }

    async onMouseUp(
      _mouse: MouseEvent,
    ) {
      if (!this.#isDragging)
        return
      this.reset()
    }
  }
  return new SidebarDragToResize()
}
