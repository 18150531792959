// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { TransferIdBuf } from '../transfer/transfer-id-buf.js';


export class UploadStartedMessageBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):UploadStartedMessageBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsUploadStartedMessageBuf(bb:flatbuffers.ByteBuffer, obj?:UploadStartedMessageBuf):UploadStartedMessageBuf {
  return (obj || new UploadStartedMessageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsUploadStartedMessageBuf(bb:flatbuffers.ByteBuffer, obj?:UploadStartedMessageBuf):UploadStartedMessageBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new UploadStartedMessageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

transferId(obj?:TransferIdBuf):TransferIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new TransferIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

folderId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

total():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startUploadStartedMessageBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addTransferId(builder:flatbuffers.Builder, transferIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, transferIdOffset, 0);
}

static addFolderId(builder:flatbuffers.Builder, folderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, folderIdOffset, 0);
}

static addTotal(builder:flatbuffers.Builder, total:number) {
  builder.addFieldInt32(2, total, 0);
}

static endUploadStartedMessageBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // transfer_id
  builder.requiredField(offset, 6) // folder_id
  return offset;
}

}
