// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { SearchResultBuf } from '../search/search-result-buf.js';


export class SearchResultValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchResultValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchResultValueBuf(bb:flatbuffers.ByteBuffer, obj?:SearchResultValueBuf):SearchResultValueBuf {
  return (obj || new SearchResultValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchResultValueBuf(bb:flatbuffers.ByteBuffer, obj?:SearchResultValueBuf):SearchResultValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchResultValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value(obj?:SearchResultBuf):SearchResultBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new SearchResultBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSearchResultValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endSearchResultValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // value
  return offset;
}

static createSearchResultValueBuf(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchResultValueBuf.startSearchResultValueBuf(builder);
  SearchResultValueBuf.addValue(builder, valueOffset);
  return SearchResultValueBuf.endSearchResultValueBuf(builder);
}
}
