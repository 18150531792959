import { Gesture } from "./BaseGesture"

export default function () {
  const { isPrimaryClick, getSidebarFileFromEvent, gestureState, getSidebarSharedFolderFromEvent, isOverSidebar, getSidebarSearchFromEvent, gestureLayers } = useGestures()
  const { addFileToSelection, isSelected, selectFile, selectSearch, deselectFile, clearSelected } = useSelection()
  const { stopEditing } = useProperty()
  const { navigateToFile, navigateToSpace } = useNavigation()
  const { closePeek } = usePeek()
  const { onSavedSearch } = useSearch()

  class SidebarItemClickToSelectOrOpen extends Gesture {
    layer = gestureLayers.sidebar

    #didSelect = false

    reset() {
      this.#didSelect = false
    }

    async onMouseDown(mouse: MouseEvent) {
      if (!isPrimaryClick(mouse) || !isOverSidebar(mouse))
        return

      const SharedFolderInfo = getSidebarSharedFolderFromEvent(mouse)
      if (SharedFolderInfo) {
        // TODO: add shift select
        if (gestureState.value.keysPressed.has("Shift"))
          return this.console.error("Can't shift select shared folders")
        this.capture()
        this.#didSelect = false
        return
      }

      const search = getSidebarSearchFromEvent(mouse)
      if (search) {
        if (gestureState.value.keysPressed.has("Shift"))
          return this.console.error("Shift selecting searches not implemented yet")
        if (isSelected(search.searchId()))
          return // We navigate on mouse up, not down
        this.capture()
        this.#didSelect = true
        this.debug("Selecting search", search)
        selectSearch(search, "sidebar")
      }

      const file = getSidebarFileFromEvent(mouse)
      if (!file)
        return

      // If shift is pressed, do a multi-select
      if (gestureState.value.keysPressed.has("Shift")) {
        this.capture()
        this.#didSelect = true
        this.debug("Multi-select toggling file", file)
        if (isSelected(file.fileId))
          deselectFile(file)
        else
          addFileToSelection(file, "sidebar")
        return
      }

      // If we don't have shift pressed, select
      if (!isSelected(file.fileId)) {
        this.capture()
        this.debug(`Selecting file`, file)
        selectFile(file, "sidebar")
        this.#didSelect = true
      }
    }

    async onMouseUp(mouse: MouseEvent) {
      if (!isPrimaryClick(mouse) || !isOverSidebar(mouse))
        return this.reset()

      // Don't navigate on the same click as select
      if (this.#didSelect)
        return this.reset()

      // if the position of the mouse is where it was on mouse down, then we're a click
      if (gestureState.value.mouseDown?.clientX !== mouse.clientX || gestureState.value.mouseDown?.clientY !== mouse.clientY)
        return this.reset()

      const SharedFolderInfo = getSidebarSharedFolderFromEvent(mouse)
      if (SharedFolderInfo) {
        this.capture()
        stopEditing()
        closePeek()
        navigateToSpace(SharedFolderInfo)
        return this.reset()
      }

      const search = getSidebarSearchFromEvent(mouse)
      if (search) {
        this.console.log("selecting search", search)
        this.capture()
        stopEditing()
        closePeek()
        clearSelected()

        // TODO: we call this instead of a more standard "navigate", because we use
        //       navigate for after running a search as well, but is there a better
        //       way to arrange this?
        onSavedSearch(search.searchId())
        return this.reset()
      }

      const file = getSidebarFileFromEvent(mouse)
      if (!file)
        return this.reset()

      this.capture()
      stopEditing()
      closePeek()
      clearSelected()
      navigateToFile(file)

      return this.reset()
    }
  }
  return new SidebarItemClickToSelectOrOpen()
}
