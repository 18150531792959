// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

/**
 * An ordered Id is equivalent to a uuid, used for ordering purposes
 */
export class OrderedIdBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):OrderedIdBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

id():number {
  return this.bb!.readFloat64(this.bb_pos);
}

static sizeOf():number {
  return 8;
}

static createOrderedIdBuf(builder:flatbuffers.Builder, id: number):flatbuffers.Offset {
  builder.prep(8, 8);
  builder.writeFloat64(id);
  return builder.offset();
}

}
