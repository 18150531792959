// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class BoolValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):BoolValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBoolValueBuf(bb:flatbuffers.ByteBuffer, obj?:BoolValueBuf):BoolValueBuf {
  return (obj || new BoolValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBoolValueBuf(bb:flatbuffers.ByteBuffer, obj?:BoolValueBuf):BoolValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BoolValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

exists():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startBoolValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addExists(builder:flatbuffers.Builder, exists:boolean) {
  builder.addFieldInt8(0, +exists, +false);
}

static endBoolValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createBoolValueBuf(builder:flatbuffers.Builder, exists:boolean):flatbuffers.Offset {
  BoolValueBuf.startBoolValueBuf(builder);
  BoolValueBuf.addExists(builder, exists);
  return BoolValueBuf.endBoolValueBuf(builder);
}
}
