// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { RequestIdBuf } from '../request/request-id-buf.js';


export class SelfInfoRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SelfInfoRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSelfInfoRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SelfInfoRequestDatagramBuf):SelfInfoRequestDatagramBuf {
  return (obj || new SelfInfoRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSelfInfoRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SelfInfoRequestDatagramBuf):SelfInfoRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SelfInfoRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startSelfInfoRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static endSelfInfoRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  return offset;
}

static createSelfInfoRequestDatagramBuf(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset):flatbuffers.Offset {
  SelfInfoRequestDatagramBuf.startSelfInfoRequestDatagramBuf(builder);
  SelfInfoRequestDatagramBuf.addRequestId(builder, requestIdOffset);
  return SelfInfoRequestDatagramBuf.endSelfInfoRequestDatagramBuf(builder);
}
}
