// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { RoleUuidBuf } from '../entity/role-uuid-buf.js';


export class RoleUuidValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):RoleUuidValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsRoleUuidValueBuf(bb:flatbuffers.ByteBuffer, obj?:RoleUuidValueBuf):RoleUuidValueBuf {
  return (obj || new RoleUuidValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsRoleUuidValueBuf(bb:flatbuffers.ByteBuffer, obj?:RoleUuidValueBuf):RoleUuidValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new RoleUuidValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

role(obj?:RoleUuidBuf):RoleUuidBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RoleUuidBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startRoleUuidValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addRole(builder:flatbuffers.Builder, roleOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, roleOffset, 0);
}

static endRoleUuidValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // role
  return offset;
}

static createRoleUuidValueBuf(builder:flatbuffers.Builder, roleOffset:flatbuffers.Offset):flatbuffers.Offset {
  RoleUuidValueBuf.startRoleUuidValueBuf(builder);
  RoleUuidValueBuf.addRole(builder, roleOffset);
  return RoleUuidValueBuf.endRoleUuidValueBuf(builder);
}
}
