import { Gesture } from "./BaseGesture"

export default function () {
  const { getPathFileFromEvent, isPrimaryClick, gestureState, gestureLayers } = useGestures()
  const { stopEditing } = useProperty()
  const { navigateToFile } = useNavigation()
  const { closePeek } = usePeek()

  class PathFileClickToSelectOrOpen extends Gesture {
    layer = gestureLayers.navigation

    #didClick = false

    reset() {
      this.#didClick = false
    }

    async onMouseDown(mouse: MouseEvent) {
      if (!isPrimaryClick(mouse)
      )
        return this.reset()
      const file = getPathFileFromEvent(mouse)
      if (!file)
        return this.reset()

      // TODO: shift-select?
      // // If shift is pressed, do a multi-select
      // if (gestureState.value.keysPressed.has("Shift")) {
      //   this.capture()
      //   this.#didSelect = true
      //   this.debug(`Multi-select toggling file`, file)
      //   if (isSelected(file.fileId))
      //     deselectFile(file)
      //   else
      //     addFileToSelection(file)
      //   return
      // }

      this.capture()
      this.#didClick = true
    }

    async onMouseUp(mouse: MouseEvent) {
      // If the mouse up is not at the same location as mouse down its not a click
      if (!isPrimaryClick(mouse)
        || !this.#didClick
        || gestureState.value.mouseDown?.clientX !== mouse.clientX
        || gestureState.value.mouseDown?.clientY !== mouse.clientY
      )
        return this.reset()
      const file = getPathFileFromEvent(mouse)
      if (!file)
        return this.reset()

      this.capture()
      stopEditing()
      closePeek()
      navigateToFile(file)
      return this.reset()
    }
  }
  return new PathFileClickToSelectOrOpen()
}
