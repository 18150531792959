// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';


export class SearchV1IntentImageBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1IntentImageBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1IntentImageBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentImageBuf):SearchV1IntentImageBuf {
  return (obj || new SearchV1IntentImageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1IntentImageBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentImageBuf):SearchV1IntentImageBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1IntentImageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

/**
 * For external images, they must be uploaded via a signed uri, while for local
 * images, we can just use the internal storage path to the a thumbnail/uri 
 * that is greater than 1024 px.
 */
uri():string|null
uri(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
uri(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

/**
 * An optional file id if the image is in our system
 */
fileId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startSearchV1IntentImageBuf(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addUri(builder:flatbuffers.Builder, uriOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, uriOffset, 0);
}

static addFileId(builder:flatbuffers.Builder, fileIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, fileIdOffset, 0);
}

static endSearchV1IntentImageBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // uri
  return offset;
}

}
