// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { ErrorBuf } from '../datagram/error-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';
import { SharedFolderInfoBuf } from '../shared-folder/shared-folder-info-buf.js';


export class SharedFolderCreateResponseDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SharedFolderCreateResponseDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSharedFolderCreateResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderCreateResponseDatagramBuf):SharedFolderCreateResponseDatagramBuf {
  return (obj || new SharedFolderCreateResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSharedFolderCreateResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderCreateResponseDatagramBuf):SharedFolderCreateResponseDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SharedFolderCreateResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

error(obj?:ErrorBuf):ErrorBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ErrorBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

sharedFolderInfo(obj?:SharedFolderInfoBuf):SharedFolderInfoBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new SharedFolderInfoBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSharedFolderCreateResponseDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addError(builder:flatbuffers.Builder, errorOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, errorOffset, 0);
}

static addSharedFolderInfo(builder:flatbuffers.Builder, sharedFolderInfoOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, sharedFolderInfoOffset, 0);
}

static endSharedFolderCreateResponseDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  return offset;
}

}
