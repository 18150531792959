// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { TransferIdBuf } from '../transfer/transfer-id-buf.js';


export class UploadFinishedMessageBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):UploadFinishedMessageBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsUploadFinishedMessageBuf(bb:flatbuffers.ByteBuffer, obj?:UploadFinishedMessageBuf):UploadFinishedMessageBuf {
  return (obj || new UploadFinishedMessageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsUploadFinishedMessageBuf(bb:flatbuffers.ByteBuffer, obj?:UploadFinishedMessageBuf):UploadFinishedMessageBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new UploadFinishedMessageBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

transferId(obj?:TransferIdBuf):TransferIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new TransferIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

total():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

completed():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

failed():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startUploadFinishedMessageBuf(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addTransferId(builder:flatbuffers.Builder, transferIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, transferIdOffset, 0);
}

static addTotal(builder:flatbuffers.Builder, total:number) {
  builder.addFieldInt32(1, total, 0);
}

static addCompleted(builder:flatbuffers.Builder, completed:number) {
  builder.addFieldInt32(2, completed, 0);
}

static addFailed(builder:flatbuffers.Builder, failed:number) {
  builder.addFieldInt32(3, failed, 0);
}

static endUploadFinishedMessageBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // transfer_id
  return offset;
}

static createUploadFinishedMessageBuf(builder:flatbuffers.Builder, transferIdOffset:flatbuffers.Offset, total:number, completed:number, failed:number):flatbuffers.Offset {
  UploadFinishedMessageBuf.startUploadFinishedMessageBuf(builder);
  UploadFinishedMessageBuf.addTransferId(builder, transferIdOffset);
  UploadFinishedMessageBuf.addTotal(builder, total);
  UploadFinishedMessageBuf.addCompleted(builder, completed);
  UploadFinishedMessageBuf.addFailed(builder, failed);
  return UploadFinishedMessageBuf.endUploadFinishedMessageBuf(builder);
}
}
