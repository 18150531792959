// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { EntityPropertySelectionBuf } from '../entity/entity-property-selection-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';
import { SearchBuf } from '../search/search-buf.js';


export class SearchRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SearchRequestDatagramBuf):SearchRequestDatagramBuf {
  return (obj || new SearchRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SearchRequestDatagramBuf):SearchRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

search(obj?:SearchBuf):SearchBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new SearchBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

folders(index: number, obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 20, this.bb!) : null;
}

foldersLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

pageSize():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

fileSelection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

pathSelection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSearchRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(6);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addSearch(builder:flatbuffers.Builder, searchOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, searchOffset, 0);
}

static addFolders(builder:flatbuffers.Builder, foldersOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, foldersOffset, 0);
}

static startFoldersVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(20, numElems, 4);
}

static addPageSize(builder:flatbuffers.Builder, pageSize:number) {
  builder.addFieldInt32(3, pageSize, 0);
}

static addFileSelection(builder:flatbuffers.Builder, fileSelectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, fileSelectionOffset, 0);
}

static addPathSelection(builder:flatbuffers.Builder, pathSelectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, pathSelectionOffset, 0);
}

static endSearchRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // search
  builder.requiredField(offset, 8) // folders
  return offset;
}

}
