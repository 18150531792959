import { Gesture } from "./BaseGesture"

export default function () {
  const { gestureState, getSidebarFileFromEvent, getSidebarFileElementFromEvent, gestureLayers } = useGestures()
  const { sidebarId } = useGlobals()
  const { addFilesToSidebar, lastIndex } = useSidebar()
  const { selectedFiles } = useSelection()
  const { myself } = useUser()

  class SidebarFileDropToAdd extends Gesture {
    layer = gestureLayers.sidebar

    isOverSidebar(mouse: MouseEvent) {
      return getFirstMatchingParent(
        mouse.target,
        e => e.id === sidebarId,
      )
    }

    async onMouseMove(mouse: MouseEvent) {
      if (!gestureState.value.mouseDown || gestureState.value.gesture !== "drag-file" || !this.isOverSidebar(mouse)) {
        return
      }

      if (!gestureState.value.sidebarDragOver)
        gestureState.value.sidebarDragOver = true

      this.capture()

      const hoveredFile = getSidebarFileFromEvent(mouse)
      if (!hoveredFile) {
        // if we're not hovered over any files then add to the end
        // TODO: can we be smarter and just measure the pixel height instead of using the
        // hover? Otherwise adding to the top will be annoying.
        gestureState.value.sidebarFilesDropBetween = [lastIndex(), undefined]
        gestureState.value.fileDraggedOver = undefined
        return
      }

      if (
        myself.value.home?.fileId === hoveredFile.fileId
        || myself.value.archive?.fileId === hoveredFile.fileId
      ) {
        gestureState.value.sidebarFilesDropBetween = undefined
        gestureState.value.fileDraggedOver = hoveredFile
        return
      }

      const element = getSidebarFileElementFromEvent(mouse)!
      this.console.log("dragging over element", element)

      // check for if you are over a file.
      // if you are, check for where in the height of the element you are
      // if you are in the top or bottom 20%, update your spaceBetween state
      // if you are on top, set the hover and hover target state
      // if you are not either, unset the spaceBetween to set it at the end
    }

    async onMouseUp(mouse: MouseEvent) {
      if (gestureState.value.gesture !== "drag-file" || !this.isOverSidebar(mouse))
        return
      if (!gestureState.value.sidebarDragOver)
        throw new Error("sidebarDragOver should be set on mouse up")

      const files = selectedFiles()
      if (files.length === 0)
        return this.console.warn("No selected items to drop")

      this.debug("Dropped files over sidebar", files)
      this.capture()

      if (gestureState.value.fileDraggedOver !== undefined) {
        this.console.error(
          "Dragging files onto a file to move not defined",
          files,
          gestureState.value.fileDraggedOver,
        )
        // move the file to this folder, if possible.
      }

      // dropBetween is set when we want to drop between files (add to sidebar) rather than
      // drop into an item (move)
      else if (gestureState.value.sidebarFilesDropBetween !== undefined) {
        addFilesToSidebar(files, gestureState.value.sidebarFilesDropBetween)
      }

      this.capture()
    }
  }
  return new SidebarFileDropToAdd()
}
