import { Gesture } from "./BaseGesture"

export default function () {
  const globals = useGlobals()
  const { gestureLayers } = useGestures()
  class MouseGesturesOnPeek extends Gesture {
    layer = gestureLayers.peek

    reset() { }

    async onMouseDown(mouse: MouseEvent) {
      this.console.log("Mouse down mouse gestures on peek")
      if (getFirstMatchingParent(
        mouse.target,
        t => t.id === globals.peekWindowId,
      )) {
        this.console.log("Matched to peek view")
        // If we are in peek view, then we need to cancel gestures so they don't get
        // transmitted to what's behind it.
        // TODO: can we do this in a more elegant way? Perhaps by having a gesture
        // handler akin to the renderer gesture handler?
        return this.capture()
      }
    }
  }
  return new MouseGesturesOnPeek()
}
