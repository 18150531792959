import { Property, PropertySchema } from "../entity"
import { InterfaceFile } from "."
import unknownIcon64 from "~/assets/images/files/unknown/file-unknown@64.webp"
import unknownIcon192 from "~/assets/images/files/unknown/file-unknown@192.webp"
import unknownIcon512 from "~/assets/images/files/unknown/file-unknown@512.webp"
import unknownIcon1536 from "~/assets/images/files/unknown/file-unknown@1536.webp"

import type { File } from "~/models/polyschema/File"

export class InterfaceUnknownFile extends InterfaceFile {
  constructor(file: File) {
    super(file)
  }

  isFolder(): boolean {
    return false
  }

  isImage(): boolean {
    return false
  }

  icon64CropUri() {
    return unknownIcon64
  }

  icon192CropUri() {
    return unknownIcon192
  }

  icon512CropUri() {
    return unknownIcon512
  }

  icon1536CropUri() {
    return unknownIcon1536
  }

  icon64NativeUri() {
    return unknownIcon64
  }

  icon192NativeUri() {
    return unknownIcon192
  }

  icon512NativeUri() {
    return unknownIcon512
  }

  icon1536NativeUri() {
    return unknownIcon1536
  }
}
