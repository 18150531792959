import { Gesture } from "./BaseGesture"

export default function () {
  const globals = useGlobals()
  const { clearSelected, isSelectionEmpty } = useSelection()
  const { getSidebarFileFromEvent, isOverSidebar, gestureLayers } = useGestures()
  const { closePeek } = usePeek()

  class SidebarBackgroundClickToDeselect extends Gesture {
    layer = gestureLayers.sidebar

    async onMouseDown(mouse: MouseEvent) {
      if (getSidebarFileFromEvent(mouse) || !isOverSidebar(mouse))
        return

      if (getFirstMatchingParent(
        mouse.target,
        div => div.classList?.contains(globals.navIgnoreMainViewGestures),
      ))
        return

      if (!isSelectionEmpty()) {
        this.debug("Deselecting selected files")
        clearSelected()
        closePeek()
      }
    }
  }
  return new SidebarBackgroundClickToDeselect()
}
