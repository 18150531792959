// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';


export class SharedFolderIdValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SharedFolderIdValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSharedFolderIdValueBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderIdValueBuf):SharedFolderIdValueBuf {
  return (obj || new SharedFolderIdValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSharedFolderIdValueBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderIdValueBuf):SharedFolderIdValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SharedFolderIdValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

sharedFolderId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startSharedFolderIdValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addSharedFolderId(builder:flatbuffers.Builder, sharedFolderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, sharedFolderIdOffset, 0);
}

static endSharedFolderIdValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // shared_folder_id
  return offset;
}

static createSharedFolderIdValueBuf(builder:flatbuffers.Builder, sharedFolderIdOffset:flatbuffers.Offset):flatbuffers.Offset {
  SharedFolderIdValueBuf.startSharedFolderIdValueBuf(builder);
  SharedFolderIdValueBuf.addSharedFolderId(builder, sharedFolderIdOffset);
  return SharedFolderIdValueBuf.endSharedFolderIdValueBuf(builder);
}
}
