// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { FileBuf } from '../file/file-buf.js';
import { NotificationMessageIdBuf } from '../message/notification-message-id-buf.js';
import { NotificationSenderIdBuf } from '../message/notification-sender-id-buf.js';


export class FolderListPageMessageDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FolderListPageMessageDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFolderListPageMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FolderListPageMessageDatagramBuf):FolderListPageMessageDatagramBuf {
  return (obj || new FolderListPageMessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFolderListPageMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FolderListPageMessageDatagramBuf):FolderListPageMessageDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FolderListPageMessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

messageId(obj?:NotificationMessageIdBuf):NotificationMessageIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new NotificationMessageIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

senderId(obj?:NotificationSenderIdBuf):NotificationSenderIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new NotificationSenderIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

folderId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

files(index: number, obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

filesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

path(index: number, obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

pathLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startFolderListPageMessageDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addMessageId(builder:flatbuffers.Builder, messageIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, messageIdOffset, 0);
}

static addSenderId(builder:flatbuffers.Builder, senderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, senderIdOffset, 0);
}

static addFolderId(builder:flatbuffers.Builder, folderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(2, folderIdOffset, 0);
}

static addFiles(builder:flatbuffers.Builder, filesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, filesOffset, 0);
}

static createFilesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startFilesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addPath(builder:flatbuffers.Builder, pathOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, pathOffset, 0);
}

static createPathVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startPathVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endFolderListPageMessageDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // message_id
  builder.requiredField(offset, 6) // sender_id
  builder.requiredField(offset, 8) // folder_id
  builder.requiredField(offset, 10) // files
  builder.requiredField(offset, 12) // path
  return offset;
}

}
