import { Property } from "~/classes/entity"
import type { PropertyFilterType } from "~/classes/generated/interface"
import { PropertyColor, PropertySize, ViewModeType } from "~/classes/generated/interface"
import ColumnMode from "~/assets/icons/viewmode/column.svg?component"
import DetailMode from "~/assets/icons/viewmode/detail.svg?component"
import FeedMode from "~/assets/icons/viewmode/feed.svg?component"
import GridMode from "~/assets/icons/viewmode/grid.svg?component"
import IconMode from "~/assets/icons/viewmode/icon.svg?component"
import TreeMode from "~/assets/icons/viewmode/tree.svg?component"

interface ViewModeSettings {
  mode: ViewModeType
  show: ViewModeShow[]
  sort: ViewModeSort[]
  filter: ViewModeFilter[]
  /** The widths of each column in column view mode */
  columnView: {
    columns: number[]
  }
  detailView: {
    maxColumnWidth: number // in pixels, between ~100 and viewport width
  }
}
interface ViewModeShow {
  propertyId: Property
  size: PropertySize
  color: PropertyColor
  scale: number | null
}
interface ViewModeSort {
  propertyId: Property
  descending: boolean
}
interface ViewModeFilter {
  propertyId: Property
  type: PropertyFilterType
}

// TODO: persist the view mode as a user setting, requiring us to update the user settings
// whenever we want to save the view mode, and to load the view mode from update messages etc.
// when there is an update to the user's settings.
interface State {
  current: ViewModeSettings
  global?: ViewModeSettings
  byFolder: Record<string, ViewModeSettings>
}
let viewModeState: Ref<State>

const console = useLogger("use-view-mode", theme.colors.purple.hex)
const defaultColumnModeWidth = 220

function switchViewMode(mode: ViewModeType) {
  const { capture } = useAnalytics()
  viewModeState.value.current.mode = mode
  // TODO: if leaving column mode, we can clean up folders that aren't the current one
  // e.g. parents and children

  capture("user_changed_view_mode", { mode })
}

/** Whether the current view mode is hierarchical */
function isHierarchical() {
  return [ViewModeType.Column, ViewModeType.Tree].includes(viewModeState.value.current.mode)
}

/** Gets the property show options iff the current property is being shown, else undefined */
function propertyShowOptions(propertyId: Property) {
  for (const prop of viewModeState.value.current.show)
    if (prop.propertyId === propertyId)
      return prop
}
function isShowingProperty(propertyId: Property) {
  return propertyShowOptions(propertyId) !== undefined
}

function showViewModeProperty(
  propertyId: Property,
  color = PropertyColor.Default,
  size = PropertySize.Small,
) {
  if (isShowingProperty(propertyId))
    return console.error("Already showing view mode property")
  const { capture } = useAnalytics()
  viewModeState.value.current.show = [
    ...viewModeState.value.current.show,
    { propertyId, color, size, scale: null },
  ]
  capture("user_added_view_mode_property", { propertyId })
}

function hideViewModeProperty(propertyId: Property) {
  const { capture } = useAnalytics()
  viewModeState.value.current.show = viewModeState.value.current.show.filter(p => p.propertyId !== propertyId)
  capture("user_removed_view_mode_property", { propertyId })
}

function previewScale() {
  const previewProp = viewModeState.value.current.show.find(i => i.propertyId === Property.VIRTUAL_PREVIEW)
  if (!previewProp || previewProp.scale === null)
    throw new Error("No preview property scale in view modes")
  return previewProp.scale
}

/** Updates the preview scale, but can only be between 0 and 1 */
function updatePreviewScale(value: number) {
  if (value < 0)
    value = 0
  if (value > 1)
    value = 1
  viewModeState.value.current.show[0].scale = value
}

function propertySortOptions(propertyId: Property) {
  for (const prop of viewModeState.value.current.sort)
    if (prop.propertyId === propertyId)
      return prop
}

function isSortingProperty(propertyId: Property) {
  return propertySortOptions(propertyId) !== undefined
}

/** Used to show a sort badge in the UI. In searches this excludes the search relevance */
function isCustomSort() {
  return viewModeState
    .value
    .current
    .sort
    .filter(s => s.propertyId !== Property.VIRTUAL_SEARCH_RELEVANCE)
    .length > 0
}

function addViewModeSort(propertyId: Property, descending?: boolean) {
  if (isSortingProperty(propertyId))
    return console.error("Already sorting by view mode property")
  descending ??= false
  viewModeState.value.current.sort = [
    ...viewModeState.value.current.sort,
    { propertyId, descending },
  ]
}
function removeViewModeSort(propertyId: string) {
  viewModeState.value.current.sort = viewModeState.value.current.sort.filter(
    p => p.propertyId !== propertyId,
  )
}

function clearViewModeSorts() {
  const { isInSearchMode } = useMainView()
  if (isInSearchMode())
    viewModeState.value.current.sort = [{
      propertyId: Property.VIRTUAL_SEARCH_RELEVANCE,
      descending: true,
    }]
  else
    viewModeState.value.current.sort = []
}

function removeSearchFromSort() {
  viewModeState.value.current.sort = viewModeState.value.current.sort.filter(s => s.propertyId !== Property.VIRTUAL_SEARCH_RELEVANCE)
}

function addSearchToSort() {
  if (isSortingProperty(Property.VIRTUAL_SEARCH_RELEVANCE))
    return
  viewModeState.value.current.sort = [{
    propertyId: Property.VIRTUAL_SEARCH_RELEVANCE,
    descending: true,
  }, ...viewModeState.value.current.sort]
}

function propertyFilterOptions(propertyId: Property) {
  for (const prop of viewModeState.value.current.filter)
    if (prop.propertyId === propertyId)
      return prop
}
function isFilteringProperty(propertyId: Property) {
  return propertyFilterOptions(propertyId) !== undefined
}

const viewModes: Record<ViewModeType, {
  name: string
  icon: Component
  type: ViewModeType
}> = {
  [ViewModeType.Icon]: { type: ViewModeType.Icon, name: "Icon", icon: IconMode },
  [ViewModeType.Detail]: { type: ViewModeType.Detail, name: "Detail", icon: DetailMode },
  [ViewModeType.Grid]: { type: ViewModeType.Grid, name: "Grid", icon: GridMode },
  [ViewModeType.Feed]: { type: ViewModeType.Feed, name: "Feed", icon: FeedMode },
  [ViewModeType.Column]: { type: ViewModeType.Column, name: "Column", icon: ColumnMode },
  [ViewModeType.Tree]: { type: ViewModeType.Tree, name: "Tree", icon: TreeMode },
}

const showableProperties: Partial<Record<Property, {
  name: string
  icon: PropertyIconType
  propertyId: Property
  editable?: boolean
  sortable?: boolean
  filterable?: boolean
}>> = {
  [Property.VIRTUAL_PREVIEW]: {
    propertyId: Property.VIRTUAL_PREVIEW,
    name: "Preview",
    icon: "preview",
  },
  [Property.NAME]: {
    propertyId: Property.NAME,
    name: "Name",
    icon: "name",
    editable: true,
    sortable: true,
    filterable: true,
  },
  [Property.VIRTUAL_PATH]: {
    name: "Path",
    icon: "path",
    propertyId: Property.VIRTUAL_PATH,
  },
  [Property.SIZE]: {
    name: "File size",
    icon: "size",
    propertyId: Property.SIZE,
    sortable: true,
    filterable: true,
  },
  [Property.CREATED_AT]: {
    name: "Created",
    icon: "created",
    propertyId: Property.CREATED_AT,
    sortable: true,
    filterable: true,
  },
  [Property.MODIFIED_AT]: {
    name: "Modified",
    icon: "created",
    propertyId: Property.MODIFIED_AT,
    sortable: true,
    filterable: true,
  },
  [Property.VIRTUAL_DIMENSIONS]: {
    name: "Dimensions",
    icon: "dimensions",
    propertyId: Property.VIRTUAL_DIMENSIONS,
  },
  [Property.VIRTUAL_TAGS]: {
    name: "Tags",
    icon: "name",
    propertyId: Property.VIRTUAL_TAGS,
    editable: true,
    filterable: true,
  },
  [Property.FILE_NOTES]: {
    name: "Notes",
    icon: "prompt",
    propertyId: Property.FILE_NOTES,
    editable: true,
    filterable: true,
  },
  [Property.FILE_RATING]: {
    name: "Rating",
    icon: "prompt",
    propertyId: Property.FILE_RATING,
    editable: true,
    sortable: true,
    filterable: true,
  },
  [Property.GENERATION_PROMPT]: {
    name: "Gen. Prompt",
    icon: "prompt",
    propertyId: Property.GENERATION_PROMPT,
    editable: true,
    sortable: false,
    filterable: true,
  },
  [Property.GENERATION_SOURCE]: {
    name: "Gen. Source",
    icon: "prompt",
    propertyId: Property.GENERATION_SOURCE,
    editable: true,
    sortable: false,
    filterable: true,
  },
  [Property.GENERATION_MODEL]: {
    name: "Gen. Model",
    icon: "prompt",
    propertyId: Property.GENERATION_MODEL,
    editable: true,
    sortable: false,
    filterable: true,
  },
  [Property.GENERATION_SEED]: {
    name: "Gen. Seed",
    icon: "prompt",
    propertyId: Property.GENERATION_SEED,
    editable: true,
    sortable: false,
    filterable: true,
  },
}

const viewModePropertySizes: Record<PropertySize, {
  textCss: string
  singleLineCss: string
  label: string
}> = {
  [PropertySize.Tiny]: {
    textCss: "t-property-xs",
    singleLineCss: "h-$page-properties-xs-h-single-outer overflow-hidden",
    label: "Tiny",
  },
  [PropertySize.Small]: {
    textCss: "t-property-sm",
    singleLineCss: "h-$page-properties-sm-h-single-outer overflow-hidden",
    label: "Small",
  },
  [PropertySize.Medium]: {
    textCss: "t-property-md",
    singleLineCss: "h-$page-properties-md-h-single-outer overflow-hidden",
    label: "Medium",
  },
  [PropertySize.Large]: {
    textCss: "t-property-lg",
    singleLineCss: "h-$page-properties-lg-h-single-outer overflow-hidden",
    label: "Large",
  },
  [PropertySize.Huge]: {
    textCss: "t-property-xl",
    singleLineCss: "h-$page-properties-xl-h-single-outer overflow-hidden",
    label: "Huge",
  },
  [PropertySize.Giant]: {
    textCss: "t-property-2xl",
    singleLineCss: "h-$page-properties-2xl-h-single-outer overflow-hidden",
    label: "Giant",
  },
}

const viewModePropertyColors: Record<PropertyColor, {
  textCss: string
  bgCss: string
  label: string
}> = {
  [PropertyColor.Default]: {
    textCss: "text-$global-t-default",
    label: "Default",
    bgCss: "bg-$page-properties-bg-property",
  },
  [PropertyColor.Steel]: {
    textCss: "text-$global-steel-primary",
    label: "Steel",
    bgCss: "bg-$global-steel-accent",
  },
  [PropertyColor.Amber]: {
    textCss: "text-$global-amber-primary",
    label: "Amber",
    bgCss: "bg-$global-amber-accent",
  },
  [PropertyColor.Green]: {
    textCss: "text-$global-green-primary",
    label: "Green",
    bgCss: "bg-$global-green-accent",
  },
  [PropertyColor.Teal]: {
    textCss: "text-$global-teal-primary",
    label: "Teal",
    bgCss: "bg-$global-teal-accent",
  },
  [PropertyColor.Blue]: {
    textCss: "text-$global-blue-primary",
    label: "Blue",
    bgCss: "bg-$global-blue-accent",
  },
  [PropertyColor.Purple]: {
    textCss: "text-$global-purple-primary",
    label: "Purple",
    bgCss: "bg-$global-purple-accent",
  },
  [PropertyColor.Violet]: {
    textCss: "text-$global-violet-primary",
    label: "Violet",
    bgCss: "bg-$global-violet-accent",
  },
  [PropertyColor.Pink]: {
    textCss: "text-$global-pink-primary",
    label: "Pink",
    bgCss: "bg-$global-pink-accent",
  },
  [PropertyColor.Red]: {
    textCss: "text-$global-red-primary",
    label: "Red",
    bgCss: "bg-$global-red-accent",
  },
}

export default function () {
  viewModeState = useState("view-mode-state", () => ({
    current: {
      version: 0,
      mode: ViewModeType.Icon,
      show: [{
        propertyId: Property.VIRTUAL_PREVIEW,
        size: PropertySize.Medium,
        color: PropertyColor.Default,
        scale: 0.4,
      }, {
        propertyId: Property.NAME,
        size: PropertySize.Medium,
        color: PropertyColor.Default,
        scale: null,
      }, {
        propertyId: Property.CREATED_AT,
        size: PropertySize.Small,
        color: PropertyColor.Steel,
        scale: null,
      }],
      sort: [],
      filter: [],
      columns: [],
      columnView: {
        columns: [defaultColumnModeWidth],
      },
      detailView: {
        maxColumnWidth: 500,
      },
    },
    global: undefined,
    byFolder: {},
  }))

  return {
    viewModeState,
    viewModes,
    isHierarchical,
    showableProperties,
    viewModePropertySizes,
    viewModePropertyColors,
    defaultColumnModeWidth,
    previewScale,
    updatePreviewScale,
    switchViewMode,
    showViewModeProperty,
    hideViewModeProperty,
    propertyShowOptions,
    isShowingProperty,
    isCustomSort,
    propertySortOptions,
    isSortingProperty,
    addViewModeSort,
    removeViewModeSort,
    clearViewModeSorts,
    removeSearchFromSort,
    addSearchToSort,
    propertyFilterOptions,
    isFilteringProperty,
  }
}
