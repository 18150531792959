import { Gesture } from "./BaseGesture"

export default function () {
  const { isEditingTextInput, gestureLayers } = useGestures()
  const { mainViewState, sortedContents } = useMainView()
  const { searchState, openSearchBar } = useSearch()
  const { selectFile, selectFiles, isSelectionEmpty } = useSelection()
  const { createNewFolderInCurrentView } = useContextMenu()
  const { panelState, hidePanel, showPanel, togglePanel } = usePanel()
  const { sidebarState, hideSidebar, showSidebar } = useSidebar()
  const { previewScale, updatePreviewScale } = useViewMode()

  class GlobalKeyboardShortcut extends Gesture {
    layer = gestureLayers.keyboardShortcut

    async onKeyDown(
      keyboard: KeyboardEvent,
    ) {
      // TODO: ensure we aren't in any text input elements

      if (keyboard.key === "k" && this.commandKeyModifier(keyboard)) {
        this.capture()
        keyboard.preventDefault()
        this.openSearchBarIfClosed()
      }
      else if (keyboard.key === "a" && this.commandKeyModifier(keyboard)) {
        if (isEditingTextInput())
          return
        if (["folder", "search-results"].includes(mainViewState.value.state)) {
          this.capture()
          keyboard.preventDefault()
          this.console.log("Selecting all folder view items")
          selectFiles(Object.values(mainViewState.value.contents), "main-view")
        }
      }
      else if (keyboard.key === "d" && this.commandKeyModifier(keyboard)) {
        if (isEditingTextInput())
          return
        if (mainViewState.value.state !== "folder")
          return
        this.capture()
        keyboard.preventDefault()
        this.console.log("Creating a new folder in current view")
        createNewFolderInCurrentView()
      }
      // Hides both panels
      else if (keyboard.key === "\\" && this.commandKeyModifier(keyboard)) {
        if (isEditingTextInput())
          return
        this.capture()
        keyboard.preventDefault()
        this.console.log("Toggling sidebar and panel")
        if (sidebarState.value.visible || panelState.value.visible) {
          hideSidebar()
          hidePanel()
        }
        else {
          showSidebar()
          showPanel()
        }
      }
      // Increments preview scale by 0.5. Note that '=' is the key for cmd+plus
      else if (["+", "=", "Add", "ZoomIn"].includes(keyboard.key) && this.commandKeyModifier(keyboard)) {
        if (isEditingTextInput())
          return
        if (!["folder", "search-results"].includes(mainViewState.value.state))
          return this.console.warn("Not changing preview size as are not in right mode")
        this.capture()
        keyboard.preventDefault()
        this.console.log("Increasing preview size")
        updatePreviewScale(previewScale() + 0.05)
      }
      // Decrements preview scale by 0.5
      else if (["-", "Subtract", "ZoomOut"].includes(keyboard.key) && this.commandKeyModifier(keyboard)) {
        if (isEditingTextInput())
          return
        if (!["folder", "search-results"].includes(mainViewState.value.state))
          return this.console.warn("Not changing preview size as are not in right mode")
        this.capture()
        keyboard.preventDefault()
        this.console.log("Decreasing preview size")
        updatePreviewScale(previewScale() - 0.05)
      }
      // Info -- show info panel
      else if (keyboard.key === "i" && this.commandKeyModifier(keyboard)) {
        if (isEditingTextInput())
          return
        this.capture()
        keyboard.preventDefault()
        this.console.log("Toggling info panel")
        togglePanel("details")
      }
      else if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(keyboard.key) && isSelectionEmpty()) {
        if (isEditingTextInput())
          return
        const file = sortedContents.value[0]
        if (file)
          selectFile(file, "main-view")
        this.capture()
        keyboard.preventDefault()
      }
    }

    openSearchBarIfClosed() {
      // TODO: if there are items selected, place those in the search?

      if (searchState.value.isEditing)
        return
      this.console.log("Opening search bar from keyboard shortcut")
      openSearchBar()
    }

    /**
     * TODO: address different OS'es, and test on Windows
     * @param keyboard event
     * @returns whether the OS-specific modifier key is pressed
     */
    commandKeyModifier(keyboard: KeyboardEvent) {
      return keyboard.metaKey || keyboard.ctrlKey
    }

    shiftKeyModifier(keyboard: KeyboardEvent) {
      return keyboard.shiftKey
    }
  }
  return new GlobalKeyboardShortcut()
}
