// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class NonNullableStringValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NonNullableStringValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsNonNullableStringValueBuf(bb:flatbuffers.ByteBuffer, obj?:NonNullableStringValueBuf):NonNullableStringValueBuf {
  return (obj || new NonNullableStringValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsNonNullableStringValueBuf(bb:flatbuffers.ByteBuffer, obj?:NonNullableStringValueBuf):NonNullableStringValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new NonNullableStringValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startNonNullableStringValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endNonNullableStringValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // value
  return offset;
}

static createNonNullableStringValueBuf(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  NonNullableStringValueBuf.startNonNullableStringValueBuf(builder);
  NonNullableStringValueBuf.addValue(builder, valueOffset);
  return NonNullableStringValueBuf.endNonNullableStringValueBuf(builder);
}
}
