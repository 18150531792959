// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class SearchV1TextPropertySearchFilterBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1TextPropertySearchFilterBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1TextPropertySearchFilterBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf {
  return (obj || new SearchV1TextPropertySearchFilterBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1TextPropertySearchFilterBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1TextPropertySearchFilterBuf):SearchV1TextPropertySearchFilterBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1TextPropertySearchFilterBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

matchesAny(index: number):string
matchesAny(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
matchesAny(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

matchesAnyLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

startsWithAny(index: number):string
startsWithAny(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
startsWithAny(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

startsWithAnyLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

containsAny(index: number):string
containsAny(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
containsAny(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

containsAnyLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

notContainsAny(index: number):string
notContainsAny(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
notContainsAny(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

notContainsAnyLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startSearchV1TextPropertySearchFilterBuf(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addMatchesAny(builder:flatbuffers.Builder, matchesAnyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, matchesAnyOffset, 0);
}

static createMatchesAnyVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startMatchesAnyVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addStartsWithAny(builder:flatbuffers.Builder, startsWithAnyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, startsWithAnyOffset, 0);
}

static createStartsWithAnyVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startStartsWithAnyVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addContainsAny(builder:flatbuffers.Builder, containsAnyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, containsAnyOffset, 0);
}

static createContainsAnyVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startContainsAnyVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addNotContainsAny(builder:flatbuffers.Builder, notContainsAnyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, notContainsAnyOffset, 0);
}

static createNotContainsAnyVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startNotContainsAnyVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endSearchV1TextPropertySearchFilterBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSearchV1TextPropertySearchFilterBuf(builder:flatbuffers.Builder, matchesAnyOffset:flatbuffers.Offset, startsWithAnyOffset:flatbuffers.Offset, containsAnyOffset:flatbuffers.Offset, notContainsAnyOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchV1TextPropertySearchFilterBuf.startSearchV1TextPropertySearchFilterBuf(builder);
  SearchV1TextPropertySearchFilterBuf.addMatchesAny(builder, matchesAnyOffset);
  SearchV1TextPropertySearchFilterBuf.addStartsWithAny(builder, startsWithAnyOffset);
  SearchV1TextPropertySearchFilterBuf.addContainsAny(builder, containsAnyOffset);
  SearchV1TextPropertySearchFilterBuf.addNotContainsAny(builder, notContainsAnyOffset);
  return SearchV1TextPropertySearchFilterBuf.endSearchV1TextPropertySearchFilterBuf(builder);
}
}
