import { Gesture } from "./BaseGesture"

export default function () {
  const { getMainViewFileFromEvent, isPrimaryClick, gestureState, isOverMainView, isOverWaterfall, gestureLayers } = useGestures()
  const { addFileToSelection, selectFile, isSelected, deselectFile } = useSelection()
  const { mainViewState, scrollToFile, updateContentBoundsSync } = useMainView()
  const { stopEditing } = useProperty()
  const { navigateToFile } = useNavigation()
  const { closePeek } = usePeek()

  class MainOrWaterfallViewFileClickToSelectOrOpen extends Gesture {
    layer = gestureLayers.mainView

    #didSelect = false

    reset() {
      this.#didSelect = false
    }

    async onMouseDown(
      mouse: MouseEvent,
    ) {
      if (
        !isPrimaryClick(mouse)
        || !["folder", "search-results"].includes(mainViewState.value.state)
        || !(isOverMainView(mouse) || isOverWaterfall(mouse))
      )
        return

      const location: SelectionLocation = isOverMainView(mouse) ? "main-view" : "waterfall"

      // This should also work for waterfall view, as it uses the data-file-id
      const file = getMainViewFileFromEvent(mouse)
      if (!file)
        return

      // If shift is pressed, do a multi-select
      if (gestureState.value.keysPressed.has("Shift")) {
        this.capture()
        this.#didSelect = true
        this.debug(`Multi-select toggling file`, file)
        if (isSelected(file.fileId))
          deselectFile(file)
        else
          addFileToSelection(file, location)
        return
      }

      // If we don't have shift pressed, select or open
      if (!isSelected(file.fileId)) {
        this.capture()
        this.debug(`Selecting file`, file)
        selectFile(file, location)
        updateContentBoundsSync()
        scrollToFile(file.fileId)
        this.#didSelect = true
      }
    }

    async onMouseUp(
      mouse: MouseEvent,
    ) {
      if (
        !isPrimaryClick(mouse)
        || !["folder", "search-results"].includes(mainViewState.value.state)
        || !(isOverMainView(mouse) || isOverWaterfall(mouse))
      )
        return this.reset()

      // Don't navigate on the same click as select
      if (this.#didSelect)
        return this.reset()

      // this.debug('not on selection click')

      // if the position of the mouse is where it was on mouse down, then we're a click
      if (gestureState.value.mouseDown?.clientX !== mouse.clientX || gestureState.value.mouseDown?.clientY !== mouse.clientY)
        return this.reset()

      // this.debug('location match')

      const file = getMainViewFileFromEvent(mouse)
      if (!file)
        return this.reset()

      // this.debug('file match')

      this.capture()
      stopEditing()
      closePeek()
      navigateToFile(file)

      return this.reset()
    }
  }
  return new MainOrWaterfallViewFileClickToSelectOrOpen()
}
