// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { EntityPropertySelectionBuf } from '../entity/entity-property-selection-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class FileCopyRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FileCopyRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFileCopyRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FileCopyRequestDatagramBuf):FileCopyRequestDatagramBuf {
  return (obj || new FileCopyRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFileCopyRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FileCopyRequestDatagramBuf):FileCopyRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FileCopyRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

clientId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

fileId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

parentId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

selection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startFileCopyRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addClientId(builder:flatbuffers.Builder, clientIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, clientIdOffset, 0);
}

static addFileId(builder:flatbuffers.Builder, fileIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(2, fileIdOffset, 0);
}

static addParentId(builder:flatbuffers.Builder, parentIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(3, parentIdOffset, 0);
}

static addSelection(builder:flatbuffers.Builder, selectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, selectionOffset, 0);
}

static endFileCopyRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // client_id
  builder.requiredField(offset, 8) // file_id
  builder.requiredField(offset, 10) // parent_id
  builder.requiredField(offset, 12) // selection
  return offset;
}

}
