// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileBuf } from '../file/file-buf.js';
import { NotificationMessageIdBuf } from '../message/notification-message-id-buf.js';
import { NotificationSenderIdBuf } from '../message/notification-sender-id-buf.js';


export class FileCopyMessageDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FileCopyMessageDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFileCopyMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FileCopyMessageDatagramBuf):FileCopyMessageDatagramBuf {
  return (obj || new FileCopyMessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFileCopyMessageDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FileCopyMessageDatagramBuf):FileCopyMessageDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FileCopyMessageDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

messageId(obj?:NotificationMessageIdBuf):NotificationMessageIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new NotificationMessageIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

senderId(obj?:NotificationSenderIdBuf):NotificationSenderIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new NotificationSenderIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

file(obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startFileCopyMessageDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addMessageId(builder:flatbuffers.Builder, messageIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, messageIdOffset, 0);
}

static addSenderId(builder:flatbuffers.Builder, senderIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, senderIdOffset, 0);
}

static addFile(builder:flatbuffers.Builder, fileOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, fileOffset, 0);
}

static endFileCopyMessageDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // message_id
  builder.requiredField(offset, 6) // sender_id
  builder.requiredField(offset, 8) // file
  return offset;
}

}
