import { Gesture } from "./BaseGesture"

export default function () {
  const { stopEditing } = useProperty()
  const { isSelectionEmpty } = useSelection()
  const { gestureState, isOverSidebar, isPrimaryClick, getSidebarFileFromEvent, gestureLayers } = useGestures()
  class SidebarFileDragToMove extends Gesture {
    layer = gestureLayers.sidebar
    isPreDrag = false

    reset() {
      this.isPreDrag = false
      this.switchMode("none")
    }

    // Dragging to move means we want to
    async onMouseDown(mouse: MouseEvent) {
      if (!isPrimaryClick(mouse) || !isOverSidebar(mouse))
        return

      // A prior gesture should have selected an object if it was pressed, so let's
      // start a drag here
      if (getSidebarFileFromEvent(mouse))
        this.isPreDrag = true
    }

    async onMouseMove(
      mouse: MouseEvent,
    ) {
    // TODO:
    // start drag animation and switch modes
    // keep dragged item on screen as mode is switched

      if (!gestureState.value.mouseDown
        || isSelectionEmpty()
        || gestureState.value.gesture === "drag-file"
      )
        this.isPreDrag = false

      if (!this.isPreDrag)
        return

      // check displacement from mouse down to tell if we have become a full drag or not
      const xDist = gestureState.value.mouseDown!.clientX - mouse.clientX
      const yDist = gestureState.value.mouseDown!.clientY - mouse.clientY
      const dist = Math.sqrt(xDist ** 2 + yDist ** 2)
      if (dist < 3)
        return

      // we need to capture this mouse move as the drag is occurring
      this.capture()
      stopEditing()
      this.isPreDrag = false
      this.switchMode("drag-file")
    }

    async onMouseUp(
      _mouse: MouseEvent,
    ) {
      this.isPreDrag = false
    }
  }
  return new SidebarFileDragToMove()
}
