import { UUID } from "uuidv7"
import type * as flatbuffers from "flatbuffers"
import { partition } from "../helpers"
import { createEntityIdBuf, createEntityPropertyIdBuf, createEntityPropertySchemaBuf, createOrderedIdBuf, parseEntityId, parseEntityPropertyGroupId, parseEntityPropertySchemaValue, parseMaybeOrderedId } from "."
import type { EntityIdBuf, EntityPropertyIdBuf } from "~/classes/generated/entity"
import { EntityApiIdBuf } from "~/classes/generated/entity"
import { UserBuf, UserPropertyBuf, UserSidebarItemBuf } from "~/classes/generated/user"

// User

export function parseUserId(buf: EntityIdBuf) {
  if (buf.api() !== EntityApiIdBuf.UserIdV0 && buf.api() !== EntityApiIdBuf.UserIdClientOnly)
    throw new Error(`API Id is not a user: ${buf.api()}`)
  return parseEntityId(buf)
}

export function parseMaybeUserId(buf: EntityIdBuf | null) {
  if (!buf)
    return undefined
  return parseUserId(buf)
}

export function parseUserPropertyId(buf: EntityPropertyIdBuf) {
  // TODO: validate as user property
  const group_id = parseEntityPropertyGroupId(buf.group()!).toString(16).padStart(8, "0")
  const key = UUID.ofInner(new Uint8Array(range(16).map(k => buf.key()!.key(k)) as number[]))
  return `${group_id}-${key.toString()}`
}

export function parseUserProperty(buf: UserPropertyBuf) {
  const userProperty: UserProperty = {
    propertyId: parseUserPropertyId(buf.propertyId()!),
    version: buf.version(),
    schema: buf.schema(),
    schemaVersion: buf.schemaVersion(),
    value: parseEntityPropertySchemaValue(buf.schema(), buf.valueArray()!),
    timestamp: Number(buf.timestamp()),
    deleted: buf.deleted(),
    pending: buf.pending(),
    stale: buf.stale(),
    index: parseMaybeOrderedId(buf.index()),
  }
  return userProperty
}

export function parseUser(buf: UserBuf): User {
  const userId = parseUserId(buf.userId()!)
  const properties: Record<string, UserProperty> = {}
  for (let i = 0; i < buf.propertiesLength(); i++) {
    const property = parseUserProperty(buf.properties(i)!)
    properties[property.propertyId] = property
  }
  return {
    userId,
    properties,
  }
}

export function parseMaybeUser(user: UserBuf | null) {
  if (user)
    return parseUser(user)
}

export function addUserPropertyToBuf(fbb: flatbuffers.Builder, property: UserProperty) {
  const valueBuf = createEntityPropertySchemaBuf(property.value)
  const value = fbb.createByteVector(valueBuf)

  UserPropertyBuf.startUserPropertyBuf(fbb)
  UserPropertyBuf.addPropertyId(fbb, createEntityPropertyIdBuf(fbb, property.propertyId))
  if (property.version !== undefined)
    UserPropertyBuf.addVersion(fbb, property.version)
  UserPropertyBuf.addSchema(fbb, property.schema)
  if (property.schemaVersion !== undefined)
    UserPropertyBuf.addSchemaVersion(fbb, property.schemaVersion)
  UserPropertyBuf.addValue(fbb, value)
  UserPropertyBuf.addTimestamp(fbb, BigInt(property.timestamp))
  if (property.deleted)
    UserPropertyBuf.addDeleted(fbb, property.deleted)
  if (property.pending)
    UserPropertyBuf.addPending(fbb, property.pending)
  if (property.stale)
    UserPropertyBuf.addStale(fbb, property.stale)
  if (property.index)
    UserPropertyBuf.addIndex(fbb, createOrderedIdBuf(fbb, property.index)!)
  return UserPropertyBuf.endUserPropertyBuf(fbb)
}

export function addUserToBuf(fbb: flatbuffers.Builder, user: User) {
  const innerProperties = Object.values(user.properties).map(p => addUserPropertyToBuf(fbb, p))
  const properties = UserBuf.createPropertiesVector(fbb, innerProperties)
  const userId = createEntityIdBuf(fbb, user.userId)
  UserBuf.startUserBuf(fbb)
  UserBuf.addUserId(fbb, userId)
  UserBuf.addProperties(fbb, properties)
  return UserBuf.endUserBuf(fbb)
}

export function parseUserSidebarItem(buf: UserSidebarItemBuf): UserSidebarItem {
  return {
    fileId: buf.fileId() ? parseEntityId(buf.fileId()!) : undefined,
    searchId: buf.searchId() ? parseEntityId(buf.searchId()!) : undefined,
  }
}

export function addUserSidebarItemToBuf(fbb: flatbuffers.Builder, item: UserSidebarItem) {
  UserSidebarItemBuf.startUserSidebarItemBuf(fbb)
  if (item.fileId)
    UserSidebarItemBuf.addFileId(fbb, createEntityIdBuf(fbb, item.fileId))
  if (item.searchId)
    UserSidebarItemBuf.addSearchId(fbb, createEntityIdBuf(fbb, item.searchId))
  return UserSidebarItemBuf.endUserSidebarItemBuf(fbb)
}
