import type { CookieRef } from "#app"
import { InterfaceUser } from "~/classes/users/InterfaceUser"
import { InterfaceFile, InterfaceFolderFile } from "~/classes/file"
import { sendSelfInfoRequest } from "~/classes/handlers/user/info"
import { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"

/**
 * TODO: create a method that checks whether the client's cookies are
 * valid still.
 * TODO: on logout remember to delete the client's cookie data.
 */
interface State {
  user?: InterfaceUser
  home?: InterfaceFolderFile
  archive?: InterfaceFolderFile
  uiPreferences: Record<string, any>
}
const console = useLogger("use-user", theme.colors.purple.hex)

/**
 * The client token cookie is sent by the server and we don't edit it in our application. It
 * helps with deciding immediately whether to load the main/login page or to try and load
 * the user's home directory
 */
let clientTokenCookie: CookieRef<string | null>
let myself: Ref<State>

function defaultUserData(): State {
  return {
    user: undefined,
    home: undefined,
    archive: undefined,
    uiPreferences: {},
  }
}

const demoUserEmail = "office@withpoly.com"
function isDemoUser() {
  return myself.value.user?.email() === demoUserEmail
}

function clearUserData() {
  myself.value = defaultUserData()
  clientTokenCookie.value = null
}

function hasStoredLoginCredentials() {
  return isDesktop() || !!clientTokenCookie.value
}

function isSignedIn() {
  return !!(myself.value.user && hasStoredLoginCredentials())
}

function requestSelfInfo() {
  sendSelfInfoRequest({
    requestId: generateWorkerRequestId(),
  })
}

/** TODO: move this to a handler function */
async function onUserInfoReceived(response: SelfInfoResponseDatagram) {
  const { subscribeToFolderUpdates } = useFileSystem()
  const { navigateToHome, initFileSystemNavigation } = useNavigation()
  const { isOnAuthPage } = useAuthentication()
  const { loadSharedFoldersFromUserInfo } = useSharedFolders()
  const { loadPinnedItemsFromUserInfo } = useSidebar()

  const { selfInfo, error, cacheOnly } = response
  // console.debug('Received user info:', selfInfo)
  if (error || !selfInfo)
    throw new Error("Should not have gotten here")

  const { user, home, archive, sidebarSharedFolders } = selfInfo

  const differentUserLoggedIn = !myself.value.home || myself.value.home.fileId !== home.fileId

  myself.value.user = new InterfaceUser(user)
  myself.value.home = new InterfaceFolderFile(home)
  myself.value.archive = new InterfaceFolderFile(archive)

  loadSharedFoldersFromUserInfo(selfInfo)
  loadPinnedItemsFromUserInfo(selfInfo)

  if (!cacheOnly) {
    console.log("Subscribing to entity updates")
    const updates = [home.fileId, archive.fileId]
    for (const SharedFolderInfo of sidebarSharedFolders)
      updates.push(SharedFolderInfo.home.fileId, SharedFolderInfo.archive.fileId)

    // TODO: subscribing to updates to the shared folders themselves
    subscribeToFolderUpdates(updates)
  }

  // If we are at the login or signup screen, redirect the user to the main page
  if (isOnAuthPage()) {
    console.log("Received user info while on auth page, navigating to home")
    return navigateToHome()
  }

  // After we receive our self info, we can finally initialize our file system, which lets us
  // request user data, e.g. list our home folder, etc. In the future, we could also skip
  // this by creating a direct request for home, but since we don't have our
  // home id we need to wait for this to return (which is fast if you've already logged in)
  if (differentUserLoggedIn) {
    initFileSystemNavigation()
  }
}

/**
 * The useUser composable handles log in and user data, such as logging in via REST,
 * as well as logging in via the websocket connection.
 */
export default function () {
  clientTokenCookie = useCookie("client_token")
  myself = useState("user-data", defaultUserData)

  // const emailVerification = useState(
  //   'user-verification',
  //   () => ({
  //     email: '',
  //     nonce: '',
  //   }),
  // )

  return {
    myself,
    clientTokenCookie,
    hasStoredLoginCredentials,
    isSignedIn,
    clearUserData,
    requestSelfInfo,
    onUserInfoReceived,

    // REMOVE AFTER DEMO
    isDemoUser,
    demoUserEmail,
  }
}
