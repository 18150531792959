// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

/**
 * The property size represents the size of an element in our UI
 */
export enum PropertySize {
  Tiny = -2,
  Small = -1,
  Medium = 0,
  Large = 1,
  Huge = 2,
  Giant = 3
}
