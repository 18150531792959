// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class AnyIntegerValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):AnyIntegerValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsAnyIntegerValueBuf(bb:flatbuffers.ByteBuffer, obj?:AnyIntegerValueBuf):AnyIntegerValueBuf {
  return (obj || new AnyIntegerValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsAnyIntegerValueBuf(bb:flatbuffers.ByteBuffer, obj?:AnyIntegerValueBuf):AnyIntegerValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new AnyIntegerValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt64(this.bb_pos + offset) : BigInt('0');
}

static startAnyIntegerValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, value:bigint) {
  builder.addFieldInt64(0, value, BigInt('0'));
}

static endAnyIntegerValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createAnyIntegerValueBuf(builder:flatbuffers.Builder, value:bigint):flatbuffers.Offset {
  AnyIntegerValueBuf.startAnyIntegerValueBuf(builder);
  AnyIntegerValueBuf.addValue(builder, value);
  return AnyIntegerValueBuf.endAnyIntegerValueBuf(builder);
}
}
