import * as flatbuffers from "flatbuffers"
import { InterfaceFile } from "~/classes/file"
import { addEntityPropertySelectionToBuf, addFileToBuf, createRequestIdBuf, parseFile, parseRequestId } from "~/utils/flatbuffers"
import { FileMoveRequestDatagramBuf, RequestDatagramBuf } from "~/classes/generated/request"
import type { ErrorBuf } from "~/classes/generated/datagram"
import { DatagramBuf } from "~/classes/generated/datagram"
import type { FileMoveResponseDatagramBuf } from "~/classes/generated/response"
import type { FileMoveMessageDatagramBuf } from "~/classes/generated/message"
import { parseMaybeFile } from "~/utils/flatbuffers/file"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"

const console = useLogger("file/move")

export function sendFileMoveRequests(requests: FileMoveRequestDatagram[]) {
  const { sendWorkerDatagramBuf } = useCache()
  const fbb = new flatbuffers.Builder(64)
  const requestVector = []
  for (const request of requests) {
    const file = addFileToBuf(fbb, request.file)
    let selection
    if (request.selection)
      selection = addEntityPropertySelectionToBuf(fbb, request.selection)
    FileMoveRequestDatagramBuf.startFileMoveRequestDatagramBuf(fbb)
    FileMoveRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
    FileMoveRequestDatagramBuf.addFile(fbb, file)
    if (selection)
      FileMoveRequestDatagramBuf.addSelection(fbb, selection)
    const moveRequest = FileMoveRequestDatagramBuf.endFileMoveRequestDatagramBuf(fbb)
    RequestDatagramBuf.startRequestDatagramBuf(fbb)
    RequestDatagramBuf.addFileMove(fbb, moveRequest)
    requestVector.push(RequestDatagramBuf.endRequestDatagramBuf(fbb))
  }

  const vector = DatagramBuf.createRequestsVector(fbb, requestVector)
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, vector)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

/** Parse a Flatbuffers representation of a response */
export function handleFileMoveResponseDatagramBuf(datagram: FileMoveResponseDatagramBuf) {
  // TODO: get rid of datagram result, and find out a better way to
  // error out of this, since we need to send notifications
  onFileMoveResponse({
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    file: parseMaybeFile(datagram.file()),
  })
}

export function onFileMoveResponse(response: FileMoveResponseDatagram) {
  const { removeFileFromFolderView, addFileToFolderView } = useMainView()
  const { navigationState } = useNavigation()

  if (response.error)
    return onFileMoveErrored(response.error)
  if (!response.file)
    throw new Error("No file in move response")

  console.log("File moved", response)

  // FIXME: what if the updated file is on the current path? We need to refresh the
  // path in that case.

  // Update the navigation if the moved file is the navigation
  const iFile = InterfaceFile.untyped(response.file as any)
  if (iFile.fileId === navigationState.value.file?.fileId)
    navigationState.value.file = navigationState.value.file.mergeWith(iFile)

  // remove the file from the current directory if the file was in it.
  if (iFile.parentId() !== navigationState.value.file?.fileId)
    removeFileFromFolderView(iFile)
  if (iFile.parentId() === navigationState.value.file?.fileId)
    addFileToFolderView(iFile)
}

export function onFileMoveErrored(error: DatagramError) {
  const { notifyDatagramError } = useNotifications()
  return notifyDatagramError(error, "File deletion was unsuccessful")
}

export function handleFileMoveMessageDatagramBuf(message: FileMoveMessageDatagramBuf) {
  const { mainViewState, addFileToFolderView, removeFileFromFolderView } = useMainView()
  const { navigationState } = useNavigation()
  console.log("Received file move message", message)

  const file = parseFile(message.file()!)
  const iFile = InterfaceFile.from(file)
  if (iFile.parentId() === navigationState.value.file?.fileId) {
    const oldFile = mainViewState.value.contents[iFile.fileId]
    if (oldFile)
      addFileToFolderView(oldFile.mergeWith(iFile))
    else
      addFileToFolderView(iFile)
  }
  else {
    removeFileFromFolderView(iFile)
  }
}
