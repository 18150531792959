// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityPropertyIdBuf } from '../entity/entity-property-id-buf.js';
import { EntityPropertySelectionBuf } from '../entity/entity-property-selection-buf.js';
import { FileBuf } from '../file/file-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class FolderListRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FolderListRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFolderListRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FolderListRequestDatagramBuf):FolderListRequestDatagramBuf {
  return (obj || new FolderListRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFolderListRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FolderListRequestDatagramBuf):FolderListRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FolderListRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

folder(obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

pageSize():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

sort(obj?:EntityPropertyIdBuf):EntityPropertyIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new EntityPropertyIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

desc():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

selection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

pathSelection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startFolderListRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(7);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addFolder(builder:flatbuffers.Builder, folderOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, folderOffset, 0);
}

static addPageSize(builder:flatbuffers.Builder, pageSize:number) {
  builder.addFieldInt32(2, pageSize, 0);
}

static addSort(builder:flatbuffers.Builder, sortOffset:flatbuffers.Offset) {
  builder.addFieldStruct(3, sortOffset, 0);
}

static addDesc(builder:flatbuffers.Builder, desc:boolean) {
  builder.addFieldInt8(4, +desc, +false);
}

static addSelection(builder:flatbuffers.Builder, selectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, selectionOffset, 0);
}

static addPathSelection(builder:flatbuffers.Builder, pathSelectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, pathSelectionOffset, 0);
}

static endFolderListRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // folder
  builder.requiredField(offset, 10) // sort
  return offset;
}

}
