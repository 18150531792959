// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { ErrorBuf } from '../datagram/error-buf.js';
import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { EntityPropertyIdBuf } from '../entity/entity-property-id-buf.js';
import { FileBuf } from '../file/file-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';
import { SearchBuf } from '../search/search-buf.js';


export class SearchResponseDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchResponseDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SearchResponseDatagramBuf):SearchResponseDatagramBuf {
  return (obj || new SearchResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SearchResponseDatagramBuf):SearchResponseDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

error(obj?:ErrorBuf):ErrorBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ErrorBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

search(obj?:SearchBuf):SearchBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new SearchBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

files(index: number, obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

filesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

index():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

total():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

pageSize():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

searchId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

cached():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

sort(obj?:EntityPropertyIdBuf):EntityPropertyIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? (obj || new EntityPropertyIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

desc():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 24);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startSearchResponseDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(11);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addError(builder:flatbuffers.Builder, errorOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, errorOffset, 0);
}

static addSearch(builder:flatbuffers.Builder, searchOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, searchOffset, 0);
}

static addFiles(builder:flatbuffers.Builder, filesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, filesOffset, 0);
}

static createFilesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startFilesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addIndex(builder:flatbuffers.Builder, index:number) {
  builder.addFieldInt32(4, index, 0);
}

static addTotal(builder:flatbuffers.Builder, total:number) {
  builder.addFieldInt32(5, total, 0);
}

static addPageSize(builder:flatbuffers.Builder, pageSize:number) {
  builder.addFieldInt32(6, pageSize, 0);
}

static addSearchId(builder:flatbuffers.Builder, searchIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(7, searchIdOffset, 0);
}

static addCached(builder:flatbuffers.Builder, cached:boolean) {
  builder.addFieldInt8(8, +cached, +false);
}

static addSort(builder:flatbuffers.Builder, sortOffset:flatbuffers.Offset) {
  builder.addFieldStruct(9, sortOffset, 0);
}

static addDesc(builder:flatbuffers.Builder, desc:boolean) {
  builder.addFieldInt8(10, +desc, +false);
}

static endSearchResponseDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 18) // search_id
  return offset;
}

}
