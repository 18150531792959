// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class SearchV1IntentPropertyBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1IntentPropertyBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1IntentPropertyBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentPropertyBuf):SearchV1IntentPropertyBuf {
  return (obj || new SearchV1IntentPropertyBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1IntentPropertyBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentPropertyBuf):SearchV1IntentPropertyBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1IntentPropertyBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

property():string|null
property(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
property(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

greaterThan():string|null
greaterThan(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
greaterThan(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

lessThan():string|null
lessThan(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
lessThan(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

matches():string|null
matches(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
matches(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

startsWith():string|null
startsWith(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
startsWith(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

contains():string|null
contains(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
contains(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

notContains():string|null
notContains(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
notContains(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startSearchV1IntentPropertyBuf(builder:flatbuffers.Builder) {
  builder.startObject(7);
}

static addProperty(builder:flatbuffers.Builder, propertyOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, propertyOffset, 0);
}

static addGreaterThan(builder:flatbuffers.Builder, greaterThanOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, greaterThanOffset, 0);
}

static addLessThan(builder:flatbuffers.Builder, lessThanOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, lessThanOffset, 0);
}

static addMatches(builder:flatbuffers.Builder, matchesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, matchesOffset, 0);
}

static addStartsWith(builder:flatbuffers.Builder, startsWithOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, startsWithOffset, 0);
}

static addContains(builder:flatbuffers.Builder, containsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, containsOffset, 0);
}

static addNotContains(builder:flatbuffers.Builder, notContainsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, notContainsOffset, 0);
}

static endSearchV1IntentPropertyBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // property
  return offset;
}

static createSearchV1IntentPropertyBuf(builder:flatbuffers.Builder, propertyOffset:flatbuffers.Offset, greaterThanOffset:flatbuffers.Offset, lessThanOffset:flatbuffers.Offset, matchesOffset:flatbuffers.Offset, startsWithOffset:flatbuffers.Offset, containsOffset:flatbuffers.Offset, notContainsOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchV1IntentPropertyBuf.startSearchV1IntentPropertyBuf(builder);
  SearchV1IntentPropertyBuf.addProperty(builder, propertyOffset);
  SearchV1IntentPropertyBuf.addGreaterThan(builder, greaterThanOffset);
  SearchV1IntentPropertyBuf.addLessThan(builder, lessThanOffset);
  SearchV1IntentPropertyBuf.addMatches(builder, matchesOffset);
  SearchV1IntentPropertyBuf.addStartsWith(builder, startsWithOffset);
  SearchV1IntentPropertyBuf.addContains(builder, containsOffset);
  SearchV1IntentPropertyBuf.addNotContains(builder, notContainsOffset);
  return SearchV1IntentPropertyBuf.endSearchV1IntentPropertyBuf(builder);
}
}
