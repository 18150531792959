import { Gesture } from "./BaseGesture"

export default function () {
  const { gestureLayers, } = useGestures()
  const { closePeek, peekState } = usePeek()
  const { peekBackgroundId } = useGlobals()

  class PeekViewBackgroundClickToClosePeek extends Gesture {
    layer = gestureLayers.mainViewBackground

    async onMouseDown(mouse: MouseEvent) {
      if (!getFirstMatchingParent(
        mouse.target,
        div => div.id === peekBackgroundId,
      ))
        return

      if (peekState.value.visible) {
        this.debug("Closing peek")
        closePeek()
        this.capture()
      }
    }
  }
  return new PeekViewBackgroundClickToClosePeek()
}
