import { uuidv7 } from "uuidv7"
import { Property, PropertyGroup, PropertySchema, hasGroup } from "../entity"

export class InterfaceSearch {
  _search: Search

  constructor(search: Search) {
    this._search = search
  }

  static fakeClientId() {
    return `ca11ab1e-${uuidv7()}`
  }

  /** When we don't have enough info about a file to type it */
  static blank(searchId?: string) {
    searchId = searchId ?? this.fakeClientId()
    return new InterfaceSearch({ searchId, properties: {} })
  }

  static fake({
    searchId,
    intent,
  }: { searchId?: string, intent: SearchV1Intent }) {
    // Creates a new search object with a fake search id
    searchId = searchId ?? this.fakeClientId()
    const timestamp = Date.now()
    const properties = {
      [Property.SEARCH_INTENT]: {
        propertyId: Property.SEARCH_INTENT,
        version: 0,
        schema: PropertySchema.SearchIntentValue,
        schemaVersion: 0,
        value: { type: PropertySchema.SearchIntentValue, intent },
        timestamp,
        pending: true,
      },
    } as Record<Property, SearchProperty>
    return new InterfaceSearch({ searchId, properties })
  }

  searchId() {
    return this._search.searchId
  }

  searchProperty(id: Property): SearchProperty | undefined {
    return this._search.properties?.[id]
  }

  intent(): SearchV1Intent | undefined {
    const property = this._search.properties[Property.SEARCH_INTENT]
    if (!property)
      return
    if (property.value.type !== PropertySchema.SearchIntentValue)
      throw new Error("Invalid search intent schema")
    return property.value.intent
  }

  lastRun(): number | undefined {
    const property = this._search.properties[Property.SEARCH_EXECUTED_AT]
    if (!property)
      return
    if (property.value.type !== PropertySchema.UtcTimestampMillisValue)
      throw new Error("Invalid search intent schema")
    return property.value.utcTimestampMillis
  }

  /** all the search results of this search in descending order */
  sortedResults(): SearchResult[] {
    const results = []
    for (const prop of Object.values(this._search.properties)) {
      if (hasGroup(prop!.propertyId as Property, PropertyGroup.SEARCH_RESULTS_META)) {
        if (prop!.value.type === PropertySchema.SearchResultValue)
          results.push(prop!.value.result)
        else {
          console.error("Malformed property", prop)
        }
      }
    }
    // We sort in descending order
    return results.sort((a, b) => b.score - a.score)
  }
}
