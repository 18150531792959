import { Property, PropertySchema } from "../entity"
import { InterfaceFile } from "."
import type { File } from "~/models/polyschema/File"
import folderIcon64 from "~/assets/images/files/folder/poly_folder@64.webp"
import folderIcon192 from "~/assets/images/files/folder/poly_folder@256.webp"
import folderIcon512 from "~/assets/images/files/folder/poly_folder@512.webp"
import folderIcon1536 from "~/assets/images/files/folder/poly_folder@1536.webp"

export class InterfaceFolderFile extends InterfaceFile {
  constructor(file: File) {
    super(file)
  }

  static fake({
    userId,
    clientId,
    parentId,
    name,
  }: { userId: string, clientId: string, parentId: string, name: string }) {
    const timestamp = Date.now()
    return new InterfaceFolderFile({
      fileId: clientId,
      properties: {
        [Property.NAME]: {
          propertyId: Property.NAME,
          version: 0,
          schema: PropertySchema.NonNullableStringValue,
          schemaVersion: 0,
          value: { type: PropertySchema.NonNullableStringValue, nonNullableString: name },
          timestamp,
          pending: true,
          author: userId,
        },
        [Property.PARENT_ID]: {
          propertyId: Property.PARENT_ID,
          version: 0,
          schema: PropertySchema.FileIdValue,
          schemaVersion: 0,
          value: { type: PropertySchema.FileIdValue, fileId: parentId },
          timestamp,
          pending: true,
          author: userId,
        },
        [Property.CREATED_AT]: {
          propertyId: Property.CREATED_AT,
          version: 0,
          schema: PropertySchema.UtcTimestampMillisValue,
          schemaVersion: 0,
          value: { type: PropertySchema.UtcTimestampMillisValue, utcTimestampMillis: timestamp },
          timestamp,
          pending: true,
          author: userId,
        },
        [Property.MIME_TYPE]: {
          propertyId: Property.MIME_TYPE,
          version: 0,
          schema: PropertySchema.MimeTypeValue,
          schemaVersion: 0,
          value: { type: PropertySchema.MimeTypeValue, mimeType: "inode/directory" },
          timestamp,
          pending: true,
          author: userId,
        },
        [Property.CLIENT_ID]: {
          propertyId: Property.CLIENT_ID,
          version: 0,
          schema: PropertySchema.FileIdValue,
          schemaVersion: 0,
          value: { type: PropertySchema.FileIdValue, fileId: clientId },
          timestamp,
          pending: true,
          author: userId,
        },
      },
    })
  }

  /** When we don't have enough info about a file to type it */
  static blank(fileId?: string) {
    fileId = fileId ?? InterfaceFile.fakeClientId()
    return InterfaceFolderFile.untyped({ fileId, properties: {} }) as InterfaceFolderFile
  }

  isFolder(): boolean {
    return true
  }

  isImage(): boolean {
    return false
  }

  icon64CropUri() {
    return folderIcon64
  }

  icon192CropUri() {
    return folderIcon192
  }

  icon512CropUri() {
    return folderIcon512
  }

  icon1536CropUri() {
    return folderIcon1536
  }

  icon64NativeUri() {
    return folderIcon64
  }

  icon192NativeUri() {
    return folderIcon192
  }

  icon512NativeUri() {
    return folderIcon512
  }

  icon1536NativeUri() {
    return folderIcon1536
  }
}
