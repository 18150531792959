// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';


export class UserIdValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):UserIdValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsUserIdValueBuf(bb:flatbuffers.ByteBuffer, obj?:UserIdValueBuf):UserIdValueBuf {
  return (obj || new UserIdValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsUserIdValueBuf(bb:flatbuffers.ByteBuffer, obj?:UserIdValueBuf):UserIdValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new UserIdValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

userId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startUserIdValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addUserId(builder:flatbuffers.Builder, userIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, userIdOffset, 0);
}

static endUserIdValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // user_id
  return offset;
}

static createUserIdValueBuf(builder:flatbuffers.Builder, userIdOffset:flatbuffers.Offset):flatbuffers.Offset {
  UserIdValueBuf.startUserIdValueBuf(builder);
  UserIdValueBuf.addUserId(builder, userIdOffset);
  return UserIdValueBuf.endUserIdValueBuf(builder);
}
}
