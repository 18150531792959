// @ts-expect-error "untyped lib"
import { diff_match_patch as DiffMatchPatch } from "diff-match-patch"
import { Property, PropertySchema, VirtualProperties } from "~/classes/entity"
import type { InterfaceFile } from "~/classes/file/InterfaceFile"
import { PropertyColor } from "~/classes/generated/interface"
import { deepEqual } from "~/utils/helpers"

interface State {
  file?: InterfaceFile
  propertyId?: Property
  /**
   * This is used for editing the text of properties directly
   * TODO: do we need to track the new value of text properties in state?
   */
  newValue: PropertySchemaType
}
let propertyEditorState: Ref<State>
const differ = new DiffMatchPatch()

const TagColors = {
  yellow: PropertyColor.Amber,
  green: PropertyColor.Green,
  teal: PropertyColor.Teal,
  blue: PropertyColor.Blue,
  purple: PropertyColor.Purple,
  violet: PropertyColor.Violet,
  pink: PropertyColor.Pink,
  red: PropertyColor.Red,
  gray: PropertyColor.Steel,
}

function startEditingProperty(file: InterfaceFile, propertyId: Property) {
  const property = file.property(propertyId)
  if (!property)
    throw new Error("Can't edit nonexistent property")
  propertyEditorState.value.file = file
  propertyEditorState.value.propertyId = property.propertyId as Property
  propertyEditorState.value.newValue = clone(property.value)
}

function isEditing() {
  return !!propertyEditorState.value.file
}

function isEditingProperty(fileId: string, propertyId: Property) {
  return propertyEditorState.value.file?.fileId === fileId && propertyEditorState.value.propertyId === propertyId
}

function stopEditing() {
  const { capture } = useAnalytics()
  if (!isEditing())
    return

  const propertyId = propertyEditorState.value.propertyId
  if (!propertyId) {
    console.error("PropertyID not set?")
    return clearEditingState()
  }

  const file = propertyEditorState.value.file
  if (!file) {
    console.error("File not set?")
    return clearEditingState()
  }

  if (VirtualProperties.has(propertyId as any)) {
    // TODO: handle tag creation? Or is that just inside of each tag's callback?
    console.warn("Exiting since this is a virtual property")
    return clearEditingState()
  }

  const oldVal = file.property(propertyId)?.value
  const newVal = propertyEditorState.value.newValue

  // Check if the value is different
  if (!newVal || !oldVal) {
    console.error("No new value to insert or old value to override")
    return clearEditingState()
  }
  if (newVal.type === PropertySchema.NonNullableStringValue && newVal.nonNullableString === "") {
    console.warn("String is empty, reverting to old value")
    return clearEditingState()
  }
  if (deepEqual(oldVal, newVal)) {
    console.info("Old and new property values match, exiting", oldVal, newVal)
    return clearEditingState()
  }

  // Send an update command to update the value in the database
  const { updateFileProperty } = useFileSystem()
  updateFileProperty(file, propertyId as Property, newVal)

  capture("user_edited_file_property", { propertyId })

  // now reset the values
  return clearEditingState()
}

function clearEditingState() {
  propertyEditorState.value.file = undefined
  propertyEditorState.value.propertyId = undefined
  propertyEditorState.value.newValue = { type: PropertySchema.NonNullableStringValue, nonNullableString: "" }
}

function createAndEditFileNotesProperty(file: InterfaceFile) {
  const { myself } = useUser()
  const { addFileProperty } = useFileSystem()
  if (!myself.value.user)
    throw new Error("No user object")
  const propertyId = Property.FILE_NOTES
  const property: FileProperty = {
    propertyId,
    value: { type: PropertySchema.NullableStringValue, nullableString: "" },
    timestamp: Date.now(),
    schema: PropertySchema.NullableStringValue,
    author: myself.value.user.userId,
  }
  addFileProperty(file, property)
  // NOTE: even though addFileProperty says we shouldn't add the property to the
  // file, we do so here in order to allow us to proactively set the editable
  // property and do things like focus on the element to start typing
  file.addProperty(property)
  propertyEditorState.value.file = file
  propertyEditorState.value.propertyId = propertyId
  propertyEditorState.value.newValue = { type: PropertySchema.NullableStringValue, nullableString: "" }
}

function getPropertyAsDiff(file: InterfaceFile, compare: InterfaceFile, propertyId: Property): [number, string][] {
  const fileProperty = file.property(propertyId)
  const compareProperty = compare.property(propertyId)

  let filePropertyValue = ""
  let comparePropertyValue = ""

  if (!fileProperty)
    throw new Error("file does not have this property")
  else if (fileProperty.value.type === PropertySchema.NonNullableStringValue)
    filePropertyValue = fileProperty.value.nonNullableString
  else if (fileProperty.value.type === PropertySchema.NullableStringValue)
    filePropertyValue = fileProperty.value.nullableString
  else
    throw new Error(`Property schema not supported: ${fileProperty.value.type}`)

  if (!compareProperty)
    return [[-1, filePropertyValue]]
  else if (compareProperty.value.type === PropertySchema.NonNullableStringValue)
    comparePropertyValue = compareProperty.value.nonNullableString
  else if (compareProperty.value.type === PropertySchema.NullableStringValue)
    comparePropertyValue = compareProperty.value.nullableString
  else
    throw new Error(`Property schema not supported: ${compareProperty.value.type}`)

  const diff = differ.diff_main(filePropertyValue, comparePropertyValue)
  // https://github.com/google/diff-match-patch/wiki/API#diff_cleanupefficiencydiffs--null
  differ.diff_cleanupEfficiency(diff)

  console.log("Got property diff", filePropertyValue, comparePropertyValue, diff)
  return diff
}

export default function () {
  propertyEditorState = useState("property-editor-state", () => ({
    newValue: { type: PropertySchema.NonNullableStringValue, nonNullableString: "" },
  }))

  return {
    TagColors,
    propertyEditorState,
    startEditingProperty,
    stopEditing,
    isEditing,
    isEditingProperty,
    clearEditingState,
    createAndEditFileNotesProperty,
    getPropertyAsDiff,
  }
}
