import { Property, PropertySchema } from "../entity"
import { InterfaceFile } from "."

import type { File } from "~/models/polyschema/File"

export class InterfaceImageFile extends InterfaceFile {
  constructor(file: File) {
    super(file)
  }

  static fake({
    userId,
    clientId,
    parentId,
    uri,
    name,
    size,
    createdAt,
    type,
    width,
    height,
    parameters,
  }: { userId: string, clientId: string, parentId: string, uri: string, name: string, size: number, createdAt: number, type: string, width: number, height: number, parameters?: GenerationMetadata }) {
    // NOTE: timestamps are zero here since we don't care about when the PROPERTIES were created, we care about when the FILE was created.
    const timestamp = Date.now()
    const properties = {
      [Property.URI]: {
        propertyId: Property.URI,
        version: 0,
        schema: PropertySchema.S3BlobUriValue,
        schemaVersion: 0,
        value: { type: PropertySchema.S3BlobUriValue, s3BlobUri: uri },
        timestamp,
        pending: true,
        author: userId,
      },
      [Property.NAME]: {
        propertyId: Property.NAME,
        version: 0,
        schema: PropertySchema.NonNullableStringValue,
        schemaVersion: 0,
        value: { type: PropertySchema.NonNullableStringValue, nonNullableString: name },
        timestamp,
        pending: true,
        author: userId,
      },
      [Property.SIZE]: {
        propertyId: Property.SIZE,
        version: 0,
        schema: PropertySchema.SizeBytesValue,
        schemaVersion: 0,
        value: { type: PropertySchema.SizeBytesValue, sizeBytes: size },
        timestamp,
        pending: true,
        author: userId,
      },
      [Property.PARENT_ID]: {
        propertyId: Property.PARENT_ID,
        version: 0,
        schema: PropertySchema.FileIdValue,
        schemaVersion: 0,
        value: { type: PropertySchema.FileIdValue, fileId: parentId },
        timestamp: 0,
        pending: true,
        author: userId,
      },
      [Property.CREATED_AT]: {
        propertyId: Property.CREATED_AT,
        version: 0,
        schema: PropertySchema.UtcTimestampMillisValue,
        schemaVersion: 0,
        value: { type: PropertySchema.UtcTimestampMillisValue, utcTimestampMillis: createdAt },
        timestamp,
        pending: true,
        author: userId,
      },
      [Property.MIME_TYPE]: {
        propertyId: Property.MIME_TYPE,
        version: 0,
        schema: PropertySchema.MimeTypeValue,
        schemaVersion: 0,
        value: { type: PropertySchema.MimeTypeValue, mimeType: type },
        timestamp,
        pending: true,
        author: userId,
      },
      [Property.CLIENT_ID]: {
        propertyId: Property.CLIENT_ID,
        version: 0,
        schema: PropertySchema.FileIdValue,
        schemaVersion: 0,
        value: { type: PropertySchema.FileIdValue, fileId: clientId },
        timestamp,
        pending: true,
        author: userId,
      },
      // Image Properties
      [Property.WIDTH]: {
        propertyId: Property.WIDTH,
        version: 0,
        schema: PropertySchema.PositiveIntegerValue,
        schemaVersion: 0,
        value: { type: PropertySchema.PositiveIntegerValue, positiveInteger: width },
        timestamp,
        pending: true,
        author: userId,
      },
      [Property.HEIGHT]: {
        propertyId: Property.HEIGHT,
        version: 0,
        schema: PropertySchema.PositiveIntegerValue,
        schemaVersion: 0,
        value: { type: PropertySchema.PositiveIntegerValue, positiveInteger: height },
        timestamp,
        pending: true,
        author: userId,
      },
    } as Record<string, FileProperty>

    if (parameters?.raw)
      properties[Property.IMAGE_GENERATION_PARAMETERS_RAW] = {
        propertyId: Property.IMAGE_GENERATION_PARAMETERS_RAW,
        version: 0,
        schema: PropertySchema.NonNullableStringValue,
        schemaVersion: 0,
        value: { type: PropertySchema.NonNullableStringValue, nonNullableString: parameters.raw.toString() },
        timestamp,
        pending: true,
        author: userId,
      }
    if (parameters?.prompt)
      properties[Property.GENERATION_PROMPT] = {
        propertyId: Property.GENERATION_PROMPT,
        version: 0,
        schema: PropertySchema.NonNullableStringValue,
        schemaVersion: 0,
        value: { type: PropertySchema.NonNullableStringValue, nonNullableString: parameters.prompt.toString() },
        timestamp,
        pending: true,
        author: userId,
      }
    if (parameters?.negative_prompt)
      properties[Property.GENERATION_NEGATIVE_PROMPT] = {
        propertyId: Property.GENERATION_NEGATIVE_PROMPT,
        version: 0,
        schema: PropertySchema.NonNullableStringValue,
        schemaVersion: 0,
        value: { type: PropertySchema.NonNullableStringValue, nonNullableString: parameters.negative_prompt.toString() },
        timestamp,
        pending: true,
        author: userId,
      }
    if (parameters?.program_used)
      properties[Property.GENERATION_SOURCE] = {
        propertyId: Property.GENERATION_SOURCE,
        version: 0,
        schema: PropertySchema.NonNullableStringValue,
        schemaVersion: 0,
        value: { type: PropertySchema.NonNullableStringValue, nonNullableString: parameters.program_used.toString() },
        timestamp,
        pending: true,
        author: userId,
      }
    if (parameters?.seed !== undefined)
      properties[Property.GENERATION_SEED] = {
        propertyId: Property.GENERATION_SEED,
        version: 0,
        schema: PropertySchema.AnyIntegerValue,
        schemaVersion: 0,
        value: { type: PropertySchema.AnyIntegerValue, anyInteger: Number.parseInt(parameters.seed) },
        timestamp,
        pending: true,
        author: userId,
      }
    if (parameters?.model)
      properties[Property.GENERATION_MODEL] = {
        propertyId: Property.GENERATION_MODEL,
        version: 0,
        schema: PropertySchema.NonNullableStringValue,
        schemaVersion: 0,
        value: { type: PropertySchema.NonNullableStringValue, nonNullableString: parameters.model.toString() },
        timestamp,
        pending: true,
        author: userId,
      }
    if (parameters?.model_used_version)
      properties[Property.GENERATION_MODEL_VERSION] = {
        propertyId: Property.GENERATION_MODEL_VERSION,
        version: 0,
        schema: PropertySchema.NonNullableStringValue,
        schemaVersion: 0,
        value: { type: PropertySchema.NonNullableStringValue, nonNullableString: parameters.model_used_version.toString() },
        timestamp,
        pending: true,
        author: userId,
      }

    return new InterfaceImageFile({
      fileId: clientId,
      properties,
    })
  }

  isFolder(): boolean {
    return false
  }

  isImage(): boolean {
    return true
  }
}
