import * as flatbuffers from "flatbuffers"
import { DatagramBuf } from "~/classes/generated/datagram"
import { RequestDatagramBuf, SearchRequestDatagramBuf } from "~/classes/generated/request"
import { addSearchToBuf, parseMaybeSearch } from "~/utils/flatbuffers/search"
import { addEntityPropertySelectionToBuf, createEntityIdBuf, createRequestIdBuf, parseEntityId, parseFile, parseRequestId } from "~/utils/flatbuffers"
import type { SearchResponseDatagramBuf } from "~/classes/generated/response"
import { parseMaybeDatagramError, parseVector } from "~/utils/flatbuffers/datagram"
import type { File } from "~/models/polyschema/File"

const console = useLogger("search/intent")

export function sendSearchRequest(request: SearchRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()
  const fbb = new flatbuffers.Builder(64)

  console.log("Executing search", request)

  const search = addSearchToBuf(fbb, request.search)
  let fileSelection
  if (request.fileSelection)
    fileSelection = addEntityPropertySelectionToBuf(fbb, request.fileSelection)
  let pathSelection
  if (request.pathSelection)
    pathSelection = addEntityPropertySelectionToBuf(fbb, request.pathSelection)
  let folders
  if (request.folders.length) {
    SearchRequestDatagramBuf.startFoldersVector(fbb, request.folders.length)
    for (const folder of request.folders)
      createEntityIdBuf(fbb, folder)
    folders = fbb.endVector()
  }

  SearchRequestDatagramBuf.startSearchRequestDatagramBuf(fbb)
  SearchRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  SearchRequestDatagramBuf.addSearch(fbb, search)
  SearchRequestDatagramBuf.addPageSize(fbb, request.pageSize)
  if (fileSelection !== undefined)
    SearchRequestDatagramBuf.addFileSelection(fbb, fileSelection)
  if (pathSelection !== undefined)
    SearchRequestDatagramBuf.addPathSelection(fbb, pathSelection)
  if (folders !== undefined)
    SearchRequestDatagramBuf.addFolders(fbb, folders)
  const createRequest = SearchRequestDatagramBuf.endSearchRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addSearch(fbb, createRequest)
  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function handleSearchResponseDatagramBuf(datagram: SearchResponseDatagramBuf) {
  const { emitInterfaceEvent } = useEvents()

  let files: File[] = []
  if (datagram.filesLength())
    files = parseVector(i => datagram.files(i), datagram.filesLength()).map(parseFile)

  const response: SearchResponseDatagram = {
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    searchId: parseEntityId(datagram.searchId()!),
    search: parseMaybeSearch(datagram.search()),
    index: datagram.index(),
    total: datagram.total(),
    pageSize: datagram.pageSize(),
    files,
    cached: datagram.cached(),
  }
  if (response.error)
    onSearchErrored(response.error)

  emitInterfaceEvent("searchResponse", response)
}

export function onSearchErrored(error: DatagramError) {
  const { notifyDatagramError } = useNotifications()
  console.error("search errored", error)
  notifyDatagramError(error, "Failed to search")
}
