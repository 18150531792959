import * as flatbuffers from "flatbuffers"
import { InterfaceFile } from "~/classes/file"
import { addFileToBuf, createRequestIdBuf, parseFile, parseFileId, parseRequestId } from "~/utils/flatbuffers"
import { FileDeleteRequestDatagramBuf, RequestDatagramBuf } from "~/classes/generated/request"
import type { ErrorBuf } from "~/classes/generated/datagram"
import { DatagramBuf } from "~/classes/generated/datagram"
import type { FileDeleteResponseDatagramBuf } from "~/classes/generated/response"
import type { FileDeleteMessageDatagramBuf } from "~/classes/generated/message"
import { parseMaybeFile } from "~/utils/flatbuffers/file"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"

const console = useLogger("file/delete")

export function sendFileDeleteRequests(requests: FileDeleteRequestDatagram[]) {
  const { sendWorkerDatagramBuf } = useCache()
  const fbb = new flatbuffers.Builder(64)
  const requestVector = []
  for (const request of requests) {
    const file = addFileToBuf(fbb, request.file)
    FileDeleteRequestDatagramBuf.startFileDeleteRequestDatagramBuf(fbb)
    FileDeleteRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
    FileDeleteRequestDatagramBuf.addFile(fbb, file)
    const deleteRequest = FileDeleteRequestDatagramBuf.endFileDeleteRequestDatagramBuf(fbb)
    RequestDatagramBuf.startRequestDatagramBuf(fbb)
    RequestDatagramBuf.addFileDelete(fbb, deleteRequest)
    requestVector.push(RequestDatagramBuf.endRequestDatagramBuf(fbb))
  }

  const vector = DatagramBuf.createRequestsVector(fbb, requestVector)
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, vector)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

/** Parse a Flatbuffers representation of a response */
export function handleFileDeleteResponseDatagramBuf(datagram: FileDeleteResponseDatagramBuf) {
  // TODO: get rid of datagram result, and find out a better way to
  // error out of this, since we need to send notifications
  onFileDeleteResponse({
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    file: parseMaybeFile(datagram.file()),
  })
}

export function onFileDeleteResponse(response: FileDeleteResponseDatagram) {
  const { removeFileFromFolderView } = useMainView()
  const { navigationState } = useNavigation()

  if (response.error)
    return onFileCreateErrored(response.error)
  if (!response.file)
    throw new Error("No file in delete response")

  const iFile = InterfaceFile.from(response.file)
  if (iFile.parentId() !== navigationState.value.file?.fileId) {
    console.warn("File deleted not in current folder, skipping", iFile)
    return
  }

  console.debug("File deleted", response.file)
  removeFileFromFolderView(new InterfaceFile(response.file))
}

export function onFileDeleteErrored(error: DatagramError) {
  const { notifyDatagramError } = useNotifications()

  return notifyDatagramError(error, "File deletion was unsuccessful")
}

export function handleFileDeleteMessageDatagramBuf(message: FileDeleteMessageDatagramBuf) {
  const { removeFileFromFolderView } = useMainView()

  const file = message.file()
  if (!file)
    return console.error("No file id in message?")

  removeFileFromFolderView(new InterfaceFile(parseFile(file)))
}
