import * as flatbuffers from "flatbuffers"
import { InterfaceFile } from "~/classes/file"
import { addEntityPropertySelectionToBuf, createEntityIdBuf, createRequestIdBuf, parseRequestId } from "~/utils/flatbuffers"
import { FilePropertiesRequestDatagramBuf, RequestDatagramBuf } from "~/classes/generated/request"
import { DatagramBuf } from "~/classes/generated/datagram"
import type { FilePropertiesResponseDatagramBuf } from "~/classes/generated/response"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"
import { parseMaybeFile } from "~/utils/flatbuffers/file"

const _console = useLogger("file/properties")

export function sendFilePropertiesRequest(request: FilePropertiesRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  const selection = addEntityPropertySelectionToBuf(fbb, request.selection)
  const pathSelection = addEntityPropertySelectionToBuf(fbb, request.pathSelection)

  FilePropertiesRequestDatagramBuf.startFilePropertiesRequestDatagramBuf(fbb)
  FilePropertiesRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  FilePropertiesRequestDatagramBuf.addFileId(fbb, createEntityIdBuf(fbb, request.fileId))

  FilePropertiesRequestDatagramBuf.addSelection(fbb, selection)
  FilePropertiesRequestDatagramBuf.addPathSelection(fbb, pathSelection)

  const propertiesRequest = FilePropertiesRequestDatagramBuf.endFilePropertiesRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addFileProperties(fbb, propertiesRequest)
  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

/** Parse a Flatbuffers representation of a response */
export function handleFilePropertiesResponseDatagramBuf(datagram: FilePropertiesResponseDatagramBuf) {
  const { emitInterfaceEvent } = useEvents()
  const { notifyDatagramError } = useNotifications()
  const response = {
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    file: parseMaybeFile(datagram.file()),
  }
  if (response.error)
    return notifyDatagramError(response.error, "Could not find file properties")

  onFilePropertiesResponse(response)
  emitInterfaceEvent("filePropertiesResponse", response)
}

export function onFilePropertiesResponse(response: FilePropertiesResponseDatagram) {
  const { addFileToFolderView } = useMainView()
  const { navigationState, setPathViewToFile } = useNavigation()
  const { notifyDatagramError } = useNotifications()

  if (response.error)
    return notifyDatagramError(response.error, "Failed to retrieve file properties")
  if (!response.file)
    throw new Error("No file in properties response")

  const iFile = InterfaceFile.from(response.file as any) // FIXME

  if (navigationState.value.file?.fileId === iFile.fileId)
    setPathViewToFile(iFile as InterfaceFolderFile)
  if (navigationState.value.file?.fileId === iFile.parentId())
    addFileToFolderView(iFile)
}
