// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { SearchPropertyBuf } from '../search/search-property-buf.js';


export class SearchBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchBuf(bb:flatbuffers.ByteBuffer, obj?:SearchBuf):SearchBuf {
  return (obj || new SearchBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchBuf(bb:flatbuffers.ByteBuffer, obj?:SearchBuf):SearchBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

searchId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

properties(index: number, obj?:SearchPropertyBuf):SearchPropertyBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new SearchPropertyBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

propertiesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startSearchBuf(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addSearchId(builder:flatbuffers.Builder, searchIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, searchIdOffset, 0);
}

static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, propertiesOffset, 0);
}

static createPropertiesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endSearchBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // search_id
  return offset;
}

static createSearchBuf(builder:flatbuffers.Builder, searchIdOffset:flatbuffers.Offset, propertiesOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchBuf.startSearchBuf(builder);
  SearchBuf.addSearchId(builder, searchIdOffset);
  SearchBuf.addProperties(builder, propertiesOffset);
  return SearchBuf.endSearchBuf(builder);
}
}
