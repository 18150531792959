// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { ErrorBuf } from '../datagram/error-buf.js';
import { FileBuf } from '../file/file-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class FilesSharedWithSelfResponseDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FilesSharedWithSelfResponseDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFilesSharedWithSelfResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FilesSharedWithSelfResponseDatagramBuf):FilesSharedWithSelfResponseDatagramBuf {
  return (obj || new FilesSharedWithSelfResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFilesSharedWithSelfResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FilesSharedWithSelfResponseDatagramBuf):FilesSharedWithSelfResponseDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FilesSharedWithSelfResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

error(obj?:ErrorBuf):ErrorBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ErrorBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

files(index: number, obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

filesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

index():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

total():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startFilesSharedWithSelfResponseDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addError(builder:flatbuffers.Builder, errorOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, errorOffset, 0);
}

static addFiles(builder:flatbuffers.Builder, filesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, filesOffset, 0);
}

static createFilesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startFilesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addIndex(builder:flatbuffers.Builder, index:number) {
  builder.addFieldInt32(3, index, 0);
}

static addTotal(builder:flatbuffers.Builder, total:number) {
  builder.addFieldInt32(4, total, 0);
}

static endFilesSharedWithSelfResponseDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  return offset;
}

}
