/**********************************************************
* A notification can mean one of several things
*
* - An "alert", which is an individually closeable message
*   that can take one of several "severity" levels, and
*   may have some optional action buttons or links to
*   relevant information.
* - A "job" which is a long-running task that usually has
*   a progress bar, and cannot be directly closed but must
*   be canceled. Canceling the job retains an alert that
*   can then be closed.
*
*  Notifications are combined into a tray that the user
*  can view, and individually action on different alerts.
*  A new notification will cause the try to briefly open
*  and for that alert to be visible.
*
/*********************************************************/

import type { ErrorBuf } from "~/classes/generated/datagram"

interface State {
  alerts: Record<string, AlertNotification>
  tray: {
    isOpen: boolean
    isPeek: boolean
    peekTimeout?: any
    showRipple: boolean
    rippleTimeout?: any
  }
}

let notificationState: Ref<State>

function openNotificationTray() {
  notificationState.value.tray.isOpen = true
  notificationState.value.tray.isPeek = false
}

/** Gets an ordered list of all alerts and jobs */
function _sortedNotifications(): AnyNotification[] {
  const { transferState } = useTransferManager()
  return [
    ...Object.values(notificationState.value.alerts),
    ...Object.values(transferState.value.jobs),
  ].sort((a, b) => b.timestamp - a.timestamp)
}

function notify(notification: AnyNotification) {
  if (notification.type === "error-alert" || notification.type === "success-alert")
    notificationState.value.alerts[notification.id] = notification
  showNotificationAnimations()
  // TODO: only show a "peek" view of the tray
}

function notifySuccess(title: string, description: string) {
  const message: SuccessAlertNotification = {
    type: "success-alert",
    id: generateWorkerRequestId(),
    timestamp: Date.now(),
    title,
    description,
  }
  notificationState.value.alerts[message.id] = message
  showNotificationAnimations()
  return message
}

function notifyError(title: string, description: string) {
  const error: ErrorAlertNotification = {
    type: "error-alert",
    errorType: "generic",
    id: generateWorkerRequestId(),
    timestamp: Date.now(),
    title,
    description,
  }
  notificationState.value.alerts[error.id] = error
  showNotificationAnimations()
  return error
}

function notifyDatagramError({ type: _, details }: DatagramError, title?: string) {
  const error: ErrorAlertNotification = {
    type: "error-alert",
    errorType: "generic",
    id: generateWorkerRequestId(),
    timestamp: Date.now(),
    title: title || "An error occurred",
    description: details,
  }
  notificationState.value.alerts[error.id] = error
  showNotificationAnimations()
}

/** Whether the element should show a progress bar */
function hasProgress() {
  const { transferState } = useTransferManager()
  return len(transferState.value.jobs) !== 0
}

function progressFraction() {
  const { transferState } = useTransferManager()
  let complete = 0
  let total = 0
  for (const job of Object.values(transferState.value.jobs)) {
    total += job.total
    complete += job.failed + job.completed
  }
  return total ? complete / total : 0
}

function clearNotificationById(id: string) {
  const { clearJobById } = useTransferManager()

  delete notificationState.value.alerts[id]

  clearJobById(id)
}

function clearNotifications() {
  notificationState.value.alerts = {}
  closeNotificationTray()
}

function closeNotificationTray() {
  notificationState.value.tray.isOpen = false
}

/**
 * Shows the "peek" view of the notification and a ripple for any errors. Should not be
 * called unless we know there is something to show.
 */
function showNotificationAnimations() {
  notificationState.value.tray.showRipple = true
  clearTimeout(notificationState.value.tray.rippleTimeout)
  notificationState.value.tray.rippleTimeout = setTimeout(() => {
    notificationState.value.tray.showRipple = false
  }, 1500)

  notificationState.value.tray.isPeek = true
  notificationState.value.tray.isOpen = true
  clearTimeout(notificationState.value.tray.peekTimeout)
  notificationState.value.tray.peekTimeout
  = setTimeout(() => {
      if (notificationState.value.tray.isPeek && notificationState.value.tray.isOpen) {
        closeNotificationTray()
      }
    }, 3000)
}

function jobFractionStarted(job: JobNotification) {
  const started = job.completed + job.failed + job.inProgress
  if (!job.total)
    return 0
  return started / job.total
}

function jobFractionFinished(job: JobNotification) {
  const started = job.completed + job.failed
  if (!job.total)
    return 0
  return started / job.total
}

function jobFractionSucceeded(job: JobNotification) {
  const started = job.completed
  if (!job.total)
    return 0
  return started / job.total
}

export default function () {
  notificationState = useState("notification-state", () => ({
    alerts: {},
    tray: {
      isOpen: false,
      isPeek: false,
      showRipple: false,
    },
  }))
  const sortedNotifications = computed(_sortedNotifications)

  return {
    notificationState,
    openNotificationTray,
    sortedNotifications,
    clearNotifications,
    clearNotificationById,
    closeNotificationTray,
    notify,
    notifyError,
    notifyDatagramError,
    notifySuccess,
    jobFractionStarted,
    jobFractionFinished,
    jobFractionSucceeded,
    hasProgress,
    progressFraction,
  }
}
