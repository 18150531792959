// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class MimeTypeValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):MimeTypeValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsMimeTypeValueBuf(bb:flatbuffers.ByteBuffer, obj?:MimeTypeValueBuf):MimeTypeValueBuf {
  return (obj || new MimeTypeValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsMimeTypeValueBuf(bb:flatbuffers.ByteBuffer, obj?:MimeTypeValueBuf):MimeTypeValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new MimeTypeValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startMimeTypeValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endMimeTypeValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // value
  return offset;
}

static createMimeTypeValueBuf(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  MimeTypeValueBuf.startMimeTypeValueBuf(builder);
  MimeTypeValueBuf.addValue(builder, valueOffset);
  return MimeTypeValueBuf.endMimeTypeValueBuf(builder);
}
}
