import { Property, PropertySchema, sharedWith } from "~/classes/entity"
import type { InterfaceFile } from "~/classes/file"
import { sendSharedFolderMembershipRequest } from "~/classes/handlers/sharedFolder/membership"
import { sendUserFindRequestBatch } from "~/classes/handlers/user/find"
import { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"
import { InterfaceUser } from "~/classes/users/InterfaceUser"
import type { SharedFolderMembershipResponseDatagram } from "~/models/polyschema/SharedFolderMembershipResponseDatagram"

interface State {
  /** Mapping from shared folder id to info */
  all: Record<string, InterfaceSharedFolderInfo>
  new: {
    dialogOpen: boolean
    name: string
    handle: string
    loading: boolean
  }
  current?: {
    sharedFolderInfo: InterfaceSharedFolderInfo
    users: Record<string, InterfaceUser>
    invite: {
      isLoading: boolean
      email: string
      requestId?: string
      userId?: string
    }
    // TODO: delete
    isPublicDemo?: boolean
  }
}

let sharedFolderState: Ref<State>
const console = useLogger("use-shared-folders", theme.colors.violet.hex)

function loadSharedFoldersFromUserInfo(userInfo: SelfInfo) {
  const sharedFolders = userInfo.sidebarSharedFolders.map(s => new InterfaceSharedFolderInfo(s))
  sharedFolderState.value.all = dictByKey(sharedFolders, s => s.sharedFolderId())
}

function resetSpaceCreationState() {
  sharedFolderState.value.new = {
    dialogOpen: false,
    name: "",
    handle: "",
    loading: false,
  }
}

function resetCurrentSharedFolderState() {
  sharedFolderState.value.current = undefined
}

/** This function is called when the user navigates to a new shared folder */
function onSwitchedSpaces(sharedFolderId?: string, priorSharedFolderId?: string) {
  if (!sharedFolderId) {
    if (priorSharedFolderId) {
      console.log("Resetting shared folder info since we navigated away from a shared folder")
      return resetCurrentSharedFolderState()
    }
    return
  }
  const sharedFolderInfo = sharedFolderState.value.all[sharedFolderId]
  if (!sharedFolderInfo)
    return console.error("Space discovery is not implemented yet")
  sharedFolderState.value.current = {
    sharedFolderInfo,
    users: {},
    invite: {
      isLoading: false,
      email: "",
    },
  }

  const requestId = generateWorkerRequestId()
  const requests: UserFindRequestDatagram[] = []
  for (const userProperty of sharedFolderInfo.users()) {
    if (!userProperty.sharedWithUserId)
      throw new Error("No shared-with field on user, broken datagram")
    if (userProperty.value.type !== PropertySchema.RoleUuidValue)
      throw new Error("Shared with property invalid")
    requests.push({
      requestId,
      userId: userProperty.sharedWithUserId,
    })
  }
  // Whoever needs to get this user info will need to listen on this handler
  sendUserFindRequestBatch(requests)
}

/**
 * Inviting a user requires we know their user id. In order to retrieve that we must get the
 * user's id via a find request before we can actually do the inviting. Therefore, we make a
 * find request and store the request id, so that we can continue the request. Once we receive
 * the user's data, then we can call this method again, and do the actual inviting. Note that
 * if the find response is a failure, then we want to set isLoading and requestId to none
 * and send an error message.
 */
function inviteUserToCurrentSharedFolder() {
  const { myself } = useUser()
  const current = sharedFolderState.value.current
  if (!current || !myself.value.user)
    return console.error("No current shared folder to invite to")
  current.invite.isLoading = true
  if (!current.invite.userId) {
    // If there's no user id for the associated user, we need to request it
    current.invite.requestId = generateWorkerRequestId()
    console.log("No user id for user, requesting user info")
    return sendUserFindRequestBatch([{
      requestId: current.invite.requestId,
      email: current.invite.email,
    }])
  }
  // Updating the membership requires we create a fake shared folder object that only has
  // the user membership as a single property of the home and archive directories
  const propertyId = sharedWith(current.invite.userId)
  const property: FileProperty = {
    propertyId,
    schema: PropertySchema.RoleUuidValue,
    schemaVersion: 0,
    version: 0,
    author: myself.value.user.userId,
    value: {
      type: PropertySchema.RoleUuidValue,
      roleUuid: "0f000000-0000-0000-0000-000000000000", // FIXME: owner
    },
    timestamp: Date.now(),
    pending: true,
    sharedWithUserId: current.invite.userId,
  }
  current.sharedFolderInfo.home().addProperty(property)
  current.sharedFolderInfo.archive().addProperty(property)

  // This could be a lot cleaner...
  current.invite.requestId = generateWorkerRequestId()
  sendSharedFolderMembershipRequest({
    requestId: current.invite.requestId,
    sharedFolderInfo: {
      sharedFolder: {
        sharedFolderId: current.sharedFolderInfo._info.sharedFolder.sharedFolderId,
        properties: {},
      },
      home: {
        fileId: current.sharedFolderInfo._info.home.fileId,
        properties: { [propertyId]: property },
      },
      archive: {
        fileId: current.sharedFolderInfo._info.archive.fileId,
        properties: { [propertyId]: property },
      },
    },
  })
}

function leaveSharedFolder(sharedFolderId: string) {
  const { myself } = useUser()
  const sharedFolderInfo = sharedFolderState.value.all[sharedFolderId]
  if (!sharedFolderInfo || !myself.value.user)
    return console.error("No shared folder or Not logged in")
  const propertyId = sharedWith(myself.value.user.userId)
  const property = sharedFolderInfo.home().property(propertyId as Property)
  if (!property)
    throw new Error("Shared folder info incomplete -- needs own membership")
  property.deleted = true
  property.pending = true
  sendSharedFolderMembershipRequest({
    requestId: generateWorkerRequestId(),
    sharedFolderInfo: {
      sharedFolder: {
        sharedFolderId: sharedFolderInfo.sharedFolderId(),
        properties: {},
      },
      home: {
        fileId: sharedFolderInfo.home().fileId,
        properties: { [propertyId]: property },
      },
      archive: {
        fileId: sharedFolderInfo.archive().fileId,
        properties: { [propertyId]: property },
      },
    },
  })
}

function onUserInfoResponse(response: UserFindResponseDatagram) {
  console.log("Response received", response)
  if (response.error || !response.user) {
    if (sharedFolderState.value.current?.invite.requestId === response.requestId)
      sharedFolderState.value.current!.invite.isLoading = false
    return console.warn("Error in retrieving user info")
  }
  if (!sharedFolderState.value.current)
    return
  for (const userProperty of sharedFolderState.value.current.sharedFolderInfo.users()) {
    if (userProperty.sharedWithUserId === response.user.userId)
      sharedFolderState.value.current.users[response.user.userId] = new InterfaceUser(response.user)
    if (sharedFolderState.value.current.invite.requestId === response.requestId) {
      sharedFolderState.value.current.invite.userId = response.user.userId
      inviteUserToCurrentSharedFolder()
    }
  }
}

function onSharedFolderMembershipResponse(response: SharedFolderMembershipResponseDatagram) {
  console.log("Space membership update", response)

  if (response.requestId === sharedFolderState.value.current?.invite.requestId) {
    console.log("Space change membership response received")
    resetSpaceCreationState()
  }

  if (response.error || !response.sharedFolderInfo)
    return console.warn("Can't update membership. request errored")
  onSharedFolderInfoUpdated(new InterfaceSharedFolderInfo(response.sharedFolderInfo))
}

function onSharedFolderInfoUpdated(sharedFolderInfo: InterfaceSharedFolderInfo) {
  const { subscribeToSharedFolderUpdates } = useFileSystem()
  const { myself } = useUser()
  const { navigationState, navigateToHome } = useNavigation()
  if (!myself.value.user)
    throw new Error("Not logged in")
  const sharedWithProperty = sharedFolderInfo
    .home()
    .property(sharedWith(myself.value.user.userId) as Property)
  if (!sharedWithProperty)
    return console.error("Received invalid/irrelevant shared folder", sharedFolderInfo)

  if (sharedWithProperty.deleted) {
    console.warn("You were removed from a shared space (or you left)", sharedFolderInfo)
    delete sharedFolderState.value.all[sharedFolderInfo.sharedFolderId()]
    // TODO: unsubscribe / update our cache / received "You were unsubscribed" message.
    const currentFile = navigationState.value.file
    if (currentFile?.path()?.find(
      f => [sharedFolderInfo.home().fileId, sharedFolderInfo.archive().fileId].includes(f.fileId),
    ))
      navigateToHome() // TODO: make this prettier
    return
  }

  // This update info object has all the information about the shared folder only
  // because for now I made the server request all properties. In the future, this
  // may not be the case!
  if (!sharedFolderState.value.all[sharedFolderInfo.sharedFolderId()]) {
    sharedFolderState.value.all[sharedFolderInfo.sharedFolderId()] = sharedFolderInfo

    console.log("Received invitation to a shared folder, subscribing to future updates", sharedFolderInfo)
    subscribeToSharedFolderUpdates(sharedFolderInfo)
  }
  const sharedFolder = sharedFolderState.value.all[sharedFolderInfo.sharedFolderId()]

  if (sharedFolder) {
    for (const prop of sharedFolderInfo.users())
      sharedFolder._info.home.properties[prop.propertyId] = prop
    for (const prop of sharedFolderInfo.archiveUsers())
      sharedFolder._info.archive.properties[prop.propertyId] = prop
  }

  if (!sharedFolderState.value.current)
    return console.warn("No current shared folder to update, skipping membership change")

  for (const prop of sharedFolder.users())
    sharedFolderState.value.current.sharedFolderInfo._info.home.properties[prop.propertyId] = prop
  for (const prop of sharedFolder.archiveUsers())
    sharedFolderState.value.current.sharedFolderInfo._info.home.properties[prop.propertyId] = prop

  // retrieve info about the invited user
  const requestId = generateWorkerRequestId()
  const requests: UserFindRequestDatagram[] = []
  for (const userProperty of sharedFolderInfo.users()) {
    if (!userProperty.sharedWithUserId)
      throw new Error("No shared-with field on user, broken datagram")
    if (userProperty.value.type !== PropertySchema.RoleUuidValue)
      throw new Error("Shared with property invalid")
    requests.push({
      requestId,
      userId: userProperty.sharedWithUserId,
    })
  }
  // Whoever needs to get this user info will need to listen on this handler
  sendUserFindRequestBatch(requests)
}

/** Adds info about a shared folder to the user's sidebar */
function addSharedFolderInfo(SharedFolderInfo: InterfaceSharedFolderInfo) {
  console.log("Adding shared folder info to sidebar", SharedFolderInfo)
  sharedFolderState.value.all[SharedFolderInfo.sharedFolderId()] = SharedFolderInfo
}

function createSpace() {
  const { myself } = useUser()

  if (!myself.value.user)
    throw new Error("Not logged in")

  sharedFolderState.value.new.loading = true

  sendSharedFolderCreateRequest({
    requestId: generateWorkerRequestId(),
    sharedFolder: {
      sharedFolderId: InterfaceSharedFolderInfo.fakeSharedFolderId(),
      properties: InterfaceSharedFolderInfo.creationProperties({
        name: sharedFolderState.value.new.name,
        handle: sharedFolderState.value.new.handle.toLocaleLowerCase(),
        userId: myself.value.user.userId,
      }),
    },
  })
}

function getUserSharedFolderInfoByHandle(handle: string) {
  for (const SharedFolderInfo of Object.values(sharedFolderState.value.all))
    if (SharedFolderInfo.handle() === handle)
      return SharedFolderInfo
}

function getUserSharedFolderInfoFromHome(folder: InterfaceFile) {
  const sharedFolderIdProperty = folder.property(Property.SHARED_FOLDER_HOME_OWNER_ID)
  if (!sharedFolderIdProperty || sharedFolderIdProperty.value.type !== PropertySchema.SharedFolderIdValue)
    return
  return sharedFolderState.value.all[sharedFolderIdProperty.value.sharedFolderId]
}

function getUserSharedFolderInfoFromArchive(folder: InterfaceFile) {
  const sharedFolderIdProperty = folder.property(Property.SHARED_FOLDER_ARCHIVE_OWNER_ID)
  if (!sharedFolderIdProperty || sharedFolderIdProperty.value.type !== PropertySchema.SharedFolderIdValue)
    return
  return sharedFolderState.value.all[sharedFolderIdProperty.value.sharedFolderId]
}

export default function () {
  sharedFolderState = useState("shared-folder-state", () => ({
    all: {},
    new: {
      dialogOpen: false,
      name: "",
      handle: "",
      loading: false,
    },
  }))

  return {
    sharedFolderState,
    addSharedFolderInfo,
    resetSpaceCreationState,
    resetCurrentSharedFolderState,
    onSwitchedSpaces,
    onUserInfoResponse,
    getUserSharedFolderInfoByHandle,
    createSpace,
    getUserSharedFolderInfoFromHome,
    getUserSharedFolderInfoFromArchive,
    onSharedFolderMembershipResponse,
    inviteUserToCurrentSharedFolder,
    loadSharedFoldersFromUserInfo,
    onSharedFolderInfoUpdated,
    leaveSharedFolder,
  }
}
