export default function () {
  return {
    /**
     * Main layout, including all elements/components. Basically the top level container that
     * separates the navigation part of the app from the rest (auth, etc.)
     */
    mainLayoutId: "poly-main-layout",
    /**
     * The main view is the primary view of your content, i.e. your folder, search results,
     * etc.
     */
    mainViewId: "poly-main-view",
    mainViewTopBarPadding: 44,
    mainViewTreeChevronClass: "poly-tree-chevron",
    mainViewColumnDraggerClass: "poly-column-dragger",

    /**
     * This if from the 'perfect-scrollbar' library. We can't use the wrapper class because
     * it wraps the whole element. Instead we have to use just this scrollbar container
     */
    scrollbarClass: "ps__rail-y",
    peekBackgroundId: "poly-peek-background",
    peekWindowId: "poly-peek-window",
    /**
     * This is used to help our transform go from preview image to full screen
     * preview, so this needs to be set for each thumbnail depending on which is
     * the current one.
     */
    thumbnailPreviewImage: "poly-thumbnail-preview-image",

    imageRendererContainerClass: "poly-image-preview-container",
    /**
     * This class is used to ignore gesture events that would otherwise be based on "clicking
     * out" of something, e.g. cancelling a file selection
     */
    navIgnoreMainViewGestures: "poly-nav-ignore-main-view-gestures",

    folderDataFileId: "data-file-id",

    panelId: "poly-panel",
    panelDraggerId: "poly-panel-dragger",

    sidebarId: "poly-sidebar",
    sidebarWaterfallId: "poly-waterfall-sidebar",
    sidebarDraggerId: "poly-sidebar-dragger",

    contextMenuId: "poly-context-menu",

    searchViewId: "poly-search-view",
    searchSuggestionsId: "poly-nav-search-bar-suggestions",
    searchResultsIntentPillId: "poly-search-results-intent-pill",
    searchButton: "poly-search-button",

  }
}
