import { Gesture } from "./BaseGesture"

export default function () {
  const { gestureState, gestureLayers } = useGestures()
  const { uploadExternalImageIntoSearch } = useSearch()
  const { searchButton } = useGlobals()

  class DropExternalFilesIntoSearch extends Gesture {
    layer = gestureLayers.drag

    reset(): void {}

    async onDrop(drag: DragEvent) {
      if (gestureState.value.gesture !== "drag-in-unknown-file")
        return

      // check if we are over the search bar and its open
      if (!getFirstMatchingParent(drag.target, e => e.classList?.contains(searchButton)))
        return this.console.info("Not over search bar")

      this.console.log("Dropped file over search bar!", drag)
      drag.preventDefault()
      this.capture()
      this.switchMode("none")

      if (!drag.dataTransfer)
        throw new Error("No data to transfer on drop")
      this.console.log(
        "Transferred files",
        drag.dataTransfer.files,
        drag.dataTransfer.items,
      )

      return uploadExternalImageIntoSearch(drag.dataTransfer)
    }
  }
  return new DropExternalFilesIntoSearch()
}
