// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityPropertyGroupIdBuf } from '../entity/entity-property-group-id-buf.js';
import { EntityPropertyIdBuf } from '../entity/entity-property-id-buf.js';
import { EntityPropertySelectionBuf } from '../entity/entity-property-selection-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class SearchFindRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchFindRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchFindRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SearchFindRequestDatagramBuf):SearchFindRequestDatagramBuf {
  return (obj || new SearchFindRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchFindRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SearchFindRequestDatagramBuf):SearchFindRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchFindRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

groupId(obj?:EntityPropertyGroupIdBuf):EntityPropertyGroupIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityPropertyGroupIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

propertyId(obj?:EntityPropertyIdBuf):EntityPropertyIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new EntityPropertyIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

limit():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

prefix():string|null
prefix(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
prefix(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

contains(index: number):string
contains(index: number,optionalEncoding:flatbuffers.Encoding):string|Uint8Array
contains(index: number,optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb!.__vector(this.bb_pos + offset) + index * 4, optionalEncoding) : null;
}

containsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

selection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

cacheOnly():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startSearchFindRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(8);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addGroupId(builder:flatbuffers.Builder, groupIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, groupIdOffset, 0);
}

static addPropertyId(builder:flatbuffers.Builder, propertyIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(2, propertyIdOffset, 0);
}

static addLimit(builder:flatbuffers.Builder, limit:number) {
  builder.addFieldInt32(3, limit, 0);
}

static addPrefix(builder:flatbuffers.Builder, prefixOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, prefixOffset, 0);
}

static addContains(builder:flatbuffers.Builder, containsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, containsOffset, 0);
}

static createContainsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startContainsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addSelection(builder:flatbuffers.Builder, selectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, selectionOffset, 0);
}

static addCacheOnly(builder:flatbuffers.Builder, cacheOnly:boolean) {
  builder.addFieldInt8(7, +cacheOnly, +false);
}

static endSearchFindRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  return offset;
}

}
