// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { EntityPropertySelectionBuf } from '../entity/entity-property-selection-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class FilePropertiesRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FilePropertiesRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFilePropertiesRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FilePropertiesRequestDatagramBuf):FilePropertiesRequestDatagramBuf {
  return (obj || new FilePropertiesRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFilePropertiesRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FilePropertiesRequestDatagramBuf):FilePropertiesRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FilePropertiesRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

fileId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

selection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

pathSelection(obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new EntityPropertySelectionBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

cacheOnly():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startFilePropertiesRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addFileId(builder:flatbuffers.Builder, fileIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, fileIdOffset, 0);
}

static addSelection(builder:flatbuffers.Builder, selectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, selectionOffset, 0);
}

static addPathSelection(builder:flatbuffers.Builder, pathSelectionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, pathSelectionOffset, 0);
}

static addCacheOnly(builder:flatbuffers.Builder, cacheOnly:boolean) {
  builder.addFieldInt8(4, +cacheOnly, +false);
}

static endFilePropertiesRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // file_id
  builder.requiredField(offset, 8) // selection
  builder.requiredField(offset, 10) // path_selection
  return offset;
}

}
