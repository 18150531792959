import type { DownloadFinishedMessageBuf, DownloadStartedMessageBuf, DownloadStatusMessageBuf } from "~/classes/generated/transfer"
import { parseTransferId } from "~/utils/flatbuffers/transfer"

const console = useLogger("transfer/download")

export function handleDownloadStartedMessageBuf(buf: DownloadStartedMessageBuf) {
  const { transferState } = useTransferManager()

  const message: DownloadStartedMessage = {
    transferId: parseTransferId(buf.transferId()!),
    waiting: buf.waiting(),
  }

  console.debug("Download Started", message)

  const old = transferState.value.jobs[message.transferId] as DownloadJob
  transferState.value.jobs[message.transferId] = {
    type: "download",
    id: message.transferId,
    status: "downloading",
    timestamp: old?.timestamp ?? Date.now(),
    destination: old?.destination ?? "",
    inProgress: old?.inProgress ?? 0,
    completed: old?.completed ?? 0,
    failed: old?.failed ?? 0,
    total: old?.total ?? 0,
    sources: old?.sources ?? [],
  }
}

export function handleDownloadStatusMessageBuf(buf: DownloadStatusMessageBuf) {
  const { transferState } = useTransferManager()

  const message: DownloadStatusMessage = {
    transferId: parseTransferId(buf.transferId()!),
    waiting: buf.waiting(),
    inProgress: buf.inProgress(),
    completed: buf.completed(),
    failed: buf.failed(),
  }

  console.debug("Transfer Status", message)

  const old = transferState.value.jobs[message.transferId] as DownloadJob
  transferState.value.jobs[message.transferId] = {
    type: "download",
    id: message.transferId,
    status: "downloading",
    timestamp: old?.timestamp ?? Date.now(),
    destination: old?.destination ?? "",
    inProgress: message.inProgress,
    completed: message.completed,
    failed: message.failed,
    total: old?.total ?? 0,
    sources: old?.sources ?? [],
  }
}

export function handleDownloadFinishedMessageBuf(buf: DownloadFinishedMessageBuf) {
  const { transferState } = useTransferManager()

  const message: DownloadFinishedMessage = {
    transferId: parseTransferId(buf.transferId()!),
    waiting: buf.waiting(),
    completed: buf.completed(),
    failed: buf.failed(),
  }

  console.debug("Transfer Finished", message)

  if (!transferState.value.jobs[message.transferId])
    return
  transferState.value.jobs[message.transferId].status = "complete"
  transferState.value.jobs[message.transferId].inProgress = 0
  transferState.value.jobs[message.transferId].failed = message.failed
  transferState.value.jobs[message.transferId].completed = message.completed
  // await sleep(2500)
  // delete transferState.value.jobs[message.transferId]
}
