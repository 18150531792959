import * as flatbuffers from "flatbuffers"
import { InterfaceFile, InterfaceFolderFile } from "~/classes/file"
import { addEntityPropertySelectionToBuf, addFileToBuf, createEntityPropertyIdBuf, createRequestIdBuf, parseFile, parseFileId, parseRequestId, parseVector } from "~/utils/flatbuffers"
import { FolderListRequestDatagramBuf, RequestDatagramBuf } from "~/classes/generated/request"
import { DatagramBuf, ErrorTypeBuf } from "~/classes/generated/datagram"
import type { FolderListPageResponseDatagramBuf } from "~/classes/generated/response"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"
import { Property } from "~/classes/entity"

// We log the start time of every folder list request
let startTime = Date.now()
const console = useLogger("file/list", theme.colors.pink.hex)

/** @deprecated use batch version */
export function sendFolderListRequest(request: FolderListRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()

  startTime = Date.now()

  const fbb = new flatbuffers.Builder(64)
  const folder = addFileToBuf(fbb, request.folder)

  let selection, pathSelection
  if (request.selection)
    selection = addEntityPropertySelectionToBuf(fbb, request.selection)
  if (request.pathSelection)
    pathSelection = addEntityPropertySelectionToBuf(fbb, request.pathSelection)
  FolderListRequestDatagramBuf.startFolderListRequestDatagramBuf(fbb)
  FolderListRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  FolderListRequestDatagramBuf.addFolder(fbb, folder)
  if (selection)
    FolderListRequestDatagramBuf.addSelection(fbb, selection)
  if (pathSelection)
    FolderListRequestDatagramBuf.addPathSelection(fbb, pathSelection)
  if (request.desc !== undefined)
    FolderListRequestDatagramBuf.addDesc(fbb, request.desc)
  FolderListRequestDatagramBuf.addPageSize(fbb, request.pageSize || 50) // FIXME: update this
  const sort = request.sort || Property.CREATED_AT
  FolderListRequestDatagramBuf.addSort(fbb, createEntityPropertyIdBuf(fbb, sort))
  const listRequest = FolderListRequestDatagramBuf.endFolderListRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addFolderList(fbb, listRequest)
  const requests = DatagramBuf.createRequestsVector(fbb, [RequestDatagramBuf.endRequestDatagramBuf(fbb)])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requests)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function sendFolderListRequests(requests: FolderListRequestDatagram[]) {
  const { sendWorkerDatagramBuf } = useCache()

  startTime = Date.now()

  const fbb = new flatbuffers.Builder(64)
  const requestOffsets: number[] = []

  for (const request of requests) {
    const folder = addFileToBuf(fbb, request.folder)
    let selection, pathSelection
    if (request.selection)
      selection = addEntityPropertySelectionToBuf(fbb, request.selection)
    if (request.pathSelection)
      pathSelection = addEntityPropertySelectionToBuf(fbb, request.pathSelection)
    FolderListRequestDatagramBuf.startFolderListRequestDatagramBuf(fbb)
    FolderListRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
    FolderListRequestDatagramBuf.addFolder(fbb, folder)
    if (selection)
      FolderListRequestDatagramBuf.addSelection(fbb, selection)
    if (pathSelection)
      FolderListRequestDatagramBuf.addPathSelection(fbb, pathSelection)
    if (request.desc !== undefined)
      FolderListRequestDatagramBuf.addDesc(fbb, request.desc)
    FolderListRequestDatagramBuf.addPageSize(fbb, request.pageSize || 50) // FIXME: update this
    const sort = request.sort || Property.CREATED_AT
    FolderListRequestDatagramBuf.addSort(fbb, createEntityPropertyIdBuf(fbb, sort))
    const listRequest = FolderListRequestDatagramBuf.endFolderListRequestDatagramBuf(fbb)

    RequestDatagramBuf.startRequestDatagramBuf(fbb)
    RequestDatagramBuf.addFolderList(fbb, listRequest)
    requestOffsets.push(RequestDatagramBuf.endRequestDatagramBuf(fbb))
  }

  const requestBuffers = DatagramBuf.createRequestsVector(fbb, requestOffsets)
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requestBuffers)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

/** Parse a Flatbuffers representation of a response */
export function handleFolderListResponseDatagramBuf(datagram: FolderListPageResponseDatagramBuf) {
  const files = parseVector(i => datagram.files(i), datagram.filesLength()).map(parseFile)
  const path = parseVector(i => datagram.path(i), datagram.pathLength()).map(parseFile)
  const { emitInterfaceEvent } = useEvents()

  // Now, let's go ahead and beef up all of our files with the path data
  // so that any further handlers don't need to do this work again.
  for (const file of files)
    file.path = path

  const response: FolderListPageResponseDatagram = {
    folderId: parseFileId(datagram.folderId()!),
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    files,
    index: datagram.index(),
    total: datagram.total(),
    pageSize: datagram.pageSize(),
    path,
  }

  console.debug("Folder list latency (ms):", Date.now() - startTime)

  // TODO: turn this into a mitt based handler and place it somewhere else? Or distribute to
  // its various listeners
  emitInterfaceEvent("folderListPageResponse", response)

  if (response.error)
    onFolderListPageErrored(response.error, response.requestId, response.folderId)
}

export function onFolderListPageErrored(
  error: DatagramError,
  _requestId: string,
  folderId: string,
) {
  const { navigationState, navigateToHome } = useNavigation()
  const { notifyDatagramError } = useNotifications()

  // NOTE: We only want to send these errors if this folder request corresponds
  // with the user's current directory, as otherwise the user might get too many
  // notifications for non-userspace jobs.
  if (folderId === navigationState.value.file?.fileId) {
    if (error.type === ErrorTypeBuf.FileNotFound) {
      // The file was not found, we should mention it wasn't found then redirect
      // to the home directory.
      navigateToHome()
      return notifyDatagramError(error, "That folder doesn't seem to exist anymore")
    }
    return notifyDatagramError(error, "There was a problem listing the folder")
  }

  return console.warn("Folder list errored", error)
}
