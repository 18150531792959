import { UUID } from "uuidv7"
import * as flatbuffers from "flatbuffers"
import { partition } from "../helpers"
import { addUserSidebarItemToBuf } from "./user"
import { addSearchV1IntentToBuf, parseFileId, parseSearchResult, parseSearchV1Intent, parseSharedFolderId, parseUserId, parseUserSidebarItem, parseVector } from "."
import { AnyIntegerValueBuf, BoolValueBuf, EmailValueBuf, EntityHandleValueBuf, EntityIdBuf, EntityPropertyGroupIdBuf, EntityPropertyIdBuf, EntityPropertySelectionBuf, ExifJsonStringValueBuf, FileIdValueBuf, HashableTagValueBuf, MimeTypeValueBuf, NonNullableStringValueBuf, NullableStringValueBuf, PositiveFloatValueBuf, PositiveIntegerValueBuf, RoleUuidBuf, RoleUuidValueBuf, S3BlobUriValueBuf, S3ThumbnailUriValueBuf, SearchIntentValueBuf, SearchQueryValueBuf, SearchResultValueBuf, SharedFolderIdValueBuf, SizeBytesValueBuf, UserIdValueBuf, UserSidebarItemValueBuf, UtcTimestampMillisValueBuf } from "~/classes/generated/entity"
import type { PropertyGroup } from "~/classes/entity"
import { PropertySchema } from "~/classes/entity"

// Entities
export function parseEntityPropertyGroupId(buf: EntityPropertyGroupIdBuf) {
  return buf.id()
}

export function createEntityPropertyGroupIdBuf(fbb: flatbuffers.Builder, groupId: PropertyGroup) {
  return EntityPropertyGroupIdBuf.createEntityPropertyGroupIdBuf(fbb, groupId)
}

export function parseEntityId(buf: EntityIdBuf) {
  const api = buf.api().toString(16).padStart(8, "0")
  const key = UUID.ofInner(new Uint8Array(range(16).map(k => buf.key(k)) as number[]))
  return `${api}-${key.toString()}`
}
export function createEntityIdBuf(fbb: flatbuffers.Builder, entityId: string) {
  const [api, key] = partition(entityId, "-")
  const apiId = Number.parseInt(api, 16)
  const uuid = UUID.parse(key)
  return EntityIdBuf.createEntityIdBuf(fbb, apiId, Array.from(uuid.bytes))
}

export function parseEntityPropertyId(buf: EntityPropertyIdBuf) {
  // TODO: validate as user property
  const group_id = parseEntityPropertyGroupId(buf.group()!).toString(16).padStart(8, "0")
  const key = UUID.ofInner(new Uint8Array(range(16).map(k => buf.key()!.key(k)) as number[]))
  return `${group_id}-${key.toString()}`
}

export function createEntityPropertyIdBuf(fbb: flatbuffers.Builder, propertyId: string) {
  const [group, key] = partition(propertyId, "-")
  const groupId = Number.parseInt(group, 16)
  const uuid = UUID.parse(key)
  return EntityPropertyIdBuf.createEntityPropertyIdBuf(fbb, groupId, Array.from(uuid.bytes))
}

export function parseEntityPropertySelection(buf: EntityPropertySelectionBuf | null): EntityPropertySelection {
  if (!buf || buf.everything())
    return { everything: true }
  const properties = parseVector(i => buf.properties(i), buf.propertiesLength()).map(parseEntityPropertyId)
  const groups = parseVector(i => buf.groups(i), buf.groupsLength()).map(parseEntityPropertyGroupId)
  return { properties, groups }
}
export function addEntityPropertySelectionToBuf(fbb: flatbuffers.Builder, selection: EntityPropertySelection) {
  let properties
  if (selection.properties) {
    EntityPropertySelectionBuf.startPropertiesVector(fbb, selection.properties.length)
    for (const propertyId of selection.properties)
      createEntityPropertyIdBuf(fbb, propertyId)
    properties = fbb.endVector()
  }
  let groups
  if (selection.groups) {
    EntityPropertySelectionBuf.startPropertiesVector(fbb, selection.groups.length)
    for (const groupId of selection.groups)
      EntityPropertyGroupIdBuf.createEntityPropertyGroupIdBuf(fbb, groupId)
    groups = fbb.endVector()
  }
  EntityPropertySelectionBuf.startEntityPropertySelectionBuf(fbb)
  if (properties !== undefined)
    EntityPropertySelectionBuf.addProperties(fbb, properties)
  if (groups !== undefined)
    EntityPropertySelectionBuf.addGroups(fbb, groups)
  if (selection.everything !== undefined)
    EntityPropertySelectionBuf.addEverything(fbb, selection.everything)
  return EntityPropertySelectionBuf.endEntityPropertySelectionBuf(fbb)
}

export function parseEntityPropertySchemaValue(
  schema: PropertySchema,
  buf: Uint8Array,
): PropertySchemaType {
  const byteBuf = new flatbuffers.ByteBuffer(buf)
  switch (schema) {
    case PropertySchema.FileIdValue: {
      const value = FileIdValueBuf.getRootAsFileIdValueBuf(byteBuf).fileId()!
      const fileId = parseFileId(value)
      return { type: PropertySchema.FileIdValue, fileId }
    }
    case PropertySchema.UserIdValue: {
      const value = UserIdValueBuf.getRootAsUserIdValueBuf(byteBuf).userId()!
      const userId = parseUserId(value)
      return { type: PropertySchema.UserIdValue, userId }
    }
    case PropertySchema.SharedFolderIdValue: {
      const value = SharedFolderIdValueBuf.getRootAsSharedFolderIdValueBuf(byteBuf).sharedFolderId()!
      const sharedFolderId = parseSharedFolderId(value)
      return { type: PropertySchema.SharedFolderIdValue, sharedFolderId }
    }
    case PropertySchema.UtcTimestampMillisValue: {
      const value = UtcTimestampMillisValueBuf.getRootAsUtcTimestampMillisValueBuf(byteBuf).timestamp()!
      const utcTimestampMillis = Number(value)
      return { type: PropertySchema.UtcTimestampMillisValue, utcTimestampMillis }
    }
    case PropertySchema.S3BlobUriValue: {
      const s3BlobUri = S3BlobUriValueBuf.getRootAsS3BlobUriValueBuf(byteBuf).blobUri()!
      return { type: PropertySchema.S3BlobUriValue, s3BlobUri }
    }
    case PropertySchema.S3ThumbnailUriValue: {
      const s3ThumbnailUri = S3ThumbnailUriValueBuf.getRootAsS3ThumbnailUriValueBuf(byteBuf).thumbnailUri()!
      return { type: PropertySchema.S3ThumbnailUriValue, s3ThumbnailUri }
    }
    case PropertySchema.NonNullableStringValue: {
      const nonNullableString = NonNullableStringValueBuf.getRootAsNonNullableStringValueBuf(byteBuf).value()!
      return { type: PropertySchema.NonNullableStringValue, nonNullableString }
    }
    case PropertySchema.NullableStringValue: {
      const nullableString = NullableStringValueBuf.getRootAsNullableStringValueBuf(byteBuf).value() ?? ""
      return { type: PropertySchema.NullableStringValue, nullableString }
    }
    case PropertySchema.HashableTagValue: {
      const hashableTag = HashableTagValueBuf.getRootAsHashableTagValueBuf(byteBuf).tag()!
      return { type: PropertySchema.HashableTagValue, hashableTag }
    }
    case PropertySchema.EmailValue: {
      const email = EmailValueBuf.getRootAsEmailValueBuf(byteBuf).email()!
      return { type: PropertySchema.EmailValue, email }
    }
    case PropertySchema.EntityHandleValue: {
      const entityHandle = EntityHandleValueBuf.getRootAsEntityHandleValueBuf(byteBuf).handle()!
      return { type: PropertySchema.EntityHandleValue, entityHandle }
    }
    case PropertySchema.BoolValue: {
      const bool = BoolValueBuf.getRootAsBoolValueBuf(byteBuf).exists()!
      return { type: PropertySchema.BoolValue, bool }
    }
    case PropertySchema.PositiveIntegerValue: {
      const value = PositiveIntegerValueBuf.getRootAsPositiveIntegerValueBuf(byteBuf).value()!
      const positiveInteger = Number(value)
      return { type: PropertySchema.PositiveIntegerValue, positiveInteger }
    }
    case PropertySchema.PositiveFloatValue: {
      const PositiveFloat = PositiveFloatValueBuf.getRootAsPositiveFloatValueBuf(byteBuf).value()!
      return { type: PropertySchema.PositiveFloatValue, PositiveFloat }
    }
    case PropertySchema.SizeBytesValue: {
      const value = SizeBytesValueBuf.getRootAsSizeBytesValueBuf(byteBuf).size()!
      const sizeBytes = Number(value)
      return { type: PropertySchema.SizeBytesValue, sizeBytes }
    }
    case PropertySchema.AnyIntegerValue: {
      const value = AnyIntegerValueBuf.getRootAsAnyIntegerValueBuf(byteBuf).value()!
      const anyInteger = Number(value)
      return { type: PropertySchema.AnyIntegerValue, anyInteger }
    }
    case PropertySchema.MimeTypeValue: {
      const mimeType = MimeTypeValueBuf.getRootAsMimeTypeValueBuf(byteBuf).value()!
      return { type: PropertySchema.MimeTypeValue, mimeType }
    }
    case PropertySchema.ExifJsonStringValue: {
      const exifString = ExifJsonStringValueBuf.getRootAsExifJsonStringValueBuf(byteBuf).value()!
      const exif = JSON.parse(exifString)
      return { type: PropertySchema.ExifJsonStringValue, exif }
    }
    case PropertySchema.RoleUuidValue: {
      const value = RoleUuidValueBuf.getRootAsRoleUuidValueBuf(byteBuf).role()!
      const key = UUID.ofInner(new Uint8Array(range(16).map(k => value.role(k)) as number[]))
      const roleUuid = key.toString()
      return { type: PropertySchema.RoleUuidValue, roleUuid }
    }
    case PropertySchema.UserSidebarItemValue: {
      const value = UserSidebarItemValueBuf.getRootAsUserSidebarItemValueBuf(byteBuf).value()!
      const userSidebarItem = parseUserSidebarItem(value)
      return { type: PropertySchema.UserSidebarItemValue, userSidebarItem }
    }
    case PropertySchema.SearchIntentValue: {
      const value = SearchIntentValueBuf.getRootAsSearchIntentValueBuf(byteBuf).value()!
      const intent = parseSearchV1Intent(value)
      return { type: PropertySchema.SearchIntentValue, intent }
    }
    case PropertySchema.SearchResultValue: {
      const value = SearchResultValueBuf.getRootAsSearchResultValueBuf(byteBuf).value()!
      const result = parseSearchResult(value)
      return { type: PropertySchema.SearchResultValue, result }
    }
    case PropertySchema.SearchQueryValue: {
      // We don't actually parse the query on the client, so we leave it as a buffer
      const query = SearchQueryValueBuf.getRootAsSearchQueryValueBuf(byteBuf).value()!
      return { type: PropertySchema.SearchQueryValue, query }
    }
  }
  throw new Error(`Unknown schema ${schema}`)
}

export function createEntityPropertySchemaBuf(
  value: PropertySchemaType,
) {
  const schemaFbb = new flatbuffers.Builder(64)
  if (value.type === PropertySchema.FileIdValue) {
    const fileId = createEntityIdBuf(schemaFbb, value.fileId)
    const buf = FileIdValueBuf.createFileIdValueBuf(schemaFbb, fileId)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.UserIdValue) {
    const userId = createEntityIdBuf(schemaFbb, value.userId)
    const buf = UserIdValueBuf.createUserIdValueBuf(schemaFbb, userId)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.SharedFolderIdValue) {
    const sharedFolderId = createEntityIdBuf(schemaFbb, value.sharedFolderId)
    const buf = SharedFolderIdValueBuf.createSharedFolderIdValueBuf(schemaFbb, sharedFolderId)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.UtcTimestampMillisValue) {
    const buf = UtcTimestampMillisValueBuf.createUtcTimestampMillisValueBuf(schemaFbb, BigInt(value.utcTimestampMillis))
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.S3BlobUriValue) {
    const s3BlobUri = schemaFbb.createString(value.s3BlobUri)
    const buf = S3BlobUriValueBuf.createS3BlobUriValueBuf(schemaFbb, s3BlobUri)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.S3ThumbnailUriValue) {
    const s3ThumbnailUri = schemaFbb.createString(value.s3ThumbnailUri)
    const buf = S3ThumbnailUriValueBuf.createS3ThumbnailUriValueBuf(schemaFbb, s3ThumbnailUri)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.NonNullableStringValue) {
    const nonNullableString = schemaFbb.createString(value.nonNullableString)
    const buf = NonNullableStringValueBuf.createNonNullableStringValueBuf(schemaFbb, nonNullableString)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.NullableStringValue) {
    const nullableString = schemaFbb.createString(value.nullableString)
    const buf = NullableStringValueBuf.createNullableStringValueBuf(schemaFbb, nullableString)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.HashableTagValue) {
    const hashableTag = schemaFbb.createString(value.hashableTag)
    const buf = HashableTagValueBuf.createHashableTagValueBuf(schemaFbb, hashableTag)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.EmailValue) {
    const email = schemaFbb.createString(value.email)
    EmailValueBuf.startEmailValueBuf(schemaFbb)
    EmailValueBuf.createEmailValueBuf(schemaFbb, email)
    const buf = EmailValueBuf.endEmailValueBuf(schemaFbb)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.EntityHandleValue) {
    const entityHandle = schemaFbb.createString(value.entityHandle)
    const buf = EntityHandleValueBuf.createEntityHandleValueBuf(schemaFbb, entityHandle)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.BoolValue) {
    BoolValueBuf.startBoolValueBuf(schemaFbb)
    BoolValueBuf.createBoolValueBuf(schemaFbb, value.bool)
    const buf = BoolValueBuf.endBoolValueBuf(schemaFbb)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.PositiveIntegerValue) {
    const buf = PositiveIntegerValueBuf.createPositiveIntegerValueBuf(schemaFbb, BigInt(value.positiveInteger))
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.PositiveFloatValue) {
    const buf = PositiveFloatValueBuf.createPositiveFloatValueBuf(schemaFbb, value.PositiveFloat)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.SizeBytesValue) {
    const buf = SizeBytesValueBuf.createSizeBytesValueBuf(schemaFbb, BigInt(value.sizeBytes))
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.AnyIntegerValue) {
    const buf = AnyIntegerValueBuf.createAnyIntegerValueBuf(schemaFbb, BigInt(value.anyInteger))
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.MimeTypeValue) {
    const mimetype = schemaFbb.createString(value.mimeType)
    const buf = MimeTypeValueBuf.createMimeTypeValueBuf(schemaFbb, mimetype)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.RoleUuidValue) {
    const uuid = UUID.parse(value.roleUuid)
    const role = RoleUuidBuf.createRoleUuidBuf(schemaFbb, Array.from(uuid.bytes))
    const buf = RoleUuidValueBuf.createRoleUuidValueBuf(schemaFbb, role)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.SearchIntentValue) {
    const intent = addSearchV1IntentToBuf(schemaFbb, value.intent)
    const buf = SearchIntentValueBuf.createSearchIntentValueBuf(schemaFbb, intent)
    schemaFbb.finish(buf)
  }
  else if (value.type === PropertySchema.UserSidebarItemValue) {
    const item = addUserSidebarItemToBuf(schemaFbb, value.userSidebarItem)
    const buf = UserSidebarItemValueBuf.createUserSidebarItemValueBuf(schemaFbb, item)
    schemaFbb.finish(buf)
  }
  else
    throw new Error(`Unknown/invalid schema type: ${value.type}`)
  return schemaFbb.asUint8Array()
}
