<script setup lang="ts">
import { PropertyColor, PropertySize } from "~/classes/generated/interface"

const props = defineProps<{
  value: string
  size: PropertySize
}>()

const { TagColors } = useProperty()

const color = computed(() => {
  for (const [prefix, color] of Object.entries(TagColors))
    if (props.value.startsWith(`${prefix}:`))
      return color
  return PropertyColor.Default
})
const text = computed(() => {
  for (const prefix of Object.keys(TagColors))
    if (props.value.startsWith(`${prefix}:`))
      return props.value.slice(prefix.length + 1)
  return props.value
})
</script>

<template>
  <div
    class="tag group"
    :class="{
      // colors
      default: color === PropertyColor.Default,
      yellow: color === PropertyColor.Amber,
      green: color === PropertyColor.Green,
      teal: color === PropertyColor.Teal,
      blue: color === PropertyColor.Blue,
      purple: color === PropertyColor.Purple,
      violet: color === PropertyColor.Violet,
      pink: color === PropertyColor.Pink,
      red: color === PropertyColor.Red,
      gray: color === PropertyColor.Steel,
      // sizes
      tiny: size === PropertySize.Tiny,
      small: size === PropertySize.Small,
      medium: size === PropertySize.Medium,
      large: size === PropertySize.Large,
      huge: size === PropertySize.Huge,
      giant: size === PropertySize.Giant,
    }"
  >
    <div class="circle" />
    <div
      class="name"
      :class="{ blank: text.length === 0 }"
    >
      {{ text }}
    </div>
  </div>
</template>

<style lang="sass" scoped>
@import '~/assets/styles/generated/variables.sass'
.tag
  @apply rounded-full inline-flex items-center pl-2 pr-1 py-0.5 cursor-pointer text-ellipses-inline max-w-full
  &.default
    @apply bg-[#{$page-tag-bg-default}]
  &.yellow
    @apply bg-[#{$global-amber-primary}]
  &.green
    @apply bg-[#{$global-green-primary}]
  &.teal
    @apply bg-[#{$global-teal-primary}]
  &.blue
    @apply bg-[#{$global-blue-primary}]
  &.purple
    @apply bg-[#{$global-purple-primary}]
  &.violet
    @apply bg-[#{$global-violet-primary}]
  &.pink
    @apply bg-[#{$global-pink-primary}]
  &.red
    @apply bg-[#{$global-red-primary}]
  &.gray
    @apply bg-[#{$global-steel-primary}]
  &.tiny
    @apply t-property-xs pl-1.25 pr-0.25 py-0.25
  &.small
    @apply t-property-sm pl-1.25 pr-0.375 py-0.375
  &.medium
    @apply t-property-md h-[#{$page-properties-md-h-single-outer}] pl-1.75 pr-0.75 py-0.25
  &.large
    @apply t-property-lg pl-2.25 pr-1.25 py-0.5 gap-0.25
  &.huge
    @apply t-property-xl pl-2.5 pr-1.75 py-0.5 gap-0.5
  &.giant
    @apply t-property-2xl pl-3.25 pr-2.25 gap-1
.name
  @apply px-1 h-full text-ellipses-inline
  &.blank
    @apply pr-0
.circle
  @apply rounded-full bg-[#{$page-tag-bg-hole}] flex-none
.tiny
  .circle
    @apply h-1.5 w-1.5
.small
  .circle
    @apply h-1.75 w-1.75
.medium
  .circle
    @apply h-2 w-2
.large
  .circle
    @apply h-2.5 w-2.5
.huge
  .circle
    @apply h-3.5 w-3.5

.giant
  .circle
    @apply h-5 w-5
</style>
