// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class UtcTimestampMillisValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):UtcTimestampMillisValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsUtcTimestampMillisValueBuf(bb:flatbuffers.ByteBuffer, obj?:UtcTimestampMillisValueBuf):UtcTimestampMillisValueBuf {
  return (obj || new UtcTimestampMillisValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsUtcTimestampMillisValueBuf(bb:flatbuffers.ByteBuffer, obj?:UtcTimestampMillisValueBuf):UtcTimestampMillisValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new UtcTimestampMillisValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

timestamp():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt('0');
}

static startUtcTimestampMillisValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addTimestamp(builder:flatbuffers.Builder, timestamp:bigint) {
  builder.addFieldInt64(0, timestamp, BigInt('0'));
}

static endUtcTimestampMillisValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createUtcTimestampMillisValueBuf(builder:flatbuffers.Builder, timestamp:bigint):flatbuffers.Offset {
  UtcTimestampMillisValueBuf.startUtcTimestampMillisValueBuf(builder);
  UtcTimestampMillisValueBuf.addTimestamp(builder, timestamp);
  return UtcTimestampMillisValueBuf.endUtcTimestampMillisValueBuf(builder);
}
}
