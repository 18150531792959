// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { SearchV1IntentFragmentBuf } from '../search/search-v1-intent-fragment-buf.js';


export class SearchV1IntentBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1IntentBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1IntentBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentBuf):SearchV1IntentBuf {
  return (obj || new SearchV1IntentBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1IntentBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentBuf):SearchV1IntentBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1IntentBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

fragments(index: number, obj?:SearchV1IntentFragmentBuf):SearchV1IntentFragmentBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new SearchV1IntentFragmentBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

fragmentsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startSearchV1IntentBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addFragments(builder:flatbuffers.Builder, fragmentsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, fragmentsOffset, 0);
}

static createFragmentsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startFragmentsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endSearchV1IntentBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // fragments
  return offset;
}

static createSearchV1IntentBuf(builder:flatbuffers.Builder, fragmentsOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchV1IntentBuf.startSearchV1IntentBuf(builder);
  SearchV1IntentBuf.addFragments(builder, fragmentsOffset);
  return SearchV1IntentBuf.endSearchV1IntentBuf(builder);
}
}
