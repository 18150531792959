import { Gesture } from "./BaseGesture"

// TODO: read clipboard state on desktop and reset our state if an external clipboard
//       event occurred
// TODO: we could possibly do something where we replace the paste event with our own
//       most recent event here, if the user externally modified clipboard, but it might
//       cause confusion more than help.

/**
 * This gesture allows us to sense dragging-in of unknown items into the file browser,
 * which lets us render a "ghost" image in the browser. Note that our regular drag
 * events should take precedence over this such that it never gets reached in the case
 * of a better drag-in handler for times that we do know the items (e.g. tauri native).
 */
export default function () {
  const { isEditingTextInput, gestureLayers } = useGestures()
  const { resetClipboardState, onFilesCut, onFilesCopied, onPasted } = useClipboard()
  const { navigationState } = useNavigation()
  const { selectedFiles } = useSelection()
  const { capture } = useAnalytics()

  class KeyboardShortcutsForClipboardActions extends Gesture {
    layer = gestureLayers.keyboardShortcut

    reset(): void {
      this.switchMode("none")
      resetClipboardState()
    }

    async onCut(clipboard: ClipboardEvent) {
      this.console.log("Cut event encountered", clipboard)
      // Resetting in case of other events/actions canceling our cut
      this.reset()

      if (isEditingTextInput())
        return this.console.log("Skipping cut event since we're in a text input")
      const files = selectedFiles()
      if (files.length === 0)
        return this.console.error("Cannot cut without anything selected")

      this.console.log("Cutting files", files)
      this.capture()
      clipboard.preventDefault()
      return onFilesCut(files)
    }

    async onCopy(clipboard: ClipboardEvent) {
      this.console.log("Copy event encountered", clipboard)

      // Resetting in case of other events/actions canceling our cut
      this.reset()

      if (isEditingTextInput())
        return this.console.log("Skipping copy event since we're in a text input")
      const files = selectedFiles()
      if (files.length === 0)
        return this.console.error("Cannot copy without anything selected")

      this.console.log("Copying files", files)
      this.capture()
      clipboard.preventDefault()
      return onFilesCopied(files)
    }

    async onPaste(clipboard: ClipboardEvent) {
      this.console.log("Paste event encountered", clipboard)
      if (isEditingTextInput()) {
        this.console.log("Skipping paste event since we're in a text input")
        return this.reset()
      }

      this.capture()

      // TODO: check if you have anything selected as the user might
      // want to be pasting into a folder or into a sidebar item
      if (!clipboard.clipboardData) {
        this.console.error("No data to transfer on paste")
        return this.reset()
      }
      const destination = navigationState.value.file
      if (!destination || !destination.isFolder()) {
        this.console.error("No destination folder")
        return this.reset()
      }
      clipboard.preventDefault()
      this.capture()
      this.console.log("pasted item(s)", clipboard.clipboardData.items)
      capture("user_pasted_files_into_view")

      await onPasted(
        clipboard.clipboardData,
        destination as InterfaceFolderFile,
      )
      this.reset()
    }
  }
  return new KeyboardShortcutsForClipboardActions()
}
