import { ViewModeType } from "../generated/interface"
import { Gesture } from "./BaseGesture"

export default function () {
  const { gestureState, gestureLayers } = useGestures()
  const { mainViewState } = useMainView()
  const { viewModeState, defaultColumnModeWidth } = useViewMode()
  const { mainViewColumnDraggerClass } = useGlobals()

  // WIP
  //
  // Getting column id
  // Saving your column sizes somehow?

  class ColumnViewDragToResize extends Gesture {
    layer = gestureLayers.viewMode

    #isDragging = false
    #dragStartX = 0
    #dragColumnIndex = 0
    #columnSizeStart = 0

    #columnMinWidth = 120
    #columnMaxWidth = 2400

    reset() {
      this.#isDragging = false
      this.#dragStartX = 0
      this.#dragColumnIndex = 0
      this.#columnSizeStart = 0
    }

    selectedDivider(mouse: MouseEvent): number | undefined {
      const columnDividerElement = getFirstMatchingParent(
        mouse.target,
        t => t.classList?.contains(mainViewColumnDraggerClass),
      )
      if (!columnDividerElement)
        return
      const index = columnDividerElement.dataset.columnIndex
      if (!index)
        throw new Error("No index for column")
      return Number.parseInt(index)
    }

    async onMouseDown(
      mouse: MouseEvent,
    ) {
      if (!["folder", "search-results"].includes(mainViewState.value.state))
        return this.reset()
      if (!enumEq(ViewModeType.Column, viewModeState.value.current.mode))
        return this.reset()
      if (!gestureState.value.mouseDown)
        throw new Error("No mouse position to use")

      const dividerIndex = this.selectedDivider(mouse)
      if (dividerIndex === undefined)
        return this.reset()

      viewModeState.value.current.columnView ??= { columns: [] }

      // If we have too few columns them we add columns to the widths array
      while (dividerIndex > len(viewModeState.value.current.columnView.columns) - 1)
        viewModeState.value.current.columnView.columns.push(defaultColumnModeWidth)

      const sizeStart = viewModeState.value.current.columnView.columns[dividerIndex]
      if (sizeStart === undefined)
        throw new Error("No column size")

      this.debug("Started dragging divider", dividerIndex)
      this.#isDragging = true
      this.capture()
      this.switchMode("drag-divider")
      this.#dragStartX = gestureState.value.mouseDown.clientX
      this.#columnSizeStart = sizeStart
      this.#dragColumnIndex = dividerIndex
      this.console.log("User clicked on column dragger, resizing")
    }

    async onMouseMove(
      mouse: MouseEvent,
    ) {
      if (!this.#isDragging)
        return this.reset()

      this.capture()
      const distX = mouse.pageX - this.#dragStartX

      viewModeState.value.current.columnView ??= { columns: [] }
      const columnWidths = viewModeState.value.current.columnView.columns || []
      if (columnWidths[this.#dragColumnIndex] === undefined)
        throw new Error("No column in array")
      columnWidths[this.#dragColumnIndex] = clamp(
        this.#columnSizeStart + distX,
        this.#columnMinWidth,
        this.#columnMaxWidth,
      )
      // Note we have to do a set here since vue doesn't know about in-item mutations
      viewModeState.value.current.columnView.columns = columnWidths
    }

    async onMouseUp(
      _mouse: MouseEvent,
    ) {
      if (!this.#isDragging)
        return
      this.reset()
    }
  }
  return new ColumnViewDragToResize()
}
