// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { EntityPropertyIdBuf } from '../entity/entity-property-id-buf.js';


export class SharedFolderPropertyBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SharedFolderPropertyBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSharedFolderPropertyBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderPropertyBuf):SharedFolderPropertyBuf {
  return (obj || new SharedFolderPropertyBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSharedFolderPropertyBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderPropertyBuf):SharedFolderPropertyBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SharedFolderPropertyBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

propertyId(obj?:EntityPropertyIdBuf):EntityPropertyIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new EntityPropertyIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

schema():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

schemaVersion():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

timestamp():bigint {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint64(this.bb_pos + offset) : BigInt('0');
}

value(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readUint8(this.bb!.__vector(this.bb_pos + offset) + index) : 0;
}

valueLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

valueArray():Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? new Uint8Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

deleted():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

pending():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

stale():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

author(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

static startSharedFolderPropertyBuf(builder:flatbuffers.Builder) {
  builder.startObject(10);
}

static addPropertyId(builder:flatbuffers.Builder, propertyIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, propertyIdOffset, 0);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(1, version, 0);
}

static addSchema(builder:flatbuffers.Builder, schema:number) {
  builder.addFieldInt32(2, schema, 0);
}

static addSchemaVersion(builder:flatbuffers.Builder, schemaVersion:number) {
  builder.addFieldInt32(3, schemaVersion, 0);
}

static addTimestamp(builder:flatbuffers.Builder, timestamp:bigint) {
  builder.addFieldInt64(4, timestamp, BigInt('0'));
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, valueOffset, 0);
}

static createValueVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset {
  builder.startVector(1, data.length, 1);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt8(data[i]!);
  }
  return builder.endVector();
}

static startValueVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(1, numElems, 1);
}

static addDeleted(builder:flatbuffers.Builder, deleted:boolean) {
  builder.addFieldInt8(6, +deleted, +false);
}

static addPending(builder:flatbuffers.Builder, pending:boolean) {
  builder.addFieldInt8(7, +pending, +false);
}

static addStale(builder:flatbuffers.Builder, stale:boolean) {
  builder.addFieldInt8(8, +stale, +false);
}

static addAuthor(builder:flatbuffers.Builder, authorOffset:flatbuffers.Offset) {
  builder.addFieldStruct(9, authorOffset, 0);
}

static endSharedFolderPropertyBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // property_id
  builder.requiredField(offset, 14) // value
  builder.requiredField(offset, 22) // author
  return offset;
}

}
