import { Property, PropertySchema } from "~/classes/entity"
import { InterfaceFile, InterfaceFolderFile, InterfaceImageFile } from "~/classes/file"

interface State {
  action?: "cut" | "copy"
  files: Record<string, InterfaceFile>
}

let clipboardState: Ref<State>
function defaultState(): State {
  return {
    files: {},
  }
}

const console = useLogger("use-clipboard", theme.colors.amber.hex)

function resetClipboardState() {
  clipboardState.value = defaultState()
}

async function onFilesCopied(files: InterfaceFile[]) {
  return _onYanked("copy", files)
}

async function onFilesCut(files: InterfaceFile[]) {
  return _onYanked("cut", files)
}

async function _onYanked(action: "cut" | "copy", files: InterfaceFile[]) {
  clipboardState.value.action = action
  clipboardState.value.files = dictByKey(files, f => f.fileId)
  const html = files.map(asClipboardHtml)
  const type = "text/html"
  const blob = new Blob(html, { type })
  await navigator.clipboard.write([
    new ClipboardItem({ [type]: blob }),
  ])

  clipboardState.value.action = action
  clipboardState.value.files = dictByKey(files, f => f.fileId)
}

function onPasted(data: DataTransfer, destination: InterfaceFolderFile) {
  const { uploadFromDataTransfer, copyFiles } = useTransferManager()
  const { moveFiles } = useFileSystem()

  if (data.files.length || !clipboardState.value.action)
    return uploadFromDataTransfer(data, destination)

  // Take the files from the paste event, parse them to see if they are
  // usable by us.
  const files: InterfaceFile[] = []
  for (const type of data.types) {
    const item = Document.parseHTMLUnsafe(data.getData(type))
    for (const node of item.body.children as unknown as HTMLElement[]) {
      const parsed = parseClipboardHtml(node)
      if (parsed)
        files.push(parsed)
      else
        console.error("Could not parse node as file", node)
    }
  }

  if (clipboardState.value.action === "cut") {
    // TODO: move files
    return moveFiles(files, destination)
  }
  else {
    if (!clipboardState.value.action)
      console.warn("Pasting from unknown location, default to copy")
    return copyFiles(files, destination)
  }
}

function asClipboardHtml(file: InterfaceFile) {
  const mimeType = file.mimeType()
  const parent = file.property(Property.PARENT_ID)
  if (!mimeType || !parent)
    throw new Error("No mime type or parent, can't add to clipboard")
  if (mimeType === "inode/directory")
    return `<div 
        data-file-id="${file.fileId}" 
        data-mime-type="${mimeType}" 
        data-parent-id="${file.parentId()}" 
        data-parent-version="${parent.version}"
      >
        ${file.name()}
      </div>`
  else if (mimeType.startsWith("image"))
    return `<img 
        src="${file.clientUri()}" 
        data-file-id="${file.fileId}"
        data-mime-type="${mimeType}" 
        data-parent-id="${file.parentId()}" 
        data-parent-version="${parent.version}"
      >`
  throw new Error(`Unknown mime type, can't convert to HTML: ${mimeType}`)
}

function parseClipboardHtml(element: HTMLElement): InterfaceFile | undefined {
  const { myself } = useUser()
  const mimeType = element.dataset.mimeType
  const fileId = element.dataset.fileId
  const parentId = element.dataset.parentId
  const version = Number.parseInt(element.dataset.parentVersion || "0")

  if (!myself.value.user || !mimeType || !fileId || !parentId) {
    console.error("Cannot parse this element without a mime type or file id")
    return
  }
  // TODO: Not sure if it matters about the file types?
  let file
  if (mimeType === "inode/directory") {
    file = InterfaceFolderFile.blank(fileId)
  }
  if (mimeType.startsWith("image/") && "src" in element.attributes) {
    file = InterfaceImageFile.blank(fileId)
  }
  else
    file = InterfaceFile.blank(fileId)

  // We add the parent property to the object so that when we make the
  // move request we have the right version
  file.properties[Property.PARENT_ID] = {
    propertyId: Property.PARENT_ID,
    version,
    schema: PropertySchema.FileIdValue,
    schemaVersion: 0,
    value: { type: PropertySchema.FileIdValue, fileId: parentId },
    timestamp: Date.now(),
    pending: true,
    author: myself.value.user.userId,
  }

  return file
}

export default function () {
  clipboardState = useState("clipboard-state", defaultState)

  return {
    resetClipboardState,
    clipboardState,
    onFilesCut,
    onFilesCopied,
    onPasted,
  }
}
