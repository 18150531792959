import { UUID, uuidv7obj } from "uuidv7"
import type * as flatbuffers from "flatbuffers"
import { OrderedIdBuf } from "~/classes/generated/entity"
import { RequestIdBuf } from "~/classes/generated/request"
import type { ErrorBuf } from "~/classes/generated/datagram"

export function parseVector<T>(accessor: (n: number) => T | null, length: number) {
  const vec = []
  for (let i = 0; i < length; i++) {
    const item = accessor(i)
    if (item === null)
      throw new Error("Invalid vector length")
    vec.push(accessor(i))
  }
  return vec as NonNullable<T>[]
}

export function parseRequestId(buf: RequestIdBuf) {
  const key = UUID.ofInner(new Uint8Array(range(16).map(k => buf.id(k)) as number[]))
  return key.toString()
}
export function parseOrderedId(buf: OrderedIdBuf) {
  return buf.id()!
}
export function parseMaybeOrderedId(buf: OrderedIdBuf | null) {
  if (buf)
    return parseOrderedId(buf)
}
export function createRequestIdBuf(fbb: flatbuffers.Builder, requestId?: string) {
  const uuid = requestId ? UUID.parse(requestId) : uuidv7obj()
  return RequestIdBuf.createRequestIdBuf(fbb, Array.from(uuid.bytes))
}

export function createOrderedIdBuf(fbb: flatbuffers.Builder, orderedId?: number) {
  if (orderedId === undefined)
    return
  return OrderedIdBuf.createOrderedIdBuf(fbb, orderedId)
}

export function parseDatagramError(errorBuf: ErrorBuf): DatagramError {
  const type = errorBuf.type()
  const details = errorBuf.details()
  if (details === null)
    throw new Error("No error details provided")
  return { type, details }
}

export function parseMaybeDatagramError(errorBuf: ErrorBuf | null) {
  if (errorBuf)
    return parseDatagramError(errorBuf)
}
