// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileBuf } from '../file/file-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class FileCreateRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FileCreateRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFileCreateRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FileCreateRequestDatagramBuf):FileCreateRequestDatagramBuf {
  return (obj || new FileCreateRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFileCreateRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FileCreateRequestDatagramBuf):FileCreateRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FileCreateRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

file(obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

cacheOnly():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startFileCreateRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addFile(builder:flatbuffers.Builder, fileOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, fileOffset, 0);
}

static addCacheOnly(builder:flatbuffers.Builder, cacheOnly:boolean) {
  builder.addFieldInt8(2, +cacheOnly, +false);
}

static endFileCreateRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // file
  return offset;
}

}
