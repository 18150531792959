// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';


export class SearchResultBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchResultBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchResultBuf(bb:flatbuffers.ByteBuffer, obj?:SearchResultBuf):SearchResultBuf {
  return (obj || new SearchResultBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchResultBuf(bb:flatbuffers.ByteBuffer, obj?:SearchResultBuf):SearchResultBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchResultBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

fileId(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

score():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
}

static startSearchResultBuf(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addFileId(builder:flatbuffers.Builder, fileIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, fileIdOffset, 0);
}

static addScore(builder:flatbuffers.Builder, score:number) {
  builder.addFieldFloat32(1, score, 0.0);
}

static endSearchResultBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // file_id
  return offset;
}

static createSearchResultBuf(builder:flatbuffers.Builder, fileIdOffset:flatbuffers.Offset, score:number):flatbuffers.Offset {
  SearchResultBuf.startSearchResultBuf(builder);
  SearchResultBuf.addFileId(builder, fileIdOffset);
  SearchResultBuf.addScore(builder, score);
  return SearchResultBuf.endSearchResultBuf(builder);
}
}
