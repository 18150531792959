// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class SearchV1IntentColorBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1IntentColorBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1IntentColorBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentColorBuf):SearchV1IntentColorBuf {
  return (obj || new SearchV1IntentColorBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1IntentColorBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentColorBuf):SearchV1IntentColorBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1IntentColorBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

r():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
}

g():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
}

b():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
}

a():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
}

static startSearchV1IntentColorBuf(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addR(builder:flatbuffers.Builder, r:number) {
  builder.addFieldInt8(0, r, 0);
}

static addG(builder:flatbuffers.Builder, g:number) {
  builder.addFieldInt8(1, g, 0);
}

static addB(builder:flatbuffers.Builder, b:number) {
  builder.addFieldInt8(2, b, 0);
}

static addA(builder:flatbuffers.Builder, a:number) {
  builder.addFieldInt8(3, a, 0);
}

static endSearchV1IntentColorBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSearchV1IntentColorBuf(builder:flatbuffers.Builder, r:number, g:number, b:number, a:number):flatbuffers.Offset {
  SearchV1IntentColorBuf.startSearchV1IntentColorBuf(builder);
  SearchV1IntentColorBuf.addR(builder, r);
  SearchV1IntentColorBuf.addG(builder, g);
  SearchV1IntentColorBuf.addB(builder, b);
  SearchV1IntentColorBuf.addA(builder, a);
  return SearchV1IntentColorBuf.endSearchV1IntentColorBuf(builder);
}
}
