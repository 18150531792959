/* eslint-disable unused-imports/no-unused-vars */

export abstract class Gesture {
  requestedMode?: GestureModeType = undefined
  isCapturing = false

  /** A gesture's layer is the priority we give it's handler methods */
  layer!: number

  console = useLogger(this.constructor.name)
  debug = this.console.debug
  warn = this.console.warn
  error = this.console.error

  constructor(layer?: number) {
    if (layer !== undefined)
      this.layer = layer
  }

  switchMode(mode: GestureModeType) {
    this.requestedMode = mode
  }

  unswitchMode() {
    this.requestedMode = undefined
  }

  /**
   * All subclasses should implement a reset method that clears any local state.
   * This is executed by the behavior system before the behavior is
   * mounted as well (not after unmounted as that may be within an event
   * handler)
   */
  reset() {}

  uncapture() {
    this.isCapturing = false
  }

  capture() {
    this.isCapturing = true
  }

  async onMounted() {}
  async onUnmounted() {}
  async onMouseDown(mouse: MouseEvent) {}
  async onMouseUp(mouse: MouseEvent) {}
  async onMouseEnter(mouse: MouseEvent) {}
  async onMouseLeave(mouse: MouseEvent) {}
  async onMouseMove(mouse: MouseEvent) {}
  async onContextMenu(mouse: MouseEvent) {}
  async onWheel(mouse: WheelEvent) {}
  async onKeyDown(keyboard: KeyboardEvent) {}
  async onKeyUp(keyboard: KeyboardEvent) {}
  async onDragEnter(drag: DragEvent) { }
  async onDragLeave(drag: DragEvent) { }
  async onDragOver(drag: DragEvent) { }
  async onDrop(drag: DragEvent) { }
  async onCut(clipboard: ClipboardEvent) { }
  async onCopy(clipboard: ClipboardEvent) { }
  async onPaste(clipboard: ClipboardEvent) { }
}
