import { Gesture } from "./BaseGesture"

export default function () {
  const { createNewWindow } = useTauri()
  const { gestureLayers } = useGestures()

  class DesktopKeyboardShortcut extends Gesture {
    layer = gestureLayers.keyboardShortcut

    async onKeyDown(
      keyboard: KeyboardEvent,
    ) {
      if (!isDesktop()) {
        return this.reset()
      }

      if (keyboard.key === "n" && this.commandKeyModifier(keyboard)) {
        keyboard.preventDefault()
        this.capture()
        createNewWindow()
        return this.reset()
      }
    }

    /**
     * TODO: address different OS'es, and test on Windows
     * @param keyboard event
     * @returns whether the OS-specific modifier key is pressed
     */
    commandKeyModifier(keyboard: KeyboardEvent) {
      return keyboard.metaKey || keyboard.ctrlKey
    }
  }

  return new DesktopKeyboardShortcut()
}
