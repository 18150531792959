import * as flatbuffers from "flatbuffers"
import type { UserFindResponseDatagramBuf } from "~/classes/generated/response"
import { createEntityIdBuf, createRequestIdBuf, parseRequestId } from "~/utils/flatbuffers"
import { RequestDatagramBuf, UserFindRequestDatagramBuf } from "~/classes/generated/request"
import { DatagramBuf } from "~/classes/generated/datagram"
import { parseMaybeDatagramError } from "~/utils/flatbuffers/datagram"
import { parseMaybeUser } from "~/utils/flatbuffers/user"

const console = useLogger("user/find", theme.colors.teal.hex)

export function sendUserFindRequestBatch(requests: UserFindRequestDatagram[]) {
  const { sendWorkerDatagramBuf } = useCache()

  const fbb = new flatbuffers.Builder(64)
  const userRequests = []
  for (const request of requests) {
    const maybeHandle = request.handle ? fbb.createString(request.handle) : undefined
    const maybeEmail = request.email ? fbb.createString(request.email) : undefined
    UserFindRequestDatagramBuf.startUserFindRequestDatagramBuf(fbb)
    UserFindRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
    if (request.userId)
      UserFindRequestDatagramBuf.addUserId(fbb, createEntityIdBuf(fbb, request.userId))
    if (maybeHandle)
      UserFindRequestDatagramBuf.addHandle(fbb, maybeHandle)
    if (maybeEmail)
      UserFindRequestDatagramBuf.addEmail(fbb, maybeEmail)
    const buf = UserFindRequestDatagramBuf.endUserFindRequestDatagramBuf(fbb)
    RequestDatagramBuf.startRequestDatagramBuf(fbb)
    RequestDatagramBuf.addUserFind(fbb, buf)
    userRequests.push(RequestDatagramBuf.endRequestDatagramBuf(fbb))
  }

  const requestBuf = DatagramBuf.createRequestsVector(fbb, userRequests)
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, requestBuf)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function handleUserFindResponseDatagramBuf(datagram: UserFindResponseDatagramBuf) {
  const { emitInterfaceEvent } = useEvents()
  const response: UserFindResponseDatagram = {
    requestId: parseRequestId(datagram.requestId()!),
    user: parseMaybeUser(datagram.user()),
    error: parseMaybeDatagramError(datagram.error()),
  }
  if (response.error)
    onUserFindErrored(response.error)

  emitInterfaceEvent("userFindResponse", response)
}

export function onUserFindErrored(error: DatagramError) {
  const { notifyDatagramError } = useNotifications()
  console.error("user find errored", error)
  notifyDatagramError(error, "Failed to find user details")
}
