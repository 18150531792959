// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { FileBuf } from '../file/file-buf.js';
import { SharedFolderBuf } from '../shared-folder/shared-folder-buf.js';


export class SharedFolderInfoBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SharedFolderInfoBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSharedFolderInfoBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderInfoBuf):SharedFolderInfoBuf {
  return (obj || new SharedFolderInfoBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSharedFolderInfoBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderInfoBuf):SharedFolderInfoBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SharedFolderInfoBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

sharedFolder(obj?:SharedFolderBuf):SharedFolderBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new SharedFolderBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

home(obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

archive(obj?:FileBuf):FileBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new FileBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSharedFolderInfoBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addSharedFolder(builder:flatbuffers.Builder, sharedFolderOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, sharedFolderOffset, 0);
}

static addHome(builder:flatbuffers.Builder, homeOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, homeOffset, 0);
}

static addArchive(builder:flatbuffers.Builder, archiveOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, archiveOffset, 0);
}

static endSharedFolderInfoBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // shared_folder
  builder.requiredField(offset, 6) // home
  builder.requiredField(offset, 8) // archive
  return offset;
}

}
