import { Node, mergeAttributes } from "@tiptap/core"
import { VueNodeViewRenderer } from "@tiptap/vue-3"
import editableTag from "~/components/File/Property/EditableTag.vue"

const _console = useLogger("TagRef", theme.colors.violet.hex)

export class TagRef {
  static TAG = "tagRef"

  static createNode() {
    return Node.create({
      name: TagRef.TAG,
      group: "block",
      atom: true,
      selectable: true,
      parseHTML() {
        return [{ tag: TagRef.TAG }]
      },
      renderHTML({ HTMLAttributes }) {
        return [TagRef.TAG, mergeAttributes(HTMLAttributes), 0]
      },
      addNodeView() {
        return VueNodeViewRenderer(editableTag)
      },
      addAttributes() {
        return {
          value: {
            default: "",
            parseHTML: element => element.getAttribute("value"),
            renderHTML: ({ value }) => ({ value }),
          },
        }
      },
    })
  }
}
