import { UUID } from "uuidv7"
import type * as flatbuffers from "flatbuffers"
import { partition } from "../helpers"
import { addEntityPropertySelectionToBuf, createEntityIdBuf, createEntityPropertyIdBuf, createEntityPropertySchemaBuf, parseEntityId, parseEntityPropertyId, parseEntityPropertySchemaValue, parseEntityPropertySelection, parseMaybeUserId, parseUserId } from "."
import type { EntityIdBuf } from "~/classes/generated/entity"
import { EntityApiIdBuf } from "~/classes/generated/entity"
import type { File } from "~/models/polyschema/File"
import { FileBuf, FilePropertyBuf } from "~/classes/generated/file"

// File operations
export function parseFileId(buf: EntityIdBuf) {
  if (buf.api() !== EntityApiIdBuf.FileIdV0 && buf.api() !== EntityApiIdBuf.FileIdClientOnly)
    throw new Error(`API Id is not a file: ${buf.api()}`)
  return parseEntityId(buf)
}

export function parseMaybeFileId(buf: EntityIdBuf | null) {
  if (buf)
    return parseFileId(buf)
}

export function parseFileProperty(buf: FilePropertyBuf) {
  const fileProperty: FileProperty = {
    propertyId: parseEntityPropertyId(buf.propertyId()!),
    version: buf.version(),
    schema: buf.schema(),
    schemaVersion: buf.schemaVersion(),
    value: parseEntityPropertySchemaValue(buf.schema(), buf.valueArray()!),
    timestamp: Number(buf.timestamp()),
    deleted: buf.deleted(),
    pending: buf.pending(),
    stale: buf.stale(),
    sharedWithUserId: parseMaybeUserId(buf.sharedWithUserId()),
    author: parseUserId(buf.author()!),
  }
  return fileProperty
}

export function addFilePropertyToBuf(fbb: flatbuffers.Builder, property: FileProperty) {
  const valueBuf = createEntityPropertySchemaBuf(property.value)
  const value = fbb.createByteVector(valueBuf)

  FilePropertyBuf.startFilePropertyBuf(fbb)
  FilePropertyBuf.addPropertyId(fbb, createEntityPropertyIdBuf(fbb, property.propertyId))
  if (property.version !== undefined)
    FilePropertyBuf.addVersion(fbb, property.version)
  FilePropertyBuf.addSchema(fbb, property.schema)
  if (property.schemaVersion !== undefined)
    FilePropertyBuf.addSchemaVersion(fbb, property.schemaVersion)
  FilePropertyBuf.addValue(fbb, value)
  FilePropertyBuf.addTimestamp(fbb, BigInt(property.timestamp))
  if (property.deleted)
    FilePropertyBuf.addDeleted(fbb, property.deleted)
  if (property.pending)
    FilePropertyBuf.addPending(fbb, property.pending)
  if (property.stale)
    FilePropertyBuf.addStale(fbb, property.stale)
  if (property.sharedWithUserId)
    FilePropertyBuf.addSharedWithUserId(fbb, createEntityIdBuf(fbb, property.sharedWithUserId))
  FilePropertyBuf.addAuthor(fbb, createEntityIdBuf(fbb, property.author))
  return FilePropertyBuf.endFilePropertyBuf(fbb)
}

export function parseFile(buf: FileBuf): File {
  const fileId = parseFileId(buf.fileId()!)
  const selection = parseEntityPropertySelection(buf.selection())
  const properties: Record<string, FileProperty> = {}
  for (let i = 0; i < buf.propertiesLength(); i++) {
    const property = parseFileProperty(buf.properties(i)!)
    properties[property.propertyId] = property
  }
  const path: File[] = []
  for (let i = 0; i < buf.pathLength(); i++) {
    path.push(parseFile(buf.path(i)!))
  }
  return {
    fileId,
    selection,
    properties,
    path,
  }
}

export function parseMaybeFile(file: FileBuf | null) {
  if (file)
    return parseFile(file)
}

export function addFilePathToBuf(fbb: flatbuffers.Builder, path?: FilePath) {
  if (path === undefined)
    return undefined
  return Object.values(path).map(f => addFileToBuf(fbb, f))
}

export function addFileToBuf(fbb: flatbuffers.Builder, file: File) {
  const innerProperties = Object.values(file.properties).map(p => addFilePropertyToBuf(fbb, p))
  const properties = FileBuf.createPropertiesVector(fbb, innerProperties)
  let selection
  if (file.selection)
    selection = addEntityPropertySelectionToBuf(fbb, file.selection)
  const path = addFilePathToBuf(fbb, file.path)
  const fileId = createEntityIdBuf(fbb, file.fileId)
  FileBuf.startFileBuf(fbb)
  FileBuf.addFileId(fbb, fileId)
  FileBuf.addProperties(fbb, properties)
  if (selection)
    FileBuf.addSelection(fbb, selection)
  if (path)
    FileBuf.createPathVector(fbb, path)
  return FileBuf.endFileBuf(fbb)
}
