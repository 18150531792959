// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityPropertyGroupIdBuf } from '../entity/entity-property-group-id-buf.js';
import { EntityPropertyIdBuf } from '../entity/entity-property-id-buf.js';


/**
 * If this selection is missing from an object, the default is to assume that
 * this object is "everything". So if you want to imple no properties, you
 * would set everything to false and have groups and properties both be empty.
 */
export class EntityPropertySelectionBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):EntityPropertySelectionBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsEntityPropertySelectionBuf(bb:flatbuffers.ByteBuffer, obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf {
  return (obj || new EntityPropertySelectionBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsEntityPropertySelectionBuf(bb:flatbuffers.ByteBuffer, obj?:EntityPropertySelectionBuf):EntityPropertySelectionBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new EntityPropertySelectionBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

everything():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

groups(index: number, obj?:EntityPropertyGroupIdBuf):EntityPropertyGroupIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityPropertyGroupIdBuf()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 4, this.bb!) : null;
}

groupsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

properties(index: number, obj?:EntityPropertyIdBuf):EntityPropertyIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new EntityPropertyIdBuf()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 20, this.bb!) : null;
}

propertiesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startEntityPropertySelectionBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addEverything(builder:flatbuffers.Builder, everything:boolean) {
  builder.addFieldInt8(0, +everything, +false);
}

static addGroups(builder:flatbuffers.Builder, groupsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, groupsOffset, 0);
}

static startGroupsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addProperties(builder:flatbuffers.Builder, propertiesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, propertiesOffset, 0);
}

static startPropertiesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(20, numElems, 4);
}

static endEntityPropertySelectionBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createEntityPropertySelectionBuf(builder:flatbuffers.Builder, everything:boolean, groupsOffset:flatbuffers.Offset, propertiesOffset:flatbuffers.Offset):flatbuffers.Offset {
  EntityPropertySelectionBuf.startEntityPropertySelectionBuf(builder);
  EntityPropertySelectionBuf.addEverything(builder, everything);
  EntityPropertySelectionBuf.addGroups(builder, groupsOffset);
  EntityPropertySelectionBuf.addProperties(builder, propertiesOffset);
  return EntityPropertySelectionBuf.endEntityPropertySelectionBuf(builder);
}
}
