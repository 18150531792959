import mitt, { type Handler } from "mitt"
import { Properties, Property, PropertyGroup } from "~/classes/entity"
import { InterfaceFile, InterfaceFolderFile } from "~/classes/file"
import { sendFileDeleteRequests } from "~/classes/handlers/file/delete"
import { sendFolderListRequests } from "~/classes/handlers/file/list"
import { sendFileMoveRequests } from "~/classes/handlers/file/move"
import { sendFolderSubscribeRequest } from "~/classes/handlers/file/subscribe"
import { onFileUpdateLocal } from "~/classes/handlers/file/update"
import type { InterfaceSharedFolderInfo } from "~/classes/sharedFolder/InterfaceSharedFolderInfo"

/**
 * Retrieves the archive folder for a given file, used to "throw" items away
 */
function getArchiveForFile(file: InterfaceFile): InterfaceFolderFile | undefined {
  if (file.isHomeFolder() || file.isSpaceHomeFolder())
    return
  if (file.isArchiveFolder() || file.isSpaceArchiveFolder())
    return file as InterfaceFolderFile
  const root = file.rootFolder()
  if (!root)
    throw new Error("No way to know archive dir for file")
  if (root.fileId !== file.fileId)
    return getArchiveForFile(root)
}

/**
 * List the contents of a folder, and return the requestId used to submit the request. The
 * default selection is everything and the default path selection is the most limited, to
 * make requests faster
 */
async function listMultipleFoldersWithViewModeProperties(
  folderIds: string[],
  pageSize: number,
  selection?: EntityPropertySelection,
  pathSelection: EntityPropertySelection = { everything: true },
) {
  const { mainViewState } = useMainView()

  console.warn("Method not implemented fully. Need to re-request on view mode updates")

  // TODO: once we have property merging, only request properties needed in path, and merge
  //       them in
  // TODO: only request the properties required by the current view mode.
  const requests: FolderListRequestDatagram[] = []
  const requestId = generateWorkerRequestId()
  // const properties = viewModeState.value.current.show
  //   .map(s => s.propertyId)
  //   .filter(s => Properties.has(s))

  // If our current view mode is "search relevance" then we need to choose our default,
  // TODO: this requires we have a store of our defaults, and that we have a way of
  // knowing them in advance of making a navigation change. So currently this is a pretty
  // big workaround
  let sort
  let desc
  if (mainViewState.value.sortBy !== Property.VIRTUAL_SEARCH_RELEVANCE) {
    sort = mainViewState.value.sortBy
    desc = mainViewState.value.descending
  }

  for (const folderId of folderIds) {
    requests.push({
      requestId,
      folder: { fileId: folderId, properties: {} },
      selection,
      pathSelection,
      pageSize,
      sort,
      desc,
    })
  }
  sendFolderListRequests(requests)
  return requestId
}

/**
 * This method updates the file system with a new value of a property. Note that the
 * `InterfaceFile` object needs to have the latest property version otherwise an update
 * will fail. We also don't need to change the property within the `InterfaceFile` itself
 * as the update notification should be where the UI makes that change to ensure state
 * consistency.
 * @param file The file to update
 * @param propertyId The property to update
 * @param newVal the new value of the property
 * @param requestId a request id (or let one be autogenerated)
 */
function updateFileProperty(
  file: InterfaceFile,
  propertyId: Property,
  newVal: PropertySchemaType,
  requestId?: string,
) {
  if (!file.fileId)
    throw new Error("Cannot update file without a file id, might be still loading")

  const prop = file._file.properties?.[propertyId]
  if (!prop) {
    console.error(`Property '${propertyId}' not found in file`, file)
    throw new Error(`Property could not be updated as it does not exist: ${propertyId}`)
  }
  // increment the property version, set the property to the new val, then send an
  // update message to the cache, after which we are done
  prop.pending = true
  prop.version = (prop.version ?? 0) + 1
  prop.value = newVal
  requestId = requestId || generateWorkerRequestId()
  sendFileUpdateRequest({
    requestId,
    file: {
      fileId: file.fileId,
      properties: { [propertyId]: { ...prop } },
      selection: { groups: [], properties: [propertyId] },
    },
  })
  onFileUpdateLocal(file)
  return requestId
}

/**
 * In order to update a file, the updates need to be pending and marked with their new
 * version.
 */
function updateFile(
  file: InterfaceFile,
  updates: FileProperty[],
  requestId?: string,
) {
  requestId = requestId || generateWorkerRequestId()
  for (const update of updates) {
    file.addProperty(update)
  }sendFileUpdateRequest({
    requestId,
    file: {
      fileId: file.fileId,
      properties: dictByKey(updates, u => u.propertyId),
    },
  })
  onFileUpdateLocal(file)
  return requestId
}

/**
 * This method adds a new value to a property (thought under the hood its just calling
 * the update API. Note that we don't need to change the property within the
 * `InterfaceFile` itself as the update notification should be where the UI makes that
 * change to ensure state consistency across the app, and the cache should return
 * right away anyway.
 * @param file The file to update
 * @param property The new property
 * @param requestId a request id (or let one be autogenerated)
 */
function addFileProperty(file: InterfaceFile, property: FileProperty, requestId?: string) {
  if (!file.fileId)
    throw new Error("Cannot update file without a file id, might be still loading")
  // increment the property version, set the property to the new val, then send an
  // update message to the cache, after which we are done
  property.pending = true
  requestId = requestId || generateWorkerRequestId()
  sendFileUpdateRequest({
    requestId,
    file: {
      fileId: file.fileId,
      properties: { [property.propertyId]: { ...property } },
      selection: { groups: [], properties: [property.propertyId] },
    },
  })
  onFileUpdateLocal(file)
  return requestId
}

function moveFiles(files: InterfaceFile[], destination: InterfaceFolderFile) {
  // TODO: you need to update the navigation view so that if the root folder is not in the
  // parent of the destination, we remove the current object from the list of open folders
  const { notifyError } = useNotifications()
  const { navigationState } = useNavigation()
  const { removeFileFromFolderView } = useMainView()
  const requests: FileMoveRequestDatagram[] = []

  for (const file of files) {
    // First we want to check if we can make the move:
    // - Is the file already in the destination
    // - Is the destination a cycle
    if (!destination.isFolder) {
      return console.error("Can't move file(s) into non-folder file", file)
    }
    else if (file.fileId === destination.fileId) {
      notifyError(
        "Can't move a file into its own subfolder",
        "Move the destination folder to a different location first",
      )
      return console.error("Destination is one of selected files", file)
    }
    else if (file.parentId() === destination.fileId) {
      return console.error("File already in destination", file)
    }
    for (const item of destination.path() ?? [])
      if (item.fileId === file.fileId) {
        return console.error("Destination is a child of file", file)
      }
    // Now we want to send the update to the cache and let it fix our state on response
    const parentProp = file.property(Property.PARENT_ID)
    if (!(parentProp && "fileId" in parentProp.value))
      return console.error("Can't move file as it has no parent property", file)
    parentProp.version = (parentProp.version ?? 0) + 1
    parentProp.value.fileId = destination.fileId
    parentProp.pending = true

    const movedFile = {
      fileId: file.fileId,
      properties: {
        [Property.PARENT_ID]: { ...parentProp },
      },
      selection: { groups: [], properties: [] },
    }

    requests.push({
      requestId: generateWorkerRequestId(),
      file: movedFile,
    })
  }

  // Update the UI immediately if the parent is not our current one
  const currentFolder = navigationState.value.file?.fileId
  if (currentFolder && destination.fileId !== currentFolder) {
    for (const file of files)
      removeFileFromFolderView(file)
  }

  sendFileMoveRequests(requests)
}

function permanentlyDeleteFiles(files: InterfaceFile[]) {
  const requests: FileDeleteRequestDatagram[] = []

  for (const file of files) {
    if (file.isHomeFolder() || file.isArchiveFolder())
      throw new Error("Cannot delete system roots")
    // Now we want to send the update to the cache and let it fix our state on response
    const parentProp = file.property(Property.PARENT_ID)
    if (!parentProp)
      return console.error("Can't delete file as it has no parent property", file)

    parentProp.version = (parentProp.version ?? 0) + 1
    parentProp.pending = true

    requests.push({
      requestId: generateWorkerRequestId(),
      file: {
        fileId: file.fileId,
        properties: { [Property.PARENT_ID]: { ...parentProp } },
      },
    })
  }

  sendFileDeleteRequests(requests)
}

function getFileProperties(fileId: string, requestId?: string) {
  requestId = requestId || generateWorkerRequestId()
  sendFilePropertiesRequest({
    requestId,
    fileId,
    pathSelection: { properties: [Property.NAME] },
    selection: { everything: true },
  })
  return requestId
}

/**
 * Create a folder in the given parent folder, with the given name.
 * @param parent parent of the folder to create
 * @param name name of the folder to create
 * @param requestId (Optional) a request id to use for the request
 */
function createFolder(parent: InterfaceFolderFile, name: string, requestId?: string, cacheOnly?: boolean) {
  const { myself } = useUser()
  requestId = requestId || generateWorkerRequestId()
  const userId = myself.value.user!.userId
  const clientId = InterfaceFile.fakeClientId()
  const parentId = parent.fileId
  const file = InterfaceFolderFile.fake(
    {
      userId,
      clientId,
      name,
      parentId,
    },
  )
  sendFileCreateRequest({
    requestId,
    file: file._file,
    cacheOnly: cacheOnly ?? false,
  })
  return requestId
}

function subscribeToFolderUpdates(entityIds: string[]) {
  // TODO: subscribe to entities not folders, change naming
  sendFolderSubscribeRequest({
    requestId: generateWorkerRequestId(),
    folderIds: entityIds,
  })
}

function subscribeToSharedFolderUpdates(sharedFolderInfo: InterfaceSharedFolderInfo) {
  // TODO: subscribe to entities not folders, change naming
  sendFolderSubscribeRequest({
    requestId: generateWorkerRequestId(),
    folderIds: [sharedFolderInfo.home().fileId, sharedFolderInfo.archive().fileId],
  })
}

// Things we want to do:
// - [ ] If the waterfall is open, grab files differently
// - [ ] watch for scroll position, and then do more stuff (might require
//       event handling)

/**
 * useFileSystem is the file system handler for our system. This composable monitors
 * the state of the application, e.g. when the user navigates to other folders, and
 * sends the relevant cache requests to ensure that data in the application is
 * received. We also ensure that we aren't making too many calls, such as when editing
 * a field, or when navigating to a page and the path, waterfall, and folder view all
 * require the same data.
 */
export default function () {
  return {
    listMultipleFoldersWithViewModeProperties,
    updateFileProperty,
    updateFile,
    addFileProperty,
    moveFiles,
    permanentlyDeleteFiles,
    getFileProperties,
    getArchiveForFile,
    createFolder,
    subscribeToFolderUpdates,
    subscribeToSharedFolderUpdates,
  }
}
