// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class SearchV1ImageSearchFilterBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1ImageSearchFilterBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1ImageSearchFilterBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1ImageSearchFilterBuf):SearchV1ImageSearchFilterBuf {
  return (obj || new SearchV1ImageSearchFilterBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1ImageSearchFilterBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1ImageSearchFilterBuf):SearchV1ImageSearchFilterBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1ImageSearchFilterBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

textSimilarity():string|null
textSimilarity(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
textSimilarity(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

imageSimilarity():string|null
imageSimilarity(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
imageSimilarity(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

colorSimilarity():string|null
colorSimilarity(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
colorSimilarity(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startSearchV1ImageSearchFilterBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addTextSimilarity(builder:flatbuffers.Builder, textSimilarityOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, textSimilarityOffset, 0);
}

static addImageSimilarity(builder:flatbuffers.Builder, imageSimilarityOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, imageSimilarityOffset, 0);
}

static addColorSimilarity(builder:flatbuffers.Builder, colorSimilarityOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, colorSimilarityOffset, 0);
}

static endSearchV1ImageSearchFilterBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createSearchV1ImageSearchFilterBuf(builder:flatbuffers.Builder, textSimilarityOffset:flatbuffers.Offset, imageSimilarityOffset:flatbuffers.Offset, colorSimilarityOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchV1ImageSearchFilterBuf.startSearchV1ImageSearchFilterBuf(builder);
  SearchV1ImageSearchFilterBuf.addTextSimilarity(builder, textSimilarityOffset);
  SearchV1ImageSearchFilterBuf.addImageSimilarity(builder, imageSimilarityOffset);
  SearchV1ImageSearchFilterBuf.addColorSimilarity(builder, colorSimilarityOffset);
  return SearchV1ImageSearchFilterBuf.endSearchV1ImageSearchFilterBuf(builder);
}
}
