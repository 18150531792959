// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { ErrorTypeBuf } from '../datagram/error-type-buf.js';


export class ErrorBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ErrorBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsErrorBuf(bb:flatbuffers.ByteBuffer, obj?:ErrorBuf):ErrorBuf {
  return (obj || new ErrorBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsErrorBuf(bb:flatbuffers.ByteBuffer, obj?:ErrorBuf):ErrorBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ErrorBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

type():ErrorTypeBuf {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readInt8(this.bb_pos + offset) : ErrorTypeBuf.InvalidRequest;
}

details():string|null
details(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
details(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startErrorBuf(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addType(builder:flatbuffers.Builder, type:ErrorTypeBuf) {
  builder.addFieldInt8(0, type, ErrorTypeBuf.InvalidRequest);
}

static addDetails(builder:flatbuffers.Builder, detailsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, detailsOffset, 0);
}

static endErrorBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 6) // details
  return offset;
}

static createErrorBuf(builder:flatbuffers.Builder, type:ErrorTypeBuf, detailsOffset:flatbuffers.Offset):flatbuffers.Offset {
  ErrorBuf.startErrorBuf(builder);
  ErrorBuf.addType(builder, type);
  ErrorBuf.addDetails(builder, detailsOffset);
  return ErrorBuf.endErrorBuf(builder);
}
}
