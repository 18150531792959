// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { ErrorBuf } from '../datagram/error-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';
import { SelfInfoBuf } from '../user/self-info-buf.js';


export class SelfInfoResponseDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SelfInfoResponseDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSelfInfoResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SelfInfoResponseDatagramBuf):SelfInfoResponseDatagramBuf {
  return (obj || new SelfInfoResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSelfInfoResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SelfInfoResponseDatagramBuf):SelfInfoResponseDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SelfInfoResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

error(obj?:ErrorBuf):ErrorBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ErrorBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

selfInfo(obj?:SelfInfoBuf):SelfInfoBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new SelfInfoBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

cacheOnly():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startSelfInfoResponseDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addError(builder:flatbuffers.Builder, errorOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, errorOffset, 0);
}

static addSelfInfo(builder:flatbuffers.Builder, selfInfoOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, selfInfoOffset, 0);
}

static addCacheOnly(builder:flatbuffers.Builder, cacheOnly:boolean) {
  builder.addFieldInt8(3, +cacheOnly, +false);
}

static endSelfInfoResponseDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  return offset;
}

}
