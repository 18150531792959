// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum ErrorTypeBuf {
  InternalServerError = 0,
  UnauthorizedRequest = 1,

  /**
   * An invalid request is a "unforced error" that is parsable json. This
   * would be any issue a client should know in advance. An example of this
   * would be including properties in a request that are forbidden (i.e. A
   * file parent property in a file update request, when it can only exist in
   * a file move request)
   */
  InvalidRequest = 2,

  /**
   * A redundant request is one where the user request has already been done, 
   * and the client should safely ignore the response.
   */
  IgnoredRedundantRequest = 3,
  StorageUriNotFound = 4,
  StorageMetadataMismatch = 5,
  FileNotFound = 6,
  DeletedFile = 7,
  PropertyNotFound = 8,

  /**
   * A property version is invalid when a transaction-esque request (file
   * move, (file|entity) update, delete, etc.) submits a property version
   * that is not N + 1 where N is the database version. Typically, this will
   * occur if the database is ahead of the client, but it is anything, not
   * just when the database is ahead of the client (So N+2 is also invalid)
   */
  PropertyVersionInvalid = 9,

  /**
   * NOTE: version invalid takes precedence over this error.
   */
  PropertyValueUnchanged = 10,
  MoveWouldCreatePathCycle = 11,
  OperationExceedsMaxPathLength = 12,
  UserNotFound = 13,
  DeletedUser = 14,
  UserPermissionAlreadyExists = 15,
  SharedFolderNotFound = 16,
  DeletedSharedFolder = 17,
  CopyAlreadyCompletedForCopyId = 18,
  CopyPropertiesDoNotMatchOriginFile = 19
}
