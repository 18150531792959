import { Gesture } from "./BaseGesture"

export default function () {
  const { getMainViewFileFromEvent, getPropertyIdFromEvent, gestureState, gestureLayers } = useGestures()
  const { startEditingProperty, propertyEditorState } = useProperty()
  const { isSelected } = useSelection()
  class MainViewFileClickPropertyToEdit extends Gesture {
    layer = gestureLayers.mainViewProperty

    didSelect = false

    reset() {
      this.didSelect = false
    }

    async onMouseDown(mouse: MouseEvent) {
      const file = getMainViewFileFromEvent(mouse)
      if (!file)
        return
      if (!isSelected(file.fileId))
        return
      const propertyId = getPropertyIdFromEvent(mouse)
      if (!propertyId)
        return

      // make the property editable
      this.debug("Editing File property", file, file.property(propertyId))
      this.capture()
      this.didSelect = true
      if (propertyEditorState.value.file?.fileId !== file.fileId) // don't need to re-use
        startEditingProperty(file, propertyId)
    }

    async onMouseUp(
      mouse: MouseEvent,
    ) {
    // We want to capture the mouseup event for our editing selection click as we don't want another handler stealing that event from us.

      if (!this.didSelect)
        return

      // if the position of the mouse is where it was on mouse down, then we're a click
      if (gestureState.value.mouseDown?.clientX !== mouse.clientX || gestureState.value.mouseDown?.clientY !== mouse.clientY)
        return this.reset()

      this.capture()
      return this.reset()
    }
  }
  return new MainViewFileClickPropertyToEdit()
}
