// eslint-disable-next-line ts/prefer-ts-expect-error
// @ts-ignore cache issues
import type { Cache } from "polystore-cache"
import * as comlink from "comlink"
import * as flatbuffers from "flatbuffers"
import CacheWorker from "~/workers/cache.shared.worker?sharedworker"
import DatabaseWorker from "~/workers/database.shared.worker?sharedworker"
import { DatagramBuf } from "~/classes/generated/datagram"
import { handleFileCreateMessageDatagramBuf, handleFileCreateResponseDatagramBuf } from "~/classes/handlers/file/create"
import { handleFolderSubscribeResponseDatagramBuf } from "~/classes/handlers/file/subscribe"
import { handleFileDeleteMessageDatagramBuf, handleFileDeleteResponseDatagramBuf } from "~/classes/handlers/file/delete"
import { handleFileMoveMessageDatagramBuf, handleFileMoveResponseDatagramBuf } from "~/classes/handlers/file/move"
import { handleFilePropertiesResponseDatagramBuf } from "~/classes/handlers/file/properties"
import { base64ToUint8Array } from "~/utils/base64"
import { CacheBuf, LoginBuf, LogoutBuf } from "~/classes/generated/cache"
import { handleFolderListResponseDatagramBuf } from "~/classes/handlers/file/list"
import { handleSelfInfoResponseDatagramBuf } from "~/classes/handlers/user/info"
import { handleSearchResponseDatagramBuf } from "~/classes/handlers/search/request"
import { handleSearchFindResponseDatagramBuf } from "~/classes/handlers/search/find"

interface State {
  isCacheWorkerSetUp: boolean
  isWebsocketConnected: boolean
  nextReconnectionAttempt?: Date
}

let cacheState: Ref<State>
let cache: comlink.Remote<Cache>
let cachePort: MessagePort
const console = useLogger("use-cache", theme.colors.teal.hex)

function api(request: string) {
  return `/api${request}`
}

async function initCacheWorker() {
  const { addCacheCallback } = useTauri()

  if (isCacheWorkerSetUp())
    return

  cacheState.value.isCacheWorkerSetUp = true

  // On desktop, we don't create a background worker and instead forward
  // all messages to our tauri handler.
  // TODO: this currently serializes to json rather than using bytes,
  // so the web version might even be faster than desktop right now?
  if (isDesktop()) {
    addCacheCallback((b64str: string) => onWorkerMessageDatagramBuf(base64ToUint8Array(b64str)))
    return
  }
  const cacheWorker = new CacheWorker({ name: "Cache Manager" })
  cachePort = cacheWorker.port
  cache = comlink.wrap(cachePort)
  cachePort.addEventListener("message", e => onWebWorkerMessage(e))

  // Now we set up the database worker, which is a separate shared worker that contains
  // o
  const dbWorker = new DatabaseWorker({ name: "Database Worker" })
  await cache.addDatabaseChannel(comlink.transfer(dbWorker.port, [dbWorker.port]))

  console.log("Cache web worker initialized")
}

function addTransferChannel(port: MessagePort) {
  if (isDesktop())
    throw new Error("Not supposed to add ipc port on desktop")
  if (!cache)
    throw new Error("Cache not set up yet")
  cache.addTransferChannel(comlink.transfer(port, [port]))
}

function isCacheWorkerSetUp() {
  return cacheState.value.isCacheWorkerSetUp
}

async function initAuthenticatedWebsocketConnection() {
  const { requestSelfInfo, hasStoredLoginCredentials } = useUser()
  const { logOut } = useAuthentication()

  if (cacheState.value.isWebsocketConnected) {
    // FIXME: what if we are stuck connecting or currently in process of connecting when this
    // method gets called? For context, I am calling this method from each layout.
    return
  }

  if (!hasStoredLoginCredentials()) {
    console.warn("No stored login credentials!")
    // if the user doesn't have a client side cookie, they can't be authenticated
    return logOut()
  }

  console.debug("initiating authenticated ws connection")

  // Just in case, we need to wait for the background worker to be set up
  await initCacheWorker()

  // Now we can log in, which uses the default client cookie. Note that
  // our datagram handler for the response will log us out if this login fails
  sendCacheLogin()

  // Let's pull the default user info
  requestSelfInfo()
}

/**
 * Web worker (non-desktop environment) handler / listener
 * @param e the event from the web worker channel
 */
function onWebWorkerMessage(e: MessageEvent) {
  if (isComlinkMessageEvent(e))
    // console.log('Comlink message received', message.value)
    return
  onWorkerMessageDatagramBuf(e.data)
}

function onAfterWebsocketConnected() {
  const { initFileSystemNavigation } = useNavigation()
  initFileSystemNavigation()
  // Currently nothing
}

/**
 * If it's a "client did connect" message, then the socket is connected, let's
 * mark the client as connected. If the message is a user / login response, let's
 * send it to the useUser. If the message is a file response / message, send to
 * file system. If we get a socket closed event, update our state, and then try
 * to intelligently log back in.
 */
function onWorkerMessageDatagramBuf(datagram: Uint8Array) {
  // TODO: in-progress migration of datagram stuff here
  const byteBuf = new flatbuffers.ByteBuffer(datagram)
  const datagramBuf = DatagramBuf.getRootAsDatagramBuf(byteBuf)
  if (datagramBuf.responsesLength()) {
    const messages = []
    for (let i = 0; i < datagramBuf.responsesLength(); i++)
      messages.push(datagramBuf.responses(i)!)
    for (const response of messages) {
      if (response.folderList())
        return handleFolderListResponseDatagramBuf(response.folderList()!)
      if (response.fileCreate())
        return handleFileCreateResponseDatagramBuf(response.fileCreate()!)
      if (response.fileDelete())
        return handleFileDeleteResponseDatagramBuf(response.fileDelete()!)
      if (response.fileFind())
        return handleFileFindResponseDatagramBuf(response.fileFind()!)
      if (response.folderSubscribe())
        return handleFolderSubscribeResponseDatagramBuf(response.folderSubscribe()!)
      if (response.fileMove())
        return handleFileMoveResponseDatagramBuf(response.fileMove()!)
      if (response.fileProperties())
        return handleFilePropertiesResponseDatagramBuf(response.fileProperties()!)
      if (response.fileUpdate())
        return handleFileUpdateResponseDatagramBuf(response.fileUpdate()!)
      if (response.selfInfo())
        return handleSelfInfoResponseDatagramBuf(response.selfInfo()!)
      if (response.sharedFolderCreate())
        return handleSharedFolderCreateResponseDatagramBuf(response.sharedFolderCreate()!)
      if (response.userFind())
        return handleUserFindResponseDatagramBuf(response.userFind()!)
      if (response.userUpdate())
        return handleUserUpdateResponseDatagramBuf(response.userUpdate()!)
      if (response.sharedFolderMembership())
        return handleSharedFolderMembershipResponseDatagramBuf(response.sharedFolderMembership()!)
      if (response.search())
        return handleSearchResponseDatagramBuf(response.search()!)
      if (response.searchFind())
        return handleSearchFindResponseDatagramBuf(response.searchFind()!)

      console.error("Unknown response type found", response)
    }
  }
  if (datagramBuf.message()) {
    const message = datagramBuf.message()!
    if (message.fileCreate())
      return handleFileCreateMessageDatagramBuf(message.fileCreate()!)
    if (message.fileDelete())
      return handleFileDeleteMessageDatagramBuf(message.fileDelete()!)
    if (message.fileMove())
      return handleFileMoveMessageDatagramBuf(message.fileMove()!)
    if (message.fileUpdate())
      return handleFileUpdateMessageDatagramBuf(message.fileUpdate()!)
    if (message.userUpdate())
      return handleUserUpdateMessageDatagramBuf(message.userUpdate()!)
    if (message.sharedFolderMembership())
      return handleSharedFolderMembershipMessageDatagramBuf(message.sharedFolderMembership()!)
    console.error("Unknown message type found", message)
  }

  if (datagramBuf.cache())
    return onCacheMessageReceived(datagramBuf.cache()!)

  console.warn("Datagram was empty or unidentified", datagramBuf)
}

function sendWorkerDatagramBuf(msg: Uint8Array) {
  const { postDatagramBuf } = useTauri()

  if (!isCacheWorkerSetUp())
    throw new Error("Cache worker not initialized")
  if (isDesktop())
    return postDatagramBuf(msg)

  cache.onDatagramBuf(comlink.transfer(msg, [msg.buffer]))
}

function supportsFileSystemAccessAPI() {
  return "getAsFileSystemHandle" in DataTransferItem.prototype
}
function supportsWebkitGetAsEntry() {
  return "webkitGetAsEntry" in DataTransferItem.prototype
}

export function sendCacheLogin() {
  const fbb = new flatbuffers.Builder(64)
  const login = LoginBuf.createLoginBuf(fbb)
  CacheBuf.startCacheBuf(fbb)
  CacheBuf.addLogin(fbb, login)
  const cache = CacheBuf.endCacheBuf(fbb)
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addCache(fbb, cache)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

export function sendCacheLogout() {
  const fbb = new flatbuffers.Builder(64)
  const logout = LogoutBuf.createLogoutBuf(fbb)
  CacheBuf.startCacheBuf(fbb)
  CacheBuf.addLogout(fbb, logout)
  const cache = CacheBuf.endCacheBuf(fbb)
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addCache(fbb, cache)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  sendWorkerDatagramBuf(fbb.asUint8Array())
}

// TODO: move this into its own handler file
/**
 * NOTE: It is possible that we receive multiple DidConnect or DidDisconnect
 * messages, as there might be several tabs connected. So on a DidConnect message, we
 * need to be sure to be idempotent.
 * @param message The cache message we want to respond to
 */
export function onCacheMessageReceived(message: CacheBuf) {
  const { logOut } = useAuthentication()
  if (message.didConnect()) {
    console.debug("Connected to cache")
    cacheState.value.isWebsocketConnected = true
    cacheState.value.nextReconnectionAttempt = undefined
    onAfterWebsocketConnected()
    // Do any client login tasks, e.g. subscriptions?
  }
  else if (message.didPermanentlyDisconnect()) {
    console.info("Permanently disconnected from cache")
    cacheState.value.isWebsocketConnected = false
    cacheState.value.nextReconnectionAttempt = undefined
    logOut()
  }
  else if (message.didTemporarilyDisconnect()) {
    console.warn("Cache was temporarily disconnected, will retry")
    const nextAttempt = Date.now() + message.didTemporarilyDisconnect()!.seconds()
    cacheState.value.isWebsocketConnected = false
    cacheState.value.nextReconnectionAttempt = new Date(nextAttempt)
  }
  else { console.error("Unknown cache message received", message) }
}

export async function getTagPropertyId(tag: string) {
  const { tagPropertyId } = useTauri()
  if (isDesktop())
    return tagPropertyId(tag)
  if (!isCacheWorkerSetUp())
    throw new Error("Cache worker not initialized")
  return await cache.tagPropertyId(tag) as string
}

/**
 * The useCache composable is the "core" composable that handles startup and
 * manages the cache background worker. We handle all responses from the websocket,
 * and pass them to the relevant part of the application.
 */
export default function () {
  cacheState = useState<State>("client-data", () => ({
    isCacheWorkerSetUp: false,
    isWebsocketConnected: false,
  }))

  return {
    cacheState,
    isServer,
    isClient,
    isDesktop,
    isBrowser,
    api,
    sendCacheLogin,
    sendCacheLogout,
    initCacheWorker,
    initAuthenticatedWebsocketConnection,
    addTransferChannel,
    supportsFileSystemAccessAPI,
    supportsWebkitGetAsEntry,
    sendWorkerDatagramBuf,
    getTagPropertyId,
  }
}
