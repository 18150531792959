import { Gesture } from "./BaseGesture"

export default function () {
  const { stopEditing } = useProperty()
  const { searchState } = useSearch()
  const { gestureLayers } = useGestures()
  const globals = useGlobals()

  class MainViewClickToCloseSearch extends Gesture {
    layer = gestureLayers.mainView

    async onMouseDown(mouse: MouseEvent) {
      if (!searchState.value.isEditing)
        return
      if (getFirstMatchingParent(mouse.target, e => e.id === globals.searchViewId))
        return

      searchState.value.isEditing = false
      this.capture()
      this.debug("Background click stopped property edit")
      stopEditing()
    }
  }
  return new MainViewClickToCloseSearch()
}
