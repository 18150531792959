import { UUID } from "uuidv7"
import type * as flatbuffers from "flatbuffers"
import { partition } from "../helpers"
import { addFileToBuf, createEntityIdBuf, createEntityPropertyIdBuf, createEntityPropertySchemaBuf, parseEntityId, parseEntityPropertyId, parseEntityPropertySchemaValue, parseFile, parseUserId } from "."
import type { EntityIdBuf } from "~/classes/generated/entity"
import { EntityApiIdBuf } from "~/classes/generated/entity"
import { SharedFolderBuf, SharedFolderInfoBuf, SharedFolderPropertyBuf } from "~/classes/generated/shared-folder"

// Shared folder
// ------------------------

export function parseSharedFolderId(buf: EntityIdBuf) {
  if (buf.api() !== EntityApiIdBuf.SharedFolderIdV0 && buf.api() !== EntityApiIdBuf.SharedFolderIdClientOnly)
    throw new Error(`API Id is not a sharedFolder: ${buf.api()}`)
  return parseEntityId(buf)
}

export function parseMaybeSharedFolderId(buf: EntityIdBuf | null) {
  if (!buf)
    return undefined
  return parseSharedFolderId(buf)
}

export function parseSharedFolderProperty(buf: SharedFolderPropertyBuf) {
  const sharedFolderProperty: SharedFolderProperty = {
    propertyId: parseEntityPropertyId(buf.propertyId()!),
    version: buf.version(),
    schema: buf.schema(),
    schemaVersion: buf.schemaVersion(),
    value: parseEntityPropertySchemaValue(buf.schema(), buf.valueArray()!),
    timestamp: Number(buf.timestamp()),
    deleted: buf.deleted(),
    pending: buf.pending(),
    stale: buf.stale(),
    author: parseUserId(buf.author()!),
  }
  return sharedFolderProperty
}

export function addSharedFolderPropertyToBuf(fbb: flatbuffers.Builder, property: SharedFolderProperty) {
  const valueBuf = createEntityPropertySchemaBuf(property.value)
  const value = fbb.createByteVector(valueBuf)

  SharedFolderPropertyBuf.startSharedFolderPropertyBuf(fbb)
  SharedFolderPropertyBuf.addPropertyId(fbb, createEntityPropertyIdBuf(fbb, property.propertyId))
  if (property.version !== undefined)
    SharedFolderPropertyBuf.addVersion(fbb, property.version)
  SharedFolderPropertyBuf.addSchema(fbb, property.schema)
  if (property.schemaVersion !== undefined)
    SharedFolderPropertyBuf.addSchemaVersion(fbb, property.schemaVersion)
  SharedFolderPropertyBuf.addValue(fbb, value)
  SharedFolderPropertyBuf.addTimestamp(fbb, BigInt(property.timestamp))
  if (property.deleted)
    SharedFolderPropertyBuf.addDeleted(fbb, property.deleted)
  if (property.pending)
    SharedFolderPropertyBuf.addPending(fbb, property.pending)
  if (property.stale)
    SharedFolderPropertyBuf.addStale(fbb, property.stale)
  SharedFolderPropertyBuf.addAuthor(fbb, createEntityIdBuf(fbb, property.author))
  return SharedFolderPropertyBuf.endSharedFolderPropertyBuf(fbb)
}

export function parseSharedFolder(buf: SharedFolderBuf): SharedFolder {
  const sharedFolderId = parseSharedFolderId(buf.sharedFolderId()!)
  const properties: Record<string, SharedFolderProperty> = {}
  for (let i = 0; i < buf.propertiesLength(); i++) {
    const property = parseSharedFolderProperty(buf.properties(i)!)
    properties[property.propertyId] = property
  }
  return { sharedFolderId, properties }
}

export function addSharedFolderToBuf(fbb: flatbuffers.Builder, sharedFolder: SharedFolder) {
  const innerProperties = Object.values(sharedFolder.properties).map(p => addSharedFolderPropertyToBuf(fbb, p))
  const properties = SharedFolderBuf.createPropertiesVector(fbb, innerProperties)
  const sharedFolderId = createEntityIdBuf(fbb, sharedFolder.sharedFolderId)
  SharedFolderBuf.startSharedFolderBuf(fbb)
  SharedFolderBuf.addSharedFolderId(fbb, sharedFolderId)
  SharedFolderBuf.addProperties(fbb, properties)
  return SharedFolderBuf.endSharedFolderBuf(fbb)
}

export function parseSharedFolderInfo(buf: SharedFolderInfoBuf): SharedFolderInfo {
  const sharedFolder = parseSharedFolder(buf.sharedFolder()!)
  const home = parseFile(buf.home()!)
  const archive = parseFile(buf.archive()!)
  return { sharedFolder, home, archive }
}

export function parseMaybeSharedFolderInfo(buf: SharedFolderInfoBuf | null): SharedFolderInfo | undefined {
  if (!buf)
    return
  return parseSharedFolderInfo(buf)
}

export function addSharedFolderInfoToBuf(fbb: flatbuffers.Builder, SharedFolderInfo: SharedFolderInfo) {
  const sharedFolder = addSharedFolderToBuf(fbb, SharedFolderInfo.sharedFolder)
  const home = addFileToBuf(fbb, SharedFolderInfo.home)
  const archive = addFileToBuf(fbb, SharedFolderInfo.archive)

  SharedFolderInfoBuf.startSharedFolderInfoBuf(fbb)
  SharedFolderInfoBuf.addSharedFolder(fbb, sharedFolder)
  SharedFolderInfoBuf.addHome(fbb, home)
  SharedFolderInfoBuf.addArchive(fbb, archive)
  return SharedFolderInfoBuf.endSharedFolderInfoBuf(fbb)
}
