import { Gesture } from "./BaseGesture"

/**
 * This gesture allows us to sense dragging-in of unknown items into the file browser,
 * which lets us render a "ghost" image in the browser. Note that our regular drag
 * events should take precedence over this such that it never gets reached in the case
 * of a better drag-in handler for times that we do know the items (e.g. tauri native).
 */
export default function () {
  const { uploadFromDataTransfer } = useTransferManager()
  const { capture } = useAnalytics()
  const { navigationState } = useNavigation()
  const { gestureState, gestureLayers } = useGestures()

  class DragExternalFilesInToUpload extends Gesture {
    layer = gestureLayers.drag

    reset(): void {}

    async onMouseLeave(_mouse: MouseEvent) {
      // We should never reach this method, but we have it here in case there are issues
      // with events firing in the browser?
      if (gestureState.value.gesture !== "drag-in-unknown-file")
        return

      this.debug("Canceling drag-in of unknown file")
      this.capture()
      this.reset()
    }

    async onDragEnter(drag: DragEvent) {
      if (gestureState.value.gesture === "drag-in-unknown-file")
        return

      // We only care about this event `event.dataTransfer.types` is ['Files']
      if (drag.dataTransfer?.types[0] !== "Files")
        return

      this.console.log("Drag entered", drag.dataTransfer)

      this.debug("Dragged in unknown file")
      this.capture()
      this.switchMode("drag-in-unknown-file")
    }

    async onDragLeave(drag: DragEvent) {
      if (gestureState.value.gesture !== "drag-in-unknown-file")
        return

      // The dragleave event fires too often. In order to detect whether we
      // are actually dragging out of the window, we check the clientX and
      // clientY, which will both be zero if we are dragging out
      if (drag.clientX || drag.clientY)
        return

      this.debug("Dragging out, canceling drag-in of unknown file")
      this.capture()
      this.switchMode("none")
    }

    async onDrop(drag: DragEvent) {
      if (gestureState.value.gesture !== "drag-in-unknown-file")
        return

      drag.preventDefault()
      this.capture()
      this.switchMode("none")
      this.debug("Dropped file(s)")

      if (!drag.dataTransfer)
        throw new Error("No data to transfer on drop")
      this.console.log("Transferred files", drag.dataTransfer.files, drag.dataTransfer.items)

      const destination = navigationState.value.file
      if (!destination || !destination.isFolder())
        return this.console.error("No destination folder")

      uploadFromDataTransfer(
        drag.dataTransfer,
        destination as InterfaceFolderFile,
      )
      capture("user_dragged_files_into_view")
    }
  }
  return new DragExternalFilesInToUpload()
}
