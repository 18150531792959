// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class NullableStringValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NullableStringValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsNullableStringValueBuf(bb:flatbuffers.ByteBuffer, obj?:NullableStringValueBuf):NullableStringValueBuf {
  return (obj || new NullableStringValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsNullableStringValueBuf(bb:flatbuffers.ByteBuffer, obj?:NullableStringValueBuf):NullableStringValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new NullableStringValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():string|null
value(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
value(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startNullableStringValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endNullableStringValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createNullableStringValueBuf(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  NullableStringValueBuf.startNullableStringValueBuf(builder);
  NullableStringValueBuf.addValue(builder, valueOffset);
  return NullableStringValueBuf.endNullableStringValueBuf(builder);
}
}
