import * as flatbuffers from "flatbuffers"
import type { FolderSubscribeResponseDatagramBuf } from "~/classes/generated/response"
import { FolderSubscribeRequestDatagramBuf, RequestDatagramBuf } from "~/classes/generated/request"
import { DatagramBuf, ErrorTypeBuf } from "~/classes/generated/datagram"
import { createEntityIdBuf, createRequestIdBuf, parseFileId, parseRequestId } from "~/utils/flatbuffers"
import { parseMaybeDatagramError, parseVector } from "~/utils/flatbuffers/datagram"

const console = useLogger("file/subscription")

export function packFolderSubscribeRequest(request: FolderSubscribeRequestDatagram): Uint8Array {
  const fbb = new flatbuffers.Builder(64)

  FolderSubscribeRequestDatagramBuf.startFolderIdsVector(fbb, request.folderIds.length)
  for (const folderId of request.folderIds)
    createEntityIdBuf(fbb, folderId)
  const folderIds = fbb.endVector()

  FolderSubscribeRequestDatagramBuf.startFolderSubscribeRequestDatagramBuf(fbb)
  FolderSubscribeRequestDatagramBuf.addRequestId(fbb, createRequestIdBuf(fbb, request.requestId))
  FolderSubscribeRequestDatagramBuf.addFolderIds(fbb, folderIds)
  const subscribeRequest = FolderSubscribeRequestDatagramBuf.endFolderSubscribeRequestDatagramBuf(fbb)

  RequestDatagramBuf.startRequestDatagramBuf(fbb)
  RequestDatagramBuf.addFolderSubscribe(fbb, subscribeRequest)
  const requestDatagram = RequestDatagramBuf.endRequestDatagramBuf(fbb)

  const vector = DatagramBuf.createRequestsVector(fbb, [requestDatagram])
  DatagramBuf.startDatagramBuf(fbb)
  DatagramBuf.addRequests(fbb, vector)
  fbb.finish(DatagramBuf.endDatagramBuf(fbb))
  return fbb.asUint8Array()
}

export function sendFolderSubscribeRequest(request: FolderSubscribeRequestDatagram) {
  const { sendWorkerDatagramBuf } = useCache()
  sendWorkerDatagramBuf(packFolderSubscribeRequest(request))
}

/** Parse a Flatbuffers representation of a response */
export function handleFolderSubscribeResponseDatagramBuf(datagram: FolderSubscribeResponseDatagramBuf) {
  // FIXME: for some reason flatbuffers has trouble deserializing the folder ids and we receive
  // an empty array with a length zero, though if we parse the items at the indices then it
  //
  onFolderSubscribeResponse({
    requestId: parseRequestId(datagram.requestId()!),
    error: parseMaybeDatagramError(datagram.error()),
    folderIds: parseVector(n => datagram.folderIds(n), datagram.folderIdsLength()).map(parseFileId),
  })
}

export function onFolderSubscribeResponse(response: FolderSubscribeResponseDatagram) {
  const { emitInterfaceEvent } = useEvents()
  if (response.error)
    return onFolderSubscribeErrored(response.error)
  emitInterfaceEvent("folderSubscribeResponse", response)
}

export function onFolderSubscribeErrored(error: DatagramError) {
  // TODO: check for error result code, and have a specific result code for "already subscribed"
  const { notifyDatagramError } = useNotifications()
  if (enumEq(error.type, ErrorTypeBuf.IgnoredRedundantRequest))
    return console.warn("Already subscribed with that subscription id")

  return notifyDatagramError(error, "Could not subscribe to updates, your browser might not be up to date")
}
