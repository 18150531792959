import { InterfaceFile } from "~/classes/file/InterfaceFile"
import { parseFile, parseFileId } from "~/utils/flatbuffers/file"
import type { UploadFileFailedMessageBuf, UploadFilePendingMessageBuf, UploadFinishedMessageBuf, UploadSourceFileFinishedMessageBuf, UploadStartedMessageBuf, UploadStatusMessageBuf } from "~/classes/generated/transfer"
import { parseTransferId } from "~/utils/flatbuffers/transfer"
import { Property } from "~/classes/entity"
import { InterfaceFolderFile } from "~/classes/file/InterfaceFolderFile"

const console = useLogger("transfer/upload")

export function handleUploadStartedMessageBuf(buf: UploadStartedMessageBuf) {
  const { transferState } = useTransferManager()

  const message: UploadStartedMessage = {
    transferId: parseTransferId(buf.transferId()!),
    folderId: parseFileId(buf.folderId()!),
    total: buf.total(),
  }

  console.debug("Download Started", message)

  const old = transferState.value.jobs[message.transferId] as UploadJob
  transferState.value.jobs[message.transferId] = {
    type: "upload",
    id: message.transferId,
    status: "uploading",
    sources: old?.sources ?? {},
    timestamp: old?.timestamp ?? Date.now(),
    destination: old?.destination ?? InterfaceFolderFile.blank(message.folderId),
    inProgress: old?.inProgress ?? 0,
    completed: old?.completed ?? 0,
    failed: old?.failed ?? 0,
    total: message.total,
  }
}

export function handleUploadStatusMessageBuf(buf: UploadStatusMessageBuf) {
  const { transferState } = useTransferManager()
  const message: UploadStatusMessage = {
    transferId: parseTransferId(buf.transferId()!),
    total: buf.total(),
    inProgress: buf.inProgress(),
    completed: buf.completed(),
    failed: buf.failed(),
  }

  console.debug("Upload Status", message)

  if (!transferState.value.jobs[message.transferId])
    return
  transferState.value.jobs[message.transferId].inProgress = message.inProgress
  transferState.value.jobs[message.transferId].failed = message.failed
  transferState.value.jobs[message.transferId].completed = message.completed
}

export function handleUploadFilePendingMessageBuf(buf: UploadFilePendingMessageBuf) {
  const { transferState } = useTransferManager()
  const { addFileToFolderView } = useMainView()
  const { navigationState } = useNavigation()
  const message: UploadFilePendingMessage = {
    transferId: parseTransferId(buf.transferId()!),
    file: parseFile(buf.file()!),
    parentId: parseFileId(buf.parentId()!),
  }

  console.debug("Upload File Pending", message)

  // TODO: turn this into an "added file to view" event notification
  const file = InterfaceFile.from(message.file)
  const clientId = file.clientId()
  if (!transferState.value.jobs[message.transferId] || !clientId)
    return console.error("Unknown transfer", message)

  transferState.value.jobs[message.transferId].sources[clientId] = file

  if (file.parentId() === navigationState.value.file?.fileId) {
    addFileToFolderView(file)
  }
}

export function handleUploadSourceFileFinishedMessageBuf(buf: UploadSourceFileFinishedMessageBuf) {
  const { transferState } = useTransferManager()

  const message: UploadSourceFileFinishedMessage = {
    transferId: parseTransferId(buf.transferId()!),
    file: parseFile(buf.file()!),
  }

  console.debug("Upload file finished", message)

  const file = new InterfaceFile(message.file)
  const clientId = file.clientId()
  if (!transferState.value.jobs[message.transferId] || !clientId)
    return console.error("Unknown transfer", message)
  if (!transferState.value.jobs[message.transferId].sources[clientId])
    return console.error("Received unexpected file")
  transferState.value.jobs[message.transferId].sources[clientId] = file
}

export function handleUploadFileFailedMessageBuf(buf: UploadFileFailedMessageBuf) {
  const { notify } = useNotifications()

  const message: UploadFileFailedMessage = {
    transferId: parseTransferId(buf.transferId()!),
    file: parseFile(buf.file()!),
  }

  console.debug("Received Transfer Manager Message", message)

  // Let's send an upload file failed message to the UI. This will show up as a separate
  // alert for the failed file as opposed to a generic alert.
  const file = message.file.properties[Property.URI] ? new InterfaceFile(message.file) : new InterfaceFolderFile(message.file)
  notify({
    id: generateWorkerRequestId(),
    type: "error-alert",
    errorType: "upload-file-failed",
    file,
    timestamp: Date.now(),
    title: "A file failed to upload",
    description: "Details are scant right now",
  })
}

export function handleUploadFinishedMessageBuf(buf: UploadFinishedMessageBuf) {
  const { transferState } = useTransferManager()

  const message: UploadFinishedMessage = {
    transferId: parseTransferId(buf.transferId()!),
    total: buf.total(),
    completed: buf.completed(),
    failed: buf.failed(),
  }

  console.debug("Received Transfer Manager Message", message)

  if (!transferState.value.jobs[message.transferId])
    return
  transferState.value.jobs[message.transferId].status = "complete"
  transferState.value.jobs[message.transferId].inProgress = 0
  transferState.value.jobs[message.transferId].failed = message.failed
  transferState.value.jobs[message.transferId].completed = message.completed
}
