// eslint-disable-next-line ts/prefer-ts-expect-error
// @ts-ignore cache issues
import type { TransferManager } from "polystore-transfer"
import * as comlink from "comlink"
import * as flatbuffers from "flatbuffers"
import TransferWorker from "~/workers/transfer.shared.worker?sharedworker"
import type { InterfaceFolderFile } from "~/classes/file"
import { InterfaceFile } from "~/classes/file"
import { base64ToUint8Array } from "~/utils/base64"
import { TransferMessageBuf } from "~/classes/generated/transfer"
import { handleUploadFileFailedMessageBuf, handleUploadFilePendingMessageBuf, handleUploadFinishedMessageBuf, handleUploadSourceFileFinishedMessageBuf, handleUploadStartedMessageBuf, handleUploadStatusMessageBuf } from "~/classes/handlers/transfer/upload"
import { handleDownloadFinishedMessageBuf, handleDownloadStartedMessageBuf, handleDownloadStatusMessageBuf } from "~/classes/handlers/transfer/download"

export type WebUploadItem = {
  clientId: string
  parentId: string
  name: string
  file?: File
}

interface State {
  jobs: Record<string, JobNotification>
  isTransferWorkerSetUp: boolean
}
let transferState: Ref<State>
let transferManager: comlink.Remote<TransferManager>
const console = useLogger("use-transfer-manager", theme.colors.violet.hex)

async function initTransferManager() {
  const { addTransferChannel } = useCache()

  if (isTransferManagerSetUp())
    return

  transferState.value.isTransferWorkerSetUp = true

  if (isDesktop()) {
    const { addTransferCallback } = useTauri()
    addTransferCallback((b64str: string) => onTransferMessageBuf(base64ToUint8Array(b64str)))
    return console.log("TransferManager initialized")
  }

  const worker = new TransferWorker({ name: "Transfer Manager" })
  transferManager = comlink.wrap(worker.port)
  worker.port.onmessage = e => onWebWorkerMessage(e)
  const cacheTransferIpc = new MessageChannel()
  addCacheChannel(cacheTransferIpc.port1)
  addTransferChannel(cacheTransferIpc.port2)
  console.log("Transfer web worker initialized")
}

function isTransferManagerSetUp() {
  return transferState.value.isTransferWorkerSetUp
}

function addCacheChannel(port: MessagePort) {
  if (isDesktop())
    throw new Error("Not supposed to add ipc port on desktop")
  if (!transferManager)
    throw new Error("Transfer Manager not set up yet")
  transferManager.addCacheChannel(comlink.transfer(port, [port]))
}

async function downloadImageBuffer(uri: string) {
  const response = await fetch(uri, {
    headers: new Headers({ Origin: location.origin }),
  })
  const blob = await response.blob()
  return blob.arrayBuffer()
}

async function transcodeImageToPngBlobUri(image: InterfaceImageFile) {
  const uri = image.clientUri()
  if (!image.isImage || !uri)
    throw new Error("Not an image or no uri")
  if (!transferManager)
    throw new Error("No transfer manager instance")
  const arrayBuffer = await downloadImageBuffer(uri)
  const bytes = new Uint8Array(arrayBuffer)
  const png = await transferManager.transcodeToPng(bytes)
  const blob = new Blob([png])
  return URL.createObjectURL(blob)
}

function onWebWorkerMessage(e: MessageEvent) {
  if (isComlinkMessageEvent(e))
    return
  const datagram = e.data as Uint8Array
  onTransferMessageBuf(datagram)
}

async function uploadFromDataTransfer(
  transfer: DataTransfer,
  destination: InterfaceFolderFile,
) {
  const { notifyError } = useNotifications()
  const { myself } = useUser()

  const items = [...zip([...transfer.items as any], [...transfer.files as any])]
  const parentId = destination.fileId
  const root = destination.rootFolder()?.fileId
  const archive = myself.value.archive

  // // @abhay-agarwal for some reason I got some issues with
  // // multi-directory uploads? but I can't reproduce
  // ------------------------------------------------------------------
  // if (items.length > 1)
  //   console.warn(
  //     'Upload partial failure',
  //     'Browsers don\'t support dragging multiple folders',
  //   )
  // ------------------------------------------------------------------

  // TODO: send a notification for this.
  if (!root || !archive || root === archive.fileId)
    return notifyError("Upload failed", "Cannot upload into your archive directory")

  const uploads: WebUploadItem[] = []
  for (const [item, fileItem] of items) {
    const entry = item.webkitGetAsEntry()
    if (entry) {
      await _addWebUploadItemsRecursively(entry, parentId, uploads)
      continue
    }
    const file = item.getAsFile() || fileItem
    if (file) {
      const clientId = InterfaceFile.fakeClientId()
      const name = file.name
      uploads.push({ file, clientId, parentId, name })
      continue
    }
    console.error("Unknown data transfer, skipping", item)
  }
  uploadAllItems(uploads, destination)
}

async function _addWebUploadItemsRecursively(
  entry: FileSystemEntry,
  parentId: string,
  uploads: WebUploadItem[],
  max_depth = 16,
  max_files = 2 ** 16,
) {
  const { notifyError } = useNotifications()
  const clientId = InterfaceFile.fakeClientId()
  if (max_depth < 0)
    return notifyError("Can't upload this folder", `Folder exceeds max depth of ${max_depth}`)
  if (entry.isFile) {
    const fileEntry = entry as FileSystemFileEntry
    const file = await new Promise<File>((s, e) => (fileEntry.file(s, e)))
    const name = file.name
    uploads.push({ file, parentId, clientId, name })
    return
  }

  const dir = entry as FileSystemDirectoryEntry
  const name = dir.name
  uploads.push({ parentId, clientId, name })
  for await (const item of _listDirectoryEntry(dir))
    await _addWebUploadItemsRecursively(item, clientId, uploads, max_depth - 1, max_files)
}

async function* _listDirectoryEntry(directory: FileSystemDirectoryEntry) {
  const directoryReader = (directory as FileSystemDirectoryEntry).createReader()
  // Old browsers only read 100 items at a time for some reason...
  while (true) {
    const entries = await new Promise<FileSystemEntry[]>(
      (s, e) => directoryReader.readEntries(s, e),
    )
    if (!entries.length)
      break
    for (const entry of entries)
      yield entry
  }
}

function uploadAllItems(
  uploads: WebUploadItem[],
  folder: InterfaceFolderFile,
) {
  console.log("Uploading files", uploads)

  const { myself } = useUser()
  const userId = myself.value.user?.userId
  if (!userId)
    throw new Error("No user for upload")
  console.log("Debugging file path", folder)
  if (!folder)
    throw new Error("No folder to upload to")
  if (!folder.isFolder)
    throw new Error("Upload target is not a folder")
  if (!transferManager)
    return console.info("Transfer manager not needed for upload")

  const jobId = generateWorkerRequestId()

  // Our "sources" dict right now is just a bunch of blank files that
  // don't have any properties, which means we need to be careful
  // about requesting, e.g. their URIs
  const sources = Object.fromEntries(
    uploads.map(u => [u.clientId, InterfaceFile.blank(u.clientId)]),
  )

  // add the job to our hash map for real time feedback
  transferState.value.jobs[jobId] = {
    type: "upload",
    id: jobId,
    timestamp: Date.now(),
    status: "calculating",
    destination: folder,
    sources,
    total: len(uploads),
    inProgress: 0,
    completed: 0,
    failed: 0,
  }
  transferManager.uploadItems(
    jobId,
    uploads,
    folder.fileId, // take native object and strip to cloneable part
    userId,
  )
}

async function uploadFromFilePicker() {
  const { navigationState } = useNavigation()
  const files = await addFileToSelectionsWithFilePicker()
  const destination = navigationState.value.file
  if (!destination || !destination.isFolder())
    return console.error("No destination folder")

  const transfer = new window.DataTransfer()
  Array.from(files).forEach(file => transfer.items.add(file))
  return uploadFromDataTransfer(
    transfer,
    destination as InterfaceFolderFile,
  )
}

async function addFileToSelectionsWithFilePicker() {
  return new Promise<FileList>((resolve, reject) => {
    const input = document.createElement("input")
    input.type = "file"
    input.multiple = true
    input.onchange = () => resolve(input.files!)
    input.addEventListener("cancel", () => reject(new Error("File picker dismissed")))
    input.click()
  })
}

async function onTransferMessageBuf(message: Uint8Array) {
  const byteBuf = new flatbuffers.ByteBuffer(message)
  const transferMessageBuf = TransferMessageBuf.getRootAsTransferMessageBuf(byteBuf)

  if (transferMessageBuf.uploadStarted())
    return handleUploadStartedMessageBuf(transferMessageBuf.uploadStarted()!)
  else if (transferMessageBuf.uploadStatus())
    return handleUploadStatusMessageBuf(transferMessageBuf.uploadStatus()!)
  else if (transferMessageBuf.uploadFilePending())
    return handleUploadFilePendingMessageBuf(transferMessageBuf.uploadFilePending()!)
  else if (transferMessageBuf.uploadSourceFileFinished())
    return handleUploadSourceFileFinishedMessageBuf(transferMessageBuf.uploadSourceFileFinished()!)
  else if (transferMessageBuf.uploadFileFailed())
    return handleUploadFileFailedMessageBuf(transferMessageBuf.uploadFileFailed()!)
  else if (transferMessageBuf.uploadFinished())
    return handleUploadFinishedMessageBuf(transferMessageBuf.uploadFinished()!)

  else if (transferMessageBuf.downloadFinished())
    return handleDownloadFinishedMessageBuf(transferMessageBuf.downloadFinished()!)
  else if (transferMessageBuf.downloadStarted())
    return handleDownloadStartedMessageBuf(transferMessageBuf.downloadStarted()!)
  else if (transferMessageBuf.downloadStatus())
    return handleDownloadStatusMessageBuf(transferMessageBuf.downloadStatus()!)

  // TODO: download failed

  console.warn("Unknown transfer message received", message)
}

/** Downloads a single folder or file */
async function downloadFiles(files: InterfaceFile[]) {
  const { downloadFiles } = useTauri()
  console.log("Downloading files:", files)

  if (isDesktop())
    return await downloadFiles(files)

  for (const file of files) {
    const name = file.name()
    if (!name)
      throw new Error("File has no name")

    // go through the transfer manager to download if its a folder
    if (file.isFolder())
      return downloadFolderAsZipArchive(file as InterfaceFolderFile)

    const uri = file.clientUri()
    console.log(uri)
    if (!uri)
      return console.error("File has no URI", file)

    const a = document.createElement("a")
    a.href = await fetch(uri, {
      headers: new Headers({ Origin: location.origin }),
    // Doesn't seem like this is helping
    // mode: "cors",
    })
      .then(response => response.blob())
      .then(blob => URL.createObjectURL(blob))
    a.download = name
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }
}

async function downloadFolderAsZipArchive(folder: InterfaceFolderFile) {
  const jobId = generateWorkerRequestId()

  // add the job to our hash map for real time feedback
  transferState.value.jobs[jobId] = {
    type: "download",
    id: jobId,
    timestamp: Date.now(),
    sources: dictByKey([folder], f => f.fileId),
    status: "calculating",
    inProgress: 0,
    completed: 0,
    failed: 0,
    total: 1,
  }
  const parts: Uint8Array[] = await transferManager.downloadItems(
    jobId,
    clone([folder._file]),
  )
  console.log("Zipping complete! Calling browser native downloader")
  const blob = new Blob(parts)
  const a = document.createElement("a")
  a.href = URL.createObjectURL(blob)
  // TODO: sanitize
  a.download = `${folder.name ?? "download"}.zip`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

async function copyFiles(
  files: InterfaceFile[],
  destination: InterfaceFolderFile,
) {
  const { copyFiles } = useTauri()
  const { myself } = useUser()
  const userId = myself.value.user?.userId
  if (!userId)
    throw new Error("No user for upload")

  console.log("Copying files", files)
  if (isDesktop())
    return await copyFiles(files)

  const jobId = generateWorkerRequestId()
  transferState.value.jobs[jobId] = {
    type: "copy",
    id: jobId,
    timestamp: Date.now(),
    status: "calculating",
    destination: destination.fileId,
    sources: dictByKey(files, f => f.fileId),
    total: len(files),
    inProgress: 0,
    completed: 0,
    failed: 0,
  }
  // TODO: this method is really finnicky with serializing file objects. This
  // currently doesn't work.
  transferManager.copyItems(
    jobId,
    files.map(f => f._file),
    destination.fileId,
  )
}

function clearJobById(jobId: string) {
  if (!transferState.value.jobs[jobId])
    return

  console.warn("Canceling jobs is not implemented yet")
  delete transferState.value.jobs[jobId]
}

export default function () {
  transferState = useState("transfer-manager-state", () => ({
    jobs: {},
    isTransferWorkerSetUp: false,
  }))

  return {
    transferState,
    initTransferManager,
    transcodeImageToPngBlobUri,
    uploadFromFilePicker,
    uploadFromDataTransfer,
    downloadFiles,
    copyFiles,
    clearJobById,
    downloadFolderAsZipArchive,
  }
}
