// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { SearchV1IntentColorBuf } from '../search/search-v1-intent-color-buf.js';
import { SearchV1IntentImageBuf } from '../search/search-v1-intent-image-buf.js';
import { SearchV1IntentPropertyBuf } from '../search/search-v1-intent-property-buf.js';


export class SearchV1IntentFragmentBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchV1IntentFragmentBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchV1IntentFragmentBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentFragmentBuf):SearchV1IntentFragmentBuf {
  return (obj || new SearchV1IntentFragmentBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchV1IntentFragmentBuf(bb:flatbuffers.ByteBuffer, obj?:SearchV1IntentFragmentBuf):SearchV1IntentFragmentBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchV1IntentFragmentBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

textRef():string|null
textRef(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
textRef(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

folderRef(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

userRef(obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

imageRef(obj?:SearchV1IntentImageBuf):SearchV1IntentImageBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new SearchV1IntentImageBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

colorRef(obj?:SearchV1IntentColorBuf):SearchV1IntentColorBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new SearchV1IntentColorBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

tagRef():string|null
tagRef(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
tagRef(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

propertyRef(obj?:SearchV1IntentPropertyBuf):SearchV1IntentPropertyBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? (obj || new SearchV1IntentPropertyBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSearchV1IntentFragmentBuf(builder:flatbuffers.Builder) {
  builder.startObject(7);
}

static addTextRef(builder:flatbuffers.Builder, textRefOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, textRefOffset, 0);
}

static addFolderRef(builder:flatbuffers.Builder, folderRefOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, folderRefOffset, 0);
}

static addUserRef(builder:flatbuffers.Builder, userRefOffset:flatbuffers.Offset) {
  builder.addFieldStruct(2, userRefOffset, 0);
}

static addImageRef(builder:flatbuffers.Builder, imageRefOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, imageRefOffset, 0);
}

static addColorRef(builder:flatbuffers.Builder, colorRefOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, colorRefOffset, 0);
}

static addTagRef(builder:flatbuffers.Builder, tagRefOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, tagRefOffset, 0);
}

static addPropertyRef(builder:flatbuffers.Builder, propertyRefOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, propertyRefOffset, 0);
}

static endSearchV1IntentFragmentBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

}
