// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { SearchV1QueryBuf } from '../search/search-v1-query-buf.js';


export class SearchQueryValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchQueryValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchQueryValueBuf(bb:flatbuffers.ByteBuffer, obj?:SearchQueryValueBuf):SearchQueryValueBuf {
  return (obj || new SearchQueryValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchQueryValueBuf(bb:flatbuffers.ByteBuffer, obj?:SearchQueryValueBuf):SearchQueryValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchQueryValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value(obj?:SearchV1QueryBuf):SearchV1QueryBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new SearchV1QueryBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSearchQueryValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valueOffset, 0);
}

static endSearchQueryValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // value
  return offset;
}

static createSearchQueryValueBuf(builder:flatbuffers.Builder, valueOffset:flatbuffers.Offset):flatbuffers.Offset {
  SearchQueryValueBuf.startSearchQueryValueBuf(builder);
  SearchQueryValueBuf.addValue(builder, valueOffset);
  return SearchQueryValueBuf.endSearchQueryValueBuf(builder);
}
}
