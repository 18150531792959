import { Node, mergeAttributes } from "@tiptap/core"
import type { Editor } from "@tiptap/vue-3"
import { VueNodeViewRenderer } from "@tiptap/vue-3"
import { Property } from "../entity"
import { InterfaceFile } from "../file/InterfaceFile"
import pathCompletion from "~/components/Search/Editor/PathCompletion.vue"

const console = useLogger("PathCompletion", theme.colors.violet.hex)

export class PathCompletion {
  static TAG = "pathCompletion"

  static createNode() {
    return Node.create({
      name: PathCompletion.TAG,
      group: "block",
      content: "inline*",
      parseHTML() {
        return [{ tag: PathCompletion.TAG }]
      },
      renderHTML({ HTMLAttributes }) {
        return [PathCompletion.TAG, mergeAttributes(HTMLAttributes), 0]
      },
      addNodeView() {
        return VueNodeViewRenderer(pathCompletion)
      },
      // NOTE: the path completion is triggered by a keyboard shortcut from the FileCompletion
      // component
    })
  }

  static createAtCursor(editor: Editor) {
    return editor.chain().insertContent({ type: PathCompletion.TAG }).focus().run()
  }

  static containsCursor(editor: Editor) {
    // TODO: what happens during a large selection action?
    return editor.state.selection.$from.node().type.name === PathCompletion.TAG
  }

  static onUpdated(content: string) {
    if (content === "") {
    // on empty string, we want to show either our defaults or to list the folder
      console.log("content empty")
      return // FIXME
    }

    console.log("Searching for completions matching: ", content)
    // now we need to check our cache for files matching this completion. We need just the
    // bare bones properties of each file and its path to maximize speed
    sendFileFindRequest({
      requestId: generateWorkerRequestId(),
      propertyId: Property.NAME,
      limit: 10,
      cacheOnly: true,
      prefix: content,
      selection: {
        properties: [Property.NAME, Property.MIME_TYPE, Property.IMG_THUMBNAIL_CROP_64X64],
      },
      pathSelection: {
        properties: [Property.NAME],
      },
    })
  }

  static onUpdateResponse(response: FileFindResponseDatagram) {
    if (!response.files)
      return
    const { searchState } = useSearch()
    searchState.value.suggestions = {
      files: response.files.map(InterfaceFile.from),
      selection: 0,
    }
  }

  /** If selection is not included, goes with the current selection */
  static acceptSuggestion(selection?: number) {
    const { searchState } = useSearch()
    searchState.value.suggestions.selection ??= selection ?? 0
    if (searchState.value.mode !== "path")
      throw new Error("Must be in path mode")

  // accepting the suggestion means adding this to your search
  }

  /**
   * Exits the path completion mode and accepts the most recent suggestion, placing cursor
   * to the right of the current completion. This is used, for example, when you press
   * 'escape' or 'right arrow'
   */
  static acceptLatestCompletion() {
    return console.error("accept completion not implemented")
  }
}
