import { UUID, uuidv7 } from "uuidv7"
import { Property, PropertyGroup, PropertySchema, hasGroup, orderedId } from "../entity"

export class InterfaceUser {
  _user: User

  constructor(user: User) {
    this._user = user
  }

  property(id: Property): UserProperty | undefined {
    return this._user.properties[id]
  }

  static fakeUserId() {
    return `defea7ed-${uuidv7()}`
  }

  get userId(): string {
    return this._user.userId
  }

  email(): string {
    const property = this.property(Property.USER_EMAIL)
    if (!property)
      throw new Error("users must have an email")
    if (property.value.type === PropertySchema.EmailValue)
      return property.value.email
    console.error("Invalid schema", property.value)
    throw new Error("`Schema invalid for email")
  }

  handle(): string | undefined {
    const property = this.property(Property.USER_HANDLE)
    if (!property)
      return
    if (property.value.type === PropertySchema.EntityHandleValue)
      return property.value.entityHandle
    console.error("Invalid schema", property.value)
    throw new Error("`Schema invalid for handle")
  }

  addProperty(property: UserProperty) {
    this._user.properties[property.propertyId] = property
  }

  removeProperty(property: UserProperty) {
    delete this._user.properties[property.propertyId]
  }

  static createPropertyForSidebarFile(fileId: string, index: number): UserProperty {
    return {
      propertyId: orderedId(PropertyGroup.USER_SIDEBAR_ORDERED_META, index),
      schema: PropertySchema.UserSidebarItemValue,
      value: { type: PropertySchema.UserSidebarItemValue, userSidebarItem: { fileId } },
      index,
      timestamp: Date.now(),
    }
  }

  static createPropertyForSidebarSearch(searchId: string, index: number): UserProperty {
    return {
      propertyId: orderedId(PropertyGroup.USER_SIDEBAR_ORDERED_META, index),
      schema: PropertySchema.UserSidebarItemValue,
      value: { type: PropertySchema.UserSidebarItemValue, userSidebarItem: { searchId } },
      index,
      timestamp: Date.now(),
    }
  }

  sidebarFileItems() {
    const items: Map<number, string> = new Map()
    for (const property of Object.values(this._user.properties)) {
      if (!hasGroup(property.propertyId as Property, PropertyGroup.USER_SIDEBAR_ORDERED_META))
        continue
      if (property.index === undefined)
        throw new Error("Sidebar property doesn't have an index")
      if (property.value.type !== PropertySchema.UserSidebarItemValue)
        throw new Error("Not a sidebar item value")
      if (property.value.userSidebarItem.fileId === undefined)
        continue
      items.set(property.index, property.value.userSidebarItem.fileId)
    }
    return items
  }

  sortedSidebarFileItems() {
    return [...this.sidebarFileItems().entries()].sort((a, b) => a[0] - b[0])
  }

  sidebarSearchItems() {
    const items: Map<number, string> = new Map()
    for (const property of Object.values(this._user.properties)) {
      if (!hasGroup(property.propertyId as Property, PropertyGroup.USER_SIDEBAR_ORDERED_META))
        continue
      if (property.index === undefined)
        throw new Error("Sidebar property doesn't have an index")
      if (property.value.type !== PropertySchema.UserSidebarItemValue)
        throw new Error("Not a sidebar item value")
      if (property.value.userSidebarItem.searchId === undefined)
        continue
      items.set(property.index, property.value.userSidebarItem.searchId)
    }
    return items
  }

  sortedSidebarSearchItems() {
    return [...this.sidebarSearchItems().entries()].sort((a, b) => a[0] - b[0])
  }
}
