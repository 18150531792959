// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { ErrorBuf } from '../datagram/error-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';
import { SearchBuf } from '../search/search-buf.js';


export class SearchFindResponseDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SearchFindResponseDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSearchFindResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SearchFindResponseDatagramBuf):SearchFindResponseDatagramBuf {
  return (obj || new SearchFindResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSearchFindResponseDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SearchFindResponseDatagramBuf):SearchFindResponseDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SearchFindResponseDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

error(obj?:ErrorBuf):ErrorBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new ErrorBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

searches(index: number, obj?:SearchBuf):SearchBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new SearchBuf()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

searchesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startSearchFindResponseDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addError(builder:flatbuffers.Builder, errorOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, errorOffset, 0);
}

static addSearches(builder:flatbuffers.Builder, searchesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, searchesOffset, 0);
}

static createSearchesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startSearchesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endSearchFindResponseDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  return offset;
}

}
