// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class PositiveFloatValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):PositiveFloatValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsPositiveFloatValueBuf(bb:flatbuffers.ByteBuffer, obj?:PositiveFloatValueBuf):PositiveFloatValueBuf {
  return (obj || new PositiveFloatValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsPositiveFloatValueBuf(bb:flatbuffers.ByteBuffer, obj?:PositiveFloatValueBuf):PositiveFloatValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new PositiveFloatValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

value():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 1.0;
}

static startPositiveFloatValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValue(builder:flatbuffers.Builder, value:number) {
  builder.addFieldFloat64(0, value, 1.0);
}

static endPositiveFloatValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createPositiveFloatValueBuf(builder:flatbuffers.Builder, value:number):flatbuffers.Offset {
  PositiveFloatValueBuf.startPositiveFloatValueBuf(builder);
  PositiveFloatValueBuf.addValue(builder, value);
  return PositiveFloatValueBuf.endPositiveFloatValueBuf(builder);
}
}
