import { Property } from "~/classes/entity"

interface PropertyDisplay {
  propertyId: Property
  editable?: boolean
  multiline?: boolean
  monospace?: boolean
  inline?: boolean
}
interface State {
  selected: PanelType
  visible: boolean
  width: number
}
let panelState: Ref<State>

const console = useLogger("use-panel", theme.colors.purple.hex)

/** Show the panel and optionally switch to the specified panel */
function showPanel(type?: PanelType) {
  panelState.value.visible = true
  if (type)
    panelState.value.selected = type
}

function hidePanel() {
  panelState.value.visible = false
}

function togglePanel(type?: PanelType) {
  if (!type || type === panelState.value.selected)
    panelState.value.visible ? hidePanel() : showPanel()
  else
    showPanel(type)
}

function targetFiles(): InterfaceFile[] {
  const { selectedFiles } = useSelection()
  const { navigationState } = useNavigation()
  const { mainViewState } = useMainView()

  switch (mainViewState.value.state) {
    case "preview": {
      if (!navigationState.value.file)
        throw new Error("No file to target in details panel")
      return [navigationState.value.file]
    }
    case "folder": {
      const files = selectedFiles()
      if (files.length)
        return files

      // If nothing else, we show details for the containing folder
      const file = navigationState.value.file
      if (file)
        return [file]
      throw new Error("No target file to show")
    }
    case "search-results": {
      const files = selectedFiles()
      if (files.length)
        return files

      // Should we show a "search results details" panel?
      return []
    }
  }
}

const corePropertyDisplay = {
  [Property.NAME]: {
    propertyId: Property.NAME,
    editable: true,
  } as PropertyDisplay,
  [Property.VIRTUAL_PATH]: {
    propertyId: Property.VIRTUAL_PATH,
  } as PropertyDisplay,
  [Property.SIZE]: {
    propertyId: Property.SIZE,
    inline: true,
  } as PropertyDisplay,
  [Property.CREATED_AT]: {
    propertyId: Property.CREATED_AT,
    inline: true,
  } as PropertyDisplay,
  [Property.MODIFIED_AT]: {
    propertyId: Property.MODIFIED_AT,
    inline: true,
  } as PropertyDisplay,
  [Property.MIME_TYPE]: {
    propertyId: Property.MIME_TYPE,
    inline: true,
  } as PropertyDisplay,
  [Property.VIRTUAL_DIMENSIONS]: {
    propertyId: Property.VIRTUAL_DIMENSIONS,
    inline: true,
  } as PropertyDisplay,
}

const generativePropertyDisplay = {
  [Property.GENERATION_PROMPT]: {
    propertyId: Property.GENERATION_PROMPT,
    editable: true,
    multiline: true,
  } as PropertyDisplay,
  [Property.GENERATION_NEGATIVE_PROMPT]: {
    propertyId: Property.GENERATION_NEGATIVE_PROMPT,
    editable: true,
    multiline: true,
  } as PropertyDisplay,
  [Property.GENERATION_SOURCE]: {
    propertyId: Property.GENERATION_SOURCE,
    editable: true,
    monospace: true,
  } as PropertyDisplay,
  [Property.GENERATION_MODEL]: {
    propertyId: Property.GENERATION_MODEL,
    editable: true,
    monospace: true,
  } as PropertyDisplay,
  [Property.GENERATION_MODEL_VERSION]: {
    propertyId: Property.GENERATION_MODEL_VERSION,
    editable: true,
    monospace: true,
  } as PropertyDisplay,
  [Property.GENERATION_SEED]: {
    propertyId: Property.GENERATION_SEED,
    editable: false,
    monospace: true,
  } as PropertyDisplay,
}

export default function () {
  panelState = useState("panel-state", () => ({
    selected: "details",
    visible: false,
    width: 224,
  }))

  return {
    panelState,
    showPanel,
    hidePanel,
    togglePanel,
    targetFiles,
    corePropertyDisplay,
    generativePropertyDisplay,
  }
}
