// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class EmailValueBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):EmailValueBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsEmailValueBuf(bb:flatbuffers.ByteBuffer, obj?:EmailValueBuf):EmailValueBuf {
  return (obj || new EmailValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsEmailValueBuf(bb:flatbuffers.ByteBuffer, obj?:EmailValueBuf):EmailValueBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new EmailValueBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

email():string|null
email(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
email(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startEmailValueBuf(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addEmail(builder:flatbuffers.Builder, emailOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, emailOffset, 0);
}

static endEmailValueBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // email
  return offset;
}

static createEmailValueBuf(builder:flatbuffers.Builder, emailOffset:flatbuffers.Offset):flatbuffers.Offset {
  EmailValueBuf.startEmailValueBuf(builder);
  EmailValueBuf.addEmail(builder, emailOffset);
  return EmailValueBuf.endEmailValueBuf(builder);
}
}
