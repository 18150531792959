import { Property } from "../entity"
import { InterfaceSearch } from "./InterfaceSearch"

const console = useLogger("FileCompletion", theme.colors.purple.hex)

export class SearchCompletion {
  static onUpdated(content: string | null) {
    if (content === null || content === "")
      return console.info("Skipping search completion, received null content")
    // console.log("Searching for search completions matching: ", content)
    sendSearchFindRequest({
      requestId: generateWorkerRequestId(),
      propertyId: Property.SEARCH_INTENT,
      limit: 10,
      cacheOnly: true,
      prefix: content,
      selection: {
        properties: [Property.SEARCH_INTENT],
      },
    })
  }

  static onUpdateResponse(response: SearchFindResponseDatagram) {
    if (!response.searches)
      return
    const { searchState } = useSearch()
    searchState.value.suggestions = {
      searches: response.searches.map(s => new InterfaceSearch(s)),
      selection: 0,
    }
  }

  /** If selection is not included, goes with the current selection */
  static acceptSuggestion(selection?: number) {
    const { searchState } = useSearch()
    searchState.value.suggestions.selection ??= selection ?? 0

    if (searchState.value.mode !== "search")
      throw new Error("Must be in search mode")

    console.warn("accepting search suggestions not implemented")
  }
}
