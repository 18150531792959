import { Gesture } from "./BaseGesture"

export default function () {
  const { mainViewState } = useMainView()
  const { isEditingTextInput, gestureLayers } = useGestures()
  const router = useRouter()

  class PreviewShortcuts extends Gesture {
    layer = gestureLayers.keyboardShortcut

    async onKeyDown(keyboard: KeyboardEvent) {
      // TODO: ensure we aren't in any text input elements

      if (mainViewState.value.state !== "preview" || isEditingTextInput())
        return

      if (keyboard.key === "Escape") {
        // TODO: ensure that going back keeps us in Poly.
        // other
        keyboard.preventDefault()
        this.capture()
        this.console.log("Escape pressed in image preview, navigating back")
        router.back()
        return this.reset()
      }
    }
  }
  return new PreviewShortcuts()
}
