// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class LoginBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):LoginBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsLoginBuf(bb:flatbuffers.ByteBuffer, obj?:LoginBuf):LoginBuf {
  return (obj || new LoginBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsLoginBuf(bb:flatbuffers.ByteBuffer, obj?:LoginBuf):LoginBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new LoginBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static startLoginBuf(builder:flatbuffers.Builder) {
  builder.startObject(0);
}

static endLoginBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createLoginBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  LoginBuf.startLoginBuf(builder);
  return LoginBuf.endLoginBuf(builder);
}
}
