import { UUID } from "uuidv7"
import type * as flatbuffers from "flatbuffers"
import { partition } from "../helpers"
import { addEntityPropertySelectionToBuf, createEntityPropertyIdBuf, createEntityPropertySchemaBuf, parseEntityId, parseEntityPropertyId, parseEntityPropertySchemaValue, parseEntityPropertySelection, parseMaybeUserId, parseUserId } from "."
import { EntityApiIdBuf, EntityIdBuf } from "~/classes/generated/entity"
import type { File } from "~/models/polyschema/File"
import { FileBuf, FilePropertyBuf } from "~/classes/generated/file"
import type { TransferIdBuf } from "~/classes/generated/transfer"

export function parseTransferId(buf: TransferIdBuf) {
  const key = UUID.ofInner(new Uint8Array(range(16).map(k => buf.id(k)) as number[]))
  return key.toString()
}
