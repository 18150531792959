import { useThrottleFn } from "@vueuse/core"
import { Gesture } from "./BaseGesture"

export default function () {
  const { getMainViewFileFromEvent, gestureState, gestureLayers } = useGestures()
  const { mainViewState, updateContentBoundsSync } = useMainView()
  const globals = useGlobals()
  const { selectFiles } = useSelection()

  class MainViewBackgroundDragToMultiSelect extends Gesture {
    layer = gestureLayers.mainViewBackground

    isDragging = false
    throttledUpdate = useThrottleFn(this.updateSelection, 1000 / 30)

    reset() {
      this.isDragging = false
      this.switchMode("none")
    }

    isOverSidebar(mouse: MouseEvent) {
      return getFirstMatchingParent(mouse.target, e => e.id === globals.sidebarId)
    }

    async onMouseDown(mouse: MouseEvent) {
      if (!["folder", "search-results"].includes(mainViewState.value.state))
        return

      if (this.isOverSidebar(mouse))
        return

      if (getMainViewFileFromEvent(mouse))
        return

      if (getFirstMatchingParent(
        mouse.target,
        div => div.classList?.contains(globals.navIgnoreMainViewGestures) || div.classList?.contains(globals.scrollbarClass),
      ))
        return

      // this.debug('Started drag at', mouseDown)
      this.isDragging = true
      this.capture()
      this.switchMode("drag-select")
      updateContentBoundsSync()
    }

    async onMouseMove(_mouse: MouseEvent) {
      if (!this.isDragging)
        return

      this.capture()
      this.throttledUpdate()
    }

    async updateSelection() {
      if (!gestureState.value.mouseDown || !gestureState.value.mousePosition)
        throw new Error("No mouse event")
      const { scrollX, scrollY } = window
      const { clientX: x1, clientY: y1 } = gestureState.value.mouseDown
      const { clientX: x2, clientY: y2 } = gestureState.value.mousePosition
      const left = scrollX + Math.min(x1, x2)
      const right = scrollX + Math.max(x1, x2)
      const top = scrollY + Math.min(y1, y2)
      const bottom = scrollY + Math.max(y1, y2)

      const selection = []
      const entries = Object.entries(mainViewState.value.contentBounds)
      for (const [fileId, bounds] of entries) {
        if (top > bounds.bottom)
          continue
        if (bottom < bounds.top)
          continue
        if (left > bounds.right)
          continue
        if (right < bounds.left)
          continue
        const file = mainViewState.value.contents[fileId]
        if (!file)
          continue
        selection.push(file)
      }
      selectFiles(selection, "main-view")
    }

    async onMouseUp(_mouse: MouseEvent) {
      if (this.isDragging) {
        // this.debug(`Ended drag at ${str(mousePosition)}`)
        this.capture()
        this.reset()
      }
    }
  }
  return new MainViewBackgroundDragToMultiSelect()
}
