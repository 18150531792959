import type * as flatbuffers from "flatbuffers"
import { parseFileId, parseMaybeFileId } from "./file"
import { createEntityIdBuf, createEntityPropertyIdBuf, createEntityPropertySchemaBuf, parseEntityId, parseEntityPropertyId, parseEntityPropertySchemaValue, parseFile, parseUserId } from "."
import type { EntityIdBuf } from "~/classes/generated/entity"
import { EntityApiIdBuf } from "~/classes/generated/entity"
import { SearchPropertyBuf, type SearchResultBuf, SearchV1IntentPropertyBuf } from "~/classes/generated/search"
import { SearchBuf, SearchV1IntentBuf, SearchV1IntentColorBuf, SearchV1IntentFragmentBuf, SearchV1IntentImageBuf } from "~/classes/generated/search"

// NOTE: We don't need to parse or serialize the search query object,
// since we never use it on the client side and the server should be
// careful not to send it to the user (as the format is technically
// private to us)

export function parseSearchId(buf: EntityIdBuf) {
  if (buf.api() !== EntityApiIdBuf.SearchIdV0 && buf.api() !== EntityApiIdBuf.SearchIdClientOnly)
    throw new Error(`API Id is not a search: ${buf.api()}`)
  return parseEntityId(buf)
}

export function parseSearchProperty(buf: SearchPropertyBuf) {
  const searchProperty: SearchProperty = {
    propertyId: parseEntityPropertyId(buf.propertyId()!),
    version: buf.version(),
    schema: buf.schema(),
    schemaVersion: buf.schemaVersion(),
    value: parseEntityPropertySchemaValue(buf.schema(), buf.valueArray()!),
    timestamp: Number(buf.timestamp()),
    deleted: buf.deleted(),
    pending: buf.pending(),
    stale: buf.stale(),
  }
  return searchProperty
}

export function addSearchPropertyToBuf(
  fbb: flatbuffers.Builder,
  property: SearchProperty,
) {
  const valueBuf = createEntityPropertySchemaBuf(property.value)
  const value = fbb.createByteVector(valueBuf)

  SearchPropertyBuf.startSearchPropertyBuf(fbb)
  SearchPropertyBuf.addPropertyId(fbb, createEntityPropertyIdBuf(fbb, property.propertyId))
  if (property.version !== undefined)
    SearchPropertyBuf.addVersion(fbb, property.version)
  SearchPropertyBuf.addSchema(fbb, property.schema)
  if (property.schemaVersion !== undefined)
    SearchPropertyBuf.addSchemaVersion(fbb, property.schemaVersion)
  SearchPropertyBuf.addValue(fbb, value)
  SearchPropertyBuf.addTimestamp(fbb, BigInt(property.timestamp))
  if (property.deleted)
    SearchPropertyBuf.addDeleted(fbb, property.deleted)
  if (property.pending)
    SearchPropertyBuf.addPending(fbb, property.pending)
  if (property.stale)
    SearchPropertyBuf.addStale(fbb, property.stale)
  return SearchPropertyBuf.endSearchPropertyBuf(fbb)
}

export function parseSearch(buf: SearchBuf): Search {
  const searchId = parseSearchId(buf.searchId()!)
  const properties: Record<string, SearchProperty> = {}
  for (let i = 0; i < buf.propertiesLength(); i++) {
    const property = parseSearchProperty(buf.properties(i)!)
    properties[property.propertyId] = property
  }
  return { searchId, properties }
}

export function parseMaybeSearch(search: SearchBuf | null) {
  if (search)
    return parseSearch(search)
}

export function addSearchToBuf(fbb: flatbuffers.Builder, search: Search) {
  const innerProperties = Object.values(search.properties).map(p => addSearchPropertyToBuf(fbb, p))
  const properties = SearchBuf.createPropertiesVector(fbb, innerProperties)
  const searchId = createEntityIdBuf(fbb, search.searchId)
  SearchBuf.startSearchBuf(fbb)
  SearchBuf.addSearchId(fbb, searchId)
  SearchBuf.addProperties(fbb, properties)
  return SearchBuf.endSearchBuf(fbb)
}

export function parseSearchV1Intent(buf: SearchV1IntentBuf): SearchV1Intent {
  const fragments: SearchV1IntentFragment[] = []
  for (let i = 0; i < buf.fragmentsLength(); i++) {
    fragments.push(parseSearchV1IntentFragment(buf.fragments(i)!))
  }
  return { fragments }
}

export function parseSearchV1IntentFragment(buf: SearchV1IntentFragmentBuf): SearchV1IntentFragment {
  if (buf.textRef())
    return { type: "textRef", value: buf.textRef()! }
  else if (buf.folderRef())
    return { type: "folderRef", value: parseEntityId(buf.folderRef()!) }
  else if (buf.userRef())
    return { type: "userRef", value: parseEntityId(buf.userRef()!) }
  else if (buf.imageRef()) {
    const imageRef = buf.imageRef()!
    return { type: "imageRef", value: { uri: imageRef.uri()!, fileId: parseMaybeFileId(imageRef.fileId()) } }
  }
  else if (buf.colorRef()) {
    const colorRef = buf.colorRef()!
    return {
      type: "colorRef",
      value: { r: colorRef.r(), g: colorRef.g(), b: colorRef.b(), a: colorRef.a() },
    }
  }
  else if (buf.tagRef())
    return { type: "tagRef", value: buf.tagRef()! }
  else if (buf.propertyRef()) {
    const ref = buf.propertyRef()!
    const value: SearchV1IntentProperty = {
      property: ref.property()!,
      greaterThan: ref.greaterThan() || undefined,
      lessThan: ref.lessThan() || undefined,
      matches: ref.matches() || undefined,
      startsWith: ref.startsWith() || undefined,
      contains: ref.contains() || undefined,
      notContains: ref.notContains() || undefined,
    }
    return { type: "propertyRef", value }
  }
  throw new Error("Unknown search intent fragment or all null fields")
}

export function addSearchV1IntentToBuf(fbb: flatbuffers.Builder, intent: SearchV1Intent): number {
  const fragments = []
  for (const fragment of intent.fragments)
    fragments.push(addSearchV1IntentFragmentToBuf(fbb, fragment))
  const fragmentBuf = SearchV1IntentBuf.createFragmentsVector(fbb, fragments)
  return SearchV1IntentBuf.createSearchV1IntentBuf(fbb, fragmentBuf)
}

export function addSearchV1IntentFragmentToBuf(fbb: flatbuffers.Builder, fragment: SearchV1IntentFragment): number {
  switch (fragment.type) {
    case "textRef": {
      const value = fbb.createString(fragment.value)
      SearchV1IntentFragmentBuf.startSearchV1IntentFragmentBuf(fbb)
      SearchV1IntentFragmentBuf.addTextRef(fbb, value)
      return SearchV1IntentFragmentBuf.endSearchV1IntentFragmentBuf(fbb)
    }
    case "folderRef": {
      const value = createEntityIdBuf(fbb, fragment.value)
      SearchV1IntentFragmentBuf.startSearchV1IntentFragmentBuf(fbb)
      SearchV1IntentFragmentBuf.addFolderRef(fbb, value)
      return SearchV1IntentFragmentBuf.endSearchV1IntentFragmentBuf(fbb)
    }
    case "userRef": {
      const value = createEntityIdBuf(fbb, fragment.value)
      SearchV1IntentFragmentBuf.startSearchV1IntentFragmentBuf(fbb)
      SearchV1IntentFragmentBuf.addUserRef(fbb, value)
      return SearchV1IntentFragmentBuf.endSearchV1IntentFragmentBuf(fbb)
    }
    case "imageRef": {
      const uri = fbb.createString(fragment.value.uri)
      SearchV1IntentImageBuf.startSearchV1IntentImageBuf(fbb)
      SearchV1IntentImageBuf.addUri(fbb, uri)
      if (fragment.value.fileId)
        SearchV1IntentImageBuf.addFileId(fbb, createEntityIdBuf(fbb, fragment.value.fileId))
      const value = SearchV1IntentImageBuf.endSearchV1IntentImageBuf(fbb)
      SearchV1IntentFragmentBuf.startSearchV1IntentFragmentBuf(fbb)
      SearchV1IntentFragmentBuf.addImageRef(fbb, value)
      return SearchV1IntentFragmentBuf.endSearchV1IntentFragmentBuf(fbb)
    }
    case "colorRef": {
      const value = SearchV1IntentColorBuf.createSearchV1IntentColorBuf(
        fbb,
        fragment.value.r,
        fragment.value.g,
        fragment.value.b,
        fragment.value.a,
      )
      SearchV1IntentFragmentBuf.startSearchV1IntentFragmentBuf(fbb)
      SearchV1IntentFragmentBuf.addColorRef(fbb, value)
      return SearchV1IntentFragmentBuf.endSearchV1IntentFragmentBuf(fbb)
    }
    case "tagRef": {
      const value = fbb.createString(fragment.value)
      SearchV1IntentFragmentBuf.startSearchV1IntentFragmentBuf(fbb)
      SearchV1IntentFragmentBuf.addTagRef(fbb, value)
      return SearchV1IntentFragmentBuf.endSearchV1IntentFragmentBuf(fbb)
    }
    case "propertyRef": {
      const property = fbb.createString(fragment.value.property)
      let greaterThan, lessThan, contains, matches, notContains, startsWith
      if (fragment.value.greaterThan)
        greaterThan = fbb.createString(fragment.value.greaterThan)
      if (fragment.value.lessThan)
        lessThan = fbb.createString(fragment.value.lessThan)
      if (fragment.value.contains)
        contains = fbb.createString(fragment.value.contains)
      if (fragment.value.matches)
        matches = fbb.createString(fragment.value.matches)
      if (fragment.value.notContains)
        notContains = fbb.createString(fragment.value.notContains)
      if (fragment.value.startsWith)
        startsWith = fbb.createString(fragment.value.startsWith)

      SearchV1IntentPropertyBuf.startSearchV1IntentPropertyBuf(fbb)
      SearchV1IntentPropertyBuf.addProperty(fbb, property)
      if (greaterThan)
        SearchV1IntentPropertyBuf.addGreaterThan(fbb, greaterThan)
      if (lessThan)
        SearchV1IntentPropertyBuf.addLessThan(fbb, lessThan)
      if (contains)
        SearchV1IntentPropertyBuf.addContains(fbb, contains)
      if (matches)
        SearchV1IntentPropertyBuf.addMatches(fbb, matches)
      if (notContains)
        SearchV1IntentPropertyBuf.addNotContains(fbb, notContains)
      if (startsWith)
        SearchV1IntentPropertyBuf.addStartsWith(fbb, startsWith)
      return SearchV1IntentPropertyBuf.endSearchV1IntentPropertyBuf(fbb)
    }
  }
}

export function parseSearchResult(buf: SearchResultBuf): SearchResult {
  return {
    fileId: parseFileId(buf.fileId()!),
    score: buf.score(),
  }
}
