// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

export class NotificationMessageIdBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):NotificationMessageIdBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

id(index: number):number|null {
    return this.bb!.readUint8(this.bb_pos + 0 + index);
}

static sizeOf():number {
  return 16;
}

static createNotificationMessageIdBuf(builder:flatbuffers.Builder, id: number[]|null):flatbuffers.Offset {
  builder.prep(1, 16);

  for (let i = 15; i >= 0; --i) {
    builder.writeInt8((id?.[i] ?? 0));

  }

  return builder.offset();
}

}
