// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

export enum PropertyColor {
  Default = 0,
  Amber = 1,
  Green = 2,
  Teal = 3,
  Blue = 4,
  Purple = 5,
  Pink = 6,
  Red = 7,
  Steel = 8,
  Violet = 9
}
