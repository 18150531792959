// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { RequestIdBuf } from '../request/request-id-buf.js';
import { SharedFolderBuf } from '../shared-folder/shared-folder-buf.js';


export class SharedFolderCreateRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):SharedFolderCreateRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsSharedFolderCreateRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderCreateRequestDatagramBuf):SharedFolderCreateRequestDatagramBuf {
  return (obj || new SharedFolderCreateRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsSharedFolderCreateRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:SharedFolderCreateRequestDatagramBuf):SharedFolderCreateRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new SharedFolderCreateRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

sharedFolder(obj?:SharedFolderBuf):SharedFolderBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new SharedFolderBuf()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
}

static startSharedFolderCreateRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addSharedFolder(builder:flatbuffers.Builder, sharedFolderOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, sharedFolderOffset, 0);
}

static endSharedFolderCreateRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // shared_folder
  return offset;
}

}
