import { Gesture } from "./BaseGesture"

/**
 * Orchestrates dragging a file on top of the search bar to initiate a search.
 * This requires we have a move handler as well as a drop handler
 */
export default function () {
  const { searchButton } = useGlobals()
  const { addFilesToSearchQuery, openSearchBar } = useSearch()
  const { selectedFiles } = useSelection()
  const { gestureState, gestureLayers } = useGestures()

  class SearchBarFileDragInToSearch extends Gesture {
    layer = gestureLayers.search

    reset() {
      this.switchMode("none")
    }

    // TODO: cursor on search bar for where to drop into?

    async onMouseUp(
      mouse: MouseEvent,
    ) {
      if (!gestureState.value.mouseDown || gestureState.value.gesture !== "drag-file")
        return

      // check whether we moved over the search bar
      if (getFirstMatchingParent(mouse.target, e => e.classList?.contains(searchButton))) {
        this.debug("Item dropped over search button")

        this.capture()
        this.reset()

        addFilesToSearchQuery(selectedFiles())
        openSearchBar()
      }
    }
  }
  return new SearchBarFileDragInToSearch()
}
