import { Gesture } from "./BaseGesture"

export default function () {
  const { isEditing, stopEditing } = useProperty()
  const { getMainViewFileFromEvent, gestureLayers } = useGestures()

  class MainViewBackgroundClickToStopEditing extends Gesture {
    layer = gestureLayers.mainViewBackground

    async onMouseDown(
      mouse: MouseEvent,
    ) {
      if (getMainViewFileFromEvent(mouse))
        return
      if (!isEditing())
        return
      this.debug("Background click stopped property edit")
      stopEditing()
    }
  }
  return new MainViewBackgroundClickToStopEditing()
}
