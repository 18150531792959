import { Gesture } from "./BaseGesture"

export default function () {
  const { searchState } = useSearch()
  const { isEditingTextInput, gestureLayers } = useGestures()

  class SearchBarEscToClose extends Gesture {
    layer = gestureLayers.keyboardShortcut

    async onKeyDown(keyboard: KeyboardEvent) {
      if (keyboard.key !== "Escape")
        return

      // The search bar handles escape presses from inside the text bar
      if (isEditingTextInput())
        return

      if (searchState.value.isEditing) {
        this.debug("Escape pressed, closing search bar")
        keyboard.preventDefault()
        this.capture()
        searchState.value.isEditing = false
      }
    }
  }
  return new SearchBarEscToClose()
}
