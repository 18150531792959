import mitt, { type Handler } from "mitt"

type InterfaceEvent = {
  fileCopyResponse: FileCopyResponseDatagram
  fileCreateResponse: FileCreateResponseDatagram
  fileDeleteResponse: FileDeleteResponseDatagram
  fileMoveResponse: FileMoveResponseDatagram
  filePrepareResponse: FilePrepareResponseDatagram
  filePropertiesResponse: FilePropertiesResponseDatagram
  fileShareResponse: FileShareResponseDatagram
  filesSharedWithSelfResponse: FilesSharedWithSelfResponseDatagram
  fileUpdateResponse: FileUpdateResponseDatagram
  folderListPageResponse: FolderListPageResponseDatagram
  folderSubscribeResponse: FolderSubscribeResponseDatagram
  sharedFolderCreateResponse: SharedFolderCreateResponseDatagram
  sharedFolderPropertiesResponse: SharedFolderPropertiesResponseDatagram
  searchResponse: SearchResponseDatagram
  selfInfoResponse: SelfInfoResponseDatagram
  userFindResponse: UserFindResponseDatagram
  userUpdateResponse: UserUpdateResponseDatagram
  SharedFolderMembershipResponse: SharedFolderMembershipResponseDatagram
  fileFindResponse: FileFindResponseDatagram
  searchFindResponse: SearchFindResponseDatagram
  /**
   * An event that should trigger right after the file system acknowledges the intent to
   * change to a new folder. The path should have the new contents, though the file
   * provided might be a blank file if we are starting a fresh navigation, e.g. page load.
   * It is assumed that no file system operations have been started at all, but the navigation
   * path is assumed to be provided. The File provided as the event input might have a full
   * path and all details if the file was previously loaded, e.g. clicking on a file in the UI.
   */
  afterNavigatedToFolder: InterfaceFolderFile
  /**
   * Expanding a folder is used in column and tree view to denote when a folder is opened but
   * the view is not switched to exclusively that folder. So the folder's contents are
   * shown, but the folder is not made into the main focus.
   */
  afterExpandedFolder: InterfaceFolderFile
  /** This is for when a folder is closed in the tree view */
  afterShrunkFolder: InterfaceFolderFile
  /**
   * An event that triggers right after the file system acknowledges the intent to change to
   * a non-folder file. This is used when, for example, we are navigating to an image preview
   */
  afterNavigatedToNonFolderFile: InterfaceFile
  /**
   * An event that triggers when a user navigates to an existing search. For a new search,
   * that is kicked off by the search bar and doesn't have a materialized search object, so
   * it doesn't follow this callback and instead goes directly to the onSearchResponse
   */
  afterNavigatedToExistingSearch: InterfaceSearch
}
const emitter = mitt<InterfaceEvent>()
const console = useLogger("use-events", theme.colors.amber.hex)

function addInterfaceEventListener<Key extends keyof InterfaceEvent>(
  event: Key,
  handler: Handler<InterfaceEvent[Key]>,
) {
  // console.debug("Adding File System Event Listener:", event)
  emitter.on<Key>(event, handler)
}

function removeInterfaceEventListener<Key extends keyof InterfaceEvent>(
  event: Key,
  handler: Handler<InterfaceEvent[Key]>,
) {
  // console.debug("Removing File System Event Listener:", event)
  emitter.off<Key>(event, handler)
}

function emitInterfaceEvent<Key extends keyof InterfaceEvent>(
  event: Key,
  datagram: InterfaceEvent[Key],
) {
  console.info("Emitting File System Event :", event, datagram)
  emitter.emit<Key>(event, datagram)
}

export default function () {
  return {
    addInterfaceEventListener,
    removeInterfaceEventListener,
    emitInterfaceEvent,
  }
}
