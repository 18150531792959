// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { EntityIdBuf } from '../entity/entity-id-buf.js';
import { RequestIdBuf } from '../request/request-id-buf.js';


export class FolderSubscribeRequestDatagramBuf {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):FolderSubscribeRequestDatagramBuf {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsFolderSubscribeRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FolderSubscribeRequestDatagramBuf):FolderSubscribeRequestDatagramBuf {
  return (obj || new FolderSubscribeRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsFolderSubscribeRequestDatagramBuf(bb:flatbuffers.ByteBuffer, obj?:FolderSubscribeRequestDatagramBuf):FolderSubscribeRequestDatagramBuf {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new FolderSubscribeRequestDatagramBuf()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

requestId(obj?:RequestIdBuf):RequestIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? (obj || new RequestIdBuf()).__init(this.bb_pos + offset, this.bb!) : null;
}

folderIds(index: number, obj?:EntityIdBuf):EntityIdBuf|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new EntityIdBuf()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 20, this.bb!) : null;
}

folderIdsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startFolderSubscribeRequestDatagramBuf(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addRequestId(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset) {
  builder.addFieldStruct(0, requestIdOffset, 0);
}

static addFolderIds(builder:flatbuffers.Builder, folderIdsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, folderIdsOffset, 0);
}

static startFolderIdsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(20, numElems, 4);
}

static endFolderSubscribeRequestDatagramBuf(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  builder.requiredField(offset, 4) // request_id
  builder.requiredField(offset, 6) // folder_ids
  return offset;
}

static createFolderSubscribeRequestDatagramBuf(builder:flatbuffers.Builder, requestIdOffset:flatbuffers.Offset, folderIdsOffset:flatbuffers.Offset):flatbuffers.Offset {
  FolderSubscribeRequestDatagramBuf.startFolderSubscribeRequestDatagramBuf(builder);
  FolderSubscribeRequestDatagramBuf.addRequestId(builder, requestIdOffset);
  FolderSubscribeRequestDatagramBuf.addFolderIds(builder, folderIdsOffset);
  return FolderSubscribeRequestDatagramBuf.endFolderSubscribeRequestDatagramBuf(builder);
}
}
