import type { Editor } from "@tiptap/vue-3"
import { Property } from "~/classes/entity"
import type { InterfaceFile } from "~/classes/file"
import { sendSearchRequest } from "~/classes/handlers/search/request"
import { InterfaceSearch } from "~/classes/search"
import { FileCompletion } from "~/classes/search/FileCompletion"
import { PathCompletion } from "~/classes/search/PathCompletion"
import { PropertyCompletion } from "~/classes/search/PropertyCompletion"
import { SearchCompletion } from "~/classes/search/SearchCompletion"
import { TagCompletion } from "~/classes/search/TagCompletion"
import type { SearchV1IntentPropertyName } from "~/models/polyschema/SearchV1IntentPropertyName"
// TODO:
// - on click outside of an ongoing completion

interface State {
  /** The query inside of the search bar while editing */
  intent: TiptapJsonSearchV1Intent
  /** The mode for our search helper dropdown */
  mode: SearchEditorMode
  completion: {
    content: string | null // we need null to track vue props
  }
  suggestions: {
    selection?: number
    navigation?: boolean
    tags?: string[]
    files?: InterfaceFile[]
    searches?: InterfaceSearch[]
    properties?: {
      type?: SearchV1IntentPropertyName
      suggestions: string[]
    }
    currentFile?: InterfaceFile | "Shared"
  }
  editor?: Editor
  isEditing: boolean
  isLoading: boolean

  latestRequestId?: string
}
let searchState: Ref<State>

const console = useLogger("use-search", theme.colors.pink.hex)

function defaultEditorContentJson(): TiptapJsonSearchV1Intent {
  return { type: "doc", content: [{ type: "paragraph", content: [] }] }
}

/** Opens the search bar, optionally placing the intent inside */
function openSearchBar(intent?: SearchV1Intent) {
  const { capture } = useAnalytics()
  if (intent) {
    searchState.value.intent = defaultEditorContentJson()
    addContentToSearchQuery(intent)
  }
  searchState.value.isEditing = true
  console.log("Opened search with content:", searchState.value.intent)
  capture("user_opened_search_bar")
}

function closeSearchBar() {
  searchState.value.isEditing = false
  searchState.value.isLoading = false
}

function clearSearchEditor() {
  searchState.value.intent = defaultEditorContentJson()
  searchState.value.suggestions = {}
}

/**
 * TODO: should we be storing the in-progress query somewhere, then navigating to
 * that one here? Should we store a hash map of request id to query?
 */
async function navigateToSearchResults() {
  const encodedContent = JSON.stringify(searchState.value.intent)
  navigateTo(encodeURI(`/search/${searchState.value.latestRequestId}?q=${encodedContent}`))
}

function parseEditorContent(editorContent: TiptapJsonSearchV1Intent): SearchV1Intent {
  const { type: dType, content: dContent } = editorContent
  if (dType !== "doc")
    throw new Error("Editor JSON not a doc type")

  const fragments: SearchV1IntentFragment[] = []
  for (const fragment of dContent) {
    const fType = fragment.type
    if (fType === "paragraph") {
      if (!fragment.content)
        continue
      if (fragment.content.length !== 1)
        throw new Error("Paragraph should only have one child")
      const textRef = fragment.content[0].text.trim()
      if (textRef)
        fragments.push({ type: "textRef", value: textRef })
    }
    else if (fType === "folderRef") {
      fragments.push({ type: "folderRef", value: fragment.attrs.fileId })
    }
    else if (fType === "imageRef") {
      fragments.push({
        type: "imageRef",
        value: { fileId: fragment.attrs.fileId, uri: fragment.attrs.uri },
      })
    }
    else if (fType === "tagRef") {
      fragments.push({
        type: "tagRef",
        value: fragment.attrs.value,
      })
    }
    else { throw new Error(`Unknown fragment type: ${fType}`) }
  }

  return { fragments }
}

function asEditorContent(intent: SearchV1Intent): TiptapJsonSearchV1Intent {
  const content: (TiptapJsonSearchFragment | TiptapJsonParagraphFragment)[] = []
  let prior: SearchV1IntentFragment | undefined
  for (const fragment of intent.fragments) {
    if (fragment.type === "textRef") {
      content.push({
        type: "paragraph",
        content: [{ type: "text", text: fragment.value }],
      })
      continue
    }
    // push an "in between" text ref
    if (prior?.type !== "textRef")
      content.push({ type: "paragraph", content: [] })

    if (fragment.type === "imageRef")
      content.push({ type: "imageRef", attrs: fragment.value })
    else if (fragment.type === "folderRef")
      content.push({ type: "folderRef", attrs: { fileId: fragment.value } })
    else if (fragment.type === "tagRef")
      content.push({ type: "tagRef", attrs: { value: fragment.value } })
    else
      console.error("Unknown fragment type", fragment)
    prior = fragment
  }
  return { type: "doc", content }
}

function addContentToSearchQuery(intent: SearchV1Intent) {
  searchState.value.intent.content = [
    ...(searchState.value.intent.content || []),
    ...asEditorContent(intent).content,
  ]
  if (last(searchState.value.intent.content).type !== "paragraph")
    searchState.value.intent.content.push({
      type: "paragraph",
      content: [{ type: "text", text: " " }],
    })
}

function addFilesToSearchQuery(files: InterfaceFile[]) {
  const fragments: (TiptapJsonSearchFragment | TiptapJsonParagraphFragment)[] = []
  for (const file of files)
    if (file.isFolder())
      fragments.push({ type: "folderRef", attrs: { fileId: file.fileId, name: file.name() } })
    else if (file.isImage())
      fragments.push({ type: "imageRef", attrs: { fileId: file.fileId, uri: file.icon64CropUri()! } })
    else
      console.error("Cannot add file to search", file)
  fragments.push({ type: "paragraph", content: [{ type: "text", text: " " }] })
  searchState.value.intent.content = [...searchState.value.intent.content, ...fragments]
}

function uploadExternalImageIntoSearch(transfer: DataTransfer) {
  const items = [...zip([...transfer.items as any], [...transfer.files as any])]
  for (const [item, fileItem] of items) {
    const file = item.getAsFile() || fileItem
    if (file) {
      console.log("Found file to upload", file)
    }
    console.error("Unknown data transfer, skipping", item)
  }
}

/**
 * Sends a search request to the server using the user's search intent.
 * @param search the tiptap search intent representation of the user request
 * @param requestId an optional request id to track the search
 */
async function onSearch(search: TiptapJsonSearchV1Intent, requestId?: string) {
  const { capture } = useAnalytics()
  const { myself } = useUser()
  const { sharedFolderState } = useSharedFolders()

  if (!myself.value.home)
    throw new Error("Not logged in")

  let intent
  try { intent = parseEditorContent(search) }
  catch (error) { return console.error("Empty or un-parseable search", error) }
  if (intent.fragments.length === 0)
    return console.warn("Search is empty, skipping")

  console.log("Processing search intent", intent)

  // need to create a new search object from this intent
  const iSearch = InterfaceSearch.fake({ intent })

  searchState.value.isLoading = true
  searchState.value.latestRequestId = requestId ?? generateWorkerRequestId()

  // Create the list of folders
  const folders = Object.values(sharedFolderState.value.all).map(f => f.home().fileId)
  folders.push(myself.value.home.fileId)

  sendSearchRequest({
    requestId: searchState.value.latestRequestId,
    search: iSearch._search,
    folders,
    pageSize: 150, // TODO: paginated search results, infinite scroll
  })

  capture("user_made_a_search", { intent, saved: false })
}

/**
 * Executes a saved search, i.e. a search that has already been done before where the
 * resulting query object is saved on the server. In this case, we simply need to know the
 * searchId.
 *
 * @param searchId the id of the search object
 */
async function onSavedSearch(searchId: string, requestId?: string) {
  const { capture } = useAnalytics()
  const { sharedFolderState } = useSharedFolders()
  const { myself } = useUser()

  if (!myself.value.home)
    throw new Error("Not logged in")

  const iSearch = InterfaceSearch.blank(searchId)

  searchState.value.isLoading = true
  searchState.value.latestRequestId = requestId ?? generateWorkerRequestId()

  // TODO: should saved searches have a folder specification?
  //       For now, let's just create the list of folders
  const folders = Object.values(sharedFolderState.value.all).map(f => f.home().fileId)
  folders.push(myself.value.home.fileId)

  sendSearchRequest({
    requestId: searchState.value.latestRequestId,
    search: iSearch._search,
    pageSize: 100,
    folders,
  })
  capture("user_made_a_search", { saved: true })
}

function showSearchModeWithDefaultSuggestions() {
  searchState.value.mode = "search"
  // TODO
}

function isEditorEmpty(editor: Editor) {
  return editor.$doc.textContent === ""
}

function isInsideCompletion(editor: Editor) {
  return FileCompletion.containsCursor(editor)
    || PathCompletion.containsCursor(editor)
    || TagCompletion.containsCursor(editor)
    || PropertyCompletion.containsCursor(editor)
}

function getCurrentNodeText(editor: Editor) {
  return editor.state.selection.$from.node().textContent
}

async function onFileCompletionResponse(response: FileFindResponseDatagram) {
  // TODO: keep the selection position if the results come from the same query,
  //       otherwise reset to zero
  // TODO: check for request id, etc.
  console.log("Received file completion response", response)
  if (!response.files)
    return console.warn("Completion response errored", response.error)
  if (searchState.value.mode === "file")
    return FileCompletion.onUpdateResponse(response)
  if (searchState.value.mode === "path")
    return PathCompletion.onUpdateResponse(response)
  if (searchState.value.mode === "tag")
    return TagCompletion.onUpdateResponse(response)
  if (searchState.value.mode === "property")
    return PropertyCompletion.onUpdateResponse(response)
  console.error("Unhandled completion response")
}

async function onSearchCompletionResponse(response: SearchFindResponseDatagram) {
  // TODO: keep the selection position if the results come from the same query,
  //       otherwise reset to zero
  // TODO: check for request id, etc.
  console.log("Received search completion response", response)
  if (!response.searches)
    return console.warn("Completion response errored", response.error)
  if (searchState.value.mode === "search")
    return SearchCompletion.onUpdateResponse(response)
  console.error("Unhandled completion response")
}

function hasCompletions() {
  return searchState.value.suggestions.tags || searchState.value.suggestions.files || searchState.value.suggestions.searches
}

/** Moves the selection cursor for your search suggestions up or down by the amount */
function moveSelectionCursor(by: number) {
  let numItems
  if (searchState.value.suggestions.tags !== undefined)
    numItems = searchState.value.suggestions.tags.length
  else if (searchState.value.suggestions.files !== undefined)
    numItems = searchState.value.suggestions.files.length
  else
    throw new Error("No suggestions to select within")
  searchState.value.suggestions.selection ??= 0
  searchState.value.suggestions.selection += by
  searchState.value.suggestions.selection %= numItems
}

/** Tiptap editor keydown observer, returns true if we want to prevent bubbling */
function onKeyDown(editor: Editor, event: KeyboardEvent) {
  if (event.key === "/") {
    if (isInsideCompletion(editor))
      return false
    if (isEditorEmpty(editor)) {
      // create a path completion
      console.log("Initiating path completion")
      searchState.value.mode = "path"
      searchState.value.suggestions = {}
      return PathCompletion.createAtCursor(editor)
    }
    console.log("Initiating file completion")
    searchState.value.mode = "file"
    searchState.value.suggestions = {}
    return FileCompletion.createAtCursor(editor)
  }
  if (event.key === "#") {
    if (isInsideCompletion(editor))
      return false
    console.log("Initiating tag completion")
    searchState.value.mode = "tag"
    searchState.value.suggestions = {}
    return TagCompletion.createAtCursor(editor)
  }
  if (event.key === "+") {
    if (isInsideCompletion(editor))
      return false
    console.log("Initiating property completion")
    searchState.value.mode = "property"
    searchState.value.suggestions = {}
    return PropertyCompletion.createAtCursor(editor)
  }
  if (event.key === "ArrowDown") {
    if (!hasCompletions())
      return console.warn("No selection to navigate, skipping key handler")
    moveSelectionCursor(1)
    return true
  }
  if (event.key === "ArrowUp") {
    if (!hasCompletions())
      return console.warn("No selection to navigate, skipping key handler")
    moveSelectionCursor(-1)
    return true
  }
  // The editor receives the escape event first, so skip if showing a
  // suggestion, and the suggestion will handle this event itself
  if (event.key === "Escape") {
    searchState.value.isEditing = false
    return true
  }
  if (event.key === "Enter") {
    if (FileCompletion.containsCursor(editor)) {
      console.log("Accepting completion suggestion")
      FileCompletion.acceptSuggestion()
      return true
    }
    if (PathCompletion.containsCursor(editor)) {
      // TODO: navigate to the file/folder
      return true
    }
    if (TagCompletion.containsCursor(editor)) {
      console.log("Accepting tag completion suggestion")
      TagCompletion.acceptSuggestion(editor)
      return true
    }

    console.log("Executing search")
    onSearch(editor.getJSON() as any)
    return true
  }
  if (event.key === "Tab") {
    if (FileCompletion.containsCursor(editor)) {
      console.log("Accepting completion suggestion")
      FileCompletion.acceptSuggestion()
      return true
    }
  }

  return false
}

function onEditorUpdated(editor: Editor) {
  // Update our intent value to the current json
  searchState.value.intent = editor.getJSON() as any

  // now we want to update any suggestions or completions. We just update our internal
  // state so that we can set up a debounced watcher for this value
  if (FileCompletion.containsCursor(editor)) {
    const content = getCurrentNodeText(editor)
    if (content === searchState.value.completion.content)
      return console.warn("Completion text unchanged")
    searchState.value.completion.content = content
    FileCompletion.onUpdated(searchState.value.completion.content)
  }
  else if (PathCompletion.containsCursor(editor)) {
    const content = getCurrentNodeText(editor)
    if (content === searchState.value.completion.content)
      return console.warn("Completion text unchanged")
    searchState.value.completion.content = content
    PathCompletion.onUpdated(searchState.value.completion.content)
  }
  else if (TagCompletion.containsCursor(editor)) {
    const content = getCurrentNodeText(editor)
    if (content === searchState.value.completion.content)
      return console.warn("Completion text unchanged")
    searchState.value.completion.content = content
    TagCompletion.onUpdated(searchState.value.completion.content)
  }
  else if (PropertyCompletion.containsCursor(editor)) {
    const content = getCurrentNodeText(editor)
    if (content === searchState.value.completion.content)
      return console.warn("Completion text unchanged")
    searchState.value.completion.content = content
    PropertyCompletion.onUpdated(searchState.value.completion.content)
  }
  else {
    searchState.value.mode = "search"
    const content = getCurrentNodeText(editor)
    if (content === searchState.value.completion.content)
      return console.warn("Completion text unchanged")
    searchState.value.completion.content = content
    SearchCompletion.onUpdated(searchState.value.completion.content)
    // we're not in a completion, revert to search mode
  }
}

/** Accepts the current suggestion. If index is not specified, uses keyboard selection */
function acceptSearchSuggestion(mode: SearchEditorMode, index?: number) {
  const editor = searchState.value.editor
  if (!editor)
    throw new Error("No editor")
  index = index ?? searchState.value.suggestions.selection
  if (index === undefined)
    throw new Error("No selection index")
  switch (mode) {
    case "file": return FileCompletion.acceptSuggestion(index)
    case "path": return PathCompletion.acceptSuggestion(index)
    case "tag": return TagCompletion.acceptSuggestion(editor, index)
  }
  throw new Error(`Unknown suggestion mode: ${mode}`)
}

/**
 * If you are currently in a selection, will update the text of that suggestion to the
 * given text, usually used as part of an overall state update that includes changing the
 * value of the suggestion dropdown
 */
function _setCurrentSuggestionText(_text: string) {
  // TODO
}

export default function () {
  searchState = useState("search-state", () => ({
    intent: defaultEditorContentJson(),
    mode: "search",
    completion: { content: null },
    suggestions: {},
    isLoading: false,
    isEditing: false,
  } as State))

  return {
    searchState,
    openSearchBar,
    addFilesToSearchQuery,
    closeSearchBar,
    clearSearchEditor,
    onSearch,
    onSavedSearch,
    navigateToSearchResults,
    showSearchModeWithDefaultSuggestions,
    uploadExternalImageIntoSearch,
    moveSelectionCursor,
    onKeyDown,
    onEditorUpdated,
    onFileCompletionResponse,
    onSearchCompletionResponse,
    acceptSearchSuggestion,
  }
}
